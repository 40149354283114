import React, { useEffect, useState } from 'react';
import Footer from '../../layouts/Footer';
import Navbar from '../../layouts/Navbar';
import { Link, useParams } from 'react-router-dom';
import { PaymentConfirm } from '../../API/PricingApi'

export default function Cancel() {
  const params = useParams();
  const slug = params.slug;
  const Token = localStorage.getItem("token");
  const [paymentData, setPaymentData] = useState('')
  useEffect(()=>{
      let data ={
          m_payment_id:slug,
      }
      PaymentConfirm(Token, data).then((res)=>{
          // console.log(res);
          setPaymentData(res?.data?.data);
      });
  }, []);

  return (
    <>
      <Navbar />
      <div class="main py-5">
        <div class="container ">
        {paymentData?.payment_status === "cancel" ?
            <h3>Your payment was cancelled</h3>
            :
            <h3>Your payment was cancelled</h3>
        }
        <br /> <br /> <br />
        <Link to="/dashboard" className='btn btn-success'>
            Return to dashboard
        </Link>
         
        </div>
      </div>
      <Footer />
    </>
  )
}
