import React, { useState, useEffect } from "react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import CurrencyFormat from "react-currency-format";
import ApplyForHomeLoanBtn from "../shared/ApplyForHomeLoanBtn";
import { formatter } from "../../utils";

export default function RepaymentCalculator() {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [rate, setRate] = useState("11.75");
  const [year, setYear] = useState("");
  const [deposit, setDeposit] = useState("");
  const [monthlyRepayments, setMonthlyRepayments] = useState(0);
  const [amountRepayable, setAmountRepayable] = useState(0);

  const [purchasePriceError, setPurchasePriceError] = useState("");
  const [rateError, setRateError] = useState("11.75");
  const [yearError, setYearError] = useState("");
  const [loading, setLoading] = useState("");
  const [resultLoading, setResultLoading] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [scrollTop, setScrollTop] = useState(true);
  useEffect(function () {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    setScrollTop(false);
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setResultLoading(true);
    if (!purchasePrice) {
      setPurchasePriceError("Please enter purchase price");
      setLoading(false);
      setResultLoading(false);
    }
    if (!rate) {
      setRateError("Please enter interest rate");
      setLoading(false);
      setResultLoading(false);
    }
    if (!year) {
      setYearError("Please select years to repay");
      setLoading(false);
      setResultLoading(false);
    }

    if (purchasePrice && rate && year) {
      const newRate = rate / 100;
      if (deposit !== null) {
        const NewpurchasePrice = purchasePrice - deposit;
        const pmt = PMT(newRate / 12, year * 12, NewpurchasePrice);
        const newMonthlyRepayments = Math.round(pmt);
        setMonthlyRepayments(newMonthlyRepayments);
        const newAmountRepayments = Math.round(pmt * year * 12);
        setAmountRepayable(newAmountRepayments);
      } else {
        const pmt = PMT(newRate / 12, year * 12, purchasePrice);
        const newMonthlyRepayments = Math.round(pmt);
        setMonthlyRepayments(newMonthlyRepayments);
        const newAmountRepayments = Math.round(pmt * year * 12);
        setAmountRepayable(newAmountRepayments);
      }
      setLoading(false);
      setIsCalculated(true);
      setResultLoading(false);
    }
  };
  const PMT = (ir, np, pv, fv = 0) => {
    var presentValueInterstFector = Math.pow(1 + ir, np);
    var pmt =
      (ir * pv * (presentValueInterstFector + fv)) /
      (presentValueInterstFector - 1);
    return pmt;
  };

  return (
    <>
      <Navbar />
      <div class="main py-5">
        <div class="container ">
          <h1 class="text-primary semibold cal-title text-center pb-5">
            Repayment Calculator
          </h1>
          <div class="row">
            <div class="col-md-6 pb-5">
              <form onSubmit={handleSubmit} class="calculator-form">
                <div class="row">
                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Purchase price (including VAT)*
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={purchasePrice}
                      thousandSeparator={true}
                      prefix={"R "}
                      required
                      placeholder="Purchase price (including VAT)*"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setPurchasePrice(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-6">
                    <label class="d-block medium fs-15">Years to repay*</label>
                    <select
                      required
                      value={year}
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                    >
                      <option value="">-- Please select --</option>
                      {Array.from({ length: 30 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div class="form-field col-md-6">
                    <label class="d-block medium fs-15">Interest Rate*</label>
                    <input
                      type="text"
                      class="w-100"
                      placeholder="Interest Rate*"
                      value={rate}
                      required
                      onChange={(e) => {
                        setRate(e.target.value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">Deposit</label>

                    <CurrencyFormat
                      className="w-100"
                      value={deposit}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Deposit"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setDeposit(value);
                      }}
                    />
                  </div>
                  <div class="form-field col-md-12">
                    {purchasePrice && rate && year ? (
                      loading ? (
                        <button
                          type="submit"
                          className="btn-sm"
                          disabled={loading ? true : false}
                        >
                          <span className="spinner-grow spinner-grow-sm "></span>
                        </button>
                      ) : (
                        <button type="submit" class="custom-btn">
                          calculate
                        </button>
                      )
                    ) : (
                      <button type="submit" class="custom-btn" disabled>
                        calculate
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div class="col-md-6 px-md-4">
              {resultLoading ? (
                <div class="cal-right-box bg-white py-5 px-4">
                  <span className="spinner-grow spinner-grow-sm "></span>
                </div>
              ) : (
                <div class="cal-right-box bg-white py-5 px-4">
                  <h2 class="semibold pb-4">Your monthly repayments</h2>
                  <h3 class="text-primary semibold pb-5 ">
                    {formatter.format(monthlyRepayments)}
                  </h3>

                  <h2 class="semibold pb-4">Total amount repayable</h2>
                  <h3 class="text-primary semibold pb-5 ">
                    {formatter.format(amountRepayable)}
                  </h3>
                </div>
              )}
              {isCalculated && <ApplyForHomeLoanBtn />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
