export const SalePriceOption = [
    { start: 100000, end:500000, label:'R 100 000 - R 500 000'},


    { start: 500000, end:1000000, label:'R 500 000 - R 1 000 000'},
    // { start: 250000, end:350000, label:'R 250 000 - R 350 000'},
    // { start: 350000, end:500000, label:'R 350 000 - R 500 000'},
    // { start: 500000, end:600000, label:'R 500 000 - R 600 000'},
    // { start: 600000, end:700000, label:'R 600 000 - R 700 000'},
    // { start: 700000, end:800000, label:'R 700 000 - R 800 000'},
    // { start: 800000, end:900000, label:'R 800 000 - R 900 000'},
    // { start: 900000, end:1000000, label:'R 900 000 - R 1000 000'},
    { start: 1000000, end:1500000, label:'R 1000 000 - R 1 500 000'},
    // { start: 1250000, end:1500000, label:'R 1 250 000 - R 1 500 000'},
    { start: 1500000, end:2000000, label:'R 1 500 000 - R 2 000 000'},

    // { start: 1750000, end:2000000, label:'R 1 750 000 - R 2 000 000'},
    { start: 2000000, end:2500000, label:'R 2 000 000 - R 2 500 000'},
    { start: 2500000, end:3000000, label:'R 2 500 000 - R 3 000 000'},
    { start: 3000000, end:3500000, label:'R 3 000 000- R 3 500 000'},
    { start: 3500000, end:4000000, label:'R 3 500 000- R 4 000 000'},
    { start: 4000000, end:4500000, label:'R 4 000 000- R 4 500 000'},
    { start: 4500000, end:5000000, label:'R 4 500 000- R 5 000 000'},
    // { start: 5000000, end:6000000, label:'R 5 000 000- R 6 000 000'},
    { start: 5000000, end:5500000, label:'R 5 000 000- R 5 500 000'},
    { start: 5500000, end:6000000, label:'R 5 500 000- R 6 000 000'},
    { start: 6000000, end:6500000, label:'R 6 000 000- R 6 500 000'},
    { start: 6500000, end:7000000, label:'R 6 500 000- R 7 000 000'},
    { start: 7000000, end:7500000, label:'R 7 000 000- R 7 500 000'},
    { start: 7500000, end:8000000, label:'R 7 500 000- R 8 000 000'},
    { start: 8000000, end:8500000, label:'R 8 000 000- R 8 500 000'},
    { start: 8500000, end:9000000, label:'R 8 500 000- R 9 000 000'},
    { start: 9000000, end:10000000, label:'R 9 000 000- R 9 500 000'},
    { start: 9500000, end:10000000, label:'R 9 500 000- R 10 000 000'},
];