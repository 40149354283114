// const base_URL = "https://backend.askproperty.co.za/api";
const base_URL = process.env.REACT_APP_BASE_URL;

export const categoryURLs = {
  Url: base_URL + "/v1/getCategories",
};

export const postURLs = {
  Url: base_URL + "/v1/getCategoryPosts",
  postDetail: base_URL + "/v1/postDetail",
  LikePost: base_URL + "/v1/getAuth3Likes",
  CreatePost: base_URL + "/v1/createPost",
  CreateLikePost: base_URL + "/v1/postLikes",
  SharePost: base_URL + "/v1/postShare",
  SponsoredPost: base_URL + "/v2/getSponsoredPosts",
  SingleSponsoredPost: base_URL + "/v2/getSingleSponsored",
};

export const loginURLs = {
  Url: base_URL + "/auth/login",
};

export const RegisterURLs = {
  Url: base_URL + "/v1/registerIndividuals",
  agencyRegister: base_URL + "/v2/registerAgency",
};

export const ProfileURLs = {
  GetProfile: base_URL + "/v1/userProfileData",
  EditProfile: base_URL + "/v1/updateUserData",
  ChangePassword: base_URL + "/v2/changePassword",
  removeDocument: base_URL + "/v2/removeUserDocument",
  changeIndividualRole: base_URL + "/v2/changeIndividualRole",
  changeRole: base_URL + "/v2/changeUserRole",
  purchaseHistory: base_URL + "/v2/getPricingPurchaseHistory",
  documentName: base_URL + "/v2/updateDocName",
  checkUserLogin: base_URL + "/v2/checkUser",
};

export const referURLs = {
  Url: base_URL + "/v1/referFriend",
};

export const notificationURLs = {
  GetNotification: base_URL + "/v1/getNotifications",
  ReadNotification: base_URL + "/v1/readAllNotification",
};

export const AnswerURLs = {
  AnswerLater: base_URL + "/v1/answerLaterList",
  CreateAnswer: base_URL + "/v1/answerSave",
  CreateAnswerLater: base_URL + "/v1/answerLaterSave",
  CreateReply: base_URL + "/v1/answerReplySave",
  CreateDelete: base_URL + "/v1/answerDelete",
};

export const reportURLs = {
  SaveReport: base_URL + "/v1/savePostReport",
};

export const searchURLs = {
  Search: base_URL + "/v1/searchPost",
};

export const dashboardURLs = {
  AgencyDashboardUrl: base_URL + "/v2/agencyDashboard",
};

export const agentURLs = {
  addagent: base_URL + "/v2/saveAgent",
  GetAgent: base_URL + "/v2/agencyAgentList",
  DeactiveAgent: base_URL + "/v2/activateDeactivateAgent",
  SingalGetAgent: base_URL + "/v2/getAgent",
  ContactAgent: base_URL + "/v2/contactAgent",
  GetContactAgent: base_URL + "/v2/getAgentContacts",
};

export const approverURLs = {
  addApprover: base_URL + "/v2/saveApprover",
  GetApprover: base_URL + "/v2/agencyApproverList",
  DeactiveApprover: base_URL + "/v2/activateDeactivateApprover",
  SingalGetApprover: base_URL + "/v2/getApprover",
};

export const adminURLs = {
  addAdmin: base_URL + "/v2/saveAdmin",
  GetAdmin: base_URL + "/v2/agencyAdminList",
  DeactiveAdmin: base_URL + "/v2/activateDeactivateAdmin",
  SingalGetAdmin: base_URL + "/v2/getAdmin",
};

export const PropertyURLs = {
  GetDisplayProperties: base_URL + "/v2/getDisplayProperties",
  GetProperty: base_URL + "/v2/getAgencyProperties",
  GetMasterData: base_URL + "/v2/masterProperties",
  SaveProperty: base_URL + "/v2/saveProperty",
  PropertyDetail: base_URL + "/v2/getPropertyDetail",
  PropertyApproveReject: base_URL + "/v2/approveProperty",
  PropertyImageDelete: base_URL + "/v2/removePropertyImage",
  PropertyDelete: base_URL + "/v2/propertyDelete",
  GetProperties: base_URL + "/v2/getProperties",
  alertProperties: base_URL + "/v2/userAlertsOnProperty",
  ShowContactNo: base_URL + "/v2/userClickOnShowContactNot",
  RenewProperty: base_URL + "/v2/renewProperty",
};

export const ForgotPasswordURLs = {
  url: base_URL + "/v2/forgetPassword",
};

export const FollowURLs = {
  StartFollow: base_URL + "/v2/followUser",
  getFollow: base_URL + "/v2/getfollowers",
  removeFollow: base_URL + "/v2/removefollower",
  followBackUser: base_URL + "/v2/followBackUser",
  unFollowUser: base_URL + "/v2/unFollowUser",
};

export const messageURLs = {
  CreateMessageRoom: base_URL + "/v2/createMessageRoomId",
  GetMessage: base_URL + "/v2/getMessages",
  SendMessage: base_URL + "/v2/sendMessage",
  GetChatRoom: base_URL + "/v2/getUsersAllChatRoom",
  readMessage: base_URL + "/v2/readChatMessages",
};

export const pricingURLs = {
  GetPricing: base_URL + "/v2/getPricePlans",
};

export const paymentURLs = {
  coupan: base_URL + "/v2/applyCouponCode",
  startPayment: base_URL + "/v2/getPaymentIdentifier",
  paymentConfirm: base_URL + "/v2/getPaymentDetail",
};
