import React, { useState, useEffect } from "react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import CurrencyFormat from "react-currency-format";
import ApplyForHomeLoanBtn from "../shared/ApplyForHomeLoanBtn";
export default function ExtraPaymentsCalculator() {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [rate, setRate] = useState("11.75");
  const [year, setYear] = useState("");
  const [extraPayment, setExtraPayment] = useState("");
  const [overPayment, setOverPayment] = useState("");
  const [recurringPayment, setRecurringPayment] = useState("");
  const [monthlyRepayments, setMonthlyRepayments] = useState(0);
  const [amountRepayable, setAmountRepayable] = useState(0);
  const [newYear, setNewYear] = useState(0);

  const [purchasePriceError, setPurchasePriceError] = useState("");
  const [rateError, setRateError] = useState("11.75");
  const [yearError, setYearError] = useState("");
  const [loading, setLoading] = useState("");
  const [resultLoading, setResultLoading] = useState(false);
  const [scrollTop, setScrollTop] = useState(true);
  const [isCalculated, setIsCalculated] = useState(false);

  useEffect(function () {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    setScrollTop(false);
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setResultLoading(true);
    if (!purchasePrice) {
      setPurchasePriceError("Please enter purchase price");
      setLoading(false);
      setResultLoading(false);
    }
    if (!rate) {
      setRateError("Please enter interest rate");
      setLoading(false);
      setResultLoading(false);
    }
    if (!year) {
      setYearError("Please select years to repay");
      setLoading(false);
      setResultLoading(false);
    }

    if (purchasePrice && rate && year) {
      const newRate = rate / 100;
      const pmt = PMT(newRate / 12, year * 12, purchasePrice);
      const newPmt = parseInt(recurringPayment) + parseInt(extraPayment);
      setMonthlyRepayments(newPmt);
      const nper = NPER(
        rate / 12,
        -newPmt,
        overPayment && overPayment !== ""
          ? purchasePrice - overPayment
          : purchasePrice
      );
      console.log(pmt, nper / 12);
      setNewYear(parseFloat(nper / 12).toFixed(2));
      const newAmountRepayable = Math.round(nper * newPmt);
      setAmountRepayable(newAmountRepayable);
      setLoading(false);
      setResultLoading(false);
      setIsCalculated(true);
    }
  };
  const PMT = (ir, np, pv, fv = 0) => {
    var presentValueInterstFector = Math.pow(1 + ir, np);
    var pmt =
      (ir * pv * (presentValueInterstFector + fv)) /
      (presentValueInterstFector - 1);
    return pmt;
  };

  const NPER = (ir, pmt, pv, fv = 0) => {
    var nbperiods;
    if (ir != 0) ir = ir / 100;
    nbperiods = Math.log((-fv * ir + pmt) / (pmt + ir * pv)) / Math.log(1 + ir);

    return nbperiods;
  };

  const formatter = new Intl.NumberFormat(
    "Af-ZA" /
      {
        style: "currency",
        currency: "ZAR",
      }
  );
  return (
    <>
      <Navbar />
      <div class="main py-5">
        <div class="container ">
          <h1 class="text-primary semibold cal-title text-center pb-5">
            Extra Payments Calculator
          </h1>

          <div class="row">
            <div class="col-md-6 pb-5">
              <form onSubmit={handleSubmit} class="calculator-form">
                <div class="row">
                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Current bond debt*
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={purchasePrice}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Current bond debt*"
                      required
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setPurchasePrice(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-6">
                    <label class="d-block medium fs-15">Years to repay*</label>
                    <select
                      required
                      value={year}
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                    >
                      <option value="">-- Please select --</option>
                      {Array.from({ length: 30 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div class="form-field col-md-6">
                    <label class="d-block medium fs-15">Interest Rate*</label>
                    <input
                      type="text"
                      class="w-100"
                      placeholder="Interest Rate*"
                      value={rate}
                      required
                      onChange={(e) => {
                        setRate(e.target.value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Monthly repayment*
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={extraPayment}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Monthly repayment*"
                      required
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setExtraPayment(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Once-off overpayment{" "}
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={overPayment}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Once-off overpayment"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setOverPayment(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Recurring monthly payment
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={recurringPayment}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Recurring monthly payment"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setRecurringPayment(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <button disabled={loading} type="submit" class="custom-btn">
                      calculate
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-md-6 px-md-4">
              <div class="cal-right-box bg-white py-5 px-4">
                <h2 class="semibold pb-4">Total loan amount</h2>
                <h3 class="text-primary semibold pb-5 ">
                  {formatter.format(amountRepayable)}
                </h3>

                <h2 class="semibold pb-4">Total monthly repayment amount</h2>
                <h3 class="text-primary semibold pb-5 ">
                  {formatter.format(monthlyRepayments)}
                </h3>

                <h2 class="semibold pb-4">
                  Total loan term reduced to (years)
                </h2>
                <h3 class="text-primary semibold pb-5 ">R {newYear}</h3>

                {/* <h2 class="semibold pb-4">Total loan amount reduced by</h2>
                                <h3 class="text-primary semibold pb-2 ">R 0</h3> */}
              </div>
              {isCalculated && <ApplyForHomeLoanBtn />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
