import React, { useState, useEffect } from 'react'
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar'
import { GetCategory } from "../API/CategoryApi";
import { Link, useHistory } from "react-router-dom";
import { CreatePost } from "../API/PostApi";
import swal from "sweetalert";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function AddPost() {
    const [category, setCategory] = useState([])
    const [question, setQuestion] = useState("")
    const [File, setFile] = useState("")
    const [categoryId, setCategoryId] = useState("")
    const [questionError, setQuestionError] = useState("")
    const [categoryError, setCategoryError] = useState("")
    const [loading, setLoading] = useState("")
    const history = useHistory();
    const Token = localStorage.getItem("token");

    if(Token === null){
        history.push("login");
    }
    useEffect(
        function () {
            let mounted = true;
            GetCategory().then((res) => {
                if (mounted) {
                    setCategory(res.data.data);
                }
            });
            return () => {
                mounted = false;
            };
        }, []);

    const handlefile = (e) => {
        setFile({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (!question) {
            setQuestionError("Please Enter Your Question");
            setLoading(false);
        }

        if (!categoryId) {
            setCategoryError("Please Select Category");
            setLoading(false);
        }

        if (question && categoryId) {
            const formData = new FormData();
            formData.append("category_id", categoryId);
            formData.append("question", question);
            if (File) {
                formData.append("file", File.fileBytes);
            }
            const token = localStorage.getItem("token");
            CreatePost(token, formData)
                .then((res) => {
                    if(res.data.status === "success"){
                        setLoading(false);
                    swal({
                        text: "A moderator will review your post and your post will be visible shortly",
                        icon: "success",
                        buttons: true,
                        dangerMode: true,
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            history.push("/");
                        }
                    });
                 } if(res.status === 201){
                    if(res.data.message.file){
                    swal("Error", res.data.message.file[0], "error");
                    setLoading(false);
                    }else{
                        setLoading(false);
                        // swal("Error!", e.response.data.message, "error");
                        swal("Error", "somethin went wrong", "error");  
                    }
                }
                })
               .catch((e) => {
                    setLoading(false);
                    if (e && e.response && e.response.data &&
                        e.response.data.message) {
                        swal("Error!", e.response.data.message, "error");
                    }
                    // swal("Error!", "Somethink went wrong", "error");
                });
        }

    };

    const handleChangeDescription = (e) => {
      
        setQuestion(e)
    };

    return (
        <>
            <Navbar />
            <div className="main py-5">
                <div className="container ">

                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="notification-list">
                                <div className="pb-2">
                                    {/* <h4 className="h4 bold">Ask A Question </h4> */}
                                    <h4 className="h4 bold">Ask A Question or write a post</h4>

                                </div>
                                <form action="" className="add-post-form" onSubmit={handleSubmit}>
                                    <div className="p-3 bg-white border rounded">
                                        <div className="form-field ">
                                        {/* <label for="" className="bold d-block text-gray pb-2">Ask Question</label> */}
                                            <label for="" className="bold d-block text-gray pb-2">Ask Question / write a post</label>
                                            {/* <textarea name=""
                                                id=""
                                                cols="30"
                                                rows="10"
                                                className="w-100"
                                                value={question}
                                            
                                                onChange={(e) => { setQuestion(e.target.value) }}
                                            ></textarea> */}
                                            <CKEditor
                                                data={question}
                                                className="editor"
                                                
                                                cols="30"
                                                rows="10"
                                                onChange={ ( event, editor ) =>{
                                                    handleChangeDescription(editor.getData())
                                                } }
                                                editor={ ClassicEditor }
                                                config={{         
                                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                                    }}
                                            />

                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                {questionError}
                                            </p>
                                        </div>

                                        <div className="form-field py-4 post-upload row">
                                            <div className="col-md-6  mt-4 ">
                                                <input type="file"
                                                    id="add-post-file"
                                                    accept="image/*"
                                                    onChange={(e) => handlefile(e)}
                                                />
                                                <label for="add-post-file">
                                                    <img src="assets/image/add-post.png" className="pe-3 align-middle" alt="" />
                                                    Add Picture</label>
                                            </div>
                                            <div className="col-md-6  mt-4 ">
                                                {File.fileUrl ? (
                                                    <img
                                                        src={File.fileUrl}
                                                        height="170px"
                                                        width="100%"
                                                        className="mt-2"
                                                        alt=""
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>


                                        <div className="form-field pb-3">
                                            <label for="" className="bold d-block text-gray pb-2">Select Category</label>
                                            <select
                                                name=""
                                                id=""
                                                
                                                value={categoryId}
                                                onChange={(e) => { setCategoryId(e.target.value) }}
                                            >
                                                <option value="">Please Select Category</option>
                                                {category.map((item, i) => {
                                                    return (
                                                        <option value={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </select>
                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                {categoryError}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-center py-4">

                                        <Link to="/" className="border-btn me-2">Cancel</Link>
                                        {loading ?
                                            <button type="submit"  className="custom-btn" disabled={loading ? true : false}>
                                                <span className='spinner-grow spinner-grow-sm '>

                                                </span>
                                            </button>
                                            :
                                        <button type="submit" className="custom-btn">Post</button>
                                        }

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}
