import React, { useState, useEffect, useRef} from 'react';
import { PriceOption } from '../constants/PriceOption';
import { RentPriceOption } from '../constants/RentPriceOption';
import { SalePriceOption } from '../constants/SalePriceOption';
import { Link} from 'react-router-dom';
import swal from 'sweetalert';
import { AlertProperties } from "../API/PropertyApi";
const $ = window.jQuery;

export default function SubscribeModel() {
    const [priceFrom, setPriceFrom] = useState('')
    const [priceTo, setPriceTo] = useState('')
    const [propertyType, setPropertyType] = useState('')
    const [noOfBedrooms, setNoOfBedrooms] = useState(0)
    const [propertyAddress, setPropertyAddress] = useState('')
    const [propertyLocationLat, setPropertyLocationLat] = useState('')
    const [propertyLocationLong, setPropertyLocationLong] = useState('')
    const [propertyAddressError, setPropertyAddressError] = useState('')
    const [priceError, setPriceError] = useState('')
    const [propertyTypeError, setPropertyTypeError] = useState('')
    const autocompleteRef = useRef();
    const inputSaleRef = useRef();
    const Token = localStorage.getItem("token");
    const options = {
        componentRestrictions: { country: "za" },
        types: ['geocode']
    };

    const handleCloseModel = () => {
        // $("#property_popup").hide();
        $('#property_popup').modal('toggle')
    };

    useEffect(()=>{
        if(inputSaleRef.current){
            if (inputSaleRef.current && typeof window.google === "object") {
            const searchBoxSale = new window.google.maps.places.Autocomplete(
                inputSaleRef.current,
                options
            );
            
            window.google.maps.event.addListener(searchBoxSale, 'place_changed', function () {
                var places = searchBoxSale.getPlace();
                if(places){
                    setPropertyAddress(places?.formatted_address);
                    setPropertyLocationLat(places?.geometry?.location?.lat())
                    setPropertyLocationLong(places?.geometry?.location?.lng())
                }
            });
        }
        }
    },[]);

   

    const handlePropertyPrice = (priceFrom, priceTo) => {
        setPriceFrom(priceFrom);
        setPriceTo(priceTo);
    };
    const handleBedrooms = (type) => {
        if (type === "minus") {
            if (noOfBedrooms > 0) {
                setNoOfBedrooms(noOfBedrooms - 1);
            }

        } else {
            setNoOfBedrooms(parseInt(noOfBedrooms) + 1);
        }
    };

    const handleSubmit = () =>{
        if(!propertyAddress){
            setPropertyAddressError("Please select location");
            return false;
        }
        if(!propertyLocationLong){
            setPropertyAddressError("Please select location");
            return false;
        }
        if(!propertyLocationLat){
            setPropertyAddressError("Please select location");
            return false;
        }
        if(!priceFrom){
            setPriceError("Please select price");
            return false;
        }
        if(!propertyType){
            setPropertyTypeError("Please select Property Type");
            return false;
        }

        if(propertyAddress && priceFrom  &&  propertyType){
            let data = {
                address:propertyAddress,
                location_lat:propertyLocationLat,
                location_long:propertyLocationLong,
                price_from:priceFrom,
                price_to:priceTo,
                property_type:propertyType,
                no_of_bedrooms:noOfBedrooms,
            }
            AlertProperties(Token, data).then((res)=>{
                if(res.data.status === "success"){
                    $('#property_popup').modal('toggle')
                    swal("Success", res.data.message, "success");
                }else{
                    swal("Error", res.data.message, "error");
                }
            });
        }

    };

    return (
        <>
            <div className="modal-body">
                <h4 className="h4 bold text-center pb-4">Property alerts</h4>
                <div className="subscribe-popup">
                    <div className="location-field pb-4">
                        <input type="text"
                            ref={inputSaleRef}
                            placeholder="Please Enter Location"
                            className="form-control rounded border-0"
                        />
                        <p style={{ color: "red", margintop: "-10px" }}>
                            {propertyAddressError}
                        </p>

                    </div>

                    <div className="form-field pb-4 type-select">
                        <label for="">Property Type</label>
                        <div className="d-flex">
                            <span className="d-flex align-items-center"><input type="radio" name="type" onClick={() => setPropertyType('sell')} />Sell</span>
                            <span className="d-flex ps-4 align-items-center"><input type="radio" name="type" onClick={() => setPropertyType('rent')} />Rent</span>

                        </div>
                        <p style={{ color: "red", margintop: "-10px" }}>
                            {propertyTypeError}
                        </p>
                    </div>
                    {propertyType === 'sell' ?
                    <div className="form-field pb-4 price-select">
                        <label for="">Price Range</label>
                        <div className="row">

                            {SalePriceOption.map((item) => {
                                return (
                                    <div className="col-md-6">
                                        <small className="d-block"><input type="radio" name="price" onClick={() => handlePropertyPrice(item.start, item.end)} />{item.label}</small>
                                    </div>

                                );

                            })}


                        </div>
                        <p style={{ color: "red", margintop: "-10px" }}>
                            {priceError}
                        </p>
                    </div>
                    :""} 
                    {propertyType === 'rent' ?
                    <div className="form-field pb-4 price-select">
                        <label for="">Price Range</label>
                        <div className="row">

                            {RentPriceOption.map((item) => {
                                return (
                                    <div className="col-md-6">
                                        <small className="d-block"><input type="radio" name="price" onClick={() => handlePropertyPrice(item.start, item.end)} />{item.label}</small>
                                    </div>

                                );

                            })}


                        </div>
                        <p style={{ color: "red", margintop: "-10px" }}>
                            {priceError}
                        </p>
                    </div>
                    :""
                    }   
                    

                    <div className="form-field pb-4">
                        <label for="">No of Bedrooms</label>
                        <span className="bedroom-add">
                            <button className="minus" type="button" onClick={() => handleBedrooms("minus")}>-</button>
                            <input type="text" name="qty" value={noOfBedrooms} className="input-text qty" />
                            <button className="plus" type="button" onClick={() => handleBedrooms("plus")} >+</button>
                        </span>
                    </div>


                </div>


                <div className="form-btn text-center py-4">
                    <Link onClick={handleCloseModel} className="back-btn me-3">Back</Link>
                    <button type="submit" className="custom-btn" onClick={handleSubmit}>Submit</button>

                </div>
            </div>
        </>
    )
}
