import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, THead, TH, TR, TD, TBody } from "../table/index";
import { GetContactAgent } from '../API/AgentApi';
import AgencyNavbar from '../layouts/AgencyNavbar';
import Footer from '../layouts/Footer';
import Navbar from '../layouts/Navbar';
import Moment from 'moment';
const $ = window.jQuery;


export class ContactRequest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allConatctRequestList: [],
      isActive: "",
      loading: false,
    };
  }


  componentDidMount() {
    // $("#datatable").dataTable();
    this.getConatctRequestList();
  }

  getConatctRequestList = () => {
    this.setState({
      loading: true,
    });
    const token = localStorage.getItem("token");
    GetContactAgent(token)
      .then((resp) => {
        this.setState({
          allConatctRequestList: resp.data.data,
          loading: false,
        });
        $("#datatable").dataTable();
      })
      .catch((e) => {
        console.log(e);
      });
  };





  render() {
    return (
      <>

        <Navbar />

        {this.state.loading ?
          <div className="main py-5">
            <center>
              <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

              </span>
            </center>
          </div>
          :
          <div className="main py-5">
            <div className="container ">

              <div className="row pb-md-5">

                <AgencyNavbar />

                <div className="col-md-9 ps-md-5 border-start admin-right-part">

                  <div className="d-flex align-items-center justify-content-between pb-4">
                    <h2 className="text-primary semibold h1 pb-3"> Inquiries
                    </h2>

                  </div>

                  <div className="datatable">
                    <Table className="table-responsive table-sm" id="datatable">
                      <THead>
                        <TR>
                          <TH>S.No.</TH>
                          <TH style={{ width: '30%' }}>Listing</TH>
                          <TH style={{ width: '30%' }}>Contact Detail</TH>
                          <TH style={{ width: '20%' }}>Message</TH>
                          <TH>Date</TH>
                        </TR>
                      </THead>
                      <TBody>
                        {this.state?.allConatctRequestList.map((item, index) => {
                          return (
                            <TR key={item.id}>
                              <TD className="h5 semibold"><h5 className="h5 semibold" />{index + 1}</TD>
                              <TD>
                                <div className="d-flex justify-content-between gap-2" style={{wordBreak: "break-all"}}>
                                  <img src={item?.property?.property_images[0]?.user_image} alt="" className="mx-1" width={"80"} />
                                  {item?.property?.property_name}
                                  
                                </div>

                              </TD>

                              <TD>
                                <p className="m-0"><strong><i className="fa fa-person"></i> </strong> {item.name}</p>
                                <p className="m-0"><strong><i className="fa fa-envelope"></i> </strong> {item.email}</p>
                                <p className="m-0"><strong><i className="fa fa-phone"></i></strong> {item.phone}</p>
                              </TD>

                              <TD>{item.message}</TD>
                              <TD>{Moment(item.created_at).format("DD-MM-YYYY")}</TD>

                            </TR>
                          );
                        })}
                      </TBody>
                    </Table>

                  </div>


                </div>
              </div>

            </div>
          </div>
        }
        <Footer />
      </>
    );
  }

}

export default withRouter(ContactRequest);