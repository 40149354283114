import React, { useState } from 'react'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import { AddAgent } from '../../API/AgentApi'
import swal from "sweetalert";
import { useHistory, Link } from "react-router-dom";

export default function Add() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password_confirmation, setPasswordConfirmation] = useState("")
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [fcc_no, setFccno] = useState("")
    const [image, setImage] = useState("")
    const [emailerror, setEmailError] = useState("");
    const [passworderror, setpasswordError] = useState("");
    const [password_confirmationerror, setPasswordConfirmationError] = useState("")
    const [firstnameerror, setFirstNameError] = useState("")
    const [lastnameerror, setLastNameError] = useState("")
    const [phoneerror, setPhoneError] = useState("")
    const [fcc_noerror, setFccnoError] = useState("")
    const [phone, setPhone] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email) {
            setEmailError("Please enter your email address");
            setLoading(false);
        }else{
            setEmailError("");
        }

        if (!password) {
            setpasswordError("Please enter your password");
            setLoading(false);
        }else{
            setpasswordError("");
        }
        if (!password_confirmation) {
            setPasswordConfirmationError("Please enter your confirm password");
            setLoading(false);
        }else{
            setPasswordConfirmationError("");
        }
        if (password !== password_confirmation) {
            setPasswordConfirmationError("password and confirm password must be match");
            setLoading(false);
        }else{
            setPasswordConfirmationError("");
        }
        if (!last_name) {
            setLastNameError("Please enter your last name");
            setLoading(false);
        }else{
            setLastNameError("");
        }
        if (!phone) {
            setPhoneError("Please enter your phone number");
            setLoading(false);
        }else{
            setPhoneError("");
        }

        if (!first_name) {
            setFirstNameError("Please enter your first name");
            setLoading(false);
        }else{
            setFirstNameError("");
        }
        if (!fcc_no) {
            setFccnoError("Please enter FCC No");
            setLoading(false);
        }else{
            setFccnoError("");
        }

        if (email && password && password_confirmation && first_name && last_name && phone && fcc_no && password === password_confirmation) {
            const Token = localStorage.getItem("token");
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            formData.append("password_confirmation", password_confirmation);
            formData.append("first_name", first_name);
            formData.append("last_name", last_name);
            formData.append("phone", phone);
            formData.append("fcc_no", fcc_no);
            formData.append("is_active", 1);
            if (image) {
                formData.append("image", image.fileBytes);
            }
            AddAgent(Token, formData).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data.ask_question === '1') {
                        swal({
                            text: `Are you sure want to send the request?`,
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                formData.append("ask_question", 1);
                                AddAgent(Token, formData).then((resp) => {
                                    if (resp.data.ask_question === '3') {
                                        swal("Success", resp.data.message, "success");
                                        setLoading(false);
                                    } else {
                                        swal("Error", resp.data.message, "error");
                                        setLoading(false);
                                    }
                                });
                            }
                        });

                    } else if (res.data.ask_question === '0') {
                        swal("Error", "Request can not send because this user already regiter in the agency ", "error");
                        setLoading(false);
                    } else {
                        swal("Success", res.data.message, "success");
                        setLoading(false);
                        history.push('/agent');
                    }

                }
                else if (res.status === 201) {
                    if (res.data?.message?.email) {
                        swal("Error", res.data.message.email[0], "error");
                        setLoading(false);
                    } else {
                        swal("Error", "somethin went wrong", "error");
                        setLoading(false);
                    }
                } else {
                    swal("Error", res.data.message, "error");
                    setLoading(false);
                }
            })
        }


    };

    const handleImage = (e) => {
        setImage({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };

    return (
        <>
            <Navbar />
            <div className="main py-5">
                <div className="container ">

                    <div className="row pb-md-5">

                        <AgencyNavbar />

                        <div className="col-md-9 ps-md-5 border-start admin-right-part">

                            <h2 className="text-primary semibold h1 pb-3">Add New Agent</h2>


                            <form onSubmit={handleSubmit} className="admin-add-form px-0 col-lg-8">
                                <div className="row">
                                    <div className="form-field col-md-12 singup-photo">
                                        <input type="file"
                                            id="sign-profilepic"
                                            className="d-none"
                                            accept="image/*"
                                            onChange={(e) => handleImage(e)}
                                        />
                                        <label for="sign-profilepic" className="d-flex align-items-center">
                                            {image.fileUrl ? (
                                                <span>
                                                    <img
                                                        src={image.fileUrl}
                                                        height="170px"
                                                        width="100%"
                                                        className="image-priview"
                                                    />
                                                </span>
                                            ) : (
                                                <span><i className="far fa-image"></i></span>
                                            )}

                                            Image
                                        </label>

                                    </div>

                                    <div className="form-field col-md-6">
                                        <label className="d-block medium fs-15">First Name</label>
                                        <input type="text"
                                            className="w-100"
                                            placeholder=""
                                            value={first_name}
                                            onChange={(e) => { setFirstName(e.target.value) }}
                                            
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {firstnameerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-6">
                                        <label className="d-block medium fs-15">Last Name</label>
                                        <input type="text"
                                            className="w-100"
                                            placeholder=""
                                            value={last_name}
                                            onChange={(e) => { setLastName(e.target.value) }}
                                            
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {lastnameerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">Phone Number</label>
                                        <input type="number"
                                            className="w-100"
                                            placeholder=""
                                            value={phone}
                                            onChange={(e) => { setPhone(e.target.value) }}
                                            
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {phoneerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">Email</label>
                                        <input type="email"
                                            className="w-100"
                                            placeholder=""
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                            
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {emailerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">Password</label>
                                        <input type="password"
                                            className="w-100"
                                            placeholder=""
                                            value={password}
                                            onChange={(e) => { setPassword(e.target.value) }}
                                            
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {passworderror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">Confirm Password</label>
                                        <input type="password"
                                            className="w-100"
                                            placeholder=""
                                            value={password_confirmation}
                                            onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                                            
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {password_confirmationerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">FCC number</label>
                                        <input type="text"
                                            className="w-100"
                                            placeholder=""
                                            value={fcc_no}
                                            onChange={(e) => { setFccno(e.target.value) }}
                                            
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {fcc_noerror}
                                        </p>
                                    </div>

                                </div>

                                <div className="form-btn">
                                    {loading ?
                                        <button type="submit" className="btn-sm" disabled={loading ? true : false}>
                                            <span className='spinner-grow spinner-grow-sm '>

                                            </span>
                                        </button>
                                        :
                                        <button type="submit" className="custom-btn" disabled={loading ? true : false}>Save</button>
                                    }
                                    <Link to="/agent" className="back-btn ms-3">Back</Link>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}
