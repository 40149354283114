import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import ReferFriend from "../component/ReferFriend";
import Notification from "../component/Notification";
import Index from "../component/Index";
import PostDetail from "../component/PostDetail";
import Listing from "../component/Listing";
import Login from "../component/Login";
import AddPost from "../component/AddPost";
import AnswerLater from "../component/AnswerLater";
import Terms from "../component/Terms";
import PrivacyPolicy from "../component/PrivacyPolicy";
import Register from "../component/Register";
import Profile from "../component/Profile";
import EditProfile from "../component/EditProfile";
import PostDetailBySlug from "../component/PostDetailBySlug";
import AgencyRegister from "../component/AgencyRegister";
import AgencyDashboard from "../component/AgencyDashboard";
import AddAgent from "../component/agent/Add";
import Agent from "../component/agent/Index";
import EditAgent from "../component/agent/Edit";
import AddApprover from "../component/approver/Add";
import Approver from "../component/approver/Index";
import EditApprover from "../component/approver/Edit";
import AddAdmin from "../component/admin/Add";
import Admin from "../component/admin/Index";
import EditAdmin from "../component/admin/Edit";
import Property from "../component/property/Index";
import AddProperty from "../component/property/Add";
import EditProperty from "../component/property/Edit";
import ViewProperty from "../component/property/View";
import BondCalculator from "../component/calculator/BondCalculator";
import ExtraPaymentsCalculator from "../component/calculator/ExtraPaymentsCalculator";
import RepaymentCalculator from "../component/calculator/RepaymentCalculator";
import TransferCostCalculator from "../component/calculator/TransferCostCalculator";
import SendEmail from "../component/forgotPassword/SendEmail";
import OtpVerify from "../component/forgotPassword/VerifyOtp";
import ChangePassword from "../component/forgotPassword/ChangePassword";
import PasswordChange from "../component/ChangePassword";
import Follow from "../component/Follow";
import Message from "../component/Message";
import SearchProperty from "../component/SearchProperty";
import SearchPropertyDetail from "../component/SearchPropertyDetail";
import Pricing from "../component/Pricing";
import { ContactRequest } from "../component/ConatactRequest";
import Success from "../component/payment/Success";
import Cancel from "../component/payment/Cancel";
import Notify from "../component/payment/Notify";
import RequestAccept from "../component/agent/RequestAccept";
import PaymentHistory from "../component/payment/PaymentHistory";
import Calculators from "../component/calculator/Calculators";

export default function Routes() {
  const location = useLocation();
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route exact path="/add-post">
          <AddPost />
        </Route>
        <Route exact path="/answer-later">
          <AnswerLater />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>

        <Route exact path="/agency-register">
          <AgencyRegister />
        </Route>

        <Route exact path="/dashboard">
          <AgencyDashboard />
        </Route>

        <Route exact path="/add-agent">
          <AddAgent />
        </Route>

        <Route exact path="/edit-agent">
          <EditAgent />
        </Route>

        <Route exact path="/agent">
          <Agent />
        </Route>
        <Route exact path="/add-approver">
          <AddApprover />
        </Route>
        <Route exact path="/approver">
          <Approver />
        </Route>

        <Route exact path="/edit-approver">
          <EditApprover />
        </Route>

        <Route exact path="/add-admin">
          <AddAdmin />
        </Route>

        <Route exact path="/edit-admin">
          <EditAdmin />
        </Route>

        <Route exact path="/admin">
          <Admin />
        </Route>

        <Route exact path="/property">
          <Property />
        </Route>

        <Route exact path="/add-property">
          <AddProperty />
        </Route>

        <Route exact path="/edit-property">
          <EditProperty />
        </Route>

        <Route exact path="/view-property/:slug">
          <ViewProperty />
        </Route>

        <Route exact path="/listing">
          <Listing />
        </Route>

        <Route exact path="/affordabilty-calculator">
          <BondCalculator />
        </Route>

        <Route exact path="/extra-payments-calculator">
          <ExtraPaymentsCalculator />
        </Route>
        <Route exact path="/repayment-calculator">
          <RepaymentCalculator />
        </Route>
        <Route exact path="/transfer-cost-calculator">
          <TransferCostCalculator />
        </Route>
        <Route exact path="/refer-friend">
          <ReferFriend />
        </Route>
        <Route exact path="/calculators">
          <Calculators />
        </Route>
        <Route exact path="/notification">
          <Notification />
        </Route>
        <Route exact path="/post-detail">
          <PostDetail />
        </Route>
        <Route exact path="/post-detail/:slug">
          <PostDetailBySlug />
        </Route>
        <Route exact path="/profile/:id">
          <Profile />
        </Route>
        <Route exact path="/edit-profile">
          <EditProfile />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/forgot-password">
          <SendEmail />
        </Route>
        <Route exact path="/otp-verify">
          <OtpVerify />
        </Route>
        <Route exact path="/change-password">
          <ChangePassword />
        </Route>
        <Route exact path="/password-change">
          <PasswordChange />
        </Route>
        <Route exact path="/follow">
          <Follow />
        </Route>
        <Route exact path="/message/:roomId?">
          <Message />
        </Route>

        <Route exact path="/search-result/:page">
          <SearchProperty />
        </Route>

        <Route exact path="/property-detail/:slug">
          <SearchPropertyDetail />
        </Route>

        <Route exact path="/pricing">
          <Pricing />
        </Route>

        <Route exact path="/inquiries">
          <ContactRequest />
        </Route>

        <Route exact path="/payment-success/:slug">
          <Success />
        </Route>

        <Route exact path="/payment-cancel/:slug">
          <Cancel />
        </Route>
        <Route exact path="/agent-request/:slug">
          <RequestAccept />
        </Route>

        <Route exact path="/payment-notify">
          <Notify />
        </Route>

        <Route exact path="/payment-history">
          <PaymentHistory />
        </Route>
      </Switch>

      {/* <Route
            exact
            path="/search-result/:page"
            children={(props) => <SearchProperty {...props} />}
            /> */}
    </Router>
  );
}
