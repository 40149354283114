import React, { useState, useEffect } from 'react'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import {AddAgent, GetSingleAgent} from '../../API/AgentApi'
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { useLocation, Link } from "react-router-dom";

export default function Edit() {
    const [email, setEmail] = useState("")
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [fcc_no, setFccno] = useState("")
    const [image, setImage] = useState("")
    const [phone, setPhone] = useState('')
    const [firstnameerror, setFirstNameError] = useState("")
    const [fcc_noerror, setFccnoError] = useState("")
    const [lastnameerror, setLastNameError] = useState("")
    const [phoneerror, setPhoneError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const location = useLocation();
    const id = location.state.data;
    const Token = localStorage.getItem("token");
    useEffect(
        function () {
            let mounted = true;
            let data = {
                id:id
            }
            GetSingleAgent(Token, data).then((res)=>{
                console.log(res);
                if(res.data.type === "success"){
                    setFccno(res.data.data.fcc_no);
                    setFirstName(res.data.data?.user?.name);
                    setLastName(res.data.data?.user?.surname);
                    setEmail(res.data.data?.user?.email);
                    setPhone(res?.data?.data?.user?.phone);
                    setImage({
                        fileUrl: res?.data?.data?.image,
                    });
                }
            })
            return () => {
                mounted = false;
            };
        },
        []
    );


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        

        if (!last_name) {
            setLastNameError("Please enter your last name");
            setLoading(false);
        }else{
            setLastNameError("");
        }
        if (!phone) {
            setPhoneError("Please enter your phone number");
            setLoading(false);
        }else{
            setPhoneError("");
        }

        if (!first_name) {
            setFirstNameError("Please enter your first name");
            setLoading(false);
        }else{
            setFirstNameError("");
        }

        if (!fcc_no) {
            setFccnoError("Please enter FCC No");
            setLoading(false);
        }

        if (first_name && fcc_no && last_name && phone) {
            
            const formData = new FormData();
            formData.append("first_name", first_name);
            formData.append("last_name", last_name);
            formData.append("email", email);
            formData.append("fcc_no", fcc_no);
            formData.append("id", id);
            formData.append("phone", phone);
            formData.append("is_active", 1);
            if(image.fileBytes){
            formData.append("image", image.fileBytes);
            }
            AddAgent(Token, formData).then((res)=>{
                if(res.status === 200){
                    swal("Success", res.data.message, "success");
                    setLoading(false);
                    history.push('/agent');
                }
                if(res.status === 201){
                    if(res.data.message.email){
                        swal("Error", res.data.message.email[0], "error");
                        setLoading(false);
                    }else{
                        swal("Error", "somethin went wrong", "error");
                        setLoading(false);  
                    }
                }else{
                    swal("Success", res.data.message, "success");
                    setLoading(false);
                }
            })
        }


    };

    const handleImage = (e) => {
        setImage({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };

    return (
        <>
            <Navbar />
            <div className="main py-5">
                <div className="container ">

                    <div className="row pb-md-5">

                        <AgencyNavbar />

                        <div className="col-md-9 ps-md-5 border-start admin-right-part">

                            <h2 className="text-primary semibold h1 pb-3">Edit Agent</h2>


                            <form onSubmit={handleSubmit} className="admin-add-form px-0 col-lg-8">
                                <div className="row">
                                    <div className="form-field col-md-12 singup-photo">
                                        <input type="file" 
                                        id="sign-profilepic" 
                                        className="d-none" 
                                        accept="image/*"
                                        onChange={(e)=>handleImage(e)}
                                        />
                                        <label for="sign-profilepic" className="d-flex align-items-center">
                                        {image.fileUrl ? (
                                            <span>
                                            <img
                                                src={image.fileUrl}
                                                height="170px"
                                                width="100%"
                                                className="image-priview"
                                            />
                                            </span>
                                        ) : (
                                            <span><i className="far fa-image"></i></span>
                                        )}
                                           
                                            Image
                                        </label>
                                    </div>

                                    <div className="form-field col-md-6">
                                        <label className="d-block medium fs-15">First Name</label>
                                        <input type="text" 
                                        className="w-100" 
                                        placeholder="" 
                                        value={first_name}
                                        onChange={(e) => { setFirstName(e.target.value) }}
                                        
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {firstnameerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-6">
                                        <label className="d-block medium fs-15">Last Name</label>
                                        <input type="text" 
                                        className="w-100" 
                                        placeholder="" 
                                        value={last_name}
                                        onChange={(e) => { setLastName(e.target.value) }}
                                        
                                        />
                                         <p style={{ color: "red", margintop: "-10px" }}>
                                            {lastnameerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">Phone Number</label>
                                        <input type="number" 
                                        className="w-100" 
                                        placeholder="" 
                                        value={phone}
                                        onChange={(e) => { setPhone(e.target.value) }}
                                        
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {phoneerror}
                                        </p>
                                    </div>

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">Email</label>
                                        <input type="email" 
                                        className="w-100" 
                                        placeholder="" 
                                        value={email}
                                        disabled
                                        />
                                    </div>

                                   

                                    <div className="form-field col-md-12">
                                        <label className="d-block medium fs-15">FCC number</label>
                                        <input type="text" 
                                        className="w-100" 
                                        placeholder="" 
                                        value={fcc_no}
                                        onChange={(e) => { setFccno(e.target.value) }}
                                        
                                        />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {fcc_noerror}
                                        </p>
                                    </div>

                                </div>

                                <div className="form-btn">
                                {loading ?
                                    <button type="submit" className="btn-sm" disabled={loading ? true : false}>
                                        <span className='spinner-grow spinner-grow-sm '>

                                        </span>
                                    </button>
                                :
                                    <button type="submit" className="custom-btn">Save</button>
                                }
                                    <Link to="/agent" className="back-btn ms-3">Back</Link>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}
