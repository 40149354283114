import React, { useState } from "react";
import { PasswordChange } from '.././API/AuthApi'
import {  Link } from "react-router-dom";
import swal from "sweetalert";
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar'
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const $ = window.jQuery;
export default function ChangePassword() {
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [newpassworderror, setNewPassworderror] = useState("");
  const [confirmpassworderror, setConfirmPassworderror] = useState("");
  const [samepassworderror, setSamepassworderror] = useState("");

  const [password_hidden, setPasswordHidden] = useState(true);
  const [confirm_password_hidden, setConfirmPasswordHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const Token = localStorage.getItem("token");
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (!newpassword) {
      setNewPassworderror("Please enter new password");
      setLoading(false);
    }
    if (!confirmpassword) {
      setConfirmPassworderror("Please enter confirm password");
      setLoading(false);
    }

    if (newpassword !== confirmpassword) {
      setNewPassworderror("");
      setConfirmPassworderror("");
      setSamepassworderror("Passwords and confirm password must be same.");
      setLoading(false);
    }


    if (newpassword && confirmpassword && newpassword === confirmpassword) {
      setNewPassworderror("");
      setConfirmPassworderror("");
      setSamepassworderror("");
      var body = {
        new_password: newpassword,
        confirm_password: confirmpassword,
      };
      PasswordChange(body, Token)
        .then((res) => {
          setLoading(false);
          if (res.data.status === "success") {
            setNewPassword('');
            setConfirmPassword('');
            $('#passwordChange')[0].reset();
            swal({
              title: res.data.message,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: res.data.message,
              icon: "error",
              button: "OK",
            });

          }
        })
        .catch((e) => {
          setLoading(false);
          swal("Error!", "Something Went Wrong!", "error");
        });
    }
  };


  return (
    <>
      <Navbar />
      <div class="main">
        <div class="container py-5">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <form onSubmit={handleSubmit} class="site-form" id="passwordChange">
                <div class="bg-white p-4 border rounded">
                  <h4 class="h4 bold pb-3">Change Password </h4>


                  <div class="form-field">
                    <label class="d-block medium fs-15">New Password</label>
                    <div className='d-flex align-items-center' style={{position : 'relative'}}>
                    <input type={password_hidden ? "password" : "text"} 
                    class="w-100"
                    style={{paddingRight : 40}}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <div style={{
                        cursor : 'pointer', 
                        position : 'absolute', 
                        right : 10
                    }} 
                    onClick={()=>setPasswordHidden(!password_hidden)}
                    >
                        {
                            password_hidden ? 
                            <AiFillEyeInvisible size={25}/>
                            :
                            <AiFillEye size={25}/>
                        }
                    </div>

                    </div>
                    <p style={{ color: "red", marginBottom: "10px" }}>{newpassworderror}</p>
                  </div>
                  <div class="form-field">
                    <label class="d-block medium fs-15">Confirm Password</label>

                    <div className='d-flex align-items-center' 
                    style={{position : 'relative'}}>
                    <input type={confirm_password_hidden ? "password" : "text"} 
                    class="w-100"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{paddingRight : 40}}
                    />
                    <div style={{
                        cursor : 'pointer', 
                        position : 'absolute', 
                        right : 10
                    }} 
                    onClick={()=>setConfirmPasswordHidden(!confirm_password_hidden)}
                    >
                        {
                            confirm_password_hidden ? 
                            <AiFillEyeInvisible size={25}/>
                            :
                            <AiFillEye size={25}/>
                        }
                    </div>


                    </div>
                    <p style={{ color: "red", marginBottom: "10px" }}>{confirmpassworderror}</p>
                    <p style={{ color: "red", marginBottom: "10px" }}>{samepassworderror}</p>
                  </div>


                </div>

                <div class="text-center d-flex align-items-center justify-content-center  py-4">
                  <Link to="/" class="text-primary px-4">Cancel</Link>
                  {loading ?
                      <button type="submit"  class="btn-sm" disabled={loading ? true : false}>
                          <span className='spinner-grow spinner-grow-sm '>

                          </span>
                      </button>
                  :
                  <button type="submit" class="btn-sm" disabled={loading ? true : false}>Update</button>
                  }
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
