import React from "react";

const TableRow = ({
  className,
  id,
  children,
  ...props
}) => {
    return (
        <tr
            id={id}
            className={className}
            {...props}
        >
        {children}
        </tr>
    );
};

export default TableRow;