import axios from "axios";
import { notificationURLs } from '../constants/url';


  export const GetAllNotification = (token) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${notificationURLs.GetNotification}`,
    method: "Post",
});

export const GetNextNotification= (url, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: url,
    method: "POST",
});


export const AllReadNotification = (body, token) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${notificationURLs.ReadNotification}`,
  method: "Post",
  data:body,
});

