import React, { useState, useEffect } from "react";
import AgencyNavbar from "../../layouts/AgencyNavbar";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import { PropertyDetail, PropertyApproveReject } from "../../API/PropertyApi";
import { Table, THead, TH, TR, TD, TBody } from "../../table/index";
import { Link, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import Moment from "moment";
import { formatter } from "../../utils";

const $ = window.jQuery;
export default function View() {
  const location = useLocation();
  const params = useParams();
  const Token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const slug = params.slug;
  const [propertyImage, setPropertyImage] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [propertyData, setPropertyData] = useState();
  const [externalFeatures, setExternalFeatures] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [rejectPropertySlug, setRejectPropertySlug] = useState("");
  const [rejectPropertyName, setRejectPropertyName] = useState("");
  const [propertyNearestPlaces, setPropertyNearestPlaces] = useState([]);
  const [rejectPropertyReason, setRejectPropertyReason] = useState("");
  const [rejectPropertyReasonError, setRejectPropertyReasonError] =
    useState("");

  useEffect(function () {
    setPageLoading(true);

    GetPropertyDetail();
  }, []);

  const GetPropertyDetail = () => {
    PropertyDetail(Token, slug).then((res) => {
      setPropertyImage(res?.data?.data?.property?.property_images);
      setAmenities(res?.data?.data?.property?.get_selected_amenities);
      setPropertyData(res?.data?.data?.property);
      setExternalFeatures(
        res?.data?.data?.property?.get_selected_external_features
      );
      setPropertyNearestPlaces(
        res?.data?.data?.property?.property_nearest_places
      );
      setPageLoading(false);
    });
  };

  const rejectProperty = (slug, name) => {
    setRejectPropertySlug(slug);
    setRejectPropertyName(name);
  };

  const submitReason = () => {
    setButtonLoading(true);

    if (!rejectPropertyReason) {
      setRejectPropertyReasonError("Please enter your reason");
      setButtonLoading(false);
    }
    if (rejectPropertyReason) {
      let data = {
        slug: rejectPropertySlug,
        approve_type: "reject",
        reason: rejectPropertyReason,
      };
      PropertyApproveReject(data, Token).then((res) => {
        if (res.data.type === "success") {
          swal("Success", res.data.message, "success");
          setButtonLoading(false);
          GetPropertyDetail();
          $("#myModalrep").modal("hide");
        } else {
          swal("error", res.data.message, "error");
          setButtonLoading(false);
          $("#myModalrep").modal("hide");
        }
      });
    }
  };
  const approveProperty = (slug, name) => {
    swal({
      text: `Are you sure want to approve ${name}!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setButtonLoading(true);
        let data = {
          slug: slug,
          approve_type: "approve",
        };
        PropertyApproveReject(data, Token).then((res) => {
          if (res.data.type === "success") {
            swal("Success", res.data.message, "success");
            GetPropertyDetail();
            setButtonLoading(false);
          } else {
            swal("error", res.data.message, "error");
            setButtonLoading(false);
          }
        });
      }
    });
  };

  return (
    <>
      <Navbar />
      {pageLoading ? (
        <div className="main py-5">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div className="main py-5">
          <div className="container ">
            <div className="row pb-md-5">
              <AgencyNavbar />
              <div className="col-md-9 ps-md-5 border-start admin-right-part">
                {/* <div className="pb-4 mb-2">
                                <h3 className="regular m-0">{propertyData?.property_name} <span className="bold">{propertyData?.street_address}</span></h3>
                                <h2 className="h1 text-primary semibold py-3 m-0">R {propertyData?.property_price}</h2>
                                <h4 className="regular"><i className="fa fa-map-marker-alt pe-3 text-parrot"></i>{propertyData?.property_address}</h4>
                            </div> */}

                <div class="pb-4 mb-2 row">
                  <div class="col-lg-8">
                    <h3 className="regular m-0">
                      {propertyData?.property_name}{" "}
                    </h3>
                    {/* <h2 className="h1 text-primary semibold py-3 m-0"> {formatter.format(propertyData?.property_price)} </h2> */}
                    {propertyData?.is_display_poa === 1 ? (
                      <h2 className="h1 text-primary py-3 address-font semibold m-0">
                        Price on Application (P.O.A)
                      </h2>
                    ) : (
                      <h2 className="h1 text-primary py-3 address-font semibold m-0">
                        {formatter.format(propertyData?.property_price)}
                      </h2>
                    )}
                    {propertyData?.is_display_address === 1 ? (
                      <h4 className="regular">
                        <i className="fa fa-map-marker-alt pe-3 text-parrot"></i>
                        {propertyData?.property_address}
                      </h4>
                    ) : (
                      ""
                    )}
                  </div>
                  {role === "4" ? (
                    <div class="col-lg-4 mt-lg-0 mt-4">
                      <div class="agent-box rounded d-flex align-items-start">
                        {propertyData?.user?.role === 2 ? (
                          propertyData?.user?.roleWiseProfileData
                            ?.user_image === null ? (
                            <img
                              src="../../assets/image/dummy_image.png"
                              alt=""
                            />
                          ) : (
                            <img
                              src={
                                propertyData?.user?.roleWiseProfileData
                                  ?.user_image
                              }
                              alt=""
                            />
                          )
                        ) : propertyData?.user?.roleWiseProfileData
                            ?.user_image === null ? (
                          <img
                            src="../../assets/image/dummy_image.png"
                            alt=""
                          />
                        ) : (
                          <img
                            src={
                              propertyData?.user?.roleWiseProfileData
                                ?.user_image
                            }
                            alt=""
                          />
                        )}

                        <div>
                          <h5 class="h5 bold m-0 pb-1">
                            {propertyData?.user.name}{" "}
                            {propertyData?.user.surname}
                          </h5>
                          <h6 class="regular text-gray fs-14">
                            {propertyData?.user?.user_role?.name}
                          </h6>
                        </div>
                      </div>
                      {propertyData?.property_status === "pending" ||
                      propertyData?.property_status === "updated" ? (
                        <div class="agent-btn d-flex justify-content-between align-items-center pt-4">
                          <Link
                            class="custom-btn"
                            onClick={() =>
                              approveProperty(
                                propertyData?.slug,
                                propertyData?.property_name
                              )
                            }
                          >
                            Approve
                          </Link>
                          <Link
                            class="custom-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#myModalrep"
                            onClick={() =>
                              rejectProperty(
                                propertyData?.slug,
                                propertyData?.property_name
                              )
                            }
                          >
                            Reject
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      {propertyData?.property_status === "approved" ? (
                        <div class="Wd-flex justify-content-between align-items-center pt-4">
                          <Link class="custom-btn">Approved</Link>
                          {/* <Link  class="custom-btn">Approved</Link> */}
                        </div>
                      ) : (
                        ""
                      )}
                      {propertyData?.property_status === "reject" ? (
                        <div class="agent-btn d-flex justify-content-between align-items-center pt-4">
                          <Link class="custom-btn">rejected</Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="gallery ">
                  <h4 className="semibold h3">Images</h4>
                  <div className="top-gallery pb-5">
                    <div className="row">
                      <div className="col-md-7 gal-left pb-md-0 pb-3">
                        {propertyImage.length > 0 ? (
                          <a
                            href={propertyImage[0]?.image}
                            data-fancybox="images-preview"
                          >
                            <img src={propertyImage[0]?.image} />
                          </a>
                        ) : (
                          <a
                            href="../../assets/image/gal1.png"
                            data-fancybox="images-preview"
                          >
                            <img src="../../assets/image/gal1.png" />
                          </a>
                        )}
                      </div>
                      {propertyImage.length > 1 ? (
                        <div className="col-md-5 pl-md-0 gal-right">
                          {propertyImage.map((item, i) => {
                            return i > 0 ? (
                              i < 5 ? (
                                <div className="gal-item">
                                  <a
                                    href={item.image}
                                    data-fancybox="images-preview"
                                  >
                                    <img src={item.image} />
                                  </a>
                                </div>
                              ) : (
                                <div className="d-none">
                                  <a
                                    href={item.image}
                                    data-fancybox="images-preview"
                                  >
                                    <img src={item.image} />
                                  </a>
                                </div>
                              )
                            ) : (
                              ""
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="gallery pb-5">
                  <h4 className="semibold h3">Description</h4>

                  <p
                    className="light"
                    dangerouslySetInnerHTML={{
                      __html: propertyData?.description,
                    }}
                  ></p>
                </div>

                <div className="amenities pb-5">
                  <h4 className="semibold h3 pb-3">Amenities</h4>

                  <div className="row amenities-list">
                    {amenities.map((item, i) => {
                      return (
                        <div className="col-sm-5 pb-4">
                          <div className="d-flex align-items-center">
                            {item?.master_property?.image === null ? (
                              <span>
                                <img
                                  src="../../assets/image/listing-icon-3.png"
                                  alt=""
                                />
                              </span>
                            ) : (
                              <span>
                                <img
                                  src={item?.master_property?.image}
                                  alt=""
                                />
                              </span>
                            )}

                            <h5 className="h5 semibold">
                              <span className="text-gray medium">
                                {item?.master_property?.name} :
                              </span>{" "}
                              {item.data}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="property-overview pb-5">
                  <h4 className="semibold h3 pb-3">Property Overview</h4>

                  <p className="h5">
                    Listing Number <span>{propertyData?.listing_no}</span>
                  </p>
                  <p className="h5">
                    Type of Property{" "}
                    <span>{propertyData?.property_type?.name}</span>
                  </p>
                  {/* <p className="h5">Street Address <span>{propertyData?.property_address}</span></p> */}
                  {propertyData?.listing_type === "rent" ? (
                    <p className="h5">
                      Occupation Date <span>{propertyData?.occupation}</span>
                    </p>
                  ) : (
                    ""
                  )}
                  <p className="h5">
                    Erf Size <span>{propertyData?.floor_size}</span>
                  </p>
                </div>

                {/* <div className="property-overview pb-5">
                                    <h4 className="semibold h3 pb-3">Rooms</h4>

                                    <p className="h5">Bedrooms <span>3</span></p>
                                    <p className="h5">Bathrooms <span>2</span></p>
                                    <p className="h5">Reception Rooms <span>1</span></p>
                                </div> */}

                <div className="property-overview pb-5">
                  <h4 className="semibold h3 pb-3">External Features</h4>

                  {externalFeatures.map((item, i) => {
                    return (
                      <p className="h5">
                        {item?.master_property?.name}
                        {item.data === "1" ? <span>Yes</span> : <span>No</span>}
                      </p>
                    );
                  })}
                </div>

                {propertyData?.listing_type === "rent" ? (
                  ""
                ) : (
                  <div className="property-overview pb-5">
                    <h4 className="semibold h3 pb-3">Others</h4>

                    <p className="h5">
                      Retirement
                      {propertyData?.retirement === 1 ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </p>

                    <p className="h5">
                      On Show
                      {propertyData?.on_show === 1 ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </p>

                    <p className="h5">
                      Auction
                      {propertyData?.auction === 1 ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </p>

                    <p className="h5">
                      Repossessed
                      {propertyData?.repossessed === 1 ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </p>

                    <p className="h5">
                      Security Estate
                      {propertyData?.security_estate === 1 ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </p>
                  </div>
                )}

                {propertyNearestPlaces.length > 0 ? (
                  <div className="property-overview pb-5">
                    <h4 className="semibold h3 pb-3">Point of Interest</h4>
                    {propertyNearestPlaces.map((item, i) => {
                      return (
                        <p className="h5">
                          {item?.name}{" "}
                          <span> {JSON.parse(item?.distance).text} </span>
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
                {propertyData?.property_type?.id === 8 ? (
                  ""
                ) : (
                  <div className="property-overview pb-5">
                    <h4 className="semibold h3 pb-3">Building</h4>

                    <p className="h5">{propertyData?.building_type?.name} </p>
                  </div>
                )}

                {propertyData?.property_reject_reasons.length > 0 ? (
                  <div className="property-overview pb-5">
                    <h4 className="semibold h3 pb-3">Rejected Reason</h4>

                    <Table className="w-100" id="datatable">
                      <THead>
                        <TR>
                          <TH>Count</TH>
                          <TH>Reason</TH>
                          <TH>Approver Name</TH>
                          <TH>Date</TH>
                        </TR>
                      </THead>
                      <TBody>
                        {propertyData?.property_reject_reasons.map(
                          (item, i) => {
                            return (
                              <TR key={i}>
                                <TD>{i + 1}</TD>
                                <TD>{item.reason}</TD>
                                <TD>
                                  {item?.rejected_by_approver?.name +
                                    " " +
                                    item?.rejected_by_approver?.surname}
                                </TD>
                                <TD>
                                  {Moment(item.created_at).format(
                                    "DD-MM-YYYY h:mm A"
                                  )}
                                </TD>
                              </TR>
                            );
                          }
                        )}
                      </TBody>
                    </Table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="modal" id="myModalrep">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Property Reject Reason</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <form class="report-save"> */}

            <div class="modal-body">
              <label>Reason</label>
              <textarea
                class="form-control"
                name="report"
                id="report_text"
                rows="5"
                require
                value={rejectPropertyReason}
                onChange={(e) => setRejectPropertyReason(e.target.value)}
              ></textarea>
              <p style={{ color: "red", margintop: "-10px" }}>
                {rejectPropertyReasonError}
              </p>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {buttonLoading ? (
                <button type="submit" class="btn btn-success" disabled>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </button>
              ) : (
                <button
                  type="submit"
                  class="btn btn-success"
                  onClick={submitReason}
                  disabled={buttonLoading ? true : false}
                >
                  Submit{" "}
                </button>
              )}
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
