import React, { useState, useEffect } from 'react'
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar'
import { FollowBackUser, GetFollowing, RemoveFollowing, UnfollowUser } from "../API/FollowApi";
import { Link, useHistory } from 'react-router-dom';
import swal from "sweetalert";
import { CreateMessageRoom } from "../API/MessageApi";
export default function Follow() {
    const history = useHistory();
    const [followers, setFollowers] = useState([])
    const [following, setFollowing] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const Token = localStorage.getItem("token");
    useEffect(
        function () {
            let mounted = true;
            setPageLoading(true);
            getFollowers();

            return () => {
                mounted = false;
            };
        },
        []
    );
    const getFollowers = () => {
        GetFollowing(Token).then((res) => {
            let followers = res.data.data.getFollowedBy;
            let followings_ = res.data.data.getFollowers;

            let list = res.data.data.getFollowedBy
            .filter((item)=> item.is_follow_back)
            .map((item)=>{
                let followed_user = item.logged_in_user;
                let follower_user_id = item.logged_in_user_id;
                let logged_in_user = item.followed_user;
                let logged_in_user_id = item.follower_user_id;
                return({
                    ...item,
                    followed_user,
                    follower_user_id,
                    logged_in_user,
                    logged_in_user_id
                })
            })
            let followings = followings_.concat(list);
            setFollowers(followers);
            setFollowing(followings);
            // setFollowers(res?.data?.data?.getFollowers);
            // setFollowing(res?.data?.data?.getFollowedBy);
            setPageLoading(false);
        });
    };

    const removeFollowers = (id, name, type) => {
        swal({
            text: `Are you sure want to ${type} ${name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let data = {
                    follower_user_id: id
                }
                RemoveFollowing(Token, data).then((res) => {
                    if (res.data.status === "success") {
                        setLoading(false);
                        getFollowers();
                        swal("Success", res.data.message, "success");
                    } else {
                        setLoading(false);
                        swal("Error", res.data.message, "error");
                    }
                });
            }

        });

    };

    const followback = (id, name, type) => {
        swal({
            text: `Are you sure want to ${type} ${name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let data = {
                    follower_user_id: id
                }
                FollowBackUser(Token, data).then((res) => {
                    if (res.data.status === "success") {
                        setLoading(false);
                        getFollowers();
                        swal("Success", res.data.message, "success");
                    } else {
                        setLoading(false);
                        swal("Error", res.data.message, "error");
                    }
                });
            }

        });

    };

    const unfollow = (id, name, type) => {
        console.log('id',id);
        swal({
            text: `Are you sure want to ${type} ${name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let data = {
                    id: id
                }
                UnfollowUser(Token, data).then((res) => {
                    if (res.data.status === "success") {
                        setLoading(false);
                        getFollowers();
                        swal("Success", res.data.message, "success");
                    } else {
                        setLoading(false);
                        swal("Error", res.data.message, "error");
                    }
                });
            }

        });

    };

    const createMessageRoom = (userId) => {
        let data = {
            chat_receiver_id: userId
        }
        CreateMessageRoom(Token, data).then((res) => {
            if (res.data.status === "success") {
                history.push("message/" + res?.data?.data?.message_room_id, { RoomId: res?.data?.data?.message_room_id })
            }
        });
    };
    const handleProfile = (userId) => {
        history.push("/profile" + '/' + userId, { userId: userId })
    };

    return (
        <>
            <Navbar />

            {pageLoading ?
                <div className="main py-5">
                    <center>
                        <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                        </span>
                    </center>
                </div>
                :
                <div class="main py-5">
                    <div class="container ">
                        <ul class="nav nav-tabs follow-tab" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="followers-tab" data-bs-toggle="tab" data-bs-target="#followers" type="button" role="tab" aria-controls="followers" aria-selected="true">Followers {followers.length}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="following-tab" data-bs-toggle="tab" data-bs-target="#following" type="button" role="tab" aria-controls="following" aria-selected="false">Following {following.length} </button>
                            </li>

                        </ul>
                        <div class="tab-content py-5" id="myTabContent">
                            <div class="tab-pane fade show active" id="followers" role="tabpanel" aria-labelledby="home-tab">

                                <div class="row row-cols-xl-5 row-cols-md-4 row-cols-2">




                                    {followers.map((item, i) => {

                                        return (

                                            <div class="">
                                                <div class="follow-box bg-white text-center rounded p-3" >
                                                    {item?.logged_in_user?.image === null ?
                                                        <img src="assets/image/dummy_image.png" alt="" onClick={() => handleProfile(item?.logged_in_user?.id)} />
                                                        :
                                                        <img src={item?.logged_in_user?.roleWiseProfileData?.user_image} alt="" onClick={() => handleProfile(item?.logged_in_user?.id)} />
                                                    }
                                                    <h5 class="fs-17 bold m-0" onClick={() => handleProfile(item?.logged_in_user?.id)}>{item?.logged_in_user?.name} {item?.logged_in_user?.surname}</h5>
                                                    {item?.logged_in_user?.role === 5 ?
                                                        <p class="fs-14 text-gray">{item?.logged_in_user?.roleWiseProfileData?.occupation}</p>
                                                        :
                                                        item?.logged_in_user?.role === 2 ?
                                                            <p class="fs-14 text-gray">{item?.logged_in_user?.roleWiseProfileData?.agency_name
                                                            }</p>
                                                            :
                                                            <p class="fs-14 text-gray">{item?.logged_in_user?.roleWiseProfileData?.agency_profile?.agency_name
                                                            }</p>
                                                    }
                                                    {/* <Link onClick={() => removeFollowers(item?.logged_in_user?.id, item?.logged_in_user?.name, "follow back")} class="follow-btn">Follow back</Link> */}
                                                    {
                                                        item.is_follow_back
                                                        ?
                                                        <Link onClick={() => removeFollowers(item?.logged_in_user?.id, item?.logged_in_user?.name, "remove")} class="follow-btn">Remove</Link>
                                                        :
                                                        <Link onClick={() => followback(item?.logged_in_user?.id, item?.logged_in_user?.name, "follow back")} class="follow-btn">Follow back</Link>
                                                    }
                                                    <Link onClick={() => createMessageRoom(item?.logged_in_user?.id)} class="follow-btn">Message</Link>
                                                </div>
                                            </div>


                                        );
                                    })}


                                </div>

                            </div>


                            <div class="tab-pane fade" id="following" role="tabpanel" aria-labelledby="profile-tab">

                                <div class="row row-cols-xl-5 row-cols-md-4 row-cols-2">

                                    {following.map((item, i) => {

                                        return (
                                            <div class="">
                                                <div class="follow-box bg-white text-center rounded p-3" >
                                                    {item?.followed_user?.roleWiseProfileData?.image === null ?
                                                        <img src="assets/image/dummy_image.png" alt="" onClick={() => handleProfile(item?.followed_user?.id)} />
                                                        :
                                                        <img src={item?.followed_user?.roleWiseProfileData?.user_image} alt="" onClick={() => handleProfile(item?.followed_user?.id)} />
                                                    }
                                                    <h5 class="fs-17 bold m-0" onClick={() => handleProfile(item?.followed_user?.id)}>{item?.followed_user?.name}  {item?.followed_user?.surname}</h5>
                                                    {item?.followed_user?.role === 5 ?
                                                        <p class="fs-14 text-gray">{item?.followed_user?.roleWiseProfileData?.occupation}</p>
                                                        :

                                                        item?.followed_user?.role === 2 ?
                                                            <p class="fs-14 text-gray">{item?.followed_user?.roleWiseProfileData?.agency_name
                                                            }</p>
                                                            :
                                                            <p class="fs-14 text-gray">{item?.followed_user?.roleWiseProfileData?.agency_profile?.agency_name
                                                            }</p>
                                                    }



                                                    <Link
                                                    onClick={() => unfollow(item.id, item?.followed_user?.name, "unfollow")}
                                                     class="follow-btn">Unfollow</Link>
                                                    <Link onClick={() => createMessageRoom(item?.followed_user?.id)} class="follow-btn">Message</Link>
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            }
            <Footer />
        </>
    )
}
