import React, { useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function AgencyNavbar() {
  const location = useLocation();
  const history = useHistory();
  const role = localStorage.getItem("role");
  const Token = localStorage.getItem("token");

  if(Token === null){
    localStorage.clear();
    history.push('/');
  }



  return (
    <>
    <div className="col-md-3 mb-4 admin-left-part pe-md-5">
    <div className="admin-sidebar ">
        <ul>
            <li className={location.pathname === `/dashboard` ? "active" : ""}><Link to="/dashboard"><img src="../../assets/image/admin_ico1.png" alt="" /> Dashboard</Link></li>
           
            {role == 2 || role == 6 ?
            <li className={location.pathname === `/agent` 
            || location.pathname === `/add-agent` 
            || location.pathname === `/edit-agent`  ? "active" : ""}><Link to="/agent"><img src="../../assets/image/admin_ico2.png" alt="" /> Agents</Link></li>
            : "" }
            {role == 2 || role == 6 ?
            <li className={location.pathname === `/approver` 
            || location.pathname === `/add-approver` 
            || location.pathname === `/edit-approver`  ? "active" : ""}><Link to="/approver"><img src="../../assets/image/admin_ico3.png" alt="" /> Approver</Link></li>
            : "" }
            {role == 2  ?
            <li className={location.pathname === `/admin` 
            || location.pathname === `/add-admin` 
            || location.pathname === `/edit-admin`  ? "active" : ""}><Link to="/admin"><img src="../../assets/image/admin_ico4.png" alt="" /> Admin</Link></li>
            : "" }
            
            <li className={location.pathname === `/property` 
            || location.pathname === `/add-property` 
            || location.pathname === `/edit-property`
            || location.pathname === `/view-property` ? "active" : ""}><Link to="/property"><img src="../../assets/image/admin_ico5.png" alt="" /> Properties</Link></li>

            {role == 3  || role == 5 || role == 2 || role == 6   ?
            <li className={location.pathname === `/inquiries`? "active" : ""}>
              <Link to="/inquiries"><img src="../../assets/image/admin_ico2.png" alt="" /> Inquiries</Link></li>
            : "" }

            { role == 2 || role == 6 || role == 5  ?
            <li className={location.pathname === `/payment-history`? "active" : ""}>
              <Link to="/payment-history"><img src="../../assets/image/admin_ico1.png" alt="" /> Payment History</Link></li>
            : "" }

        </ul>
    </div>
</div>
</>
  )
}
