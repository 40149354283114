import React, { useState, useEffect, useRef } from "react";
import { GetProfile } from "../API/AuthApi";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  CreateLikePost,
  CreatePostReport,
  CreatePostShare,
} from "../API/PostApi";
import { AnswerSave, AnswerLaterSave, AnswerReplySave } from "../API/AnswerApi";
import { StartFollowing } from "../API/FollowApi";
import { CreateMessageRoom } from "../API/MessageApi";
import swal from "sweetalert";
import { useLocation, useParams } from "react-router-dom";
import PostItem from "./PostItem";
const $ = window.jQuery;

export default function Profile(props) {
  let params = useParams();
  const location = useLocation();

  const containerRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [postsLoading, setPostsLoading] = useState(false);

  const userId = location?.state?.userId;
  const [profileData, setProfileData] = useState("");
  const [userPost, setUserPost] = useState([]);
  const [postLimit, setPostLimit] = useState(10);
  const [Followloading, setFollowLoading] = useState(false);
  const [agencyDocument, setAgencyDocument] = useState([]);
  const [imagePriview, setImagePriview] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [followStatus, setFollowStatus] = useState();

  const history = useHistory();
  const Token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (params?.id) {
      let mounted = true;
      setPageLoading(true);
      getProfileData();
      return () => {
        mounted = false;
      };
    }
  }, [params]);

  const getProfileData = () => {
    let data = {
      // user_id: userId ? userId : user.id
      user_id: params.id,
    };
    GetProfile(data, Token).then((res) => {
      if (res.data.status === "success") {
        setProfileData(res.data.data);
        // console.log('user_data_profile',res.data.data);
        setUserPost(res?.data?.data?.user_post);
        setAgencyDocument(
          res?.data?.data?.roleWiseProfileData?.agency_document
        );
        setFollowStatus(res?.data?.followStatus);
        setPageLoading(false);
      }
    });
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImagePriview = (image) => {
    setImagePriview(image);
    $("#myModalpop").modal("show");
  };

  const handleloadMore = () => {
    const CurrentpostLimit = postLimit;
    const newPostLimit = CurrentpostLimit + 10;
    setPostLimit(newPostLimit);
  };

  const handleScroll = (e) => {
    const target = e.target;
    console.log(
      "@@",
      target.scrollTop,
      target.clientHeight,
      target.scrollHeight
    );
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const threshold = windowWidth < 450 ? 350 : 500;

    if (scrollTop + clientHeight >= scrollHeight - threshold && !postsLoading) {
      handleloadMore();
    }
  };

  const startFollow = () => {
    setFollowLoading(true);
    let data = {
      follower_user_id: userId,
    };
    StartFollowing(Token, data).then((res) => {
      if (res.data.status === "success") {
        getProfileData();
        setFollowLoading(false);

        swal("Success", res.data.message, "success");
      } else {
        setFollowLoading(false);
        swal("Error", res.data.message, "error");
      }
    });
  };

  const createMessageRoom = () => {
    let data = {
      chat_receiver_id: params.id,
    };
    console.log("data", data);
    CreateMessageRoom(Token, data).then((res) => {
      if (res.data.status === "success") {
        history.push("/message/" + res?.data?.data?.message_room_id, {
          RoomId: res?.data?.data?.message_room_id,
        });
      }
    });
  };

  const handledit = () => {
    history.push("/edit-profile", { id: profileData.id });
  };

  const handleOpenFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <>
      <Navbar
        refreshprofile={() => {
          let data = {
            // user_id: userId ? userId : user.id
            user_id: params.id,
          };
          GetProfile(data, Token).then((res) => {
            setProfileData(res.data.data);
            setUserPost(res.data.data.user_post);
            setFollowStatus(res?.data?.followStatus);
            setPageLoading(false);
          });
        }}
      />
      {pageLoading ? (
        <div className="main">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div className="main">
          <div
            className="container hide-scrollbar"
            ref={containerRef}
            style={{ overflow: "auto", height: "86vh" }}
            onScroll={handleScroll}
          >
            <div className="row pt-5 justify-content-center">
              <div className="col-lg-6  mb-5 ">
                <div className="profile-box bg-white rounded p-4 mb-4">
                  <div className="d-flex align align-items-start pb-3 w-100">
                    <div className="d-grid">
                      <span className="profile-pic me-3 pb-3">
                        {profileData?.roleWiseProfileData?.user_image ===
                        null ? (
                          <img
                            src="../assets/image/dummy_image.png"
                            alt=""
                            onClick={() =>
                              handleImagePriview(
                                "../assets/image/dummy_image.png"
                              )
                            }
                          />
                        ) : (
                          <img
                            src={profileData?.roleWiseProfileData?.user_image}
                            alt=""
                            onClick={() =>
                              handleImagePriview(
                                profileData?.roleWiseProfileData?.user_image
                              )
                            }
                          />
                        )}
                      </span>
                      {profileData?.role === 2 ? (
                        <span className="profile-pic me-3">
                          {profileData?.roleWiseProfileData?.agency_logo ===
                          null ? (
                            <img
                              src="../assets/image/dummy_image.png"
                              alt=""
                              onClick={() =>
                                handleImagePriview(
                                  "../assets/image/dummy_image.png"
                                )
                              }
                            />
                          ) : (
                            <img
                              src={
                                profileData?.roleWiseProfileData?.agency_logo
                              }
                              alt=""
                              onClick={() =>
                                handleImagePriview(
                                  profileData?.roleWiseProfileData?.agency_logo
                                )
                              }
                            />
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="pt-2 w-100">
                      <h4 className="h4 extrabold">
                        {profileData?.name} {profileData?.surname}
                      </h4>
                      <ul className="user-metadata">
                        {profileData?.role === 5 ? (
                          <li>
                            <i className="fa fa-briefcase"></i>
                            {profileData?.roleWiseProfileData?.occupation}
                          </li>
                        ) : profileData?.role === 2 ? (
                          <li>
                            <i className="fa fa-briefcase"></i>
                            {profileData?.roleWiseProfileData?.agency_name}
                          </li>
                        ) : (
                          <li>
                            <i className="fa fa-briefcase"></i>
                            {
                              profileData?.roleWiseProfileData?.agency_profile
                                ?.agency_name
                            }
                          </li>
                        )}
                        {profileData?.role === 5 ? (
                          <li>
                            <i className="fa fa-map-marker-alt"></i>{" "}
                            {profileData?.roleWiseProfileData?.city}
                          </li>
                        ) : profileData?.role === 2 ? (
                          <li>
                            <i className="fa fa-map-marker-alt"></i>{" "}
                            {profileData?.roleWiseProfileData?.city}
                          </li>
                        ) : (
                          <li>
                            <i className="fa fa-map-marker-alt"></i>{" "}
                            {
                              profileData?.roleWiseProfileData?.agency_profile
                                ?.city
                            }
                          </li>
                        )}
                        {profileData?.id === user?.id ? (
                          <li>
                            <i className="fa fa-envelope"></i>&nbsp;
                            {profileData?.email}
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                    {profileData?.id === user?.id ? (
                      <Link
                        className="custom-btn ms-auto text-nowrap"
                        onClick={handledit}
                      >
                        Edit Profile
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-justify pb-2">
                    <p className="fs-14">
                      {profileData?.roleWiseProfileData?.description}
                    </p>
                  </div>

                  {agencyDocument?.length > 0 ? (
                    role == 2 ? (
                      profileData?.id === user?.id ? (
                        <div className="row">
                          <h6>My Documents</h6>
                          <br />
                          {agencyDocument.map((item, index) => {
                            return (
                              <div className="col-md-4" key={index}>
                                {item?.user_document
                                  .substring(
                                    item?.user_document.lastIndexOf("/") + 1
                                  )
                                  .split(".")
                                  .pop() === "png" ||
                                item?.user_document
                                  .substring(
                                    item?.user_document.lastIndexOf("/") + 1
                                  )
                                  .split(".")
                                  .pop() === "jpg" ||
                                item?.user_document
                                  .substring(
                                    item?.user_document.lastIndexOf("/") + 1
                                  )
                                  .split(".")
                                  .pop() === "jpeg" ? (
                                  <>
                                    <img
                                      src={item?.user_document}
                                      alt=""
                                      style={{
                                        width: "100px",
                                        height: "120px",
                                      }}
                                    />
                                    <br /> <br />
                                    {item?.image_name === null ? (
                                      ""
                                    ) : (
                                      <div
                                        className="agencyDocument"
                                        style={{ wordWrap: "break-word" }}
                                      >
                                        {item?.image_name}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      onClick={() =>
                                        handleOpenFile(item?.user_document)
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src="../assets/image/pdf.png"
                                        alt=""
                                        style={{ width: "65px", heght: "50px" }}
                                      />
                                    </Link>
                                    <br /> <br />
                                    {item?.image_name === null ? (
                                      ""
                                    ) : (
                                      <div
                                        className="agencyDocument w-100"
                                        style={{ wordWrap: "break-word" }}
                                      >
                                        {item?.image_name}
                                      </div>
                                    )}
                                  </>
                                )}

                                {/* {item?.user_document} */}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <br />
                  {agencyDocument?.length > 0 ? (
                    role == 2 ? (
                      profileData?.id === user?.id ? (
                        <div className="row">
                          <small className=" col-md-6 offset-6 w-100">
                            This documents are only visible to you
                          </small>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {Token === null ? (
                    ""
                  ) : profileData?.id === user.id ? (
                    ""
                  ) : (
                    <div className="" style={{ display: "-webkit-inline-box" }}>
                      {followStatus === 0 ? (
                        <Link class="new-follow-btn" onClick={startFollow}>
                          Follow
                        </Link>
                      ) : (
                        <Link
                          class="new-follow-btn"
                          style={{ background: "#3c930285", color: "black" }}
                        >
                          Following
                        </Link>
                      )}
                      &nbsp;&nbsp;
                      <Link onClick={createMessageRoom} class="follow-btn">
                        Message
                      </Link>
                    </div>
                  )}
                </div>
                <div className="search-box mb-4 bg-white p-sm-3 p-2">
                  <div className="d-flex align-items-center w-100">
                    {Token === null ? (
                      " "
                    ) : user?.image === null ? (
                      <span className="user-icon">
                        <img src="../../assets/image/dummy_image.png" alt="" />
                      </span>
                    ) : (
                      <span className="user-icon">
                        {" "}
                        <img src={user?.image} alt="" />
                      </span>
                    )}

                    <form action="#" className="search-post w-100 ">
                      <Link to="/add-post">
                        <input
                          type="text"
                          placeholder="What is your property question or advice?"
                        />
                      </Link>
                    </form>
                  </div>
                </div>

                <ul className="feed-list px-lg-1">
                  <h5 className="bold h4">
                    {user?.id == params?.id
                      ? "Your"
                      : (profileData?.name || "") +
                        " " +
                        (profileData?.surname || "") +
                        "'s"}{" "}
                    Post
                  </h5>

                  {userPost?.map((item, i) => {
                    return i < postLimit ? (
                      <PostItem
                        {...{
                          item: item,
                          i: i,
                          setImagePriview: setImagePriview,
                        }}
                      />
                    ) : (
                      ""
                    );
                  })}
                  {postsLoading && (
                    <div className="main">
                      <center>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          style={{ marginTop: "50px" }}
                        ></span>
                      </center>
                    </div>
                  )}
                  {/* {userPost.length > postLimit ? (
                    <Link onClick={handleloadMore} className="h6">
                      <div
                        className="text-center feed-list"
                        style={{ background: "#fff", height: "32px" }}
                      >
                        Load More
                      </div>
                    </Link>
                  ) : (
                    ""
                  )} */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <div class="modal fade" id="myModalpop">
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content image-pop"
            style={{ background: "transparent", border: "none" }}
          >
            <button
              type="button"
              class="close text-right close-btn popup_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ color: "red", fontWeight: 900 }}
            >
              &times;
            </button>
            <img
              src={imagePriview}
              class="popup_image"
              style={{ height: "90%", width: "90%" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
