import axios from "axios";
import { categoryURLs, searchURLs } from '../constants/url';


  export const GetCategory = () =>
  axios({
    url: `${categoryURLs.Url}`,
    method: "GET",
});

export const GetSearchPost = (body) =>
  axios({
    url: `${searchURLs.Search}`,
    method: "POST",
    data:body
});


