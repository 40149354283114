import axios from "axios";
import { postURLs, reportURLs } from '../constants/url';


export const GetPost = (token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${postURLs.Url}`,
    method: "POST",
  });

export const GetSponsoredPost = () =>
  axios({
    url: `${postURLs.SponsoredPost}`,
    method: "POST",
  });
export const GetSingleSponsoredPost = (slug) =>
  axios({
    url: `${postURLs.SingleSponsoredPost}`,
    method: "POST",
    data: { 'slug': slug }
  });

export const GetNextPost = (url, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: url,
    method: "POST",
  });

export const getPostBySlug = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${postURLs.Url}`,
    method: "POST",
    data: body,
  });

export const GetPostDetail = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${postURLs.postDetail}`,
    method: "POST",
    data: body,
  });

export const LikePost = (token) =>
  axios({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    url: `${postURLs.LikePost}`,
    method: "POST",
  });

export const CreatePost = (token, body) =>
  axios({
    headers: {
      "content-type": "multipart/formdata",
      Authorization: `Bearer ${token}`,
    },
    url: `${postURLs.CreatePost}`,
    method: "POST",
    data: body,
  });

export const CreateLikePost = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${postURLs.CreateLikePost}`,
    method: "POST",
    data: body,
  });

export const CreatePostReport = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${reportURLs.SaveReport}`,
    method: "POST",
    data: body,
  });

export const CreatePostShare = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${postURLs.SharePost}`,
    method: "POST",
    data: body,
  });












