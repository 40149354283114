import axios from "axios";
import { adminURLs } from '../constants/url';


  export const AddAdmin = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${adminURLs.addAdmin}`,
    method: "Post",
    data:body,
});

export const GetAllAdmin = (token) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${adminURLs.GetAdmin}`,
    method: "Post",
});

export const GetSingleAdmin = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${adminURLs.SingalGetAdmin}`,
    method: "Post",
    data:body,
});


export const DeactiveAdmin = (token,body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${adminURLs.DeactiveAdmin}`,
    method: "Post",
    data:body,
});



