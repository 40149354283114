import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <p>Copyright© 2024, All rights Reserved</p>
            </div>

            <div className="col-md-4 text-center">
              <Link to="/privacy-policy" className="text-white">
                Privacy Policy
              </Link>
            </div>

            <div className="col-md-4 text-md-end">
              <p>
                Contact us :{" "}
                <a
                  //   to="mailto:info.askproperty.net"
                  href="mailto:info.askproperty.net"
                  className="text-white"
                >
                  info.askproperty.net
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
