import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, THead, TH, TR, TD, TBody } from "../../table/index";

import { GetAllProperty } from '../../API/PropertyApi'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import Moment from 'moment';
import { PricingPaymentHistory } from "../../API/AuthApi";
const $ = window.jQuery;


export class PaymentHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allPricingPaymentHistoryList: [],
            role: localStorage.getItem("role"),
           
            loading: false,
        };
    }


    componentDidMount() {
        // $("#datatable").dataTable();
        this.setState({
            loading: true,
        });
        this.getPricingPaymentHistory();
        
    }
  

    getPricingPaymentHistory = () => {
        const token = localStorage.getItem("token");
        PricingPaymentHistory(token)
            .then((resp) => {
                console.log('respon',JSON.stringify(resp,null,4));
                this.setState({
                    allPricingPaymentHistoryList: resp.data.data,
                    loading: false,
                });
                $("#datatable").dataTable();
            })
            .catch((e) => {
                console.log(e);
            });
    };

   
   

    render() {
        return (
            <>

                <Navbar />

                {this.state.loading ?
                    <div className="main py-5">
                        <center>
                            <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                            </span>
                        </center>
                    </div>
                    :
                    <div className="main py-5">
                        <div className="container ">

                            <div className="row pb-md-5">

                                <AgencyNavbar />

                                <div className="col-md-9 ps-md-5 border-start admin-right-part">

                                    <div className="d-flex align-items-center justify-content-between pb-4">
                                        <h2 className="text-primary semibold h1 pb-3"> Payment History</h2>
                                      
                                    </div>

                                    <div className="datatable">
                                        <Table className="w-100" id="datatable">
                                            <THead>
                                                <TR>
                                                    <TH>S.No.</TH>
                                                    <TH>Pricing</TH>
                                                    <TH>Start Date</TH>
                                                    <TH>End Date</TH>
                                                    <TH>Name of plan</TH>

                                                    <TH>Purchase Qty</TH>
                                                    <TH>Remaining Qty</TH>
                                                   

                                                </TR>
                                            </THead>
                                            <TBody>
                                                {this.state?.allPricingPaymentHistoryList.map((item, index) => {
                                                    return (
                                                        <TR key={item.id}>
                                                            <TD className="h5 semibold"><h5 className="h5 semibold" />{index + 1}</TD>
                                                            <TD>
                                                                
                                                            <h6 className="fs-15 text-primary">{item?.pricing_type}</h6>

                                                            </TD>
                                                            <TD className="h5 semibold"><h5 className="h5 text-primary"> {Moment(item.pricing_start_date).format("DD-MM-YYYY")}</h5></TD>
                                                            <TD><h6 className="fs-15 text-primary"> {Moment(item.pricing_end_date).format("DD-MM-YYYY")}</h6></TD>
                                                            <TD className="table-action semibold">
                                                            <h6 className="fs-15 text-primary">
                                                            {item?.pricing_plan?.name}
                                                            </h6>
                                                            </TD>

                                                            <TD className="table-action semibold">
                                                            <h6 className="fs-15 text-primary">
                                                            {item?.pricing_plan?.max_property_listing  === -1 ? 'Unlimited' : item?.pricing_plan?.max_property_listing }

                                                            </h6>
                                                            </TD>
                                                            <TD><h6 className="fs-15 text-primary">
                                                                 {item.remainig_qty === -1 ? 'Unlimited' : item.remainig_qty}
                                                                 
                                                            </h6></TD>
                                                           
                                                        </TR>
                                                    );
                                                })}
                                            </TBody>
                                        </Table>

                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                }
                <Footer />
            </>
        );
    }

}

export default withRouter(PaymentHistory);