import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { GetCategory } from "../API/CategoryApi";
import {
  GetPost,
  GetNextPost,
  getPostBySlug,
  GetSponsoredPost,
  GetSingleSponsoredPost,
} from "../API/PostApi";
import { Link } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import PostItem from "./PostItem";

const $ = window.jQuery;

export default function Index() {
  const history = useHistory();
  const [category, setCategory] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [post, setPost] = useState({
    Posts: [],
  });
  const [totalPosts, setTotalPosts] = useState(null);
  const [sponsoredPost, setSponsoredPost] = useState([]);
  const [nextUrl, setNextUrl] = useState("");

  const [categorySlug, setCategorySlug] = useState("");

  const [pageLoading, setPageLoading] = useState(false);
  const [postsLoading, setPostsLoading] = useState(false);

  const Token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const [imagePriview, setImagePriview] = useState("");

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(function () {
    let mounted = true;
    setPageLoading(true);
    GetCategory().then((res) => {
      if (mounted) {
        setCategory(res.data.data);
      }
    });
    GetPost(Token).then((res) => {
      if (mounted) {
        // setPost(res.data.data.data);
        setPost((prevState) => ({
          ...prevState,
          Posts: [...prevState.Posts, res.data.data.data],
        }));
        setTotalPosts(res.data.data.total);
        setNextUrl(res.data.data.next_page_url);
        setPageLoading(false);
      }
    });

    GetSponsoredPost().then((res) => {
      if (res.data.status === "success") {
        setSponsoredPost(res.data.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  const GetPostBySlug = (slug) => {
    let data = {
      category_slug: slug,
    };
    setCategorySlug(slug);
    getPostBySlug(data, Token).then((res) => {
      setPost(() => ({
        Posts: [[], res.data.data.data],
      }));

      setNextUrl(res.data.data.next_page_url);
    });
  };

  const handleloadMore = () => {
    // console.log("indie handle load more");
    if (nextUrl) {
      setPostsLoading(true);
      GetNextPost(nextUrl, Token)
        .then((res) => {
          const newPosts = res.data.data.data;
          setPost((prevState) => ({
            ...prevState,
            Posts: [...prevState.Posts, ...newPosts],
          }));

          setNextUrl(res.data.data.next_page_url);
        })
        .finally(() => {
          setPostsLoading(false);
        });
    }
  };

  const containerRef = useRef();

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const threshold = windowWidth < 450 ? 350 : 500;

    if (scrollTop + clientHeight >= scrollHeight - threshold && !postsLoading) {
      handleloadMore();
    }
  };
  const handleSponsoredPostClick = (slug) => {
    GetSingleSponsoredPost(slug).then((res) => {
      window.open(res.data.data.sponsored_url, "_blank");
    });
  };

  return (
    <>
      <Navbar />
      {pageLoading ? (
        <div className="main">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div className="main">
          <div
            className="container hide-scrollbar"
            ref={containerRef}
            style={{
              height: "90vh",
              overflow: "auto",
            }}
            onScroll={handleScroll}
          >
            <div className={`row ${windowWidth < 450 ? "pt-3" : "pt-5"}`}>
              <div className="col-lg-3 left-sidebar pb-5 d-lg-block">
                <div
                  className={`widget ${windowWidth < 450 ? "pb-2" : "pb-5"}`}
                >
                  {/* <h3 className="widget-title">Top 8 Categories</h3>
                                    <ul className="cat-list">
                                        {category.map((item, i) => {
                                            return (
                                                i < 8 ?
                                                    <li key={i} className={categorySlug === item?.slug ? "active_category" : ''} ><Link onClick={() => GetPostBySlug(item.slug)} className={categorySlug === item?.slug ? "active_category" : ''} >{item.name}</Link></li>
                                                    : ''
                                            );
                                        })}


                                    </ul> */}
                  <ul
                    className={`mx-2 ${
                      windowWidth < 450 ? "d-flex mt-0 mb-0" : "mt-2"
                    }`}
                  >
                    <li
                    // onClick={()=>{
                    //     history.push('/listing', '_blank')
                    // }}
                    >
                      <Link
                        className={`${
                          windowWidth > 450 ? "d-flex" : ""
                        } align-items-center show-pointer`}
                        to="/listing"
                        target="_blank"
                      >
                        <img
                          className="pe-3"
                          style={{
                            width: "90px",
                            height: "50px",
                          }}
                          src={"assets/image/south-africa.png"}
                        />
                        <p
                          style={{
                            fontSize: windowWidth < 450 ? "12px" : "",
                            lineHeight: windowWidth < 450 ? "18px" : "",
                          }}
                        >
                          {windowWidth < 450
                            ? "Properties in SA"
                            : "View Properties in South Africa"}
                        </p>
                      </Link>
                    </li>
                    <li className="">
                      <a
                        className={`${
                          windowWidth > 450 ? "d-flex mt-3" : ""
                        } align-items-center show-pointer`}
                        href="https://askpropertyuk.propviewr.com/properties"
                        target="_blank"
                      >
                        <img
                          style={{
                            width: "90px",
                            height: "50px",
                          }}
                          className="pe-3"
                          src={"assets/image/ukflag.png"}
                        />
                        <p
                          style={{
                            fontSize: windowWidth < 450 ? "12px" : "",
                            lineHeight: windowWidth < 450 ? "18px" : "",
                          }}
                        >
                          {windowWidth < 450
                            ? "Properties in UK"
                            : "View Properties in UK"}
                        </p>
                      </a>
                    </li>
                    <li className="">
                      <a
                        className={`${
                          windowWidth > 450 ? "d-flex mt-3" : ""
                        } align-items-center show-pointer`}
                        href="https://askpropertyuae.propviewr.com/ae/properties"
                        target="_blank"
                      >
                        <img
                          style={{
                            width: "90px",
                            height: "50px",
                          }}
                          className="pe-3"
                          src={"assets/image/uae.png"}
                          // src={"assets/image/nigeria.png"}
                        />
                        <p
                          style={{
                            fontSize: windowWidth < 450 ? "12px" : "",
                            lineHeight: windowWidth < 450 ? "18px" : "",
                          }}
                        >
                          {/* {windowWidth < 450
                            ? "Properties in Nigeria"
                            : "View Properties in Nigeria"} */}
                          {windowWidth < 450
                            ? "Properties in UAE"
                            : "View Properties in UAE"}
                        </p>
                      </a>
                    </li>

                    {windowWidth < 450 && (
                      <li>
                        <a
                          className={`${
                            windowWidth > 450 ? "d-flex mt-3" : ""
                          } align-items-center show-pointer`}
                          href="https://docs.google.com/forms/d/e/1FAIpQLSdH-JBAfxr0PDpS1Srfc6c9LB945WO34FBbgjiWpyufmS7ypw/viewform"
                          target="_blank"
                        >
                          <img
                            style={{
                              width: "90px",
                              height: "50px",
                            }}
                            className="pe-3"
                            src={"assets/image/home.jpg"}
                          />
                          <p
                            style={{
                              fontSize: windowWidth < 450 ? "12px" : "",
                              lineHeight: windowWidth < 450 ? "18px" : "",
                            }}
                          >
                            {"Apply for a home loan"}
                          </p>
                        </a>
                      </li>
                    )}
                  </ul>

                  {windowWidth > 450 && (
                    <div className="mx-2 mt-5 d-flex align-items-center justify-content-center">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdH-JBAfxr0PDpS1Srfc6c9LB945WO34FBbgjiWpyufmS7ypw/viewform"
                        target="_blank"
                        className="w-100"
                      >
                        <button
                          style={{
                            backgroundColor: "#63af30",
                            borderRadius: "10px",
                            color: "white",
                            padding: "10px",
                          }}
                          className="border-0 px-3"
                        >
                          Apply for a home loan
                        </button>
                      </a>
                    </div>
                  )}
                  {windowWidth > 450 && (
                    <div
                      className="mx-2"
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLScKSqCsPeuyHST0qI_Yn5fA5UmGIqkiyXKfiv3Y4im_QKyNCw/viewform"
                        target="_blank"
                        className="w-100"
                      >
                        <button
                          style={{
                            backgroundColor: "#63af30",
                            borderRadius: "10px",
                            color: "white",
                            padding: "10px",
                          }}
                          className="border-0 px-3"
                        >
                          Apply for pre-approval
                        </button>
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-6 middle-area mb-5">
                <div className="search-box mb-4 bg-white p-sm-3 p-2">
                  <div className="d-flex align-items-center w-100">
                    {Token === null ? (
                      " "
                    ) : user?.image === null ? (
                      <span className="user-icon">
                        <img src="assets/image/dummy_image.png" alt="" />
                      </span>
                    ) : (
                      <span className="user-icon">
                        {" "}
                        <img src={user?.image} alt="" />
                      </span>
                    )}

                    <form action="#" className="search-post w-100 ">
                      <Link to="/add-post">
                        <input
                          type="text"
                          placeholder="What is your property question or advice?"
                        />
                      </Link>
                    </form>
                  </div>
                </div>

                {/* <ul className="feed-list">
                  {post.Posts.map((ite, i) => {
                    return ite.map((item, i) => {
                      return (
                        <PostItem
                          {...{
                            item: item,
                            i: i,
                            setImagePriview: setImagePriview,
                          }}
                        />
                      );
                    });
                  })}
                </ul> */}
                <div>
                  {post.Posts.map((ite, i) => {
                    const itemsArray = Array.isArray(ite) ? ite : [ite];
                    return itemsArray.map((item, j) => (
                      <PostItem
                        key={j}
                        {...{
                          item: item,
                          i: i,
                          setImagePriview: setImagePriview,
                        }}
                      />
                    ));
                  })}
                  {postsLoading && (
                    <div className="main">
                      <center>
                        <span
                          className="spinner-grow spinner-grow-sm "
                          style={{ marginTop: "50px" }}
                        ></span>
                      </center>
                    </div>
                  )}
                </div>

                {/* {post.Posts.map((ite, i) => {
                    return ite.map((item, i) => {
                      return (
                        <PostItem
                          key={i}
                          {...{
                            item: item,
                            i: i,
                            setImagePriview: setImagePriview,
                          }}
                        />
                      );
                    });
                  })} */}

                {/* {nextUrl !== null ? (
                  <Link onClick={handleloadMore} className="h6">
                    <div
                      className="text-center feed-list"
                      style={{ background: "#fff", height: "32px" }}
                    >
                      Load More
                    </div>
                  </Link>
                ) : (
                  ""
                )} */}
              </div>
              <div className="col-lg-3 right-sidebar d-lg-block d-none">
                <div className="widget">
                  <h3 className="widget-title">Sponsored Post</h3>
                  <div className="liked-post">
                    {sponsoredPost.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="card mb-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSponsoredPostClick(item?.slug)}
                        >
                          {item?.image ? (
                            <img
                              src={item?.image}
                              alt={item?.company}
                              className="card-img-top"
                            />
                          ) : (
                            ""
                          )}

                          <div className="card-body py-0">
                            <h5 className="card-title">{item?.company}</h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="modal fade" id="myModalpop">
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content image-pop"
            style={{ background: "transparent", border: "none" }}
          >
            <button
              type="button"
              className="close text-right close-btn popup_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ color: "red", fontWeight: 900 }}
            >
              &times;
            </button>

            {imagePriview !== null ? (
              <img
                src={imagePriview}
                className="popup_image"
                style={{ height: "90%", width: "90%" }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
