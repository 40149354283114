import React from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";

export default function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <div className="main">
        <div className="container pt-md-5">
          <div className="row pt-4">
            <div className="col-md-12 ">
              <h3>
                <b>Privacy Policy</b>
              </h3>
            </div>
            <div className="col-md-12 ">
              <p>
                <span>
                  {" "}
                  Ask Property adheres to the highest standards of protecting
                  your personal information when we process it by virtue of your
                  use of our Services, your use of our website{" "}
                </span>
                <a href="http://www.askproperty.co.za">
                  <span> www.askproperty.co.za</span>
                </a>
                <span>
                  {" "}
                  or any of its related blogs, websites, applications or
                  platforms (collectively, “the Website”), when contracting
                  withus or by providing us with your personal information in
                  any other way. As such, we have created this specific and
                  detailed Privacy Policy (“Policy”) for you to read and
                  appreciate exactly how we safeguard your personal information
                  and respect your privacy.
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    For more information regarding your personal information
                    lawfully stored or used by Ask Property , please contact
                  </span>{" "}
                  <a href="mailto:admin@askproperty.co.za">
                    <span>admin@askproperty.co.za</span>
                  </a>
                  <span> and we will gladly assist.</span>
                </li>
                {/* <li>
                  <span>
                    This Policy was last updated on 8<sup>th</sup> of October
                    2022.
                  </span>
                </li> */}
                <li>
                  <span>Not all terms are necessarily defined in order.</span>
                </li>
                <li>
                  <span>
                    Please ensure that you read all the provisions below, and
                    our other Ask Property rules, terms &amp; conditions and
                    policies which may apply from time to time and made
                    unilaterally amended without consultation,however will be
                    made available for you to understand all of your, and our,
                    rights and duties.
                  </span>
                </li>
              </ul>

              <div>
                <ol>
                  <li>
                    <strong>
                      <span>i. Important information about Ask Property </span>
                    </strong>
                  </li>
                </ol>
              </div>

              <ol>
                <li>
                  <span>
                    a. This Privacy Policy aims to give you information on how
                    Ask Property collects and processes your personal data
                    through any form of your engagement with Ask Property , such
                    as when you provide your third-party services to us, or
                    correspond with us, use our Services (an agency or
                    individual), access or use the Website, or provide us with
                    your personal information in any other way (such as when
                    participating in surveys, participating in events or signing
                    up).
                  </span>
                </li>
                <br />
                <li>
                  <span>
                    b. This Privacy Policy complies with, and facilitates the
                    obligations required from, the European Commission’s General
                    Data Protection Regulation, 2016/679 (“<strong>GDPR</strong>
                    ”) as well as the South African Protection of Personal
                    Information Act, No. 4 of 2013 (“<strong>POPI</strong>”), as
                    amended.
                  </span>
                </li>
                <br />
                <li>
                  <span>
                    c. It is important that you read this Privacy Policy
                    together with any other privacy policy or fair processing
                    notice we may provide on specific occasions when we are
                    collecting or processing personal data, so that you are
                    fully aware of how and why we are using your data. This
                    Privacy Policy supplements the other notices and is not
                    intended to override them.
                  </span>
                </li>
                <br />
                <li>
                  <span>
                    d. You have the right to make a complaint at any time to the
                    South African regulator’s office (the Information
                    Regulator’s Office of South Africa). We would, however,
                    appreciate the chance to deal with your concerns before you
                    approach any such regulator, so please contact us in the
                    first instance
                  </span>
                </li>
                <br />
                <li>
                  <span>
                    e. We do not process special categories of personal data,
                    nor the data of minors. Do not provide us with any such
                    personal data, where the provisionof the same will
                    constitute an immediate and automatic material breach of
                    these terms. Should you be a minor, you may only use the
                    Services and/or provide us with your personal data if your
                    parent/guardian accepts this Privacy Policy on your behalf
                    and supervises your use of the Services/Website at all
                    times. In such a situation, your parent/guardian expressly
                    consents to our processing of their child’s/ward’s personal
                    data, and accepts all liability for their child’s/ward’s use
                    of the Services/Website at all times.
                  </span>
                </li>
              </ol>

              <b>Contact details </b>
              <p>Our full details are: </p>
              {/* <p>Full name of legal entity : ASK PROPERTY SOUTH AFRICA </p> */}
              <p>Full name of legal entity : ASK PROPERTY LISTING SERVICES</p>

              <p>Name of Information Officer : Cleone Van Niekerk </p>
              <p>Email address : cleo.vanniekerk@askproperty.co.za </p>
              <p>Postal address : N/A </p>

              <p>
                <span>
                  If you see an undefined term in this Privacy Policy (such as
                  “Listing” or “ Ask Property Platform”), it has the same
                  definition as in our
                </span>
                <span> Terms of Service Policy</span>
                <span>.</span>
              </p>
              <p>
                <span>
                  When this policy mentions “ Ask Property ,” “we,” “us,” or
                  “our,” it refers to Ask Property as the company that is
                  responsible for your information under this Privacy Policy.
                </span>
              </p>
              <p>
                <span>
                  When this policy mentions “ Ask Property ,” “you,” “your,” or
                  “you’re,” it refers to Ask Property Member(s) or user(s).
                </span>
              </p>
              <p>
                <strong>
                  <span>1. INFORMATION ASK PROPERTY COLLECT</span>
                </strong>
              </p>
              <p>
                <span>
                  There are three general categories of information we collect
                  and store.
                </span>
              </p>
              <p>
                <strong>
                  <span>1.1 Information the user gives to Ask Property .</span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>
                    1.1.1 Information that is necessary for the use of the Ask
                    Property Platform
                  </span>
                </strong>
                <span>.</span>
              </p>
              <p>
                <span>
                  We request for and collect the following personal information
                  about the user of Ask Property Platform. This information is
                  indispensable for the adequate performance of the contract
                  between you and us and to allow us to comply with our legal
                  obligations. Without it, we may not be able to provide you
                  with all the requested services.
                </span>
              </p>
              <ul>
                <li>
                  <strong>
                    <span> Ask Property Account Information.</span>
                  </strong>
                  <span>
                    {" "}
                    When you sign up for a Ask Property Account, we require
                    certain information such as your first name, gender, last
                    name, email address, occupation cell number, FCC number and
                    ID number in case of agencies etc.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Identity Data</span>
                  </strong>
                  <span></span>
                  <span>
                    {" "}
                    including first name, maiden name, last name, country of
                    residence, username or similar identifier, title,ID/passport
                    number.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Contact Data</span>
                  </strong>
                  <span>
                    {" "}
                    including email address, social media contact details and
                    telephone numbers
                  </span>
                  <span>;</span>
                </li>
                <li>
                  <strong>
                    <span>Profile and Listing Information.</span>
                  </strong>
                  <span>
                    {" "}
                    To use certain features of the Ask Property Platform
                    (creating a Listing), we may ask you to provide additional
                    information, which may include your address, phone number,
                    and a profile picture, FCC certificates number, ID number
                    etc.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Identity Verification Information.</span>
                  </strong>
                  <span>
                    {" "}
                    To help create and maintain a trusted environment, we will
                    collect identity verification information (such as ID,
                    passport or driving license, as permitted by applicable
                    laws) or other authentication information. This is more
                    applicable to Real Estate agencies and Principals in
                    particular.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Payment Information.</span>
                  </strong>
                  <span>
                    {" "}
                    To use certain features of the Ask Property Platform
                    (creating a Listing), we may require you to provide certain
                    financial information (such asyour bank account or credit
                    card information) in order to facilitate the processing of
                    payments (via Ask Property payment systems partners).
                  </span>
                </li>
                <li>
                  <strong>
                    <span>
                      Communications with Ask Property and other Members.
                    </span>
                  </strong>
                  <span>
                    {" "}
                    When you communicate with Ask Property or use the Ask
                    Property Platform to communicate with other Members, we
                    collect information about your communication and any
                    information you choose to provide.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Marketing and Communications.</span>
                  </strong>
                  <span>
                    {" "}
                    Data including your preferences in receiving notices and
                    marketing from us and our third parties and your
                    communication preferences.
                  </span>
                </li>
              </ul>
              <p>
                <strong>
                  <span>1.1.2 Information you choose to give us.</span>
                </strong>
              </p>
              <p>
                <span>
                  You may choose to provide us with additional personal
                  information in order to obtain a better user experience when
                  using Ask Property Platform. This additional information will
                  be processed based on your consent.
                </span>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Additional Profile Information.</span>
                  </strong>
                  <span>
                    You may choose to provide additional information as part of
                    your Ask Property profile (such as occupation, City and
                    self-description). Some of this information as indicated in
                    your Account settings is part of your public profile page,
                    and will be publicly visible to others such as your name,
                    City and Occupation.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Address Book Contact Information.</span>
                  </strong>
                  <span>
                    You may choose to import your address book contacts or enter
                    your contacts’ information manually to access certain
                    features of the Ask Property Platform, like inviting them to
                    use Ask Property .
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Other Information.</span>
                  </strong>
                  <span>
                    You may otherwise choose to provide us information when you
                    fill in a form, conduct a search, update or add information
                    to your Ask Property Account, respond to surveys, post to
                    community forums, participate in promotions, or use other
                    features of the Ask Property Platform.
                  </span>
                </li>
              </ul>
              <p>
                <strong>
                  <span>
                    1.1.3 Information that is necessary for the use of the
                    payment services.
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  {" "}
                  Ask Property ’sdata controller shall collect the following
                  information, as it is necessary for the adequate performance
                  of the contract with you and to comply with applicable law
                  (such as anti-money laundering regulations). Without it, you
                  will not be able to use our payment services:
                </span>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Payment Information.</span>
                  </strong>
                  <span>
                    When you use the payment services, the payments data
                    Controller requires certain financial information (like your
                    bank account or credit card information) in order to process
                    payments and comply with applicable law.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Identity Verification and Other Information.</span>
                  </strong>
                  <span>
                    If you are a principal of an agency, the payments data
                    Controller will require identity verification information
                    (such as images of your valid ID, passport, student card, or
                    driving license) or other authentication information, your
                    date of birth, your address, email address, phone number and
                    other information in order to verify your identity, provide
                    the payment services to you, and to comply with applicable
                    law.
                  </span>
                </li>
              </ul>

              <p>
                <strong>
                  <span>1.1.4 How is your personal data collected?</span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>i).Through direct interactions</span>
                </strong>
                <span>
                  . You may provide us with your Identity, Contact, Technical,
                  Profile and Financial Data by filling in various Ask Property
                  forms when signing upwith us and/or by corresponding. This may
                  include personal data you may be required to provide when you:
                </span>
              </p>

              <ul>
                <li>
                  <span>
                    Our Platform use our Services (as an Agency, Individual or
                    otherwise)
                  </span>
                </li>
                <li>
                  <span>Our Website</span>
                </li>
                <li>
                  <span>Any agreement with us</span>
                </li>
                <li>
                  <span>Any provision of services or consultation with us</span>
                </li>
                <li>
                  <span>Complete forms</span>
                </li>
                <li>
                  <span>Sign-up for news letters or subscriptions with us</span>
                </li>
                <li>
                  <span>
                    Interact with us via, messaging apps,webinar orany social
                    platform, such as a Facebook™ group
                  </span>
                </li>
                <li>
                  <span>Request information to be sent to you</span>
                </li>
                <li>
                  <span>Provide us with any feedback</span>
                </li>
              </ul>
              <p>
                <span></span>
              </p>
              <p>
                <strong>
                  <span>
                    1.2 Information We Automatically Collect from Your Use of
                    the Ask Property Platform and Payment Services.
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  When you use the Ask Property Platform andour payment
                  services, we automatically collect information, including
                  personal information, about the services you use and how you
                  use them. This information is indispensable for the adequate
                  performance of the contract between you and us, to enable us
                  to comply with legal obligations and given our legitimate
                  interest in being able to provide and improve the
                  functionalities of the Ask Property Platform and payment
                  services.
                </span>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Geo-location Information.</span>
                  </strong>
                  <span>
                    When you use certain features of the Ask Property Platform,
                    we may collect information about your precise or approximate
                    location as determined through data such as your IP address
                    or mobile device’s GPS to offer you an improved user
                    experience. Most mobile devices allow you to control or
                    disable the use of location services for applications in the
                    device’s settings menu.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Usage Information.</span>
                  </strong>
                  <span>
                    We collect information about your interactions with the Ask
                    Property Platform such as the pages or content you view,
                    your searches for Listings and other actions on the Ask
                    Property Platform.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Log Data and Device Information.</span>
                  </strong>
                  <span>
                    We automatically collect log data and device information
                    when you access and use the Ask Property Platform, even if
                    you have not created a Ask Property Account or logged in.
                    That information includes, among other things: details about
                    how you’ve used the Ask Property Platform (including if you
                    clicked on links to third party applications), IP address,
                    access dates and times, hardware and software information,
                    device information, device event information, unique
                    identifiers, crash data, cookie data, and the pages you’ve
                    viewed or engaged with before or after using the Ask
                    Property Platform.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Cookies and Similar Technologies.</span>
                  </strong>
                  <span>
                    We use cookies and other similar technologies, such as web
                    beacons, pixels, and mobile identifiers. We may also allow
                    our business partners to use these tracking technologies on
                    the Ask Property Platform, or engage others to track your
                    behavior on our behalf. While you may disable the usage of
                    cookies through your browser settings, the Ask Property
                    Platform currently does not respond to a “Do Not Track”
                    signal in the HTTP header from your browser or mobile
                    application due to lack of standardization regarding how
                    that signal should be interpreted. For more information on
                    our use of these technologies, see our
                  </span>
                  <span>Cookie Policy</span>
                  <span>. </span>
                </li>
                <li>
                  <strong>
                    <span>Payment Transaction Information.</span>
                  </strong>
                  <span>
                    {" "}
                    Ask Property collects information related to your payment
                    transactions through the Ask Property Platform, including
                    the payment instrument used, date and time, payment amount,
                    payment instrument expiration date and billing address,
                    email address, and other related transaction details. This
                    information is necessary for the adequate performance of the
                    contract between you and Ask Property payments and to allow
                    the provision of the payment services.
                  </span>
                </li>
              </ul>
              <p>
                <strong>
                  <span>1.3 Information We Collect from Third Parties.</span>
                </strong>
              </p>
              <p>
                <span>
                  {" "}
                  Ask Property may collect information, including personal
                  information,that others provide about you when they use the
                  Ask Property Platform and our payment services, or obtain
                  information from other sources and combine that with
                  information we collect through the Ask Property Platform and
                  the payment services. We do not control, supervise or respond
                  for how the third parties providing your information process
                  your personal data, and any information request regarding the
                  disclosure of your personal information to us should be
                  directed to such third parties.
                </span>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Third Party Services.</span>
                  </strong>
                  <span>
                    If you link, connect, or login to your Ask Property Account
                    with a third party service (e.g. Google, Facebook, or any
                    party interlinked to Ask Property ), the third party service
                    may send us information such as your registration and
                    profile information from that service. This information
                    varies and is controlled by that service or as authorised by
                    you via your privacy settings at that service.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Your Reviews.</span>
                  </strong>
                  <span>
                    If someone has written a review for you, it will be
                    published on your Ask Property public profile page with your
                    consent.
                  </span>
                </li>
                <li>
                  {" "}
                  <strong>Background Information.</strong>
                </li>
                <li>
                  <strong>
                    <span>Other Sources.</span>
                  </strong>
                  <span>
                    To the extent permitted by applicable law, we may receive
                    additional information about you, such as demographic data
                    or fraud detection information, from third party service
                    providers and/or partners, and combine it with information
                    we have about you. For example, we may receive background
                    check results (with your consent where required) or fraud
                    warnings from service providers like identity verification
                    services for our fraud prevention and risk assessment
                    efforts. We may receive information about you and your
                    activities on and off the Ask Property Platform through
                    partnerships, or about your experiences and interactions
                    from our partner ad networks.
                  </span>
                </li>
              </ul>
              <br />
              <p>
                <strong>
                  <span>2. HOWASK PROPERTY USE INFORMATION WE COLLECT</span>
                </strong>
              </p>
              <p>
                <span>
                  We use, store, and process information,including personal
                  information about you to provide, understand, improve, and
                  develop the Ask Property Platform, create and maintain a
                  trusted and safer environment and comply with our legal
                  obligations.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    2.1 Provide, Improve, and Develop the Ask Property Platform.
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <span>
                    Enable the Member to access and use the Ask Property
                    Platform.
                  </span>
                </li>
                <li>
                  <span>
                    Enable the Member to communicate with other Members.
                  </span>
                </li>
                <li>
                  <span>
                    Operate, protect, improve, and optimize the Ask Property
                    Platform and experience, such as by performing analytics and
                    conducting research.
                  </span>
                </li>
                <li>
                  <span>Provide customer service.</span>
                </li>
                <li>
                  <span>
                    Sendthe Member service or support messages, updates,
                    security alerts, and account notifications.
                  </span>
                </li>
                <li>
                  <span>
                    Ifa Member provide us withhis/hercontacts’ information, we
                    may process this information: (i) to facilitate the
                    Member’sreferral invitations, (ii) send the Member requests
                    for references, (iii) for fraud detection and prevention,
                    and (iv) for any purposethe Member’s authorise at the time
                    of collection.
                  </span>
                </li>
                <li>
                  <span>
                    To operate, protect, improve, and optimize the Ask Property
                    Platform and experience, and personalize and customize your
                    experience (such as making listing suggestions or ranking
                    search results), we conduct profiling based on your
                    interactions with the Ask Property Platform, your profile
                    information and preferences, and other content you submit to
                    the Ask Property Platform.
                  </span>
                </li>
              </ul>

              <div align="center">
                <table className="policy-table-border">
                  <tbody className="policy-table-border">
                    <tr className="policy-table-border">
                      <td className="policy-table-border">
                        <p>
                          <strong>
                            <span>PURPOSE / ACTIVITY</span>
                          </strong>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <strong>
                            <span>
                              LAWFUL BASIS FOR PROCESSING INCLUDING BASIS OF
                              LEGITIMATE INTEREST
                            </span>
                          </strong>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <strong>
                            <span>
                              SPECIFIC EXTERNAL THIRD PARTY TO WHOM THE
                              INFORMATION IS PROVIDED, AND FOR WHAT REASON (IF
                              ANY)
                            </span>
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To engage with you after you have contacted us
                            requesting an engagement via the Website or
                            otherwise.
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Profile</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>Express consent</span>
                            </li>
                            <li>
                              <span>Performance of a contract with you</span>
                            </li>
                            <li>
                              <span>
                                Necessary for our legitimate interests (to keep
                                our records updated and to study how engagees
                                use our services, as well as to develop our
                                services and grow our organisation)
                              </span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            Amazon based in USA (with servers in Cape Town, RSA)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To contract with you as a service provider and/or
                            creditor to Ask Property .
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Transaction</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>Performance of a contract with you</span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                            <li>
                              <span>
                                Necessary to comply with a legal obligation
                              </span>
                            </li>
                            <li>
                              <span>
                                Necessary for our legitimate interests (to allow
                                you to provide us with your services, and to
                                keep our records updated and to study how we may
                                use third-party services)
                              </span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            Amazon based in USA (with servers in Cape Town, RSA)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>To allow you to use the Website</span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Profile</span>
                        </p>
                        <p>
                          <span>Usage</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>Performance of a contract with you</span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>Alphabet Inc based in USA</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To process and service payment for any services
                            rendered to Ask Property or its service providers
                          </span>
                        </p>
                        <p>
                          <span>To manage payments, fees and charges.</span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Financial</span>
                        </p>
                        <p>
                          <span>Transaction</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>Performance of a contract with you</span>
                            </li>
                            <li>
                              <span>
                                Necessary for our legitimate interests (to make
                                or receive necessary organisation payments)
                              </span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>Payfast based in RSA</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To allow you to be a registered agent/agency on the
                            Website and use the Services as such.
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Profile</span>
                        </p>
                        <p>
                          <span>Usage</span>
                        </p>
                        <p>
                          <span>Marketing and</span>
                          <span></span>
                          <span> Communications</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>Performance of a contract with you</span>
                            </li>
                            <li>
                              <span>
                                Necessary for our legitimate interests (to make
                                or receive necessary organisation payments)
                              </span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>Agencies(based in chosen location)</span>
                        </p>
                        <p>
                          <span>
                            Authorised and contracted third-party verifications
                            service providers Amazon based in USA & Payfast
                            based in RSA
                          </span>
                          <span> </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To allow you to be a registered landlord on the
                            Website and use the Services as such.
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Profile</span>
                        </p>
                        <p>
                          <span>Usage</span>
                        </p>
                        <p>
                          <span>Social Media</span>
                        </p>
                        <p>
                          <span>Marketing and Communications</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>Performance of a contract with you</span>
                            </li>
                            <li>
                              <span>
                                Necessary for our legitimate interests (to make
                                or receive necessary organisation payments)
                              </span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            Agencies, individual users (based in chosen
                            location)
                          </span>
                        </p>
                        <p>
                          <span>
                            Authorised and contracted third-party verifications
                            service providers Hubspot based in USA
                          </span>
                          <span> </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To manage our relationship with you which may
                            include notifying you about changes to our terms or
                            Privacy Policy or Services.
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Usage</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>Performance of a contract with you</span>
                            </li>
                            <li>
                              <span>
                                Necessary to comply with a legal obligation
                              </span>
                            </li>
                            <li>
                              <span>
                                Necessary for our legitimate interests (to keep
                                our records updated and to study how engagees
                                use our Services)
                              </span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            Amazon based in USA (with servers in Cape Town, RSA)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To administer and protect our company and our
                            Website (including troubleshooting, data analysis,
                            testing, system maintenance, support, reporting and
                            Agencying of data and email Agencying)
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Usage</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>
                                Necessary for our legitimate interests (for
                                running our organisation, provision of
                                administration and IT services, network
                                security, to prevent fraud and in the context of
                                an organisation restructuring exercise)
                              </span>
                            </li>
                            <li>
                              <span>
                                Necessary to comply with a legal obligation
                              </span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            Amazon based in USA (with servers in Cape Town, RSA)
                          </span>
                        </p>

                        <p>
                          <span>Domains.co.za based in RSA</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To use data analytics to improve our Website,
                            Services,engagee-relationships and experiences.
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Usage</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>
                                Necessary for our legitimate interests (to
                                define types of engagees for our services, to
                                keep our Website updated and relevant, to
                                develop our organisation and to inform our
                                marketing strategy)
                              </span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>Alphabet Inc based in USA</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To provide you with direct anduser-specific
                            marketing, make suggestions and recommendations to
                            you about events or services that may be of interest
                            to you.
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Identity</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Marketing and Communications</span>
                        </p>
                        <p>
                          <span>Social Media</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <div>
                          <ol>
                            <li>
                              <span>
                                Necessary for our legitimate interests(to
                                develop our services and grow our organisation)
                              </span>
                            </li>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>Alphabet Inc based in USA</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="policy-table-border">
                        <p>
                          <span>
                            To provide you with email notifications, market your
                            listings, mass communications etc.
                          </span>
                        </p>

                        <p>
                          <strong>
                            <span>Type of Datause:</span>
                          </strong>
                        </p>
                        <p>
                          <span>Email address</span>
                        </p>
                        <p>
                          <span>Contact</span>
                        </p>
                        <p>
                          <span>Technical</span>
                        </p>
                        <p>
                          <span>Marketing and Communications</span>
                        </p>
                        <p>
                          <span>Social Media</span>
                        </p>
                      </td>
                      <td className="policy-table-border">
                        <ol>
                          <li>
                            <span>
                              Necessary for our legitimate interests(to develop
                              our services and grow our organisation)
                            </span>
                          </li>
                        </ol>
                        <div>
                          <ol>
                            <li>
                              <span>Express consent</span>
                            </li>
                          </ol>
                        </div>
                      </td>
                      <td className="policy-table-border">
                        <p>
                          <span>Sendgrid based in USA</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ul>
                <li>
                  <span>
                    We process this information given our legitimate interest in
                    improving the Ask Property Platform and our Members’
                    experience with it, and where it is necessary for the
                    adequate performance of the contract with you.
                  </span>
                </li>
              </ul>

              <p>
                <strong>
                  <span>
                    2.2 Create and Maintain a Trusted and Safer Environment.
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <span>
                    Detect and prevent fraud, spam, abuse, security incidents,
                    and other harmful activity.
                  </span>
                </li>
                <li>
                  <span>
                    Conduct security investigations and risk assessments.
                  </span>
                </li>
                <li>
                  <span>
                    Verify or authenticate information or identifications
                    provided by you (such as to verify your Accommodation
                    address or compare your identification photo to another
                    photo you provide).
                  </span>
                </li>
                <li>
                  <span>
                    Conduct checks against databases and other information
                    sources, including background or police checks, to the
                    extent permitted by applicable laws and with your consent
                    where required.
                  </span>
                </li>
                <li>
                  <span>Comply with our legal obligations.</span>
                </li>
                <li>
                  <span>
                    Resolve any disputes with any of our Members and enforce our
                    agreements with third parties.
                  </span>
                </li>
                <li>
                  <span>Enforce our</span>
                  <span> Terms and Conditions of Service</span>
                  <span>and other policies.</span>
                </li>
                <li>
                  <span>
                    In connection with the activities above, we may conduct
                    profiling based on your interactions with the Ask Property
                    Platform, your profile information and other content you
                    submit to the Ask Property Platform, and information
                    obtained from third parties. In limited cases, automated
                    processes may restrict or suspend access to the Ask Property
                    Platform if such processes detect a Member or activity that
                    we think poses a safety or other risk to the Ask Property
                    Platform, other Members, or third parties.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  We process this information given our legitimate interest in
                  protecting the Ask Property Platform, to measure the adequate
                  performance of our contract with you, and to comply with
                  applicable laws.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    2.3 Provide, Personalise, Measure, and Improve our
                    Advertising and Marketing.
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <span>
                    Send you promotional messages, marketing, advertising, and
                    other information that may be of interest to you based on
                    your preferences (including information about Ask Property
                    or partner campaigns and services) and social media
                    advertising through social media platforms such as Facebook
                    or Google).
                  </span>
                </li>
                <li>
                  <span>
                    Personalize, measure, and improve our advertising.
                  </span>
                </li>
                <li>
                  <span>
                    Administer referral programs, rewards, surveys, sweepstakes,
                    contests, or other promotional activities or events
                    sponsored or managed by Ask Property or its third party
                    partners.
                  </span>
                </li>
                <li>
                  <span>
                    Conduct profiling on your characteristics and preferences
                    (based on the information you provide to us, your
                    interactions with the Ask Property Platform, information
                    obtained from third parties, and your search and Lease
                    history) to send you promotional messages, marketing,
                    advertising and other information that we think may be of
                    interest to you.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  We will process your personal information for the purposes
                  listed in this section given our legitimate interest in
                  undertaking marketing activities to offer you products or
                  services that may be of your interest. You can opt-out of
                  receiving marketing communications from us by following the
                  unsubscribe instructions included in our marketing
                  communications or changing your notification settings within
                  your Ask Property Account.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    2.4 How Ask Property uses the Information Collected.
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <span>
                    Enable you to access and use the Payment Services.
                  </span>
                </li>
                <li>
                  <span>
                    Detect and prevent fraud, abuse, security incidents, and
                    other harmful activity.
                  </span>
                </li>
                <li>
                  <span>
                    Conduct security investigations and risk assessments.
                  </span>
                </li>
                <li>
                  <span>
                    Conduct checks against databases and other information
                    sources.
                  </span>
                </li>
                <li>
                  <span>
                    Comply with legal obligations (such as anti-money laundering
                    regulations).
                  </span>
                </li>
                <li>
                  <span>Enforce the Payment Policy and other policies.</span>
                </li>
                <li>
                  <span>
                    With your consent, send you promotional messages, marketing,
                    advertising, and other information that may be of interest
                    to you based on your preferences.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  {" "}
                  Ask Property processes this information given its legitimate
                  interest in improving the Payment Services and its users’
                  experience with it, and where it is necessary for the adequate
                  performance of the contract with you and to comply with
                  applicable laws.
                </span>
              </p>
              <p>
                <strong>
                  <span>3. SHARING &amp; DISCLOSURE</span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>3.1 With Your Consent.</span>
                </strong>
              </p>
              <p>
                <span>
                  Where you have provided consent, we share your information,
                  including personal information, as described at the time of
                  consent, such as when you authorise a third party application
                  or website to access your Ask Property Account or when you
                  participate in promotional activities conducted by Ask
                  Property partners or third parties.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.2 Sharing between Members.</span>
                </strong>
              </p>
              <p>
                <span>
                  To help facilitate listings or other interactions between
                  Members, we may need to share certain information, including
                  personal information, with other Members, as it is necessary
                  for the adequate performance of the contract between you and
                  us, as follows:{" "}
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    When you as an agency, agent, submit alisting, some
                    requiredinformation about you is shared with the public
                    including your full name, the full name(s) of your agency
                    etc.
                  </span>
                </li>
                <li>
                  <span>
                    We don’t share your billing information with other users..{" "}
                  </span>
                </li>
              </ul>
              <p>
                <strong>
                  <span>
                    3.3 Profiles, Listings, and other Public Information.
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  The Ask Property Platform lets you publish information,
                  including personal information, that is visible to the general
                  public. For example:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    Parts of your public profile page, such as your first name,
                    your description, and city, are publicly visible to others.
                  </span>
                </li>
                <li>
                  <span>
                    Listing pages are publicly visible and include information
                    such as the Accommodation or Experience’s approximate
                    location (neighbourhoodand city) or precise location (where
                    you have provided your consent), Listing description,
                    occupation date and your public profile photo, aggregated
                    demand information (like page views over a period of time),
                    and any additional information you choose to share.
                  </span>
                </li>
                <li>
                  <span>
                    After listing a property on ask property or asking a
                    question, other users may share your post on social media
                    platforms.
                  </span>
                </li>
                <li>
                  <span>
                    If you submit content in a community or discussion forum,
                    blog or social media post, or use a similar feature on the
                    Ask Property Platform, that content is publicly visible.{" "}
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  Based on our legitimate interest to promote the Ask Property
                  Platform we may display parts of the Ask Property Platform
                  (e.g., your Listing page) on sites operated by Ask Property’s
                  business partners, using technologies such as widgets or APIs.
                  If your Listings are displayed on a partner’s site,
                  information from your public profile page may also be
                  displayed.
                </span>
              </p>
              <p>
                <span>
                  Information you share publicly on the Ask Property Platform
                  may be indexed through third party search engines. In some
                  cases, you may opt-out of this feature in your Account
                  settings. If you change your settings or your public-facing
                  content, these search engines may not update their databases.
                  We do not control the practices of third party search engines,
                  and they may use caches containing your outdated information.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.4 Additional Services by rental listings</span>
                </strong>
              </p>
              <p>
                <span>
                  Agents or individual may need to use third party services
                  available through the Ask Property Platform to assist with
                  managing their Accommodation or providing additional services
                  requested by you, such as cleaning services or lock providers.
                  Agents may use features on the Ask Property Platform to share
                  information about the Tenant(like check-in and check-out
                  dates, Tenantname, Tenantphone number) with such third party
                  service providers for the purposes of coordinating the stay,
                  managing the Accommodation, or providing other services.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    3.5 Compliance with Law, Responding to Legal Requests,
                    Preventing Harm and Protection of our Rights.
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  {" "}
                  Ask Property may disclose your information, including personal
                  information, to courts, law enforcement or governmental
                  authorities, or authorised third parties, if and to the extent
                  we are required or permitted to do so by law or if such
                  disclosure is reasonably necessary: (i) to comply with our
                  legal obligations, (ii) to comply with legal process and to
                  respond to claims asserted against Ask Property , (iii) to
                  respond to verified requests relating to a criminal
                  investigation or alleged or suspected illegal activity or any
                  other activity that may expose us, you, or any other of our
                  users to legal liability, (iv) to enforce and administer our
                </span>
                <span> Terms and Conditions of Service Policy</span>
                <span>
                  , the Payment Policy or other agreements with Members, or (v)
                  to protect the rights, property or personal safety of Ask
                  Property , its employees, its Members, or members of the
                  public.
                </span>
              </p>
              <p>
                <span>
                  These disclosures may be necessary to comply with our legal
                  obligations, for the protection of your or another person's
                  vital interests or for the purposes of our or a third party’s
                  legitimate interest in keeping the Ask Property Platform
                  secure, preventing harm or crime, enforcing or defending legal
                  rights, or preventing damage.
                </span>
              </p>
              <p>
                <span>
                  Where appropriate, we may notify Members about legal requests
                  unless: (i) providing notice is prohibited bythe legal process
                  it self, by court order we receive, or by applicable law, or
                  (ii) we believe that providing notice would be futile,
                  ineffective, create a risk of injury or bodily harm to an
                  individual or group, or create or increase a risk of fraud
                  upon Ask Property ’sproperty, its Members and the Ask Property
                  Platform.In instances where we comply with legal requests
                  without notice for these reasons, we will attempt to notify
                  that Member about the request after the fact where appropriate
                  and where we determine in good faith that we are no longer
                  prevented from doing so.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.6 Service Providers.</span>
                </strong>
              </p>
              <p>
                <span>
                  {" "}
                  Ask Property uses a variety of third party service providers
                  to help us provide services related to the Ask Property
                  Platform. Service providers may be located inside or outside
                  of South Africa. In particular, our service providers are
                  based in South Africa, US, Europe and North and South America.
                </span>
              </p>
              <p>
                <span>
                  For example, service providers may help us: (i) verify your
                  identity or authenticate your identification documents, (ii)
                  check information against public databases, (iii) conduct
                  background or police checks, fraud prevention, and risk
                  assessment, (iv) perform product development, maintenance and
                  debugging, (v) allow the provision of the Ask Property
                  Services through third party platforms and software tools
                  (e.g. through the integration with our APIs), or (vi) provide
                  customer service, advertising, or payments services. These
                  providers have limited access to your information to perform
                  these tasks on our behalf, and are contractually bound to
                  protect and to use it only for the purposes for which it was
                  disclosed and consistent with this Privacy Policy.
                </span>
              </p>
              <p>
                <span>
                  {" "}
                  Ask Property will need to share your information, including
                  personal information, in order to ensure the adequate
                  performance of our contract with you.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.7 Corporate Affiliates.</span>
                </strong>
              </p>
              <p>
                <span>
                  To enable or support us in providing the Ask Property
                  Platform, we may share your information, including personal
                  information, within our corporate family of companies (both
                  financial and non-financial entities) that are related by
                  common ownership or control.
                </span>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Sharing with Ask Property .</span>
                  </strong>
                  <span>
                    {" "}
                    Even if your Country of Residence is not South Africa, your
                    information may be shared with Ask Property . which provides
                    the technical infrastructure for the Ask Property Platform,
                    product development and maintenance, customer support, trust
                    and safety and other business operation services to other
                    Ask Property entities. The data sharing is necessary for the
                    performance of the contract between you and us and is based
                    on our legitimate interests in providing the Ask Property
                    Platform globally.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Sharing with Ask Property payment services.</span>
                  </strong>
                  <span>
                    {" "}
                    In order to facilitate payments on or through the Ask
                    Property Platform, certain information as described above in
                    Section 1.1.3, may be shared with the relevant Ask Property
                    partners. The data sharing is necessary for the performance
                    of the contract between you and us.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>
                      Sharing with Ask Property while residing outside SA.
                    </span>
                  </strong>
                  <span>
                    {" "}
                    If your Country of Residence is South Africa, some of your
                    information may be shared with Ask Property partners when
                    you create a Listing or when you book aAgency Service
                    located outside of South Africa. The information shared in
                    these circumstances may include: (i) your name, e-mail
                    address and phone number, (ii) information relating to the
                    Listing or Agency Service, including its address, (iii)
                    Lease information for the Listing or Agency Service,
                    including but not limited to Lease dates and payment
                    amounts, and (iv) the names, nationalities, and
                    identification details (including passport/national ID
                    numbers and expiry dates) of any Individual users.
                    Additionally, when you send a message to aAgency or Tenant
                    outside of South Africa, your first name, profile picture
                    and message content sent via the Ask Property Platform will
                    be shared. The data sharing is necessary for the performance
                    of the contract between you and us.
                  </span>
                </li>
              </ul>
              <br />
              <p>
                <strong>
                  <span>3.8 Property Listing Service</span>
                </strong>
              </p>

              <ul>
                <li>
                  <span>
                    If you use the Ask Property listing service then, in
                    addition to the terms and conditions above, the following
                    terms and conditions apply.
                  </span>
                </li>
                <li>
                  <span>
                    In the following, "material" includes any text, photographs,
                    floor plans or other material prepared and submitted by any
                    third party service provider on your behalf.
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    Ask Property is not, and does not hold it self out to be:
                  </span>
                  <ul>
                    <li>
                      <span>
                        an estate agent generally, or an "estate agent" as
                        defined and contemplated in the Estate Agency Affairs
                        Act, 1976, as amended, or any replacement legislation,
                        in particular; or
                      </span>
                    </li>
                    <li>
                      <span>
                        an attorney, conveyancer, financial or tax advisor,
                        mortgage bond originator, or valuer.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    {" "}
                    Ask Property has no power as agent or otherwise to negotiate
                    or conclude any agreement on your behalf, and no such power
                    must be inferred. You are liable for all obligations and
                    consequences howsoever arising from your negotiation or
                    conclusion of any agreement pursuant to the use of the
                    Listing Service. You are strongly urged to make detailed
                    enquiries about any party that you intend negotiating or
                    contracting with, and to obtain advice from a qualified
                    professional before concluding any contract.
                  </span>
                </li>
                <li>
                  <span>You use the Listing Service at your own risk.</span>
                </li>
                <li>
                  <span>
                    When using the Listing Service you may not submit any
                    material that:
                  </span>
                  <ul>
                    <li>
                      <span>is unlawful;</span>
                    </li>
                    <li>
                      <span>is harmful to minors;</span>
                    </li>
                    <li>
                      <span>is fraudulent, false or misleading;</span>
                    </li>
                    <li>
                      <span>
                        infringes any third party intellectual property,
                        personal, confidentiality, or other rights; or
                      </span>
                    </li>
                    <li>
                      <span>is defamatory or obscene.</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    You are solely responsible for all material that you submit
                    and any consequences that may result from your submission.
                    Ask Property has the right (but not the obligation), in its
                    sole discretion and without notice, to refuse or to delete
                    any material that Ask Property in its sole discretion
                    considers to be in breach of these terms and conditions.
                  </span>
                </li>
                <li>
                  <span>
                    You must submit all material in the format and detail
                    determined by Ask Property from time to time.
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    Ask Property has the right (but not the obligation), without
                    notice:
                  </span>
                  <ul>
                    <li>
                      <span>
                        to monitor and moderate all material submitted on the
                        Listing Service; and
                      </span>
                    </li>
                    <li>
                      <span>
                        to refuse or to restrict any user's use of the Listing
                        Service, temporarily or permanently.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span> Ask Property :</span>
                  <ul>
                    <li>
                      <span>
                        is not responsible for the accuracy of any material that
                        is submitted; and
                      </span>
                    </li>
                    <li>
                      <span>
                        does not endorse any material submitted or product
                        listed, and no such endorsement must be inferred, or
                        claimed by you.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    You grant Ask Property a perpetual, royalty-free,
                    transferable right to publish all material submitted by you.
                  </span>
                </li>
                <li>
                  <span>
                    You must comply with all applicable laws, including (without
                    limitation) the Consumer Protection Act, 2008 (in
                    particular, sections 29 to 39 of that act) and the
                    Alienation of Land Act, 1981.
                  </span>
                </li>
                <li>
                  <span>
                    If you instruct Ask Property to contract with any third
                    party to prepare and submit material on your behalf, Ask
                    Property is not liable to you for any damages or costs
                    howsoever arising as a result of any act or omission on the
                    part of the third party, including (without limitation) any
                    deficiency in the material, or damage or loss incurred at
                    your premises when the third party renders the service; and
                    you indemnify Ask Property accordingly.
                  </span>
                </li>
                <li>
                  <span>
                    You agree to pay all fees and other amounts charged by Ask
                    Property for using the Listing Service from time to time. If
                    you fail to pay the fees when due, Ask Property may (without
                    prejudice to any other rights it may have), without notice,
                    suspend your use of the Listing Service temporarily or
                    permanently.
                  </span>
                </li>
              </ul>
              <p>
                <strong>
                  <span>3.9 Our Content and Materials</span>
                </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Definition of Our Content and Materials</span>
                  </strong>
                  <span>
                    . All intellectual property in or related to the Ask
                    Property Platform (specifically including, but not limited
                    to our software, the Ask Property marks, the Ask Property
                    logo, but excluding Your Content) is the property of Ask
                    Property , Inc., or its subsidiaries and affiliates, or
                    content posted by other Ask Property users licensed to us
                    (collectively “Our Content and Materials”).
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Data</span>
                  </strong>
                  <span>
                    . All data Ask Property collects (“Data”) about use of the
                    Ask Property Platform by you or others is the property of
                    Ask Property , Inc., its subsidiaries, and affiliates. For
                    clarity, Data does not include Your Content and is separate
                    from Our Content and Materials.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Our License to You.</span>
                  </strong>
                  <ul>
                    <li>
                      <span>
                        We grant you a personal, limited, non-exclusive license
                        to use and access Our Content and Materials and Data as
                        made available to you on the Ask Property Platform in
                        connection with your use of the Ask Property Platform,
                        subject to the terms and conditions of this Agreement.
                      </span>
                    </li>
                    <li>
                      <span>
                        {" "}
                        Ask Property gives you a worldwide, royalty-free,
                        revocable, personal, non-assignable and non-exclusive
                        license to re-post Our Content and Materials anywhere on
                        the web, subject to these Terms and Conditions and
                        provided that: (a) the content in question was added to
                        the Ask Property Platform after April 22, 2010; (b) the
                        user who created the content has not explicitly marked
                        the content as not for reproduction on the Ask Property
                        Platform; (c) you do not modify the content; (d) you
                        attribute Ask Property by name in readable text and with
                        a human and machine-followable link (an HTML &lt;a&gt;
                        anchor tag) linking back to the page displaying the
                        original source of the content on{" "}
                        <u>
                          <span>https://www.askproperty.co.za</span>
                        </u>{" "}
                        on every page that contains Our Content and Materials;
                        (e) upon request, either by Ask Property or a user, you
                        remove the user's name from content which the user has
                        subsequently made anonymous; (f) upon request, either by
                        Ask Property or by a user who contributed to the
                        content, you make a reasonable effort to update a
                        particular piece of content to the latest version on the
                        Ask Property Platform; and (g) upon request, either by
                        Ask Property or by a user who contributed to the
                        content, you make a reasonable attempt to delete content
                        that has been deleted or marked as not for reproduction
                        on the Ask Property Platform; (h) you don’t republish
                        more than a small portion of Our Content and Materials;
                        (i) you do not use any automated tools to aggregate or
                        create derivative works. In exercising these rights, you
                        may not implicitly or explicitly assert any connection
                        with, sponsorship or endorsement by Ask Property , or
                        any Ask Property user, without separate, express prior
                        written permission from us.
                      </span>
                    </li>
                    <li>
                      <span>
                        We may terminate our license to you at any time for any
                        reason. We have the right but not the obligation to
                        refuse to distribute any content on the Ask Property
                        Platform or to remove content. Except for the rights and
                        license granted in these Terms of Service, we reserve
                        all other rights and grant no other rights or licenses,
                        implied or otherwise.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    <span>Permitted uses</span>
                  </strong>
                  <span>
                    . If you operate a search engine, web crawler, bot, scraping
                    tool, data mining tool, bulk downloading tool, utility, or
                    similar data gathering or extraction tool, you may access
                    the Ask Property Platform, subject to the following
                    additional rules: i) you must use a descriptive user agent
                    header; ii) you must follow robots.txt at all times; iii)
                    your access must not adversely affect any aspect of the Ask
                    Property Platform’s functioning; and iv) you must make it
                    clear how to contact you, either in your user agent string,
                    or on your website if you have one. You represent and
                    warrant that you will not use any automated tools such as
                    artificial intelligence or machine learning i) to create
                    derivative works of Our Content and Materials; ii) to create
                    any service competitive to the Ask Property Platform; or
                    iii) for other commercial purposes except as expressly
                    permitted by these Terms of Service or the written consent
                    of Ask Property .
                  </span>
                  <ul>
                    <li>
                      <strong>
                        <span>No Endorsement or Verification</span>
                      </strong>
                      <span>
                        . Please note that the Ask Property Platform contains
                        access to third-party content, products and services,
                        and it offers interactions with third parties.
                        Participation or availability on the Ask Property
                        Platform does not amount to endorsement or verification
                        by us. We make no warranties or representations about
                        the accuracy, completeness, or timeliness of any content
                        posted on the Ask Property Platform by anyone.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>Ownership</span>
                      </strong>
                      <span>
                        . You acknowledge and agree that Our Content and
                        Materials remain the property of Ask Property 's users
                        or Ask Property . The content, information and services
                        made available on the Ask Property Platform are
                        protected by SOUTH AFRICA and international copyright,
                        trademark, and other laws, and you acknowledge that
                        these rights are valid and enforceable.
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <strong>
                  <span>
                    3.10 Certain offerings on the Ask Property Platform
                  </span>
                </strong>
              </p>
              <p>
                <strong>
                  <span></span>
                </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Advertisements</span>
                  </strong>
                  <span>
                    . The Ask Property Platform may include advertisements,
                    which may be targeted to content or information on the Ask
                    Property Platform, queries made through the Ask Property
                    Platform, or other information, in an effort to make them
                    relevant to you. The types and extent of advertising by Ask
                    Property are subject to change. In consideration for Ask
                    Property granting you access to and use of the Ask Property
                    Platform, you agree that Ask Property and its third-party
                    providers and partners may place such advertising on the Ask
                    Property Platform. If you wish to become an advertiser, you
                    will be required to enter into separate and supplemental
                    terms with us about providing advertising services on the
                    Ask Property Platform.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Legal &amp; Other Professional Contributors</span>
                  </strong>
                  <span>
                    . Some users who post content are members of legal and other
                    licensed professions (collectively, “Professional
                    Contributors”). Content posted by Professional Contributors
                    should not be relied on as a substitute for advice from a
                    professional that is appropriate for your specific
                    situation. Ethics rules differ by country or location, and
                    it is the responsibility of Professional Contributors to
                    determine and provide disclaimers appropriate for their
                    profession and the content provided.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Buttons, Links and Widgets</span>
                  </strong>
                  <span>
                    . You have permission to use Ask Property 's buttons, links,
                    and widgets, subject to these Terms of Service (including
                    the disclaimers and limitations of liability) and provided
                    that: (a) your use of such buttons, links and widgets link
                    only to the Ask Property Platform; (b) you will not modify
                    such buttons, links, or widgets or associated code in any
                    manner; (c) you will not use any such buttons, links, or
                    widgets in any manner which implies or suggests that Ask
                    Property endorses, sponsors, or recommends the website on
                    which such buttons, links and widgets are used; and (d) the
                    use of such buttons, links and widgets, and the website on
                    which such buttons, links and widgets are used do not
                    violate Ask Property ’s{" "}
                    <u>
                      <span>Acceptable Use Policy</span>
                    </u>
                    .
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Web resources and third-party services</span>
                  </strong>
                  <span>
                    . The Ask Property Platform may also offer you the
                    opportunity to visit links to other websites or to engage
                    with third-party products or services. You assume all risk
                    arising out of your use of such websites or resources.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Services that Require Separate Agreement</span>
                  </strong>
                  <span>
                    . Certain features or services may require that you enter
                    into a separate and supplemental written agreement prior to
                    use.
                  </span>
                </li>
              </ul>
              <p>
                <strong>
                  <span></span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>3.8 Social Media Platforms.</span>
                </strong>
              </p>
              <p>
                <span>
                  Where permissible according to applicable law we may use
                  certainlimited personal information about you, such as your
                  email address, to hash it and to share it with social media
                  platforms, such as Facebook or Google, to generate leads,
                  drive traffic to our websites or otherwise promote our
                  products and services or the Ask Property Platform. These
                  processing activities are based on our legitimate interest in
                  undertaking marketing activities to offer you products or
                  services that may be if your interest.
                </span>
              </p>
              <p>
                <span>
                  The social media platforms with which we may share your
                  personal data are not controlled or supervised by Ask Property
                  . Therefore, any questions regarding how your social media
                  platform service provider processes your personal data should
                  be directed to such provider.
                </span>
              </p>
              <p>
                <span>
                  Please note that you may, at any time ask Ask Property to
                  cease processing your data for these direct marketing purposes
                  by sending an e-mail.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.9 Collection and Remittance of Occupancy Taxes.</span>
                </strong>
              </p>
              <p>
                <span>
                  Any applicable Taxes as described in the “Taxes” section of
                  the
                </span>
                <span> Terms and Conditions of Service Policy</span>
                <span>
                  , Agencys andIndividual users, where legally permissible
                  according to applicable law, expressly grant us permission,
                  without further notice, to disclose Agencys’ andIndividual
                  users’ data and other information relating to them or to their
                  transactions,Leases, Accommodations and Taxes to the relevant
                  authority or SARS, including, but not limited to, the Agency’s
                  orTenant’s name, Listing addresses, transaction dates and
                  amounts, tax identification number(s), the amount
                  ofIncomereceived by Agencys fromIndividual users, and contract
                  information.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.10 Government Registration.</span>
                </strong>
              </p>
              <p>
                <span>
                  In jurisdictions where Ask Property facilitates or requires a
                  registration, notification, permit, or license application of
                  aAgency with a local governmental authority through the Ask
                  Property Platform in accordance with local law, we may share
                  information of participating Agencys with the relevant
                  authority, both during the application process and, if
                  applicable, periodically there after, such as the Agency’s
                  full name and contact details, Accommodation address, tax
                  reference number, Listing details, and Lease period.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.11 Business Transfers.</span>
                </strong>
              </p>
              <p>
                <span>
                  If Ask Property undertakes or is involved in any merger,
                  acquisition, reorganization, sale of assets, bankruptcy, or
                  insolvency event, then we may sell, transfer or share some or
                  all of our assets, including your information in connection
                  with such transaction or in contemplation of such transaction
                  (e.g., due diligence). In this event, we will notify you
                  before your personal information is transferred and becomes
                  subject to a different privacy policy.
                </span>
              </p>
              <p>
                <strong>
                  <span>3.12 Aggregated Data.</span>
                </strong>
              </p>
              <p>
                <span>
                  We may also share aggregated information (information about
                  our users that we combine together so that it no longer
                  identifies or references an individual user) and other
                  anonymized information for regulatory compliance, industry and
                  market analysis, demographic profiling, marketing and
                  advertising, and other business purposes.
                </span>
              </p>
              <p>
                <strong>
                  <span>4. OTHER IMPORTANT INFORMATION</span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>4.1 Analysingyour Communications.</span>
                </strong>
              </p>
              <p>
                <span>
                  We may review, scan, oranalyseyour communications on the Ask
                  Property Platform for fraud prevention, risk assessment,
                  regulatory compliance, investigation, product development,
                  research, and customer support purposes. For example, as part
                  of our fraud prevention efforts, we scan andanalyse messages
                  to mask contact information and references to other websites.
                  In some cases, we may also scan, review, oranalysemessages to
                  debug, improve,and expand product offerings. We use automated
                  methods where reasonably possible. However, occasionally we
                  may need to manually review some communications, such as for
                  fraud investigations and customer support, or to assess and
                  improve the functionality of these automated tools. We will
                  not review, scan, or analyse your communications to send third
                  party marketing messages to you, and we will not sell reviews
                  or analyses of these communications.
                </span>
              </p>
              <p>
                <span>
                  These activities are carried out based on Ask Property
                  'slegitimate interest in ensuring compliance with applicable
                  laws and our Terms, preventing fraud, promoting safety, and
                  improving and ensuring the adequate performance of our
                  services.
                </span>
              </p>
              <p>
                <strong>
                  <span>4.2 Linking Third Party Accounts.</span>
                </strong>
              </p>
              <p>
                <span>
                  You may link your Ask Property Account with your account at a
                  third party social networking service. Your contacts on these
                  third party services are referred to as “Friends.” When you
                  create this link:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    some of the information you provide to us from the linking
                    of your accounts may be published on your Ask Property
                    Account profile;
                  </span>
                </li>
                <li>
                  <span>
                    your activities on the Ask Property Platform may be
                    displayed to your Friends on the Ask Property Platform
                    and/or that third party site;
                  </span>
                </li>
                <li>
                  <span>
                    a link to your public profile on that third party social
                    networking service may be included in your Ask Property
                    public profile;
                  </span>
                </li>
                <li>
                  <span>
                    other Ask Property users may be able to see any common
                    Friends that you may have with them, or that you are a
                    Friend of their Friend if applicable;
                  </span>
                </li>
                <li>
                  <span>
                    other Ask Property users may be able to see any schools,
                    hometowns or other groups you have in common with them as
                    listed on your linked social networking service;
                  </span>
                </li>
                <li>
                  <span>
                    the information you provide to us from the linking of your
                    accounts may be stored, processed and transmitted for fraud
                    prevention and risk assessment purposes; and
                  </span>
                </li>
                <li>
                  <span>
                    the publication and display of information that you provide
                    to Ask Property through this linkage is subject to your
                    settings and authorizations on the Ask Property Platform and
                    the third party site.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  We only collect your information from linked third party
                  accounts to the extent necessary to ensure the adequate
                  performance of our contract with you, or to ensure that we
                  comply with applicable laws, or with your consent.
                </span>
              </p>
              <p>
                <strong>
                  <span>4.3 Google Maps/Earth.</span>
                </strong>
              </p>
              <p>
                <span>
                  Parts of the Ask Property Platform use Google Maps/Earth
                  services, including the Google Maps API(s). Use of Google
                  Maps/Earth is subject to
                </span>
                <a
                  href="http://www.google.com/intl/en_us/help/terms_maps.html"
                  title="http://www.google.com/intl/en_us/help/terms_maps.html"
                >
                  <span> Google Maps/Earth Additional Terms of Use</span>
                </a>
                <span> and the</span>
                <a
                  href="http://www.google.com/privacy.html"
                  title="http://www.google.com/privacy.html"
                >
                  <span> Google Privacy Policy</span>
                </a>
                <span>.</span>
              </p>
              <p>
                <strong>
                  <span>5. THIRD PARTY PARTNERS &amp; INTEGRATIONS</span>
                </strong>
              </p>
              <p>
                <span>
                  The Ask Property Platform may contain links to third party
                  websites or services, such as third party integrations,
                  co-branded services, or third party-branded services (“
                  <strong>Third Party Partners</strong>”). Ask Property doesn’t
                  own or control these Third Party Partners and when you
                  interact with them, you may be providing information directly
                  to the Third Party Partner, Ask Property , or both. These
                  Third Party Partners will have their own rules about the
                  collection, use, and disclosure of information. We encourage
                  you to review the privacy policies of the other websites you
                  visit.
                </span>
              </p>
              <p>
                <strong>
                  <span>6. YOUR RIGHTS</span>
                </strong>
              </p>
              <p>
                <span>
                  You may exercise any of the rights described in this section
                  before your applicable Ask Property Data Controller and
                  Payments Data Controller by sending an email. Please note that
                  we may ask you to verify your identity before taking further
                  action on your request.
                </span>
              </p>
              <p>
                <strong>
                  <span>6.1 Managing Your Information.</span>
                </strong>
              </p>
              <p>
                <span>
                  You may access and update some of your information through
                  your Account settings. If you have chosen to connect your Ask
                  Property Account to a third-party application, like Facebook
                  or Google, you can change your settings and remove permission
                  for the app by changing your Account settings. You are
                  responsible for keeping your personal information up-to-date.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    6.2 Rectification of Inaccurate or Incomplete Information.
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  You have the right to ask us to correct inaccurate or
                  incomplete personal information concerning you (and which you
                  cannot update yourself within your Ask Property Account).
                </span>
              </p>
              <p>
                <strong>
                  <span>6.3 Data Access and Portability.</span>
                </strong>
              </p>
              <p>
                <span>
                  Incertain circumstances, applicable law may entitle you to
                  request copies of your personal information held by us. Youmay
                  also be entitled to request copies of personal information
                  that you have provided to us in a structured, commonly used,
                  and machine-readable format and/or request us to transmit this
                  information to another service provider (where technically
                  feasible).However all your Lease contracts, house rules, and
                  history of cancelled reservations is available for
                  accessibility by you only through your Ask Property Account.
                </span>
              </p>
              <p>
                <strong>
                  <span>6.4 Data Retention and Erasure.</span>
                </strong>
              </p>
              <p>
                <span>
                  We generally retain your personal information for as long as
                  is necessary for the performance of the contract between you
                  and us and to comply with our legal obligations. If you no
                  longer want us to use your information to provide the Ask
                  Property Platform to you, you can request that we erase your
                  personal information and close your Ask Property Account.
                  Please note that if you request the erasure of your personal
                  information:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    We may retain some of your personal information as necessary
                    for our legitimate business interests, such as fraud
                    detection and prevention and enhancing safety. For example,
                    if we suspend a Ask Property Account for fraud or safety
                    reasons, we may retain certain information from that Ask
                    Property Account to prevent that Member from opening a new
                    Ask Property Account in the future.
                  </span>
                </li>
                <li>
                  <span>
                    We may retain and use your personal information to the
                    extent necessary to comply with our legal obligations. For
                    example, Ask Property may keep some of your information for
                    tax, legal reporting and auditing obligations.
                  </span>
                </li>
                <li>
                  <span>
                    Information you have shared with others (e.g., Reviews,
                    forum postings) may continue to be publicly visible on the
                    Ask Property Platform, even after your Ask Property Account
                    is cancelled. However, attribution of such information to
                    you will be removed. Additionally, some copies of your
                    information (e.g., leaserecords) may remain in our database,
                    but are disassociated from personal identifiers.
                  </span>
                </li>
                <li>
                  <span>
                    Because we maintain the Ask Property Platform to protect
                    from accidental or malicious loss and destruction, residual
                    copies of your personal information may not be removed from
                    our backup systems for a limited period of time.
                  </span>
                </li>
              </ul>
              <p>
                <strong>
                  <span>
                    6.5 Withdrawing Consent and Restriction of Processing.
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  Where you have provided your consent to the processing of your
                  personal information by Ask Property you may withdraw your
                  consent at any time by changing your Account settings or by
                  sending a communication to Ask Property specifying which
                  consent you are withdrawing. Please note that the withdrawal
                  of your consent does not affect the lawfulness of any
                  processing activities based on such consent before its
                  withdrawal.
                </span>
              </p>
              <p>
                <span>
                  Additionally, you may withdraw consent at any time where we
                  are relying on consent to process your personal data. However,
                  this will not affect the lawfulness of any processing carried
                  out before you withdraw your consent. If you withdraw your
                  consent, we may not be able to provide certain Website access
                  or Services to you. We will advise you if this is the case at
                  the time you withdraw your consent.
                </span>
              </p>
              <p>
                <span>
                  Please take note that regardless of your right to withdraw
                  consent under the <strong>GDPR</strong> and{" "}
                  <strong>POPI</strong>, other South African legislation applies
                  and may require that we continue to process your data in order
                  to comply with anti-corruption, crime-fighting and/or other
                  national legislation, which you expressly understand and agree
                  to.
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    you contest the accuracy of your personal information;
                  </span>
                </li>
                <li>
                  <span>
                    the processing is unlawful and you oppose the erasure of
                    your personal information;
                  </span>
                </li>
                <li>
                  <span>
                    we no longer need your personal information for the purposes
                    of the processing, but you require the information for the
                    establishment, exercise or defence of legal claims; or
                  </span>
                </li>
                <li>
                  <span>
                    you have objected to the processing pursuant to Section 6.6
                    and pending the verification whether the legitimate grounds
                    of Ask Property override your own.
                  </span>
                </li>
              </ol>
              <p>
                <strong>
                  <span>6.6 Objection to Processing.</span>
                </strong>
              </p>
              <p>
                <span>
                  Subject to the applicable law, you mayto require Ask Property
                  not to process your personal information for certain specific
                  purposes (including profiling) where such processing is based
                  on legitimate interest. If you object to such processing Ask
                  Property will no longer process your personal information for
                  these purposes unless we can demonstrate compelling legitimate
                  grounds for such processing or such processing is required for
                  the establishment, exercise or defence of legal claims.
                </span>
              </p>
              <p>
                <span>
                  Where your personal information is processed for direct
                  marketing purposes, you may, at any time ask Ask Property to
                  cease processing your data for these direct marketing purposes
                  by sending an e-mail to opt-out.
                </span>
              </p>
              <p>
                <strong>
                  <span>6.7 Lodging Complaints.</span>
                </strong>
              </p>
              <p>
                <span>
                  You have the right to lodge complaints about the data
                  processing activities carried out by Ask Property and Ask
                  Property Partners before the competent data protection
                  authorities.You have the right to file a complaint with its
                  lead supervisory authority, the POPI Act regulatory body.
                </span>
              </p>
              <p>
                <strong>
                  <span>7. SECURITY</span>
                </strong>
              </p>
              <p>
                <span>
                  We are continuously implementing and updating administrative,
                  technical, and physical security measures to help protect your
                  information against unauthorised access, loss, destruction, or
                  alteration. Some of the safeguards we use to protect your
                  information are firewalls and data encryption, and information
                  access controls. If you know or have reasonto believe that
                  your Ask Property Account credentials have been lost, stolen,
                  misappropriated, or otherwise compromised or in case of any
                  actual or suspected unauthorised use of your Ask Property
                  Account, please contact us following the instructions in the
                  Contact Us section below.
                </span>
              </p>
              <p>
                <strong>
                  <span>8. CHANGES TO THIS PRIVACY POLICY</span>
                </strong>
              </p>
              <p>
                <span>
                  {" "}
                  Ask Property reserves the right to modify this Privacy Policy
                  at any time in accordance with this provision. If we make
                  changes to this Privacy Policy, we will post the revised
                  Privacy Policy on the Ask Property Platform and update the
                  “Last Updated” date at the top of this Privacy Policy. We will
                  also provide you with notice of the modification by email
                  timeously before the date they become effective. If you
                  disagree with the revised Privacy Policy, you may cancel your
                  Account. If you do not cancel your Account before the date the
                  revised Privacy Policy becomes effective, your continued
                  access to or use of the Ask Property Platform will be subject
                  to the revised Privacy Policy.
                </span>
              </p>
              <p>
                <strong>
                  <span>9. CONTACT US</span>
                </strong>
              </p>
              <p>
                <span>
                  If you have any questions or complaints about this Privacy
                  Policy or Ask Property ’s information handling practices, you
                  may
                </span>
                <span>email us at info.askproperty.net </span>
                <span>or contact us via Ask Property social media pages.</span>
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
