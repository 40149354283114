import React, {useEffect, useState} from 'react';
import swal from 'sweetalert';
import { changeIndividualRole } from "../../API/AuthApi";
import Footer from '../../layouts/Footer';
import Navbar from '../../layouts/Navbar';
import { useHistory, useParams } from 'react-router-dom';

export default function RequestAccept() {
    const history = useHistory();   
    const params = useParams();
    const slug = params.slug;
    useEffect(
        function (){
            let data = {
                token_code:slug,    
            }
        

           
            changeIndividualRole(data).then((res)=>{
                if(res.data.status === "success"){
                swal({
                    icon: "success",
                    text: 'Request Accepted Successfully',
                    showCancelButton: false,
                    showConfirmButton: false
                  }).then((willDelete) => {
                        if (willDelete) {
                            localStorage.clear();
                            history.push('/login');
                        }
                    })
                    
                }
            });
        });
    
  return (
    <>
    <Navbar />
     <div class="main py-5">
        <div class="container ">
        </div>
    </div>
    <Footer />
    </>
  )
}
