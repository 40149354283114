import React, { useState } from 'react'
import swal from "sweetalert";
import { EmailSend } from "../../API/ForgotPasswordApi";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import SecondFooter from '../../layouts/SecondFooter'
import SecondHeader from '../../layouts/SecondHeader'

export default function SendEmail() {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [emailerr, setEmailErr] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit =async (event) => {
        event.preventDefault();
        setLoading(true);
          setEmailErr("");
          if (email === "") {
            setEmailErr("Please enter email address");
            setLoading(false);
          } else {
            var body = {
              email: email,
              stage:"send",
            };
            if(email){
            EmailSend(body)
              .then((res) => {
                if (res.data.status === "true") {
                  setLoading(false);
                  swal({
                    title: res.data.message,
                    icon: "success",
                    button: "OK",
                  });
                  history.push("/otp-verify", {email:email});
                }else{
                  setLoading(false);
                  swal({
                      title: res.data.message,
                      icon: "error",
                      button: "OK",
                    });
                }
                
              })
              .catch((e) => {
                setLoading(false);
                swal("Error!", "User not found on given email address", "error");
              });
          }
          }
        };
    return (
        <>
            <div class="wrapper  login-wrapper">

                <SecondHeader />

                <div class="main bg-transparent border-0">
                    <div class="container pt-4 pb-5">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 mb-5 ">
                                <div class="forgot-pwd p-4 text-center bg-white">
                                    <h3 class="h3 bold pb-3">Forgot Password</h3>
                                    <p class="fs-14 text-primary medium px-md-5">Please enter your email address to recieve
                                        verification code</p>
                                    <img src="assets/image/forgot1.png" alt="" />

                                    <form onSubmit={handleSubmit} class="forgot-step">
                                        <div class="form-field text-start pb-4">
                                            <label for="" class="d-block pb-2 medium">Email</label>
                                            <input type="email" class="w-100"  
                                            placeholder="Enter email address" 
                                            onChange={(event) => setEmail(event.target.value)}
                                            />
                                            <p style={{ color: "red", marginBottom: "10px" }}>{emailerr}</p>
                                        </div>
                                        {loading === true ?
                                        <button 
                                        class="btn-md"
                                        disabled={loading ? true :false}
                                        >
                                        <span className='spinner-grow spinner-grow-sm'>

                                        </span>
                                        </button>
                                        :
                                        <button type="submit" class="btn-md">Get OTP</button>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            <SecondFooter />

            </div>
        </>
    )
}
