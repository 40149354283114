import Routes from "./Routes/Routes";
import { gapi } from "gapi-script";

function App(props) {
  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:
        "849181327628-2mdqh2cfkfb0g4120t1bbv4c7kc67deg.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
