import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import { GetDisplayProperties, GetMasterData } from "../API/PropertyApi";
import { BedRoomOption } from "../constants/BedRoomOption";
import { SalePricingOption } from "../constants/SalePricingOption";
import { RentPricingOption } from "../constants/RentPricingOption";
import swal from "sweetalert";
import ApplyForHomeLoanBtn from "./shared/ApplyForHomeLoanBtn";
import DisplayPropertyCard from "./property/DisplayPropertyCard";

export default function Listing() {
  const history = useHistory();
  const autocompleteRef = useRef();
  const location = useLocation();
  const navigateTo = location.state;
  console.log(navigateTo);
  const inputRef = useRef();
  const inputSaleRef = useRef();
  const calculatorsRef = useRef(null);
  const [propertyStreetAddress, setPropertyStreetAddress] = useState("");
  const [lattitude, setPropertyLattitude] = useState("");
  const [longitude, setPropertyLongitude] = useState("");
  // const [propertyStreetAddress, setPropertyStreetAddress] = useState('Cape Town, South Africa')
  // const [lattitude, setPropertyLattitude] = useState(-33.9248685)
  // const [longitude, setPropertyLongitude] = useState(18.4240553)
  const [masterTypeOfProperty, setMasterTypeOfProperty] = useState([]);
  const [listing_type, setlistingType] = useState("sell");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [Bedrooms, setBedrooms] = useState("");
  const [TypeOfProperty, setTypeOfProperty] = useState("");
  const [query, setQuery] = useState("");
  const [city, setCity] = useState("");

  const [list, setList] = useState([]);
  const Token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const userApproveStatus = localStorage.getItem("userApproveStatus");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const propertyDetail = (slug) => {
    window.open(
      window.location.origin + "/property-detail" + "/" + slug,
      "_blank"
    );
    // history.push('/property-detail/' + slug, { slug: slug });
  };

  const options = {
    componentRestrictions: { country: "za" },
    types: ["geocode"],
  };

  useEffect(() => {
    if (navigateTo) {
      calculatorsRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [navigateTo]);

  useEffect(() => {
    const formData = new FormData();
    GetDisplayProperties(null).then((res) => {
      // console.log("@58", res);
      if (res.data.status === "success") {
        window.scrollTo(0, 0);
        setList(res.data.data.data);
        console.log(res.data.data.data);
      }
    });
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(
  //     function () {

  //     // const searchBox = new window.google.maps.places.Autocomplete(
  //     //     inputRef.current,
  //     //     options
  //     // );
  //     if (inputRef.current && typeof window.google === "object") {
  //         const searchBox = new window.google.maps.places.Autocomplete(
  //             inputRef.current,
  //             options
  //         );

  //     window.google.maps.event.addListener(searchBox, 'place_changed', function () {
  //         var places = searchBox.getPlace();
  //         if(places){
  //             setPropertyStreetAddress(places?.name);
  //             setPropertyLattitude(places?.geometry?.location?.lat())
  //             setPropertyLongitude(places?.geometry?.location?.lng())
  //         }
  //     });
  //     }
  // });

  // useEffect(
  //     function () {

  //     // const input = document.getElementById('inputSale');

  //     // const searchBoxSale = new window.google.maps.places.Autocomplete(
  //     //     input,
  //     //     options
  //     // );
  //     if (inputSaleRef.current && typeof window.google === "object") {
  //         const searchBoxSale = new window.google.maps.places.Autocomplete(
  //             inputSaleRef.current,
  //             options
  //         );

  //     window.google.maps.event.addListener(searchBoxSale, 'place_changed', function () {
  //         var places = searchBoxSale.getPlace();
  //         if(places){
  //             setPropertyStreetAddress(places?.name);
  //             setPropertyLattitude(places?.geometry?.location?.lat())
  //             setPropertyLongitude(places?.geometry?.location?.lng())
  //         }
  //     });

  // }
  // });

  useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current && typeof window.google === "object") {
        const searchBoxSale = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options
        );

        window.google.maps.event.addListener(
          searchBoxSale,
          "place_changed",
          function () {
            var places = searchBoxSale.getPlace();
            if (places) {
              setPropertyStreetAddress(places?.formatted_address);
              setPropertyLattitude(places?.geometry?.location?.lat());
              setPropertyLongitude(places?.geometry?.location?.lng());
            }
          }
        );
      }
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (inputSaleRef.current) {
      if (inputSaleRef.current && typeof window.google === "object") {
        const searchBoxSale = new window.google.maps.places.Autocomplete(
          inputSaleRef.current,
          options
        );

        window.google.maps.event.addListener(
          searchBoxSale,
          "place_changed",
          function () {
            var places = searchBoxSale.getPlace();
            if (places) {
              setPropertyStreetAddress(places?.formatted_address);
              setPropertyLattitude(places?.geometry?.location?.lat());
              setPropertyLongitude(places?.geometry?.location?.lng());
            }
          }
        );
      }
    }
  }, []);

  useEffect(function () {
    GetMasterData().then((res) => {
      if (res.data.type === "success") {
        setMasterTypeOfProperty(res.data.data.masterTypeOfProperty);
      }
    });
  }, []);

  const handleSearch = () => {
    if (!lattitude) {
      swal("Error", "Please select location", "error");
    }
    if (!longitude) {
      swal("Error", "Please select location", "error");
    }
    if (!listing_type) {
      swal("Error", "Listing type is required", "error");
    }
    if (lattitude && longitude && listing_type) {
      let data = {
        lattitude: lattitude,
        longitude: longitude,
        listing_type: listing_type,
        minPrice: minPrice,
        TypeOfProperty: TypeOfProperty,
        Bedrooms: Bedrooms,
        maxPrice: maxPrice,
        propertyStreetAddress: propertyStreetAddress,
      };
      console.log(data);
      history.push("/search-result/page-1", { data: data, url: null });
    }
  };

  return (
    <>
      <Navbar />
      <div className="main">
        <div className="banner pb-5">
          <div className="container py-3">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h2 className="text-black bold text-center pb-5">
                  Find Property for Sale and Rent in South Africa
                </h2>

                <ul
                  className="nav home-search border-0 mb-3"
                  id="myTab"
                  role="tablist"
                >
                  <li
                    className="border-primary"
                    style={{ borderRadius: "25px" }}
                    role="presentation"
                  >
                    <button
                      // className={
                      //   listing_type === "sell" ? "nav-link active" : "nav-link"
                      // }
                      className="d-flex align-items-center border-primary"
                      style={{
                        fontSize: "16px",
                        border: "1px solid #63af30",
                        borderRadius: "25px",
                        color: listing_type === "sell" ? "white" : "#5c5c5c",
                        background:
                          listing_type === "sell" ? "#63af30" : "transparent",
                        padding: "10px 20px",
                      }}
                      id="sell-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#sell"
                      type="button"
                      role="tab"
                      aria-controls="sell"
                      aria-selected="true"
                      onClick={() => setlistingType("sell")}
                    >
                      FOR SALE
                    </button>
                  </li>

                  <li
                    className={`ms-2 ${
                      listing_type !== "rent" && "border-primary"
                    }`}
                    style={{ borderRadius: "25px" }}
                    role="presentation"
                  >
                    <button
                      className="d-flex align-items-center border-primary"
                      style={{
                        fontSize: "16px",
                        border: "2px solid #63af30",
                        borderRadius: "25px",
                        color: listing_type === "rent" ? "white" : "#5c5c5c",
                        background:
                          listing_type === "rent" ? "#63af30" : "transparent",
                        padding: "10px 20px",
                      }}
                      id="rent-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#rent"
                      type="button"
                      role="tab"
                      aria-controls="rent"
                      aria-selected="false"
                      onClick={() => setlistingType("rent")}
                    >
                      FOR RENT
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="sell"
                    role="tabpanel"
                    aria-labelledby="sell-tab"
                  >
                    <div className="input-group bg-white align-items-center landing-search mb-4 border-primary">
                      <i className="far fa-search ps-4 pe-3"></i>

                      {/* <Autocomplete
                                            className="form-control rounded border-0"
                                            apiKey={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                            onPlaceSelected={(place) => selectedPlaced(place)}
                                            placeholder="Please Enter Location"
                                            /> */}

                      {/* <input  /> */}

                      <input
                        type="text"
                        ref={inputSaleRef}
                        id="inputSale"
                        // style={{fontSize : 10}}
                        // placeholder="Search by street name or suburb"
                        placeholder="Search by suburb or street name"
                        className="form-control rounded border-0"
                        style={{ boxShadow: "none" }}
                        // onChange={(place) => selectedPlaced(place)}
                      />

                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>

                    <div className="row text-black landing-select">
                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Property Type</h5>
                          <select
                            onChange={(e) => setTypeOfProperty(e.target.value)}
                          >
                            <option value="">Any</option>
                            {masterTypeOfProperty.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Min Price</h5>
                          <select onChange={(e) => setMinPrice(e.target.value)}>
                            <option value="">Any</option>
                            {SalePricingOption.map((item, i) => {
                              return (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Max Price</h5>
                          <select onChange={(e) => setMaxPrice(e.target.value)}>
                            <option value="">Any</option>
                            {SalePricingOption.map((item, i) => {
                              return (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Bedrooms</h5>
                          <select onChange={(e) => setBedrooms(e.target.value)}>
                            <option value="">Any</option>
                            {BedRoomOption.map((item, i) => {
                              return (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="rent"
                    role="tabpanel"
                    aria-labelledby="rent-tab"
                  >
                    <div className="input-group bg-white align-items-center landing-search mb-4 border-primary">
                      <i className="far fa-search ps-4 pe-3"></i>

                      <input
                        type="text"
                        ref={inputRef}
                        // placeholder="Search by street name or suburb"
                        placeholder="Search by suburb or street name"
                        className="form-control rounded border-0"
                        style={{ boxShadow: "none" }}
                      />

                      <button
                        type="button"
                        className="btn btn-outline-primary border-0 focus-none"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>

                    <div className="row text-black landing-select">
                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Property Type</h5>
                          <select
                            onChange={(e) => setTypeOfProperty(e.target.value)}
                          >
                            <option value="">Any</option>
                            {masterTypeOfProperty.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Min Price</h5>
                          <select onChange={(e) => setMinPrice(e.target.value)}>
                            <option value="">Any</option>
                            {RentPricingOption.map((item, i) => {
                              return (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Max Price</h5>
                          <select onChange={(e) => setMaxPrice(e.target.value)}>
                            <option value="">Any</option>
                            {RentPricingOption.map((item, i) => {
                              return (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="pb-3">
                          <h5 className="medium pb-1 h5">Bedrooms</h5>
                          <select onChange={(e) => setBedrooms(e.target.value)}>
                            <option value="">Any</option>
                            {BedRoomOption.map((item, i) => {
                              return (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{backgroundColor: 'red', height: '100px', width: '100px'}}></div> */}
                  </div>
                </div>
              </div>
            </div>

            <ul
              className={`mx-2 mt-4 d-none align-items-center justify-content-center`}
            >
              <li
                className="me-4 p-1 border"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
              >
                <a
                  className={`d-flex align-items-center show-pointer`}
                  href="https://askpropertyuk.propviewr.com/properties"
                  target="_blank"
                >
                  <img
                    style={{
                      width: windowWidth < 450 ? "50px" : "70px",
                      height: windowWidth < 450 ? "30px" : "50px",
                      borderRadius: "10px",
                    }}
                    className="pe-1"
                    src={"assets/image/ukflag.png"}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      lineHeight: "15px",
                      // color: 'white'
                    }}
                  >
                    View Properties in UK
                  </p>
                </a>
              </li>

              <li
                className="p-1 border"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
              >
                <a
                  className={`d-flex align-items-center show-pointer`}
                  href="https://askpropertyuae.propviewr.com/ae/properties"
                  target="_blank"
                >
                  <img
                    className="pe-1"
                    style={{
                      width: windowWidth < 450 ? "50px" : "70px",
                      height: windowWidth < 450 ? "30px" : "50px",
                      borderRadius: "10px",
                    }}
                    src={"assets/image/uae.png"}
                    // src={"assets/image/nigeria.png"}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      lineHeight: "15px",
                      // color: 'white'
                    }}
                  >
                    {/* {"View Properties in Nigeria"} */}
                    {"View Properties in UAE"}
                  </p>
                </a>
              </li>
            </ul>

            {/* <div style={{backgroundColor: 'red', height: '100px', width: '100px'}}>ghdgfxfgxxgfgx</div> */}
          </div>
        </div>

        <div
          className="container bg-white w-100"
          style={{
            display: "flex",
            flexWrap: "wrap",
            background: "transparent",
          }}
        >
          {list && list.length > 0
            ? list.map((property, index) => (
                <div
                  className="bg-white d-flex flex-wrap col-12 col-md-6 col-lg-4 px-1"
                  key={index}
                >
                  <DisplayPropertyCard
                    property={property}
                    onPropertyDetail={propertyDetail}
                  />
                </div>
              ))
            : null}
        </div>
        <div ref={calculatorsRef} className="d-none landing-calculator">
          <div className="container py-5">
            <div className="pb-md-4">
              <h2 className="text-center text-primary semibold pb-3 fs-40">
                Home Loan Calculators
              </h2>
              <div className="justify-content-center row pb-md-4">
                <div className="col-md-7">
                  {/* <p className="medium text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Affordability Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link to="/affordabilty-calculator" className="custom-btn">
                      Calculate
                    </Link>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Repayment Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link to="/repayment-calculator" className="custom-btn">
                      Calculate
                    </Link>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Transfer Cost Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link to="/transfer-cost-calculator" className="custom-btn">
                      Calculate
                    </Link>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Extra Payments Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link
                      to="/extra-payments-calculator"
                      className="custom-btn"
                    >
                      Calculate
                    </Link>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column flex-md-row">
                <div className="mx-2 mt-4 w-100 d-flex align-items-center justify-content-center">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScKSqCsPeuyHST0qI_Yn5fA5UmGIqkiyXKfiv3Y4im_QKyNCw/viewform"
                    target="_blank"
                    // className="w-100"
                  >
                    <button
                      style={{
                        backgroundColor: "#63af30",
                        borderRadius: "10px",
                        color: "white",
                        padding: "10px",
                      }}
                      className="border-0 px-3"
                    >
                      Apply for pre-approval
                    </button>
                  </a>
                </div>
                <ApplyForHomeLoanBtn title={"Apply now"} />
              </div>
            </div>

            {/* {role == 2 || role == 3 ?

                            userApproveStatus == 0 ?
                                <div className="d-md-flex align-items-center py-5 justify-content-center text-center">
                                    <h2 className="h1 semibold pe-md-5 m-0">Choose the best listing plan</h2>
                                    <Link className="custom-btn mt-md-0 mt-3 semibold">Choose Listing</Link>
                                </div>
                                :
                                <div className="d-md-flex align-items-center py-5 justify-content-center text-center">
                                    <h2 className="h1 semibold pe-md-5 m-0">Choose the best listing plan</h2>
                                    <Link to="/pricing" className="custom-btn mt-md-0 mt-3 semibold">Choose Listing</Link>
                                </div>
                            :

                            <div className="d-md-flex align-items-center py-5 justify-content-center text-center">
                                <h2 className="h1 semibold pe-md-5 m-0">Choose the best listing plan</h2>
                                <Link to="/pricing" className="custom-btn mt-md-0 mt-3 semibold">Choose Listing</Link>
                            </div>

                        } */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
