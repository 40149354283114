import React, { useEffect, useState } from "react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import CurrencyFormat from "react-currency-format";
import ApplyForHomeLoanBtn from "../shared/ApplyForHomeLoanBtn";
import { formatter } from "../../utils";

export default function BondCalculator() {
  const [grossIncome, setGrossIncome] = useState("");
  const [netIncome, setNetIncome] = useState("");
  const [expenses, setExpenses] = useState("");
  const [rate, setRate] = useState("11.75");
  const [year, setYear] = useState(20);
  const [surplusIncome, setSurplusIncome] = useState("");
  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [scrollTop, setScrollTop] = useState(true);
  const [isCalculated, setIsCalculated] = useState(false);

  useEffect(function () {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    setScrollTop(false);
  });

  const handleExpensesChange = (expenses) => {
    setExpenses(expenses);
    const surplusIncomeData = netIncome - expenses;
    setSurplusIncome(surplusIncomeData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // this was old method
    // const repaymentAmountData = (surplusIncome * 60) / 100;
    // setRepaymentAmount(repaymentAmountData);
    // -----------
    const repaymentAmountData = (grossIncome * 30) / 100;
    if (surplusIncome < repaymentAmountData) {
      setRepaymentAmount(surplusIncome);
    } else {
      setRepaymentAmount(repaymentAmountData);
    }
    const NewRate = rate / 100;
    const calculationData = calculation(
      surplusIncome < repaymentAmountData ? surplusIncome : repaymentAmountData,
      NewRate / 12,
      year * 12
    );
    console.log(calculationData);
    setLoanAmount(calculationData);
    setIsCalculated(true);
  };

  const calculation = (E, R, N) => {
    const newRateplus = R * Math.pow(1 + R, N);
    const newRateminus = Math.pow(1 + R, N) - 1;
    const P = (E * newRateminus) / newRateplus;
    return Math.round(P);
  };

  return (
    <>
      <Navbar />
      <div class="main py-5">
        <div class="container ">
          <h1 class="text-primary semibold cal-title text-center pb-5">
            Affordability Calculator{" "}
          </h1>

          <div class="row">
            <div class="col-md-6 pb-5">
              <form onSubmit={handleSubmit} class="calculator-form">
                <div class="row">
                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Gross monthly income *
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={grossIncome}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Gross monthly income *"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setGrossIncome(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Net monthly income*
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={netIncome}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Net monthly income *"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setNetIncome(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Total monthly expenses*
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={expenses}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Total monthly expenses *"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        handleExpensesChange(value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <label class="d-block medium fs-15">
                      Net surplus income
                    </label>

                    <CurrencyFormat
                      className="w-100"
                      value={surplusIncome}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Net surplus income"
                      disabled
                    />
                  </div>

                  <div class="form-field col-md-6">
                    <label class="d-block medium fs-15">Years to repay*</label>
                    <select
                      required
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="5" selected={year === 5 ? true : false}>
                        5
                      </option>
                      <option value="10" selected={year === 10 ? true : false}>
                        10
                      </option>
                      <option value="15" selected={year === 15 ? true : false}>
                        15
                      </option>
                      <option value="20" selected={year === 20 ? true : false}>
                        20
                      </option>
                      <option value="25" selected={year === 25 ? true : false}>
                        25
                      </option>
                      <option value="30" selected={year === 30 ? true : false}>
                        30
                      </option>
                    </select>
                  </div>

                  <div class="form-field col-md-6">
                    <label class="d-block medium fs-15">Interest Rate*</label>
                    <input
                      type="text"
                      class="w-100"
                      placeholder="Interest Rate*"
                      value={rate}
                      required
                      onChange={(e) => {
                        setRate(e.target.value);
                      }}
                    />
                  </div>

                  <div class="form-field col-md-12">
                    <button type="submit" class="custom-btn">
                      calculate
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-md-6 px-md-4">
              <div class="cal-right-box bg-white py-5 px-4">
                <h2 class="semibold pb-4">Amount you qualify for</h2>
                <h3 class="text-primary semibold pb-5 mb-md-4">
                  {formatter.format(loanAmount)}
                </h3>

                <h2 class="semibold pb-4">Your monthly repayment amount</h2>
                <h3 class="text-primary semibold pb-5">
                  {formatter.format(repaymentAmount)}
                </h3>
              </div>
              {isCalculated && <ApplyForHomeLoanBtn />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
