
export const SalePricingOption = [
    { value: 100000, label:'R 100 000'},
    { value: 250000, label:'R 250 000'},
    { value: 350000, label:'R 350 000'},
    { value: 500000, label:'R 500 000'},
    { value: 600000, label:'R 600 000'},
    { value: 700000, label:'R 700 000'},
    { value: 800000, label:'R 800 000'},
    { value: 900000, label:'R 900 000'},
    { value: 1000000, label:'R 1 000 000'},
    { value: 1250000, label:'R 1 250 000'},
    { value: 1500000, label:'R 1 500 000'},
    { value: 1750000, label:'R 1 750 000'},
    { value: 2000000, label:'R 2 000 000'},
    { value: 2500000, label:'R 2 500 000'},
    { value: 3000000, label:'R 3 000 000'},
    { value: 4000000, label:'R 4 000 000'},
    { value: 5000000, label:'R 5 000 000'},
    { value: 6000000, label:'R 6 000 000'},
    { value: 7000000, label:'R 7 000 000'},
    { value: 8000000, label:'R 8 000 000'},
    { value: 9000000, label:'R 9 000 000'},
    { value: 10000000, label:'R 10 000 000'},
];