import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { EmailSend } from "../../API/ForgotPasswordApi";
import SecondFooter from '../../layouts/SecondFooter'
import SecondHeader from '../../layouts/SecondHeader'
import swal from "sweetalert";
export default function VerifyOtp() {
    const history = useHistory();
    const location = useLocation();

    const email = location.state.email;
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const [loading, setLoding] = useState(false);
    const handleChange = (element, index) => {
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
            var body = {
              email: email,
              stage:"verify",
              otp_code:otp.join("")
            };
            if(email){
            EmailSend(body)
              .then((res) => {
                if (res.data.status === "true") {
                 
                  swal({
                    title: res.data.message,
                    icon: "success",
                    button: "OK",
                  });
                  history.push("/change-password", {email:email, otp:otp.join("")});
                }else{
                  
                  swal({
                      title: res.data.message,
                      icon: "error",
                      button: "OK",
                    });
                }
                
              })
              .catch((e) => {
                
                swal("Error!", "something went wrong", "error");
              });
          }
          
        };

        const ResendOtp = async (event) => {
            event.preventDefault();
            setLoding(true);
                var body = {
                  email: email,
                  stage:"send",
                };
                if(email){
                EmailSend(body)
                  .then((res) => {
                    setLoding(false);
                    if (res.data.status === "true") {
                     
                      swal({
                        title: res.data.message,
                        icon: "success",
                        button: "OK",
                      });
                     
                    }else{
                      
                      swal({
                          title: res.data.message,
                          icon: "error",
                          button: "OK",
                        });
                    }
                    
                  })
                  .catch((e) => {
                    
                    swal("Error!", "something went wrong", "error");
                  });
              }
              
            };
    

    return (
        <>
            <div class="wrapper  login-wrapper">
                <SecondHeader />
                <div class="main bg-transparent border-0">
                    <div class="container pt-4 pb-5">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 mb-5 ">
                                <div class="forgot-pwd p-4 text-center bg-white">
                                    <h3 class="h3 bold pb-3">Verify Your Email</h3>
                                    <p class="fs-14 text-primary medium px-md-5">Please enter the 6 digit code sent to<br />
                                    </p>
                                    <img src="assets/image/forgot2.png" alt="" />

                                    <form onSubmit={handleSubmit} class="forgot-step">
                                        <div class="otp-code d-flex py-4 d-flex">
                                            {/* <input type="text" /> */}
                                            {otp.map((data, index) => {
                                                return (
                                                    <input
                                                        className="form-control w-75 ml-2  textfield text-center"
                                                        name="otp"
                                                        onChange={(e) => handleChange(e.target, index)}
                                                        maxLength={1}
                                                        key={index}
                                                        value={data}
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                );
                                            })}

                                        </div>
                                        <button type="submit" class="btn-md">Verify</button>
                                        {loading ? 
                                         <Link   class="d-block text-primary medium pt-3 text-underline">Processing....</Link>
                                        :
                                        <Link  onClick={ResendOtp} class="d-block text-primary medium pt-3 text-underline">Resend code</Link>
                                        }
                                      </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SecondFooter />
            </div>
        </>
    )
}
