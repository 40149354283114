import axios from "axios";
import { loginURLs, referURLs, ProfileURLs, RegisterURLs } from '../constants/url';


export const UserLogin = (body) =>
  axios({
    url: `${loginURLs.Url}`,
    method: "Post",
    data:body,
});

export const SingalRegister = (body) =>
  axios({
    headers: {
      "content-type": "multipart/formdata",
    },
    url: `${RegisterURLs.Url}`,
    method: "Post",
    data:body,
});

export const RegisterAgency = (body) =>
  axios({
    headers: {
      "content-type": "multipart/formdata",
    },
    url: `${RegisterURLs.agencyRegister}`,
    method: "Post",
    data:body,
});

export const GetProfile = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.GetProfile}`,
    method: "Post",
    data:body,
});
export const removeDocument = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.removeDocument}`,
    method: "Post",
    data:body,
});
export const changeIndividualRole = (body) =>
  axios({
    url: `${ProfileURLs.changeIndividualRole}`,
    method: "Post",
    data:body,
});
export const changeRole = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.changeRole}`,
    method: "Post",
    data:body,
});


export const UpdateProfile = (body, token) =>
  axios({
    headers: {
      "Content-Type": "multipart/formdata",
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.EditProfile}`,
    method: "Post",
    data:body,
});


export const referFriend = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${referURLs.Url}`,
    method: "Post",
    data:body,
});

export const PasswordChange = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.ChangePassword}`,
    method: "Post",
    data:body,
});
export const updateDocumentName = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.documentName}`,
    method: "Post",
    data:body,
});

export const PricingPaymentHistory = (token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.purchaseHistory}`,
    method: "Post",
});
export const checkUserLogin = (token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ProfileURLs.checkUserLogin}`,
    method: "Post",
});

