import axios from "axios";
import { PropertyURLs } from "../constants/url";

export const GetAllProperty = (token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.GetProperty}`,
    method: "Post",
  });

export const GetMasterData = (token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.GetMasterData}`,
    method: "Post",
  });

export const PropertyDetail = (token, slug) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.PropertyDetail}` + "/" + slug,
    method: "Post",
  });

export const SaveProperty = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/formdata",
    },
    url: `${PropertyURLs.SaveProperty}`,
    method: "Post",
    data: body,
  });
export const RenewProperty = (body, token, slug) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/formdata",
    },
    url: `${PropertyURLs.RenewProperty}` + "/" + slug,
    method: "Post",
  });

export const UpdateProperty = (body, token, slug) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/formdata",
    },
    url: `${PropertyURLs.SaveProperty}` + "/" + slug,
    method: "Post",
    data: body,
  });

export const PropertyApproveReject = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.PropertyApproveReject}`,
    method: "Post",
    data: body,
  });

export const PropertyImageDelete = (body, token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.PropertyImageDelete}`,
    method: "Post",
    data: body,
  });

export const PropertyDelete = (slug, token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.PropertyDelete}` + "/" + slug,
    method: "Post",
    data: body,
  });

export const Getproperties = (body) =>
  axios({
    url: `${PropertyURLs.GetProperties}`,
    method: "Post",
    data: body,
  });

export const GetDisplayProperties = (body) =>
  axios({
    url: `${PropertyURLs.GetDisplayProperties}`,
    method: "Post",
    data: body,
  });

export const GetpropertiesByPage = (Url, body) =>
  axios({
    url: Url,
    method: "Post",
    data: body,
  });

export const AlertProperties = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.alertProperties}`,
    method: "Post",
    data: body,
  });
export const ShowContactNo = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PropertyURLs.ShowContactNo}`,
    method: "Post",
    data: body,
  });
