import React from "react";
import { Link } from "react-router-dom";
export default function SecondFooter() {
  return (
    <div className="footer light-footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <p>Copyright© 2024, All rights Reserved</p>
          </div>

          <div className="col-md-4 text-center">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>

          <div className="col-md-4 text-md-end">
            <p>
              Contact us :{" "}
              <a href="mailto:info.askproperty.net">info.askproperty.net</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
