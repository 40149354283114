import axios from "axios";
import { pricingURLs, paymentURLs } from '../constants/url';

export const CoupanApply = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${paymentURLs.coupan}`,
    method: "Post",
    data: body,
  });

  export const StartPayment = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${paymentURLs.startPayment}`,
    method: "Post",
    data: body,
  });

  export const PaymentConfirm = (token, body) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${paymentURLs.paymentConfirm}`,
    method: "Post",
    data: body,
  });

export const GetPricing = (token) =>
  axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${pricingURLs.GetPricing}`,
    method: "POST",
  });

  