import React, { useState, useEffect, useRef } from 'react'
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar'
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { GetMessage, SendMessage, GetChatRoom, readMessage } from "../API/MessageApi";
import io from "socket.io-client";
import swal from "sweetalert";
const $ = window.jQuery;
export default function Message() {
    const location = useLocation();
    const RoomId_ = location?.state?.RoomId;
    const [RoomId, setRoomId] = useState(RoomId_);
    const [pageLoading, setPageLoading] = useState(false)
    const [messageData, setMessageData] = useState([]);
    const [messageRoomData, setMessageRoomData] = useState([]);
    const [message, setMessage] = useState("");
    const [hideChatRoom, setHideChatRoom] = useState(true);
    const [hideMessageRoom, setHideMessageRoom] = useState(true);
    const [file, setFile] = useState("");
    const [msg_type, setMsgType] = useState("");
    const Token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const socket = useRef();
    let params = useParams();
    let history = useHistory();
    useEffect(
        function () {
            let mounted = true;
            // setRoomId(location?.state?.RoomId);
            setPageLoading(true);
            // GetAllMessage(RoomId);
            GetAllMessageRoom();
            return () => {
                mounted = false;
                if (socket?.current?.connected) {
                    socket?.current?.disconnect?.();
                } else {
                    socket?.current?.close?.();
                }
            };
        }, [params]);

    const GetAllMessage = (MessageRoomId) => {
        setRoomId(MessageRoomId);
        let data = {
            message_room_id: MessageRoomId
        }
        GetMessage(Token, data).then((res) => {
            if (res.data.status === "success") {
                setMessageData(res.data.data);
                setPageLoading(false);
                scrollToEnd();
                socket.current = io("https://cs.askproperty.co.za", {
                    transports: ["websocket"],
                    jsonp: false,
                });
                socket.current.connect();
                initiateSocket(MessageRoomId);
                let data = {
                    message_room_id:MessageRoomId,
                }
                readMessage(Token, data).then((res)=>{
                    GetChatRoom(Token).then((res) => {
                        if (res.data.status === "success") {
                            let list = res.data.data;
                            let message_room_id;
                            setMessageRoomData(list);
                        }
                    });
                })
                setHideMessageRoom(true)
                setHideChatRoom(false)
                return () => {
                    if (socket.current.connected) {
                        socket.current.disconnect();
                    } else {
                        socket.current.close();
                    }
                };
            }
        });
    };

    const handleCloseChat = () =>{
        setHideMessageRoom(false)
        setHideChatRoom(true)
    }
    const handleFile = (e) => {
        console.log('e_____r',e.target.files);
        if (e.target.files[0].type === "image/jpeg"
            || e.target.files[0].type === "image/png"
            || e.target.files[0].type === "image/bmp"
            || e.target.files[0].type === "image/gif"
            || e.target.files[0].type === "image/tiff"
        ) {
            setFile(e.target.files[0]);
            setMsgType('image');
        } else {
            setFile(e.target.files[0]);
            setMsgType('file');
        }
    };

    const GetAllMessageRoom = () => {

        GetChatRoom(Token).then((res) => {
            if (res.data.status === "success") {
                let list = res.data.data;
                let message_room_id;
                setMessageRoomData(list);
                if(params?.roomId){
                    let is_room_id_valid = res.data.data.find((item)=> item.id==params?.roomId);
                    if(is_room_id_valid){
                        message_room_id = is_room_id_valid.id;
                        // setRoomId(is_room_id_valid.id);
                    }
                    else{
                        alert('invalid_room_id');
                        history?.goBack?.();
                        return;
                    }
                }
                else{
                    message_room_id = RoomId || res?.data?.data[0]?.id;
                }
                // setPageLoading(false);
                if(!message_room_id){
                    setPageLoading(false);
                }
                setRoomId(message_room_id);
                GetAllMessage(message_room_id);
                setHideMessageRoom(false)
                setHideChatRoom(true)
            }
        });
    };

    const scrollToEnd = () => {
        setTimeout(() => {
            var container = $(".containerHeight")[0];
            container.scrollTop = container.scrollHeight;
        }, 1000);
    };

    const handleSubmit = (event) => {
        event?.preventDefault?.();
        const formData = new FormData();
        formData.append("message_room_id", RoomId);
        if (message) {
            formData.append("message", message);
        } else {
            formData.append("message", "ok");
        }

        if (msg_type) {
            formData.append("msg_type", msg_type);
        } else {
            formData.append("msg_type", "text");
        }

        if (file) {
            formData.append("file", file);
        }

        
        if(message){
            let data = {
                message_room_id: RoomId,
                message: message,
                msg_type:"text",
            }
            SendMessage(Token, data).then((res) => {
                if (res.data.status === "success") {
                    
                    setMessage("");
                    setMsgType("");
                    setFile("");
                    document.getElementById("messageInput").style.height = '60px';
                    GetAllMessageRoom();
                }
            });
        }
    
        if(msg_type){
            SendMessage(Token, formData).then((res) => {
                if (res.data.status === "success") {
                    
                    setMessage("");
                    setMsgType("");
                    setFile("");
                    document.getElementById("messageInput").style.height = '60px';
                    GetAllMessageRoom();
                
                }

            });
        }
        if(!message && !msg_type){
            swal("Error!", "Please select any file or enter the message", "error");
        }
    };

    const initiateSocket = (chat_id) => {
        socket.current.on("connect", () => {
            socket.current.emit("add user", {
                client: JSON.parse(localStorage.getItem("user")).id,
                conversation: chat_id,
            });

        });
        socket.current.on('disconnect',(er)=>{
        })

        socket.current.on("connect_error", (err) => {
            console.log("connect errr", JSON.stringify(err));
        });

        socket.current.on("Disconnected", () => {
            console.log("Socket is DisConnected: ", socket.current.id);
        });
        socket.current.on("message", (data) => {
            if (data.conversation_id == chat_id) {
                GetAllMessageRoom();
                let data = {
                    message_room_id:chat_id,
                }
                readMessage(Token, data).then((res)=>{
                    GetChatRoom(Token).then((res) => {
                        if (res.data.status === "success") {
                            let list = res.data.data;
                            let message_room_id;
                            setMessageRoomData(list);
                        }
                    });
                })
                scrollToEnd();
            }
        });

        socket.current.on("message", (resp) => {
            console.log("onMessage", JSON.stringify(resp, null, 4));
        });
    };

    const handleOpenFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    const LongText = ({ content, limit, readStatus }) => {
        if (content.length <= limit) {
            // there is nothing more to show
            return <p className={readStatus === false ? "fs-14 regular text-gray message-unread" :"fs-14 regular text-gray" } >{content}
            </p>
        }

        // In the final case, we show a text with ellipsis and a `Read more` button
        const toShow = content.substring(0, limit) + "...";
        return <p className={readStatus === false ? "fs-14 regular text-gray message-unread" :"fs-14 regular text-gray" } >
            {toShow}
            {/* <button >Read more</button> */}
        </p>
    };

    const MessageTextChange = (e) => {
        $('.autoresizing').on('input', function () {
            this.style.height = 'auto';

            this.style.height =
                (this.scrollHeight) + 'px';
        });
        setMessage(e.target.value)

    };

    return (
        <>
            <Navbar />
            <div className="main">
            {
                pageLoading
                ?
                <div className="main">
                    <center>
                        <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                        </span>
                    </center>
                </div>
                :
                !messageRoomData.length
                ?
                <div className='main d-flex justify-content-center align-items-center'>
                <div>
                    No Chats found
                </div>
                </div>
                :
                    
                <div className="container message-page">
                    <h1 className="text-primary semibold cal-title py-5">Messages</h1>
                    
                    <div className="row " >
                        <div className="col-lg-3 pb-5 d-md-block d-none">
                            <h3 className="semibold fs-23 pb-4">Recent Chat</h3>
                            <div className="message-list middle-area" style={{ border: 'none', height: "350px" }}>
                                {messageRoomData.map((item, i) => {

                                    return (
                                        <div style={{cursor : 'pointer', backgroundColor : RoomId==item.id ? 'rgb(220,220,220)' : 'transparent'}} 
                                        className="d-flex align-items-center p-2 message-item mb-2" 
                                        onClick={() => {
                                            history.push('/message/' + item.id);
                                            // GetAllMessage(item?.id);
                                            }}>
                                            {item?.room_participants[0]?.user?.roleWiseProfileData?.user_image === null ?
                                                <img src="../assets/image/dummy_image.png" className="message-img feed-user" alt="" />
                                                :
                                                <img src={item?.room_participants[0]?.user?.roleWiseProfileData?.user_image} className="message-img feed-user" alt="" />
                                            }


                                            <div className="ps-3">
                                                <h4 className="fs-17 bold"> {item?.room_participants[0]?.user?.name} {item?.room_participants[0]?.user?.surname}</h4>
                                                {item?.messages_single?.msg_type === "text" ?
                                                    <p className="fs-14 regular text-gray">

                                                        <LongText content={item?.messages_single?.msg} limit={20} readStatus={item?.messages_single?.IsAuthUserRead} />
                                                    </p>
                                                    :
                                                    item?.messages_single?.msg_type === "image" ?
                                                        <p className="fs-14 regular text-gray">
                                                            <i className="fa-solid fa-image"></i>
                                                            &nbsp;
                                                            Photo</p>
                                                        : item?.messages_single?.msg_type === "file" ?
                                                            <p className="fs-14 regular text-gray">
                                                                <i className="fa-solid fa-file"></i>
                                                                &nbsp;
                                                                File</p>
                                                            : ""
                                                }
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>

                        </div>

                        <div className="col-lg-6 message-detail mb-5 d-md-block d-none " >

                            {messageData[0]?.room_participants.map((item, i) => {

                                return (
                                    item.user_id !== user.id ?
                                        <div className="message-header d-flex align-items-center pb-3">
                                            {item?.user?.roleWiseProfileData?.user_image === null ?
                                                <img src="../assets/image/dummy_image.png" className="message-img feed-user" alt="" />
                                                :
                                                <img src={item?.user?.roleWiseProfileData?.user_image} className="message-img feed-user" alt="" />
                                            }

                                            <h4 className="fs-17 bold ps-3"> {item?.user?.name} {item?.user?.surname}</h4>
                                        </div>
                                        : ""
                                );
                            })}

                            <div className="message-body middle-area py-4 containerHeight" id="container" style={{ border: 'none', height: "280px" }}>
                                {messageData[0]?.messages.map((item, i) => {

                                    return (
                                        <>
                                            {item.user_id === user.id ?

                                                <div className="right-side pb-4">
                                                    {item?.msg_type === "text" ?
                                                        <p className="fs-13 regular message-chat mb-1" >{item?.msg}</p>
                                                        :
                                                        item?.msg_type === "image" ?
                                                            <p className="fs-13 regular message-chat mb-1">
                                                                <img src={item?.msg} alt="" />

                                                            </p>
                                                            :
                                                            item?.msg_type === "file" ?
                                                                <p className="fs-13 regular message-chat mb-1">
                                                                    <Link onClick={() => handleOpenFile(item?.msg)} target="_blank">
                                                                        <i className="fa-solid fa-file"></i>
                                                                        &nbsp;
                                                                        {item?.msg_type}
                                                                    </Link>
                                                                </p>
                                                                : ""
                                                    }
                                                    <span className="message-hour fs-12 regular">{item?.CreatedAtCustom}</span>
                                                </div>

                                                :
                                                <div className="left-side d-flex pb-4">
                                                    <div>
                                                        {item?.msg_type === "text" ?
                                                            <p className="fs-13 regular message-chat mb-1">{item?.msg}</p>
                                                            :
                                                            item?.msg_type === "image" ?
                                                                <p className="fs-13 regular message-chat mb-1">
                                                                    <img src={item?.msg} alt="" />

                                                                </p>
                                                                :
                                                                item?.msg_type === "file" ?
                                                                    <p className="fs-13 regular message-chat mb-1">
                                                                        <Link onClick={() => handleOpenFile(item?.msg)} target="_blank">
                                                                            <i className="fa-solid fa-file"></i>
                                                                            &nbsp;
                                                                            {/* {item?.msg.substring(item?.msg.lastIndexOf('/')+1)} */}
                                                                            {item?.msg_type}
                                                                        </Link>
                                                                    </p>
                                                                    : ""
                                                        }
                                                        <span className="message-hour fs-12 regular">{item?.CreatedAtCustom}</span>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    );
                                })}







                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="message-footer d-flex pt-4 align-items-end">

                                    <div className="w-100 position-relative">
                                        {/* <input type="text"
                                            name="message"
                                            placeholder="Type your message here"
                                            className="text-gray fs-14 border-0 autoresizing"
                                            value={message}
                                            onChange={(e) => { MessageTextChange(e) }}
                                            // required
                                        /> */}
                                        {
                                            file
                                            ?
                                            <div className='py-2'>
                                                <div style={{width : 100, height : 100, position : 'relative'}}>
                                                <div 
                                                onClick={()=>{
                                                    setFile('');
                                                }}
                                                style={{
                                                    position : 'absolute', 
                                                    top : 0, 
                                                    right : 0, 
                                                    backgroundColor : '#3c691d', 
                                                    width : 20, 
                                                    height : 20, 
                                                    borderRadius : 30,
                                                    cursor : 'pointer',
                                                }} 
                                                className='d-flex justify-content-center align-items-center'>
                                                    <div style={{color : 'white', fontWeight : 'bolder', fontSize : 10}}>
                                                        X
                                                    </div>
                                                    
                                                </div>
                                                <img
                                                src={msg_type=="file" ? "../assets/image/pdf.png" : URL.createObjectURL(file)}
                                                style={{width : 100+'%', height : 100+'%', borderRadius : 10, overflow : 'hidden'}}
                                                // style={{width : 100, height : 100}}
                                                />
                                                </div>
                                                
                                                
                                            </div>
                                            :
                                            null
                                        }
                                        

                                        <textarea
                                            name="message"
                                            className="text-gray fs-14 border-0 autoresizing"
                                            id="messageInput"
                                            placeholder="Type your message here"
                                            value={message}
                                            onChange={(e) => { 
                                                if(!e.target.value.includes("\n")){
                                                    MessageTextChange(e) 
                                                }
                                                else{
                                                    handleSubmit(e);
                                                }
                                                
                                            }}
                                            // onChange={(e) => { MessageTextChange(e) }}
                                            // onKeyUp={(e)=>{
                                            //     console.log('name_of_fired_event',e.key);
                                            // }}
                                            
                                        >

                                        </textarea>


                                    </div>
                                    <div className="d-flex">
                                        <span className="p-3">
                                            <input type="file" onChange={(e) => { handleFile(e) }} id="attachment" className="d-none" /><label for="attachment"><i className="fal fa-paperclip"></i></label>
                                        </span>
                                        <button type="submit" className="text-primary border-0 fs-36 bg-transparent"><i className="fa fa-paper-plane"></i></button>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="d-md-none d-block m-2">
                        {hideChatRoom === true ?
                        <div className="col-lg-3 pb-5">
                            <h3 className="semibold fs-23 pb-4">Recent Chats</h3>
                            <div className="message-list middle-area" style={{ border: 'none', height: "350px" }}>
                                {messageRoomData.map((item, i) => {

                                    return (
                                        <div style={{cursor : 'pointer'}} className="d-flex align-items-center p-2 message-item mb-2" onClick={() => GetAllMessage(item?.id)}>
                                            {item?.room_participants[0]?.user?.roleWiseProfileData?.user_image === null ?
                                                <img src="../assets/image/dummy_image.png" className="message-img feed-user" alt="" />
                                                :
                                                <img src={item?.room_participants[0]?.user?.roleWiseProfileData?.user_image} className="message-img feed-user" alt="" />
                                            }


                                            <div className="ps-3">
                                                <h4 className="fs-17 bold"> {item?.room_participants[0]?.user?.name} {item?.room_participants[0]?.user?.surname}</h4>
                                                {item?.messages_single?.msg_type === "text" ?
                                                    <p className="fs-14 regular text-gray">

                                                        <LongText content={item?.messages_single?.msg} limit={20}  readStatus={item?.messages_single?.IsAuthUserRead}/>
                                                    </p>
                                                    :
                                                    item?.messages_single?.msg_type === "image" ?
                                                        <p className="fs-14 regular text-gray">
                                                            <i className="fa-solid fa-image"></i>
                                                            &nbsp;
                                                            Photo</p>
                                                        : item?.messages_single?.msg_type === "file" ?
                                                            <p className="fs-14 regular text-gray">
                                                                <i className="fa-solid fa-file"></i>
                                                                &nbsp;
                                                                File</p>
                                                            : ""
                                                }
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>

                        </div>
                        : "" }
                        {hideMessageRoom  === true ?
                        <div className="col-lg-6 message-detail border-0 mb-5 ">
                            {messageData[0]?.room_participants.map((item, i) => {

                                return (
                                    item.user_id !== user.id ?
                                        <div className="message-header d-flex align-items-center pb-3">
                                            <Link className="chat-close me-2" onClick={handleCloseChat}><i data-v-92a4d2ce="" className="fa fa-chevron-left"></i></Link>
                                            {item?.user?.roleWiseProfileData?.user_image === null ?
                                                <img src="../assets/image/dummy_image.png" className="message-img feed-user" alt="" />
                                                :
                                                <img src={item?.user?.roleWiseProfileData?.user_image} className="message-img feed-user" alt="" />
                                            }

                                            <h4 className="fs-17 bold ps-3"> {item?.user?.name + ' ' + item?.user?.surname}</h4>

                                        </div>
                                        : ""
                                );
                            })}

                            <div className="message-body py-4 containerHeight" >
                                {messageData[0]?.messages.map((item, i) => {

                                    return (
                                        <>
                                            {item.user_id === user.id ?

                                                <div className="right-side pb-4">
                                                    {item?.msg_type === "text" ?
                                                        <p className="fs-13 regular message-chat mb-1" >{item?.msg}</p>
                                                        :
                                                        item?.msg_type === "image" ?
                                                            <p className="fs-13 regular message-chat mb-1">
                                                                <img src={item?.msg} alt="" />

                                                            </p>
                                                            :
                                                            item?.msg_type === "file" ?
                                                                <p className="fs-13 regular message-chat mb-1">
                                                                    <Link onClick={() => handleOpenFile(item?.msg)} target="_blank">
                                                                        <i className="fa-solid fa-file"></i>
                                                                        &nbsp;
                                                                        {item?.msg_type}
                                                                    </Link>
                                                                </p>
                                                                : ""
                                                    }
                                                    <span className="message-hour fs-12 regular">{item?.CreatedAtCustom}</span>
                                                </div>

                                                :
                                                <div className="left-side d-flex pb-4">
                                                    <div>
                                                        {item?.msg_type === "text" ?
                                                            <p className="fs-13 regular message-chat mb-1" >{item?.msg}</p>
                                                            :
                                                            item?.msg_type === "image" ?
                                                                <p className="fs-13 regular message-chat mb-1">
                                                                    <img src={item?.msg} alt="" />

                                                                </p>
                                                                :
                                                                item?.msg_type === "file" ?
                                                                    <p className="fs-13 regular message-chat mb-1">
                                                                        <Link onClick={() => handleOpenFile(item?.msg)} target="_blank">
                                                                            <i className="fa-solid fa-file"></i>
                                                                            &nbsp;
                                                                            {/* {item?.msg.substring(item?.msg.lastIndexOf('/')+1)} */}
                                                                            {item?.msg_type}
                                                                        </Link>
                                                                    </p>
                                                                    : ""
                                                        }
                                                        <span className="message-hour fs-12 regular">{item?.CreatedAtCustom}</span>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    );
                                })}


                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="message-footer d-flex pt-4 align-items-end">

                                    <div className="w-100 position-relative">
                                    {
                                            file
                                            ?
                                            <div className='py-2'>
                                                <div style={{width : 100, height : 100, position : 'relative'}}>
                                                <div 
                                                onClick={()=>{
                                                    setFile('');
                                                }}
                                                style={{
                                                    position : 'absolute', 
                                                    top : 0, 
                                                    right : 0, 
                                                    backgroundColor : '#3c691d', 
                                                    width : 20, 
                                                    height : 20, 
                                                    borderRadius : 30,
                                                    cursor : 'pointer',
                                                }} 
                                                className='d-flex justify-content-center align-items-center'>
                                                    <div style={{color : 'white', fontWeight : 'bolder', fontSize : 10}}>
                                                        X
                                                    </div>
                                                    
                                                </div>
                                            
                                                <img
                                                src={URL.createObjectURL(file)}
                                                style={{width : 100+'%', height : 100+'%', borderRadius : 10, overflow : 'hidden'}}
                                                // style={{width : 100, height : 100}}
                                                />
                                                </div>
                                                
                                                
                                            </div>
                                            :
                                            null
                                        }

                                        <textarea
                                            name="message"
                                            className="text-gray fs-14 border-0 autoresizing"
                                            id="messageInput"
                                            placeholder="Type your message here"
                                            value={message}
                                            onChange={(e) => { 
                                                if(!e.target.value.includes("\n")){
                                                    MessageTextChange(e) 
                                                }
                                                else{
                                                    handleSubmit(e);
                                                }
                                                
                                            }}
                                                
                                        ></textarea>

                                    </div>
                                    <div className="d-flex">
                                        <span className="p-3">
                                            <input type="file" onChange={(e) => { handleFile(e) }} id="attachment" className="d-none" /><label for="attachment"><i className="fal fa-paperclip"></i></label>
                                        </span>
                                        <button type="submit" className="text-primary border-0 fs-36 bg-transparent"><i className="fa fa-paper-plane"></i></button>
                                    </div>

                                </div>
                            </form>
                        </div>
                        : "" }
                    </div>
                </div>
                }
                                        
            </div>
            <Footer />
        </>
    )
}
