import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AccountRoutes from "./AccountRoutes.js";
export default function Routes() {
  return (
    <Router>
      <AccountRoutes />
    </Router>
  );
}
