import axios from "axios";
import { approverURLs } from '../constants/url';


  export const AddApprover = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${approverURLs.addApprover}`,
    method: "Post",
    data:body,
});

export const GetAllApprover = (token) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${approverURLs.GetApprover}`,
    method: "Post",
});

export const GetSingleApprover = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${approverURLs.SingalGetApprover}`,
    method: "Post",
    data:body,
});


export const DeactiveApprover = (token,body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${approverURLs.DeactiveApprover}`,
    method: "Post",
    data:body,
});



