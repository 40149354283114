import React, { useState, useEffect } from 'react'
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar'
import { useLocation } from "react-router-dom";
import { GetProfile, UpdateProfile, removeDocument, updateDocumentName } from "../API/AuthApi";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";

export default function EditProfile() {
    const location = useLocation();
    const history = useHistory();
    const userId = location.state.id;
    const [name, setName] = useState('')
    const [agencyName, setAgencyName] = useState('')
    const [surname, setSurname] = useState()
    const [city, setCity] = useState('')
    const [occupation, setOccupation] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [description, setDescription] = useState()
    const [file, setFile] = useState('')
    const [role, setRole] = useState('')
    const [loading, setLoading] = useState(false)
    const [nameerror, setNameError] = useState('')
    const [cityerror, setCityError] = useState('')
    const [agencyDocument, setAgencyDocument] = useState([])
    const [agencyStatus, setAgencyStatus] = useState('')
    const [occupationerror, setOccupationError] = useState('')
    const [agencyNameError, setAgencyNameError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [image_documents, setImageDocuments] = useState([])
    const [agencyLogo, setAgencyLogo] = useState("")
    const [documentName, setDocumentName] = useState('')
    const [documentnameShow, setDocumentNameShow] = useState(false)
    const [documentId, setDocumentId] = useState('')
    const [pageLoading, setPageLoading] = useState('')
    const Token = localStorage.getItem("token");
    if(Token === null){
        history.push("login");
    }
    
    useEffect(
        function () {
            setPageLoading(true);
            let mounted = true;
            let data = {
                user_id: userId
            }
            GetProfile(data, Token).then((res) => {
               
                if (mounted) {
                    setName(res?.data?.data?.name);
                    setSurname(res?.data?.data?.surname);
                    setCity(res?.data?.data?.roleWiseProfileData?.city);
                    setOccupation(res?.data?.data?.roleWiseProfileData?.occupation);
                    setDescription(res?.data?.data?.roleWiseProfileData?.description);
                    setRole(res?.data?.data?.role);
                    setEmail(res?.data?.data?.email);
                    setPhone(res?.data?.data?.phone);
                    setAgencyDocument(res?.data?.data?.roleWiseProfileData?.agency_document);
                    setFile({ fileUrl: res?.data?.data?.roleWiseProfileData?.user_image });
                    setAgencyLogo({ fileUrl: res?.data?.data?.roleWiseProfileData?.agency_logo });
                    setAgencyName(res?.data?.data?.roleWiseProfileData?.agency_name)
                    setAgencyStatus(res?.data?.data?.roleWiseProfileData?.is_approved)
                }
                setPageLoading(false);
            });

            return () => {
                mounted = false;
            };
        },
        []
    );

   
    const handleOpenFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };
    const handleDocument = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        // console.log(chosenFiles);
        setImageDocuments(chosenFiles);
    };

    const handleDeleteImage = (id) =>{
        let data = {
            document_id: id,
        }
        removeDocument(data, Token).then((res)=>{
            if(res.data.status === "success"){
                
                let data = {
                    user_id: userId
                }
                GetProfile(data, Token).then((res) => {
                    setAgencyDocument(res?.data?.data?.roleWiseProfileData?.agency_document);
                })
                swal({
                    text:res.data.message,
                    icon: "success",
                })
            }
        });
    };

    const handlefile = (e) => {
        setFile({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };
    const handleAgencyLogo = (e) => {
        setAgencyLogo({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };

    const handleEditName = (name, id) => {
        setDocumentName(name);
        setDocumentNameShow(true);
        setDocumentId(id);
    };

    const handleUpdateDocName = () =>{
        let data = {
            id:documentId,
            image_name:documentName
        }
        updateDocumentName(data, Token).then((res)=>{
            
            if(res?.data?.status === "success"){
                let data = {
                    user_id: userId
                }
                GetProfile(data, Token).then((res) => {
                    setAgencyDocument(res?.data?.data?.roleWiseProfileData?.agency_document);
                })
                setDocumentName('');
                setDocumentNameShow(false);
                setDocumentId('');
                swal({
                    text:res.data.message,
                    icon: "success",
                })
            }else{
                swal({
                    text:res.data.message,
                    icon: "error",
                })
            }
            
        });

    }

    const handleupdate = (event) => {
        event.preventDefault();
        setLoading(true);
        if (!name) {
            setNameError("Please Enter Your Name");
            setLoading(false);
        }
        if(role === 2){
        if (!agencyName) {
            setAgencyNameError("Please Enter Agency Name");
            setLoading(false);
            return false
        }else{
            setAgencyNameError('');
        }
        
        }
        if(role !== 4){
            if (!phone) {
                setPhoneError("Please Enter your phone number");
                setLoading(false);
                return false
            }else{
                setPhoneError('');
            }
            }
        if(role === 5 || role === 2){
        if (!city) {
            setCityError("Please Enter Your City");
            setLoading(false);
            return false;
        }else{
            setCityError('');
        }
        if(role === 5){
        if (!occupation) {
            setOccupationError("Please Enter Your Occupation");
            setLoading(false);
            return false;
        }else{
            setOccupationError('');
        }
        }
        }
        

        if(name){
            setNameError("");
            setCityError("");
            setOccupationError("");
            
            const token = localStorage.getItem("token");
            const formData = new FormData();
            formData.append("name", name);
            if(surname){
                formData.append("surname", surname);
            }
            if(city){
                formData.append("city", city);
            }

            if(phone){
                formData.append("phone", phone);
            }

            if(agencyName){
                formData.append("agency_name", agencyName);
            }
            if(occupation){
            formData.append("occupation", occupation);
            }
            if(description){
                formData.append("description", description);
            }
            
            if (file.fileBytes) {
                formData.append("file", file.fileBytes);
            }
            if (agencyLogo.fileBytes) {
                formData.append("image_logo", agencyLogo.fileBytes);
            }
            if (image_documents) {
                for (var i = 0; i < image_documents.length; i++) {
                    formData.append("image_documents["+i+"]", image_documents[i]);
                }
            }
            UpdateProfile(formData, token).then((res)=>{
                if(res.data.status === "success"){
                    // 
                    let data = {
                        user_id: userId
                    }
                    GetProfile(data, Token).then((res) => {
                        let data = "";
                        if(res.data.data.role === 5){
                            data = {
                                id:userId,
                                image:res.data.data.roleWiseProfileData.user_image,
                                name:res.data.data.name,
                                surname:res.data.data.surname,
                                profile:{
                                    occupation:res.data.data.roleWiseProfileData.occupation
                                }
                            }
                        } else if(res.data.data.role === 2){
                            data = {
                                id:userId,
                                image:res.data.data.roleWiseProfileData.user_image,
                                name:res.data.data.name,
                                surname:res.data.data.surname,
                                profile:{
                                    occupation:res.data.data.roleWiseProfileData.agency_name
                                }
                            }

                        }else{
                            data = {
                                id:userId,
                                image:res.data.data.roleWiseProfileData.user_image,
                                name:res.data.data.name,
                                surname:res.data.data.surname,
                                profile:{
                                    occupation:res.data.data.roleWiseProfileData.agency_profile.agency_name
                                }
                            }
                        }
                       
                        window.localStorage.setItem('user', JSON.stringify(data));
                        window.localStorage.setItem('userProfile', res.data.data.roleWiseProfileData.user_image);
                       
                    });
                    swal({
                        text:res.data.message,
                        icon: "success",
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            history.push("/profile"+ '/' + userId);
                        }
                    });
                    setLoading(false);
                }
               
            });
        }

    };
    return (
        <>
            <Navbar />
            {pageLoading ?
                <div className="main py-5">
                    <center>
                        <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                        </span>
                    </center>
                </div>
                :
            <div className="main">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <form action="" className="site-form profile-edit">
                                <div className="bg-white p-4 border rounded">
                                    <h4 className="h4 bold pb-3">Edit Profile</h4>

                                    <div className="row">

                                        <div className={role === 2 ? "form-field col-md-6 text-center" : "form-field col-md-12 text-center"}>
                                            <div className="profile-thumb position-relative text-center">
                                                <input type="file" 
                                                id="profile-field"
                                                accept="image/*"
                                                onChange={(e)=>handlefile(e)}
                                                />
                                                <label for="profile-field">
                                                    {file.fileUrl === null ?
                                                    <img src="assets/image/edit-profile.png" alt="" />
                                                    :
                                                    <img src={file.fileUrl} alt="" />
                                                    }
                                                    <span className="d-block medium pt-2">Change Profile Photo</span>
                                                </label>
                                            </div>
                                        </div>
                                        {role === 2 ?
                                        <div className="form-field col-md-6 text-center">
                                            <div className="profile-thumb position-relative text-center">
                                                <input type="file" 
                                                id="profile-field-logo"
                                                accept="image/*"
                                                onChange={(e)=>handleAgencyLogo(e)}
                                                />
                                                <label for="profile-field-logo">
                                                    {agencyLogo.fileUrl === null ?
                                                    <img src="assets/image/edit-profile.png" alt="" />
                                                    :
                                                    <img src={agencyLogo.fileUrl} alt="" />
                                                    }
                                                    <span className="d-block medium pt-2">Change Agency Logo</span>
                                                </label>
                                            </div>
                                        </div>
                                        : ""}

                                        <div className="form-field col-md-6">
                                            <label className="d-block medium fs-15">Name</label>
                                            <input type="text"
                                                className="w-100"
                                                placeholder="Enter Your name"
                                                value={name}
                                                onChange={(e) => { setName(e.target.value) }}
                                            />
                                             <p style={{ color: "red", margintop: "-10px" }}>
                                                {nameerror}
                                            </p>
                                        </div>
                                        <div className="form-field col-md-6">
                                            <label className="d-block medium fs-15">Surname</label>
                                            <input type="text"
                                                className="w-100"
                                                placeholder="Enter Your surname"
                                                value={surname}
                                                onChange={(e) => { setSurname(e.target.value) }}
                                            />
                                        </div>
                                        { role === 2 ?
                                        <div className="form-field col-md-12">
                                            <label className="d-block medium fs-15">City</label>
                                            <input type="text"
                                                className="w-100"
                                                placeholder="Enter Your City"
                                                value={city}
                                                onChange={(e) => { setCity(e.target.value) }}

                                            />
                                             <p style={{ color: "red", margintop: "-10px" }}>
                                                {cityerror}
                                            </p>
                                        </div>
                                        : "" }

                                        {role === 5 ?
                                        <div className="form-field col-md-6">
                                            <label className="d-block medium fs-15">City</label>
                                            <input type="text"
                                                className="w-100"
                                                placeholder="Enter Your City"
                                                value={city}
                                                onChange={(e) => { setCity(e.target.value) }}

                                            />
                                             <p style={{ color: "red", margintop: "-10px" }}>
                                                {cityerror}
                                            </p>
                                        </div>
                                        : "" }
                                         {role === 5 ?
                                        <div className="form-field col-md-6">
                                            <label className="d-block medium fs-15">Occupation</label>
                                            <input type="text"
                                                className="w-100"
                                                placeholder="Enter Your Occupation"
                                                value={occupation}
                                                onChange={(e) => { setOccupation(e.target.value) }}
                                            />
                                             <p style={{ color: "red", margintop: "-10px" }}>
                                                {occupationerror}
                                            </p>
                                        </div>
                                         : "" }
                                        {role === 2 ?
                                        <div className="form-field col-md-12">
                                            <label className="d-block medium fs-15">Agency Name</label>
                                            <input type="email"
                                                className="w-100"
                                                placeholder="Enter Your Email"
                                                value={agencyName}
                                                onChange={(e) => { setAgencyName(e.target.value) }}
                                                required

                                            />
                                             <p style={{ color: "red", margintop: "-10px" }}>
                                             {agencyNameError}
                                             </p>
                                        </div>
                                           

                                        :""}
                                        {role === 4 ?
                                            ""
                                        :
                                        <div className="form-field col-md-12">
                                            <label className="d-block medium fs-15">Phone Number</label>
                                            <input type="number"
                                                className="w-100"
                                                placeholder="Enter Your Phone Number"
                                                value={phone}
                                                onChange={(e) => { setPhone(e.target.value) }}
                                            />
                                             <p style={{ color: "red", margintop: "-10px" }}>
                                                {phoneError}
                                            </p>
                                        </div>
                                        }

                                        <div className="form-field col-md-12">
                                            <label className="d-block medium fs-15">Email</label>
                                            <input type="email"
                                                className="w-100"
                                                placeholder="Enter Your Email"
                                                value={email}
                                                disabled

                                            />
                                        </div>

                                        

                                        {role === 5 ?
                                        <div className="form-field col-md-12">
                                            <label className="d-block medium fs-15">How would you describe yourself?</label>
                                            <textarea name=""
                                                id=""
                                                value={description}
                                                onChange={(e) => { setDescription(e.target.value) }}
                                            >

                                            </textarea>
                                           
                                        </div>
                                        :""}

                                        {role === 2 ?
                                        agencyStatus === 0 ?
                                        <div className="form-field col-md-12">
                                            <label className="d-block medium fs-15">Upload Agency Document</label>
                                            <input type="file"
                                                className="w-100"
                                                placeholder=""
                                                multiple 
                                                onChange={(e)=>handleDocument(e)}
                                            />                                      
                                        </div>
                                        : ""
                                        :""}

                                        <div className="form-field col-md-12">
                                        {agencyDocument?.length > 0 ?
                                    <div className="row">
                                    <h6>Agency Document </h6>
                                    <br />
                                    {agencyDocument.map((item, index)=>{
                                        return (
                                        <div className="col-md-4" key={index} style={{    paddingBottom: "15px"}}>
                                            {
                                               item?.user_document.substring(item?.user_document.lastIndexOf('/')+1).split(".").pop() === 'png'
                                               || 
                                               item?.user_document.substring(item?.user_document.lastIndexOf('/')+1).split(".").pop() === 'jpg'
                                               || 
                                               item?.user_document.substring(item?.user_document.lastIndexOf('/')+1).split(".").pop() === 'jpeg'
                                               ?
                                               <>
                                               <span className="d-flex documentBox">
                                               <img src={item?.user_document} alt='' style={{width:'100px', heght:"100px"}}/>
                                               {agencyStatus === 0 ?
                                               <Link style={{ color: 'red', marginTop: "-12px", fontWeight: 500, }} onClick={() => handleDeleteImage(item.id)}>
                                               <small className="" style={{ color: 'red', marginTop: "0px" }}>X</small>
                                                </Link>
                                                : ""}
                                                <br /> 
                                                </span>
                                                <br />
                                                {item?.image_name === null ? "" :
                                               <div className="agencyDocument w-100" style={{wordWrap : 'break-word'}}>
                                               {item?.image_name} 
                                                </div>
                                                
                                                }
                                                
                                                { agencyStatus === 0 ?
                                                 <Link onClick={()=>handleEditName(item?.image_name, item?.id)}>Edit Name</Link>
                                                :""}
                                                </>
                                               :
                                               <>
                                               <span className="d-flex documentBox">
                                               <Link onClick={() => handleOpenFile(item?.user_document)} target="_blank">
                                                {/* <i className="fa-solid fa-file" ></i> */}
                                                <img src="../assets/image/pdf.png" alt='' style={{width:'100px', heght:"100px"}}/>
                                               </Link>
                                               { agencyStatus === 0 ?
                                                <Link style={{ color: 'red', marginTop: "-12px", fontWeight: 500, }} onClick={() => handleDeleteImage(item.id)}>
                                                <small className="" style={{ color: 'red', marginTop: "0px" }}>X</small>
                                                </Link>
                                                : "" }
                                                </span>
                                                <br />
                                                {item?.image_name === null ? "" :
                                               <div className="agencyDocument w-100" style={{wordWrap : 'break-word'}}>
                                               {item?.image_name} 
                                                </div>
                                                }
                                                  
                                                { agencyStatus === 0 ?
                                                <Link onClick={()=>handleEditName(item?.image_name, item?.id)}>Edit Name</Link>
                                                :""}
                                                </>
                                            }
                                            
                                            {/* {item?.user_document} */}
                                        </div>

                                        );

                                    })}
                                            

                                    </div>
                                    : "" }

                                    {/* <input type="text" value="" onChange={(e)=>{} }/> */}
                                    <br />
                                    {documentnameShow === true ?
                                    <div className="form-field col-md-12 ">
                                        <label className="d-block medium fs-15">Document Name</label>
                                       <span className="d-flex">
                                        <input type="text"
                                            className="w-100"
                                            placeholder=""
                                            value={documentName}
                                            onChange={(e)=>setDocumentName(e.target.value)}
                                        />
                                        
                                        <button className="btn-sm" onClick={handleUpdateDocName}>Save</button>  
                                        </span>                                    
                                    </div> 
                                    :"" }
                                    </div>

                                    </div>




                                </div>

                                <div className="text-center d-flex align-items-center justify-content-center  py-4">
                                    <Link to={"/profile/" + userId} className="text-primary mx-4 border-btn">Cancel</Link>
                                   
                                    {loading ?
                                    <button type="submit" className="btn-sm" disabled={loading ? true : false}>
                                        <span className='spinner-grow spinner-grow-sm'>

                                        </span>
                                    </button>
                                    :
                                    <button type="submit" className="btn-sm" disabled={loading ? true : false} onClick={handleupdate}>Update</button>
                                    }
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
            }
            <Footer />
        </>
    )
}
