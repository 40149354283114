import React, { useState, useEffect } from 'react'
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar'
import { GetAnswerLater, GetNextPost } from "../API/AnswerApi";
import { CreateLikePost, CreatePostShare } from "../API/PostApi";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { AnswerSave } from "../API/AnswerApi";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const $ = window.jQuery;

export default function AnswerLater() {
    const [post, setPost] = useState({
        Posts: []
    })
    const [nextUrl, setNextUrl] = useState(null)
    const [likeStatus, setLikeStatus] = useState('')
    const [likeCount, setCount] = useState('')
    const [postlikeId, setLikePostId] = useState('')
    const [answerInput, setAnswerInput] = useState(false)
    const [showAnswerInputId, setShowAnswerInputId] = useState('')
    const [answerText, setAnswerText] = useState('')
    const [answerTextError, setAnswerTextError] = useState('')
    const [loading, setLoading] = useState(false)
    const [imagePriview, setImagePriview] = useState('')
    const Token = localStorage.getItem("token");
    const [pageLoading, setPageLoading] = useState(false)

    const history = useHistory();
    useEffect(
        function () {
            let mounted = true;
            setPageLoading(true);
            GetAnswerLater(Token).then((res) => {

                if (mounted) {
                    setPost((prevState) => ({
                        ...prevState,
                        Posts: [...prevState.Posts, res.data.data.data],
                    }));
                    setNextUrl(res.data.data.next_page_url);
                    setPageLoading(false);
                }
            });

            return () => {
                mounted = false;
            };
        },
        []
    );

    if (Token === null) {
        history.push("login");
    }
    const handleImagePriview = (image) => {
        setImagePriview(image);
        $("#myModalpop").modal('show');
    };

    const NotLogin = () => {
        swal({
            title: "Sign up for Ask Property",
            text: "Create a profile, ask questions and answer, questions and more",
            icon: "info",
            buttons: {
                login: {
                    text: 'login'
                },
                register: {
                    text: "Register"
                }
            },
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete == 'register') {
                    history.push('/register');
                } else if (willDelete == 'login') {
                    history.push('/login');
                }
            });

    };

    const answer = (id) => {
        setShowAnswerInputId(id);
        setAnswerInput(true);

    };
    const handleAnswer = (value) => {
        // $('.autoresizing').on('input', function () {
        //     this.style.height = 'auto';

        //     this.style.height =
        //         (this.scrollHeight) + 'px';
        // });
        setAnswerText(value);
    };
    const handleAnswerSubmit = (event) => {
        event.preventDefault();
        console.log('handleAnswer submit is calling');
        setLoading(true);

        if (!answerText) {
            setAnswerTextError("Please Enter Your Answer");
            setLoading(false);
        }
        if (answerText) {
            let data = {
                post_id: showAnswerInputId,
                answer: answerText,
            }
            AnswerSave(Token, data).then((res) => {
                if (res.data.status === "success") {
                    setAnswerInput(false);
                    setLoading(false);
                    setAnswerText('');
                    GetAnswerLater(Token).then((res) => {

                        setPost(() => ({

                            Posts: [[], res.data.data.data],
                        }));
                        setNextUrl(res.data.data.next_page_url);

                    });

                    swal("Success", "Answer Submitted Successfully", "success");
                }
            });
        }


    };

    const like = (id) => {
        let data = {
            post_id: id,
        }
        setLikePostId(id);
        CreateLikePost(Token, data).then((res) => {
            setLikeStatus(res.data.data.like_status);
            setCount(res.data.data.count);


        });

    };
    const onPostDetail = (slug) => {
        history.push("/post-detail" + '/' + slug, { slug: slug })
    };

    const handleloadMore = () => {
        if (nextUrl) {
            GetNextPost(nextUrl, Token).then((res) => {
                console.log(res);
                setPost((prevState) => ({
                    ...prevState,
                    Posts: [...prevState.Posts, res.data.data.data],
                }));

                setNextUrl(res.data.data.next_page_url);
            });
        }
    };

    const LongText = ({ content, limit, slug }) => {

        if (content.length <= limit) {
            // there is nothing more to show
            return <p className="ck" dangerouslySetInnerHTML={{ __html: content }}></p>
        }

        // In the final case, we show a text with ellipsis and a `Read more` button
        const toShow = content.substring(0, limit) + "...";
        return <p className="ck">
            <span dangerouslySetInnerHTML={{ __html: toShow }}>
            </span>
            <Link onClick={() => onPostDetail(slug)}>More</Link>
            {/* <button >Read more</button> */}

        </p>
    };
    const userProfile = (userId) => {
        history.push("profile" + '/' + userId, { userId: userId })
    };

    const postShare = (slug, element) => {
        if (element === "capylink") {
            const url = window.location.origin + "/post-detail" + "/" + slug;
            navigator.clipboard.writeText(url);
            swal({
                text: "Link copied successfully",
                icon: 'success'
            });
        } else {
            const url = window.location.origin + "/post-detail" + "/" + slug;
            const data = {
                post_slug: slug,
                share_type: element,
                post_url: url,
            }
            CreatePostShare(Token, data).then((res) => {
                window.open(res.data.data.shareLinkUrl, '_blank');
            });
        }
    };

    return (
        <>
            <Navbar />
            {pageLoading ?
                <div className="main">
                    <center>
                        <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                        </span>
                    </center>
                </div>
                :
                !post.Posts.length
                    ?
                    <div className='main d-flex pt-5 justify-content-center align-items-center'>
                        <div>
                            <div className="">
                                <div>
                                    No Posts Found
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div class="main">
                        <div class="container ">
                            <div class="row pt-5 justify-content-center">
                                <div class="col-lg-6  mb-5 ">
                                    <ul class="feed-list ">
                                        <h5 class="bold h4 pb-4">Answer Later</h5>
                                        {post.Posts.map((ite, i) => {

                                            return (
                                                ite.map((item, i) => {

                                                    return (

                                                        <li>
                                                            <h5 class="fs-15 text-gray medium ">{item?.CreatedAtCustom}</h5>
                                                            <div class="feed-box bg-white mb-4">
                                                                <div class="feed-top">
                                                                    <div class="d-flex align-items-start  w-100 pb-2">
                                                                        {item?.post?.user?.roleWiseProfileData?.user_image === null ?
                                                                            <img src="assets/image/dummy_image.png" alt="" class="feed-user" onClick={() => handleImagePriview("assets/image/dummy_image.png")} />
                                                                            :
                                                                            <img src={item?.post?.user?.roleWiseProfileData?.user_image} alt="" class="feed-user" onClick={() => handleImagePriview(item?.post?.user?.roleWiseProfileData?.user_image)} />
                                                                        }

                                                                        <div class="ps-3">
                                                                            <Link onClick={() => userProfile(item?.post?.user?.id)}>
                                                                                <h3>{item?.post?.user?.name} {item?.post?.user?.surname}</h3>
                                                                            </Link>
                                                                            {item?.post?.user?.role === 5 ?
                                                                                <h4 class="">{item?.post?.user?.roleWiseProfileData?.occupation} </h4>
                                                                                :
                                                                                item?.post?.user?.role === 2 ?
                                                                                    <h4 class="">{item?.post?.user?.roleWiseProfileData?.agency_name} </h4>
                                                                                    :
                                                                                    <h4 class="">{item?.post?.user?.roleWiseProfileData?.agency_profile?.agency_name} </h4>
                                                                            }
                                                                        </div>
                                                                        <div class="ms-auto">
                                                                            <span class="fs-12">{item?.post?.CreatedAtCustom}</span>
                                                                        </div>
                                                                    </div>
                                                                    <hr class="border-top" />
                                                                    <LongText content={item?.post?.question} limit={200} slug={item?.post?.slug} />

                                                                </div>
                                                                <div class="feed-img">
                                                                    {item?.post?.post_image === null ? '' :
                                                                        <img src={item?.post?.post_image} alt="ask-feed" class="w-100" onClick={() => handleImagePriview(item?.post?.post_image)} />
                                                                    }
                                                                </div>
                                                                {Token === null ?
                                                                    <div class="feed-share border-0">
                                                                        <span onClick={NotLogin}><img src="assets/image/like.png" class="pe-2" alt="" />{item?.post?.post_like_count}</span>
                                                                        <span onClick={NotLogin}><img src="assets/image/answer.png" alt="" /></span>

                                                                        <span class="ms-auto p-0" onClick={NotLogin}><img src="assets/image/share.png" alt="" /></span>

                                                                    </div>
                                                                    :
                                                                    <div class="feed-share border-0">

                                                                        {likeStatus === "like" && item?.post?.id === postlikeId || item?.post?.isAuthUserLikedPost === true ?
                                                                            <span onClick={() => like(item?.post?.id)}><img src="assets/image/like-fill.png" className="pe-2" alt="" style={{ width: "33px", height: "25px" }} />
                                                                                {likeStatus === "like" && item?.post?.id === postlikeId ?
                                                                                    likeCount
                                                                                    :
                                                                                    item?.post?.post_like_count
                                                                                }

                                                                            </span>
                                                                            :
                                                                            likeStatus === "deslike" && item?.post?.id === postlikeId ?
                                                                                <span onClick={() => like(item?.post?.id)}><img src="assets/image/like.png" className="pe-2" alt="" />
                                                                                    {likeStatus === "deslike" && item?.post?.id === postlikeId ?
                                                                                        likeCount
                                                                                        :
                                                                                        item?.post?.post_like_count
                                                                                    }

                                                                                </span>
                                                                                :
                                                                                <span onClick={() => like(item?.post?.id)}><img src="assets/image/like.png" className="pe-2" alt="" />
                                                                                    {likeStatus === "like" && item?.post?.id === postlikeId ?

                                                                                        likeCount

                                                                                        :
                                                                                        item?.post?.post_like_count
                                                                                    }
                                                                                </span>
                                                                        }



                                                                        <span onClick={() => answer(item?.post?.id)}><img src="assets/image/answer.png" alt="" /></span>

                                                                        <Link className="ms-auto p-0" id="share_dropdown" x-placement="bottom-end" data-bs-toggle="dropdown"><img src="assets/image/share.png" alt="" /></Link>
                                                                        <div className="dropdown-menu p-3" aria-labelledby="share_dropdown">
                                                                            <Link className="d-block fs-15 medium" onClick={() => postShare(item?.slug, 'facebook')}><img src="assets/image/fb.png" alt="" className="pe-2" /> Facebook</Link>
                                                                            <Link className="d-block py-3 fs-15 medium" onClick={() => postShare(item?.slug, 'twitter')}><img src="assets/image/twitter.png" alt="" className="pe-2" /> Twitter</Link>
                                                                            <Link className="d-block fs-15 medium" onClick={() => postShare(item?.slug, 'capylink')}><img src="assets/image/link.png" alt="" className="pe-2" /> Copylink</Link>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                <hr class="mt-0 border-top mb-4" />
                                                                {answerInput && showAnswerInputId === item?.post?.id ?
                                                                    <div className="feed-comments">
                                                                        <div className="row hidden">
                                                                            <div className="col-12">
                                                                                <form className="ans-save" onSubmit={handleAnswerSubmit}>

                                                                                    <div className="row">
                                                                                        <div className="col-10">
                                                                                            {/* <textarea
                                                                                            name="answer"
                                                                                            className="form-control autoresizing"
                                                                                            style={{ height: '39px' }}
                                                                                            value={answerText}
                                                                                            onChange={(e) => handleAnswer(e)}
                                                                                        ></textarea> */}
                                                                                            <CKEditor
                                                                                                data={answerText}
                                                                                                className="editor"
                                                                                                onChange={(event, editor) => {
                                                                                                    handleAnswer(editor.getData())
                                                                                                }}
                                                                                                editor={ClassicEditor}
                                                                                                config={{
                                                                                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                                                                                }}
                                                                                            />
                                                                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                                                                {answerTextError}
                                                                                            </p>

                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            {loading ?
                                                                                                <button type="submit" className="border-btn btn" disabled={loading ? true : false}>
                                                                                                    <span className='spinner-grow spinner-grow-sm'>

                                                                                                    </span>
                                                                                                </button>
                                                                                                :

                                                                                                <button type="submit" className="border-btn btn" disabled={loading ? true : false}>Send</button>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </li>

                                                    );
                                                })

                                            );
                                        })}

                                    </ul>

                                    {nextUrl !== null ?
                                        <Link onClick={handleloadMore} className="h6">
                                            <div className="text-center feed-list" style={{ background: "#fff", height: "32px" }}>

                                                Load More

                                            </div>
                                        </Link>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <Footer />
            <div class="modal fade" id="myModalpop">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content image-pop" style={{ background: "transparent", border: "none" }}>
                        <button type="button" class="close text-right close-btn popup_btn" data-bs-dismiss="modal" aria-label="Close" style={{ color: "red", fontWeight: 900 }}>&times;</button>
                        <img src={imagePriview} class="popup_image" style={{ height: "90%", width: "90%" }} />
                    </div>
                </div>
            </div>
        </>
    )
}
