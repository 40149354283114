import axios from "axios";
import { messageURLs } from '../constants/url';


  export const CreateMessageRoom = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${messageURLs.CreateMessageRoom}`,
    method: "Post",
    data:body,
});



export const GetMessage = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${messageURLs.GetMessage}`,
  method: "Post",
  data:body,
});

export const GetChatRoom = (token) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${messageURLs.GetChatRoom}`,
  method: "Post",
});

export const SendMessage = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${messageURLs.SendMessage}`,
  method: "Post",
  data:body,
});
export const readMessage = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${messageURLs.readMessage}`,
  method: "Post",
  data:body,
});



