import React, { useState, useEffect } from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {
  GetAllNotification,
  AllReadNotification,
  GetNextNotification,
} from "../API/NotificationApi";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { Link } from "react-router-dom";
export default function Notification() {
  const [notification, setNotification] = useState({
    Notifications: [],
  });
  const [nextUrl, setNextUrl] = useState("");

  const [loading, setLoading] = useState(false);
  const Token = localStorage.getItem("token");
  const history = useHistory();
  if (Token === null) {
    history.push("login");
  }
  useEffect(function () {
    let mounted = true;
    setLoading(true);
    GetAllNotification(Token).then((res) => {
      if (mounted) {
        // console.log('notifications_list',JSON.stringify(res.data.data,null,4));
        let unread_notification_count = res.data.total_unread_notification;
        let new_list = notification.Notifications.concat(res.data.data.data);
        // new_list.forEach((item)=>{
        //     console.log('item',item);
        //     if(item.read==="unread"){
        //         unread_notification_count+=1;
        //     }
        // })
        // console.log('total_notification_count',unread_notification_count);
        localStorage.setItem("unread_notifications", unread_notification_count);
        window.dispatchEvent(new Event("storage"));
        setNotification((prevState) => ({
          ...prevState,
          Notifications: [...prevState.Notifications, res.data.data.data],
        }));
        setNextUrl(res.data.data.next_page_url);
        // setNotification(res.data.data.data)
        setLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  const handleloadMore = () => {
    if (nextUrl) {
      GetNextNotification(nextUrl, Token).then((res) => {
        setNotification((prevState) => ({
          ...prevState,
          Notifications: [...prevState.Notifications, res.data.data.data],
        }));

        setNextUrl(res.data.data.next_page_url);
      });
    }
  };

  const handleNavigation = (item) => {
    if (item.type == "follow") {
      history.push("/profile" + "/" + item?.get_user_data?.id);
    }
    if (item.type == "contact") {
      history.push("/inquiries");
    }
    if (item.type == "like" || item.type == "answer" || item.type == "post") {
      history.push("/post-detail" + "/" + item?.post?.slug);
    }
  };

  const ReadNotification = (item) => {
    if (item.read === "read") {
      handleNavigation(item);
      return;
    }
    let data = {
      read_method: "single",
      id: item.id,
    };
    AllReadNotification(data, Token).then((res) => {
      if (res.data.status === "success") {
        GetAllNotification(Token)
          .then((res) => {
            let notifications = res.data.total_unread_notification;
            localStorage.setItem("unread_notifications", notifications);
            window.dispatchEvent(new Event("storage"));
            handleNavigation(item);
          })
          .catch((e) => {});
        // history.push("/profile" + '/' + slug, { slug: slug })
        // history.push("/post-detail" + '/' + slug, { slug: slug })
      }
    });
  };
  const readAllNotification = () => {
    let data = {
      read_method: "all",
    };
    AllReadNotification(data, Token).then((res) => {
      if (res.data.status === "success") {
        GetAllNotification(Token).then((res) => {
          localStorage.setItem("unread_notifications", 0);
          window.dispatchEvent(new Event("storage"));
          setNotification(() => ({
            Notifications: [[], res.data.data.data],
          }));
          setNextUrl(res.data.data.next_page_url);
        });
        swal("Success", "Notification Read Successfully", "success");
      }
    });
  };
  const LongText = ({ content, limit }) => {
    if (content.length <= limit) {
      // there is nothing more to show
      return (
        <p
          className="fs-14 text-gray"
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      );
    }

    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content.substring(0, limit) + "...";
    return (
      <p
        className="fs-14 text-gray"
        dangerouslySetInnerHTML={{ __html: toShow }}
      ></p>
    );
  };

  return (
    <>
      <Navbar />
      {loading ? (
        <div className="main">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div className="main py-5">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="notification-list">
                  <div className="d-flex align-items-center justify-content-between pb-2">
                    <h4 className="h4 bold">Notifications</h4>
                    <Link
                      className="text-gray fs-13 text-underline"
                      onClick={readAllNotification}
                    >
                      Mark All As Read
                    </Link>
                  </div>
                  <ul>
                    {notification?.Notifications?.map((ite, i) => {
                      return ite.map((item, i) => {
                        return (
                          <li
                            className={
                              item?.read === "read"
                                ? "bg-white rounded border mb-3"
                                : "bg-white rounded border mb-3 notification-seen"
                            }
                            onClick={() => {
                              ReadNotification(item);
                            }}
                          >
                            <div className="d-flex align-items-center  w-100 p-3">
                              {item?.get_user_data?.roleWiseProfileData
                                ?.user_image === null ? (
                                <img
                                  src="assets/image/dummy_image.png"
                                  alt=""
                                  className="feed-user"
                                />
                              ) : (
                                <img
                                  src={
                                    item?.get_user_data?.roleWiseProfileData
                                      ?.user_image
                                  }
                                  alt=""
                                  className="feed-user"
                                />
                              )}

                              <div className="ps-2">
                                <h3 className="h5 bold mb-1">{item.message}</h3>
                                {item?.post !== null ? (
                                  <LongText
                                    content={item?.post?.question}
                                    limit={50}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="ms-auto">
                                <span className="fs-12">
                                  {item?.CreatedAtCustom}
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      });
                    })}
                  </ul>
                  {nextUrl !== null ? (
                    <Link onClick={handleloadMore} className="h6">
                      <div
                        className="text-center feed-list"
                        style={{ background: "#fff", height: "32px" }}
                      >
                        Load More
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
