import React, { useState, useEffect, useRef } from 'react'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import { GetMasterData, SaveProperty } from '../../API/PropertyApi'
import { useHistory, Link } from "react-router-dom";
import { GetProfile } from "../../API/AuthApi";
import swal from "sweetalert";
import CurrencyFormat from 'react-currency-format';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const $ = window.jQuery;


export default function Add() {
    const history = useHistory();
    const [masterAmenity, setMasterAmenity] = useState([])
    const [masterTypeOfProperty, setMasterTypeOfProperty] = useState([])
    const [masterExternalFeatures, setMasterExternalFeatures] = useState([])
    const [masterBuildingType, setMasterBuildingType] = useState([])
    const Token = localStorage.getItem("token");
    const [masterAmenityData, setMasterAmenityData] = useState([])
    const [masterExternalFeaturesData, setMasterExternalFeaturesData] = useState([])
    const [listing_type, setlistingType] = useState()
    const [propertyName, setPropertyName] = useState()
    const [propertyPrice, setPropertyPrice] = useState()
    const [propertyAddress, setPropertyAddress] = useState()
    const [propertyImage, setPropertyImage] = useState([])
    const [propertyDescription, setPropertyDescription] = useState()
    const [propertyType, setPropertyType] = useState()
    const [propertyStreetAddress, setPropertyStreetAddress] = useState('')
    const [lattitude, setPropertyLattitude] = useState('')
    const [longitude, setPropertyLongitude] = useState('')
    // const [propertyStreetAddress, setPropertyStreetAddress] = useState('Cape Town, South Africa')
    // const [lattitude, setPropertyLattitude] = useState(-33.9248685)
    // const [longitude, setPropertyLongitude] = useState(18.4240553)
    const [occupation, setOccupation] = useState()
    const [floorSize, setFloorSize] = useState()
    const [diff_between_properties, setDiffBetweenProperties] = useState()
    const [building_type, setBuildingType] = useState()
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)
    const [submited, setSubmited] = useState('true')
    const [amenityError, SetAmenityError] = useState('')
    const [ExternalFeaturesError, SetExternalFeaturesError] = useState('')
    const [diff_between_propertiesError, setDiffBetweenPropertiesError] = useState('')
    const [building_typeError, setBuildingTypeError] = useState('')
    const [propertyTypeError, setPropertyTypeError] = useState('')
    const [listing_typeError, setlistingTypeError] = useState('')

    const [retirement, setRetirement] = useState('')
    const [onShow, setOnShow] = useState('')
    const [auction, setAuction] = useState('')
    const [repossessed, setRepossessed] = useState('')
    const [isDisplayAddress, setIsDisplayAddress] = useState(0)
    const [securityEstate, setSecurityEstate] = useState('')
    const user = JSON.parse(localStorage.getItem("user"));
    const role = JSON.parse(localStorage.getItem("role"));
    const [otherError, setOtherError] = useState('')
    const [propertyAdressError, setPropertyAdressError] = useState('')
    const [propertyImageError, setPropertyImageError] = useState('')
    const [propertyNameError, setPropertyNameError] = useState('')
    const [propertyPriceError, setPropertyPriceError] = useState('')
    const [erfSizeError, setErfSizeError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [anyError, setAnyError] = useState('')

    const [sellPricing, setSellPricing] = useState('')
    const [rentPricing, setRentPricing] = useState('')
    const [poi, setPoi] = useState(0)
    const inputRef = useRef();
    
    const options = {
        componentRestrictions: { country: "za" },
        types: ["geocode"]
    };

    useEffect(()=>{
        if(inputRef.current){
            if (inputRef.current && typeof window.google === "object") {
                const searchBox = new window.google.maps.places.Autocomplete(
                    inputRef.current,
                    options
                );
                
               window.google.maps.event.addListener(searchBox, 'place_changed', function () {
                    var places = searchBox.getPlace();
        
                    setPropertyStreetAddress(places.formatted_address);
                    setPropertyAddress(places.formatted_address);
                    setPropertyLattitude(places.geometry.location.lat())
                    setPropertyLongitude(places.geometry.location.lng())
        
        
                });
                
            }
        }
        return () => window.google.maps.event.removeListener();
    },[inputRef.current]);


    // useEffect(()=> {
    //     console.log('Hello world');
       
    //     // const input = document.getElementById("address");
    
    //     if (inputRef.current && typeof window.google === "object") {
    //     const searchBox = new window.google.maps.places.Autocomplete(
    //         inputRef.current,
    //         options
    //     );
        
    //    window.google.maps.event.addListener(searchBox, 'place_changed', function () {
    //         var places = searchBox.getPlace();

    //         setPropertyStreetAddress(places.name);
    //         setPropertyAddress(places.name);
    //         setPropertyLattitude(places.geometry.location.lat())
    //         setPropertyLongitude(places.geometry.location.lng())


    //     });
        
    // }
    // return () => window.google.maps.event.removeListener();
    // },[]);

    useEffect(
        function () {

           
    
            setPageLoading(true);
            GetMasterData(Token).then((res) => {
                if (res.data.type === "success") {

                    setMasterAmenity(res.data.data.masterAmenity);
                    setMasterTypeOfProperty(res.data.data.masterTypeOfProperty);
                    setMasterExternalFeatures(res.data.data.masterExternalFeatures);
                    setMasterBuildingType(res.data.data.masterBuildingType);
                    let data = res.data.data.masterAmenity.map((item) => {
                        return ({
                            "id": item.id,
                            "data": 0
                        });
                    });
                    setMasterAmenityData(data);

                    let masterExternalFeaturesNewData = res.data.data.masterExternalFeatures.map((item) => {
                        return ({
                            "id": item.id,
                            "data": null
                        });
                    });
                    setMasterExternalFeaturesData(masterExternalFeaturesNewData);
                    setPageLoading(false);
                }
            });
            let data = {
                user_id: user.id
            }
            GetProfile(data).then((res) => {
                // console.log(res?.data?.data?.phone);
                if (res?.data?.status === "success") {
                    if (res?.data?.data?.phone === null) {
                        swal("Info!", "Please add mobile number from the profile page!", "info");
                        history.push('/property');
                    }
                    if(res?.data?.data?.role == 2 || res?.data?.data?.role == 5){
                    if (res?.data?.data?.roleWiseProfileData?.user) {
                        setRentPricing(res?.data?.data?.roleWiseProfileData?.user?.max_property_list_rent);
                        setSellPricing(res?.data?.data?.roleWiseProfileData?.user?.max_property_list_sale);
                    }
                }else{
                    setRentPricing(res?.data?.data?.roleWiseProfileData?.agency_profile?.user?.max_property_list_rent);
                    setSellPricing(res?.data?.data?.roleWiseProfileData?.agency_profile?.user?.max_property_list_sale);
                }
                }
            });

        },
        []
    );

    const handlePOI = (val) => {
        setPoi(val);
    };


    const handleMutipleImage = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        let new_arr = [...propertyImage, ...chosenFiles];
        if(new_arr.length>20){
            swal("Max Files Limit Reached!", "Maximum images allowed to upload are 20", "info");
            
            e.preventDefault();
            return;
        }
        if(new_arr.length>=5){
            setPropertyImageError("");
        }
        setPropertyImage(new_arr);
    };

    const handleDeleteImage = (e) => {
        const s = propertyImage.filter((item, index) => index !== e);
        setPropertyImage(s);
    };

    const selectedPlaced = (place) => {
        setPropertyStreetAddress(place.formatted_address);
        setPropertyAddress(place.formatted_address);
        setPropertyLattitude(place.geometry?.location?.lat())
        setPropertyLongitude(place.geometry?.location?.lng())
    };

    const handleChangeDescription = (e) => {
      
        setPropertyDescription(e)
    };

    const handleExternalFeatures = (item, isActive) => {
        let is_active = isActive;
        let new_Dt = masterExternalFeaturesData.map((ite) => {
            if (item.id == ite.id) {
                return ({
                    ...ite,
                    data: is_active ? 1 : 0
                })
            }
            return ite;

        })
        setMasterExternalFeaturesData(new_Dt);

    };

    let handleData = (item, isMinus) => {
        let is_minus = isMinus;
        let new_Dt = masterAmenityData.map((ite) => {
            if (item.id == ite.id) {
                return ({
                    ...ite,
                    data: is_minus ? (!ite.data ? ite.data : ite.data - 1) : parseInt(ite.data) + 1
                })
            }
            return ite;

        })
        setMasterAmenityData(new_Dt);
    };

    const handlePropertyType = (value) => {
        let pricingStatus = true;
        if (value === "rent") {
            setSecurityEstate("");
            setRepossessed("");
            setAuction("");
            setOnShow("");
            setRetirement("");
        }
        if (role === 5) {
            if (value === "sell") {
                if (sellPricing === 0) {
                    swal("Insufficient Balance!", "Insufficient Balance. Please purchase the correct plan", "info");
                    setlistingType('');
                    pricingStatus = false;
                }
            }
            if (value === "rent") {
                if (rentPricing === 0) {
                    swal("Insufficient Balance!", "Insufficient Balance. Please purchase the correct plan", "info");
                    setlistingType('');
                    pricingStatus = false;
                }
            }

        } else {
            if (value === "sell") {
                if (sellPricing === 0) {
                    swal("Insufficient Balance!", "Insufficient Balance.  Contact your Principal/Admin to purchase a listing plan before", "info");
                    setlistingType('');
                    pricingStatus = false;
                }
            }
            if (value === "rent") {
                if (rentPricing === 0) {
                    setlistingType('');
                    swal("Insufficient Balance!", "Insufficient Balance.  Contact your Principal/Admin to purchase a listing plan before!", "info");
                    pricingStatus = false;
                }
            }
           

        }


        if (pricingStatus === true) {
            setlistingType(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!listing_type) {
            setlistingTypeError("Please select property Rent or Sale")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setlistingTypeError("")
            setAnyError("")
        }
        if (!propertyName) {
            setPropertyNameError("Please enter property title")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setPropertyNameError("")
            setAnyError("")
        }
        if(poi === 0){
        if (!propertyPrice) {
            setPropertyPriceError("Please enter property price")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setPropertyPriceError("")
            setAnyError("")
        }
        }

        if (!floorSize) {
            setErfSizeError("Please enter erf size")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setErfSizeError("")
            setAnyError("")
        }

        if (!lattitude) {
            setPropertyAdressError("Please select location from suggestion");
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setPropertyAdressError("")
            setAnyError("")
        }



        if (!propertyType) {
            setPropertyTypeError("Please select type of property")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setPropertyTypeError("")
            setAnyError("")
        }

        if(!propertyImage || propertyImage.length<5){
            setPropertyImageError('Please select atleast 5 images');
            setAnyError("See error message above")
            setLoading(false);
            return false;
        }
        else{
            setPropertyImageError('');
            setAnyError('');

        }
        if (!propertyDescription) {
            setDescriptionError("Please enter property description")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setDescriptionError("")
            setAnyError("")
        }



        // if (!retirement || !onShow || !auction || !repossessed || !securityEstate) {
        //     setOtherError("Please select from Others if applicable");
        //     setAnyError("See error message above")
        //     setLoading(false);
        //     return false;
        // } else {
        //     setOtherError("")
        //     setAnyError("")
        // }
        
       

        for (var i = 0; i < masterExternalFeaturesData.length; i++) {
            if (masterExternalFeaturesData[i].data === null) {
                SetExternalFeaturesError('Please select External features');
                setAnyError("See error message above")
                setSubmited('false');
                setLoading(false);
                return false;
            } else {
                SetExternalFeaturesError('');
                setAnyError("")
            }

        }
        
        if (propertyType!=8 && !building_type) {
            setBuildingTypeError("Please select Building Type")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setBuildingTypeError("")
            setAnyError("")
        
        }
        const formData = new FormData();
        formData.append("listing_type", listing_type);
        formData.append("property_name", propertyName);
        if (propertyPrice) {
            formData.append("property_price", propertyPrice);
        }else{
            formData.append("property_price", 0);
        }

        formData.append("property_address", propertyAddress);
        formData.append("description", propertyDescription);
        for (var i = 0; i < masterAmenityData.length; i++) {
            formData.append("amenities[" + [i] + "][id]", masterAmenityData[i].id);
            formData.append("amenities[" + [i] + "][data]", masterAmenityData[i].data);
        }
        formData.append("property_type", propertyType);
        formData.append("street_address", propertyStreetAddress);
        formData.append("lattitude", lattitude);
        formData.append("longitude", longitude);
        formData.append("is_display_address", isDisplayAddress);
        formData.append("occupation", occupation);
        formData.append("floor_size", floorSize);
        formData.append("is_display_poa", poi);
        if(securityEstate){
            formData.append("security_estate", securityEstate);
        }else{
                formData.append("security_estate", '0');
        }
        if(repossessed){
                formData.append("repossessed", repossessed);
        }else{
                formData.append("repossessed", '0');
        }
        
        if(auction){
                formData.append("auction", auction);
        }else{
                formData.append("auction", '0');
        }

        if(onShow){
            formData.append("on_show", onShow);
        }else{
                formData.append("on_show", '0');
        }
        
        if(retirement){
            formData.append("retirement", retirement);
        }else{
            formData.append("retirement", '0');
        }
        // formData.append("security_estate", securityEstate);
        // formData.append("repossessed", repossessed);
        // formData.append("auction", auction);
        // formData.append("on_show", onShow);
        // formData.append("retirement", retirement);
        for (var i = 0; i < masterExternalFeaturesData.length; i++) {
            formData.append("external_features[" + [i] + "][id]", masterExternalFeaturesData[i].id);
            formData.append("external_features[" + [i] + "][data]", masterExternalFeaturesData[i].data);
        }
        if (propertyImage) {
            for (var i = 0; i < propertyImage.length; i++) {
                formData.append("images[]", propertyImage[i]);
            }
        }
        formData.append("diff_between_properties", diff_between_properties);
        if(propertyType!=8){
            formData.append("building_type", building_type);
        }
        SaveProperty(formData, Token).then((res) => {
            if (res.status === 200) {
                swal("Success", res.data.message, "success");
                setLoading(false);
                history.push('/property');
            } else {
                swal("error", res.data.message, "error");
                setLoading(false);
            }
        }).catch((err) => {
            if (e && e.response && e.response.data &&
                e.response.data.message) {
                swal("Error!", e.response.data.message, "error");
            }
            
            setLoading(false);
        });

    };

    const back = () => {
        history.push('/property');
    };

    return (
        <>
            <Navbar />
            {pageLoading ?
                <div className="main py-5">
                    <center>
                        <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                        </span>
                    </center>
                </div>
                :
                <div className="main py-5">
                    <div className="container ">

                        <div className="row pb-md-5">

                            <AgencyNavbar />

                            <div className="col-md-9 ps-md-4 border-start admin-right-part">

                                <div className="row">
                                    <form onSubmit={handleSubmit} className="add-property site-form">
                                        <div className="col-lg-8">
                                            <h2 className="text-primary semibold h1 pb-3">Add New Property</h2>


                                            <div className="d-flex align-items-center w-100 prop-sel-option pb-4">
                                                <div className="d-flex align-items-center col-4">
                                                    <label for="sell" className="d-flex align-items-center pb-0">
                                                        <img src="assets/image/sell.png" alt="" className="pe-3" /> For Sale
                                                    </label>
                                                    <input type="radio"
                                                        name="sell-rent"
                                                        id="sell"
                                                        checked={listing_type == 'sell' ? true : false}
                                                        onClick={() => handlePropertyType("sell")} />
                                                </div>

                                                <div className="d-flex align-items-center col-4">
                                                    <label for="rent" className="d-flex align-items-center pb-0">
                                                        <img src="assets/image/rent.png" alt="" className="pe-3" /> For Rent
                                                    </label>
                                                    <input type="radio"
                                                        name="sell-rent"
                                                        id="rent"
                                                        checked={listing_type == 'rent' ? true : false}
                                                        onClick={() => handlePropertyType("rent")} />
                                                </div>


                                            </div>
                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                {listing_typeError}
                                            </p>


                                            <div className="row">
                                            <div className="form-field col-md-6 offset-6">

                                            <p className="d-flex justify-content-between ">
                                                Price on Application (P.O.A)
                                                <span className="d-flex align-items-center">
                                                    <span className="d-flex align-items-center pe-4">
                                                        {poi === 0 ?
                                                            <input type="checkbox"
                                                                onClick={() => handlePOI(1)}
                                                            />
                                                            :
                                                            <input type="checkbox"
                                                                onClick={() => handlePOI(0)}
                                                            />
                                                        }

                                                    </span>
                                                </span>
                                            </p>
                                            </div>


                                                <div className="form-field col-md-6">

                                                    <label className="d-block medium fs-15">Property Title </label>
                                                    <input
                                                        type="text"
                                                        className="w-100"
                                                        placeholder="Property Title"
                                                        value={propertyName}
                                                        onChange={(e) => { setPropertyName(e.target.value) }}
                                                        
                                                    />
                                                     <p style={{ color: "red", margintop: "-10px" }}>
                                                        {propertyNameError}
                                                    </p>
                                                </div>

                                                {poi === 1 ? "" : 
                                                <div className="form-field col-md-6">

                                                    <label className="d-block medium fs-15">Property Price</label>
                                                   
                                                        
                                                        <CurrencyFormat className="w-100" placeholder="Property Price" value={propertyPrice} thousandSeparator={true} prefix={'R '} onValueChange={(values) => {
                                                            const {formattedValue, value} = values;
                                                            setPropertyPrice(value)
                                                        }}/>
                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {propertyPriceError}
                                                        </p>
                                                </div>
                                                }

                                                
                                                <h3 className="semibold h3 pb-4">Property Overview</h3>

                                                <div className="row">
                                                   
                                                    <div className="form-field col-md-12">
                                                        <label className="d-block medium fs-15">Type of Property</label>
                                                        <select name="" id="" onChange={(e) => setPropertyType(e.target.value)}>
                                                            <option value="">Select...</option>
                                                            {masterTypeOfProperty.map((item, i) => {
                                                                return (
                                                                    <option value={item.id}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {propertyTypeError}
                                                        </p>
                                                    </div>


                                                    

                                                    {listing_type === 'rent' ?
                                                        <div class="form-field col-md-6">
                                                            <label class="d-block medium fs-15">Occupation Date</label>
                                                            <input type="date"
                                                                class="w-100"
                                                                placeholder="Occupation"
                                                                value={occupation}
                                                                onChange={(e) => { setOccupation(e.target.value) }}
                                                                
                                                            />
                                                        </div>
                                                        : ""}


                                                    <div className="form-field col-md-6">
                                                        <label className="d-block medium fs-15">Erf Size</label>
                                                        <input type="text"
                                                            className="w-100"
                                                            placeholder="Erf Size"
                                                            value={floorSize}
                                                            onChange={(e) => { setFloorSize(e.target.value) }}
                                                            
                                                        />
                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {erfSizeError}
                                                        </p>
                                                    </div>



                                                </div>

                                                <div className="form-field col-md-12">
                                                    <label className="d-block medium fs-15">Property Address</label>
                                                    <input type="text"
                                                        ref={inputRef}
                                                        id="address"
                                                        placeholder="Please Enter Location"
                                                    />
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {propertyAdressError}
                                                    </p>


                                                </div>
                                                <div className="form-field col-md-12">

                                                    <p className="d-flex justify-content-between ">
                                                    Click the checkbox if you want the address visible on the frontend
                                                        <span className="d-flex align-items-center">
                                                            <span className="d-flex align-items-center pe-4">
                                                                {isDisplayAddress === 0 ?
                                                                    <input type="checkbox"
                                                                        onClick={() => setIsDisplayAddress(1)}
                                                                    />
                                                                    :
                                                                    <input type="checkbox"
                                                                        onClick={() => setIsDisplayAddress(0)}
                                                                    />

                                                                }


                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="form-field col-md-12">
                                                    <label className="d-block medium fs-15 pb-2">Property Images (We recommend a minimum of 5 pictures and 100 MB max)</label>
                                                    <div>
                                                        <input type="file"
                                                            id="prop-img"
                                                            className="d-none"
                                                            multiple
                                                            accept="image/*"
                                                            maxLength={6}
                                                            onChange={(e) => handleMutipleImage(e)}

                                                        />
                                                        <label for="prop-img" className="property-img">
                                                            <i className="far fa-image"></i>
                                                        </label>


                                                    </div>
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {propertyImageError}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {propertyImage.length > 0 ?
                                            <label className="d-block medium fs-15">Selected Images</label>
                                            : ""}
                                        {propertyImage.length > 0 ?

                                            <div className="row">

                                                {propertyImage.map((item, i) => {

                                                    return (

                                                        <div className="col-md-3 p-2 d-flex">
                                                            <img src={URL.createObjectURL(item)} style={{ width: "350px", height: "200px" }} />
                                                            <Link style={{ color: 'red', marginTop: "-12px", fontWeight: 500, }} onClick={() => handleDeleteImage(i)}>
                                                                <small className="" style={{ color: 'red', marginTop: "0px" }}>X</small>
                                                            </Link>
                                                        </div>


                                                    );

                                                })}
                                            </div>
                                            :
                                            ""
                                        }


                                        <div className="col-lg-8">
                                            <div className="row">

                                                <div className="form-field col-md-12">
                                                    <label className="d-block medium fs-15">Description</label>
                                                    {/* <textarea name=""
                                                        id=""
                                                        cols="30"
                                                        rows="10"
                                                        className="form-control autoresizing ckeditor "
                                                        value={propertyDescription}
                                                        onChange={(e) => { handleChangeDescription(e) }}
                                                        
                                                    ></textarea> */}
                                                     
                                                     <CKEditor
                                                            data={propertyDescription}
                                                            className="editor"
                                                            
                                                            cols="30"
                                                            rows="10"
                                                            onChange={ ( event, editor ) =>{
                                                                handleChangeDescription(editor.getData())
                                                            } }
                                                            editor={ ClassicEditor }
                                                            config={{         
                                                                toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                                              }}
                                                        />
                                                       
                                                     <p style={{ color: "red", margintop: "-10px" }}>
                                                        {descriptionError}
                                                    </p>
                                                </div>


                                            </div>

                                            <h4 className="semibold pb-4">Amenities</h4>

                                            <div className="select-amenity pb-5">

                                                {masterAmenity.map((item, i) => {
                                                    return (
                                                        <p>{item.name}
                                                            <span>
                                                                <button className="minus" type="button" value="-" onClick={() => handleData(item, true)}>-</button>
                                                                <input type="text" name={item.name} value={masterAmenityData[i]?.data || 0} className="input-text qty" />
                                                                <button className="plus" type="button" value="+" onClick={() => handleData(item, false)}>+</button>
                                                            </span>
                                                        </p>
                                                    );
                                                })}
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {amenityError}
                                                </p>
                                            </div>



                                            <h3 className="semibold h3 pb-3">External Features</h3>

                                            <div className="extra-features pb-5">

                                                {masterExternalFeatures.map((item, i) => {
                                                    return (
                                                        <p className="d-flex justify-content-between ">{item.name}
                                                            <span className="d-flex align-items-center">
                                                                <span className="d-flex align-items-center pe-4">
                                                                    <input type="radio" name={item.name} id="swimming-yes" checked={masterExternalFeaturesData[i]?.data === 1 ? true : false} onClick={() => handleExternalFeatures(item, true)} />
                                                                    <label for="swimming-yes">Yes</label>
                                                                </span>

                                                                <span className="d-flex align-items-center">
                                                                    <input type="radio" name={item.name} id="swimming-no" checked={masterExternalFeaturesData[i]?.data === 0 ? true : false} onClick={() => handleExternalFeatures(item, false)} />
                                                                    <label for="swimming-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    );
                                                })}

                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {ExternalFeaturesError}
                                                </p>
                                            </div>
                                            {listing_type === 'sell' ?
                                                <>
                                                    <h3 className="semibold h3 pb-3">Others</h3>

                                                    <div class="extra-features pb-5">
                                                        <p class="d-flex justify-content-between ">Retirement
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        name="Retirement"
                                                                        id="Retirement-yes"

                                                                        value={'1'}
                                                                        onChange={(e) => { setRetirement(e.target.value) }}
                                                                    />
                                                                    <label for="Retirement-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={'0'}
                                                                        onChange={(e) => { setRetirement(e.target.value) }}
                                                                        name="Retirement" id="Retirement-no" />
                                                                    <label for="Retirement-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p class="d-flex justify-content-between ">On Show
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        value={'1'}
                                                                        onChange={(e) => { setOnShow(e.target.value) }}
                                                                        name="Show" id="Show-yes" />
                                                                    <label for="Show-yes">Yes</label>
                                                                </span>
                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={'0'}
                                                                        onChange={(e) => { setOnShow(e.target.value) }}
                                                                        name="Show" id="Show-no" />
                                                                    <label for="Show-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>



                                                        <p class="d-flex justify-content-between ">Auction
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        value={'1'}
                                                                        onChange={(e) => { setAuction(e.target.value) }}
                                                                        name="Auction" id="Auction-yes" />
                                                                    <label for="Auction-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={'0'}
                                                                        onChange={(e) => { setAuction(e.target.value) }}
                                                                        name="Auction" id="Auction-no" />
                                                                    <label for="Auction-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p class="d-flex justify-content-between ">Repossessed
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        value={'1'}
                                                                        onChange={(e) => { setRepossessed(e.target.value) }}
                                                                        name="Repossessed" id="Repossessed-yes" />
                                                                    <label for="Repossessed-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={'0'}
                                                                        onChange={(e) => { setRepossessed(e.target.value) }}
                                                                        name="Repossessed" id="Repossessed-no" />
                                                                    <label for="Repossessed-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p class="d-flex justify-content-between ">Security Estate
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        value={'1'}
                                                                        onChange={(e) => { setSecurityEstate(e.target.value) }}
                                                                        name="SecurityEstate" id="SecurityEstate-yes" />
                                                                    <label for="SecurityEstate-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={'0'}
                                                                        onChange={(e) => { setSecurityEstate(e.target.value) }}
                                                                        name="SecurityEstate" id="SecurityEstate-no" />
                                                                    <label for="SecurityEstate-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {otherError}
                                                        </p>

                                                    </div>
                                                </>
                                                : ""}




                                            <div className="row">
                                                {/* <div className="form-field col-md-6">
                                                    <label className="d-block medium fs-15">Differentiate Between Properties</label>
                                                    <select name="" id="" onChange={(e) => setDiffBetweenProperties(e.target.value)}>
                                                        <option value="">Select...</option>
                                                        <option value="Under Offer">Under Offer</option>
                                                        <option value="Price Reduced">Price Reduced</option>
                                                        <option value="Property Sold">Property Sold</option>
                                                    </select>
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                    {diff_between_propertiesError}
                                                    </p>
                                                </div> */}
                                                {propertyType == 8 ? "" :
                                                <div className="form-field col-md-6">
                                                    <label className="d-block medium fs-15">Building Type</label>
                                                    <select name="" id="" onChange={(e) => setBuildingType(e.target.value)}>
                                                        <option value="">Select...</option>
                                                        {masterBuildingType.map((item, i) => {
                                                            return (
                                                                <option value={item.id}>{item.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {building_typeError}
                                                    </p>
                                                </div>
                                                }
                                            </div>


                                            <div className="add-prop-btn pt-4">
                                                {loading ?
                                                    <button type="submit" className="btn-sm" disabled={loading ? true : false}>
                                                        <span className='spinner-grow spinner-grow-sm '>

                                                        </span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="custom-btn" disabled={loading ? true : false}>Submit</button>
                                                }

                                                <button onClick={back} className="back-btn">Back</button>
                                            </div>
                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                {anyError}
                                            </p>

                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-9 ps-md-4 border-start admin-right-part offset-3">
                    </div>
                </div>
            }


            <Footer />
        </>
    )
}
