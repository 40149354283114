import React, { useState, useEffect, useRef } from 'react'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import { GetMasterData, UpdateProperty, PropertyDetail, PropertyImageDelete } from '../../API/PropertyApi'
import { useLocation, useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import CurrencyFormat from 'react-currency-format';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const $ = window.jQuery;

export default function Edit() {
    const location = useLocation();
    const history = useHistory();
    const [masterAmenity, setMasterAmenity] = useState([])
    const [masterTypeOfProperty, setMasterTypeOfProperty] = useState([])
    const [masterExternalFeatures, setMasterExternalFeatures] = useState([])
    const [masterBuildingType, setMasterBuildingType] = useState([])
    const Token = localStorage.getItem("token");
    const slug = location.state.data;
    const [masterAmenityData, setMasterAmenityData] = useState([])
    const [masterExternalFeaturesData, setMasterExternalFeaturesData] = useState([])
    const [masterExternalFeaturesDataStatus, setMasterExternalFeaturesDataStatus] = useState(false)
    const [listing_type, setlistingType] = useState()
    const [listing_no, setlistingNo] = useState()
    const [propertyName, setPropertyName] = useState()
    const [propertyPrice, setPropertyPrice] = useState()
    const [propertyAddress, setPropertyAddress] = useState()
    const [propertyImage, setPropertyImage] = useState([])
    const [propertyImagecount, setPropertyImageCount] = useState([])
    const [propertyImagePriview, setPropertyImagePriview] = useState("")
    const [propertyDescription, setPropertyDescription] = useState()
    const [propertyType, setPropertyType] = useState()
    const [propertyStreetAddress, setPropertyStreetAddress] = useState('')
    const [lattitude, setPropertyLattitude] = useState('')
    const [longitude, setPropertyLongitude] = useState('')
    const [occupation, setOccupation] = useState()
    const [floorSize, setFloorSize] = useState()
    const [diff_between_properties, setDiffBetweenProperties] = useState()
    const [building_type, setBuildingType] = useState()
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)
    const [submited, setSubmited] = useState('true')
    const [amenityError, SetAmenityError] = useState('')
    const [ExternalFeaturesError, SetExternalFeaturesError] = useState('')
    const [diff_between_propertiesError, setDiffBetweenPropertiesError] = useState('')
    const [building_typeError, setBuildingTypeError] = useState('')
    const [propertyTypeError, setPropertyTypeError] = useState('')
    const [listing_typeError, setlistingTypeError] = useState('')
    const [poi, setPoi] = useState(0)
    const [retirement, setRetirement] = useState()
    const [onShow, setOnShow] = useState()
    const [auction, setAuction] = useState()
    const [repossessed, setRepossessed] = useState()
    const [securityEstate, setSecurityEstate] = useState()
    const [propertyImageError, setPropertyImageError] = useState('')
    const [propertyNameError, setPropertyNameError] = useState('')
    const [propertyPriceError, setPropertyPriceError] = useState('')
    const [erfSizeError, setErfSizeError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [otherError, setOtherError] = useState('')
    const [propertyAdressError, setPropertyAdressError] = useState('')
    const [isDisplayAddress, setIsDisplayAddress] = useState(0)
    const autocompleteRef = useRef();
    const [anyError, setAnyError] = useState('')
    const [reasonData, setReason] = useState([])
    const [reasonError, setReasonError] = useState('')
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: "za" },
        types: ['geocode']
    };

    // useEffect(function () {

    //     // const searchBox = new window.google.maps.places.Autocomplete(
    //     //     inputRef.current,
    //     //     options
    //     // );
    //     if (inputRef.current && typeof window.google === "object") {
    //         const searchBox = new window.google.maps.places.Autocomplete(
    //             inputRef.current,
    //             options
    //         );

    //     window.google.maps.event.addListener(searchBox, 'place_changed', function () {
    //         var places = searchBox.getPlace();

    //         setPropertyStreetAddress(places.formatted_address);
    //         setPropertyAddress(places.formatted_address);
    //         setPropertyLattitude(places.geometry.location.lat())
    //         setPropertyLongitude(places.geometry.location.lng())


    //     });
    // }
    // });
    useEffect(() => {
        if (inputRef.current) {
            if (inputRef.current && typeof window.google === "object") {
                const searchBox = new window.google.maps.places.Autocomplete(
                    inputRef.current,
                    options
                );

                window.google.maps.event.addListener(searchBox, 'place_changed', function () {
                    var places = searchBox.getPlace();

                    setPropertyStreetAddress(places.formatted_address);
                    setPropertyAddress(places.formatted_address);
                    setPropertyLattitude(places.geometry.location.lat())
                    setPropertyLongitude(places.geometry.location.lng())


                });

            }
        }
        return () => window.google.maps.event.removeListener();
    }, [inputRef.current]);

    useEffect(
        function () {
            let mounted = true;
            setPageLoading(true);
            GetMasterData(Token).then((res) => {
                if (res.data.type === "success") {
                    setMasterAmenity(res.data.data.masterAmenity);
                    setMasterTypeOfProperty(res.data.data.masterTypeOfProperty);
                    setMasterExternalFeatures(res.data.data.masterExternalFeatures);
                    setMasterBuildingType(res.data.data.masterBuildingType);


                }
            });

            getPropertyData();
            setPageLoading(false);

            return () => {
                mounted = false;
            };
        },
        []
    );

    const getPropertyData = () => {
        PropertyDetail(Token, slug).then((res) => {
            setlistingType(res?.data?.data?.property?.listing_type);
            setPropertyName(res?.data?.data?.property?.property_name);
            setPropertyPrice(res?.data?.data?.property?.property_price);
            setPropertyAddress(res?.data?.data?.property?.property_address);
            setPropertyDescription(res?.data?.data?.property?.description);
            setPropertyType(res?.data?.data?.property?.property_type?.id);
            setPropertyStreetAddress(res?.data?.data?.property?.street_address);
            setOccupation(res?.data?.data?.property?.occupation);
            setFloorSize(res?.data?.data?.property?.floor_size);
            setDiffBetweenProperties(res?.data?.data?.property?.diff_between_properties);
            setBuildingType(res?.data?.data?.property?.building_type?.id);
            setlistingNo(res?.data?.data?.property?.listing_no);
            // setPropertyImage(res?.data?.data?.property?.property_images);
            setPropertyImageCount(res?.data?.data?.property?.property_images);

            setPropertyLattitude(res?.data?.data?.property?.lattitude)
            setPropertyLongitude(res?.data?.data?.property?.longitude)
            setPoi(res?.data?.data?.property?.is_display_poa);
            setSecurityEstate(res?.data?.data?.property?.security_estate);
            setRepossessed(res?.data?.data?.property?.repossessed);
            setAuction(res?.data?.data?.property?.auction);
            setOnShow(res?.data?.data?.property?.on_show);
            setRetirement(res?.data?.data?.property?.retirement);
            setIsDisplayAddress(res?.data?.data?.property?.is_display_address);
            setPropertyImagePriview('get')
            let data = res?.data?.data?.property?.get_selected_amenities.map((item) => {
                return ({
                    "id": item.master_property_id,
                    "data": item.data
                });
            });
            setMasterAmenityData(data);

            let Newdata = res?.data?.data?.property?.get_selected_external_features.map((item) => {
                return ({
                    "id": item.master_property_id,
                    "data": item.data
                });
            });
            setMasterExternalFeaturesData(Newdata);


        });

    };

    const handleChangeDescription = (e) => {

        setPropertyDescription(e)
    };

    const handlePOI = (val) => {
        setPoi(val);
    };

    const selectedPlaced = (place) => {
        setPropertyStreetAddress(place.formatted_address);
        setPropertyAddress(place.formatted_address);
        setPropertyLattitude(place.geometry?.location?.lat())
        setPropertyLongitude(place.geometry?.location?.lng())
    };

    const handleMutipleImage = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);

        let new_arr = [...propertyImage, ...propertyImagecount, ...chosenFiles];
        if (new_arr.length > 20) {
            swal("Max Files Limit Reached!", "Maximum images allowed to upload are 20", "info");

            e.preventDefault();
            return;
        }
        setPropertyImage([...propertyImage, ...chosenFiles]);
    };

    const handleDeletePreImage = (e) => {
        const s = propertyImage.filter((item, index) => index !== e);
        setPropertyImage(s);
    };

    const handleExternalFeatures = (item, isActive) => {
        let is_active = isActive;

        let new_Dt = masterExternalFeaturesData.map((ite) => {
            if (item.id == ite.id) {
                return ({
                    ...ite,
                    data: is_active ? '1' : '0'
                })
            }
            return ite;

        })
        setMasterExternalFeaturesDataStatus(true);
        setMasterExternalFeaturesData(new_Dt);
        console.log('new_dt', JSON.stringify(new_Dt, null, 4));

    };

    let handleData = (item, isMinus) => {
        let is_minus = isMinus;
        console.log(masterAmenityData);
        let new_Dt = masterAmenityData.map((ite) => {
            if (item.id == ite.id) {
                return ({
                    ...ite,
                    data: is_minus ? (!ite.data ? ite.data : ite.data - 1) : (parseInt(ite.data) + 1)
                })
            }
            return ite;

        })
        setMasterAmenityData(new_Dt);
        console.log('new_dt', JSON.stringify(new_Dt, null, 4));
    }

    const handlePropertyType = (value) => {
        setlistingType(value);
        if (value === "rent") {
            setSecurityEstate("");
            setRepossessed("");
            setAuction("");
            setOnShow("");
            setRetirement("");
        }
    };

    const handleSubmit = (e) => {
        console.log(onShow);
        e.preventDefault();
        setLoading(true);

        if (!listing_type) {
            setlistingTypeError("Please select property Rent or Sale")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setlistingTypeError("")
            setAnyError("")
        }
        if (!propertyName) {
            setPropertyNameError("Please enter property title")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setPropertyNameError("")
            setAnyError("")
        }
        if (poi === 0) {
            if (!propertyPrice) {
                setPropertyPriceError("Please enter property price")
                setAnyError("See error message above")
                setLoading(false);
                return false;
            } else {
                setPropertyPriceError("")
                setAnyError("")
            }
        }

        if (!floorSize) {
            setErfSizeError("Please enter erf size")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setErfSizeError("")
            setAnyError("")
        }

        if (!lattitude) {
            setPropertyAdressError("Please select location from suggestion");
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setPropertyAdressError("")
            setAnyError("")
        }



        if (!propertyType) {
            setPropertyTypeError("Please select type of property")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setPropertyTypeError("")
            setAnyError("")
        }
        if (propertyImage && propertyImagecount) {
            let total_images = propertyImage.length + propertyImagecount.length;
            if (total_images < 5) {
                let images_required = 5 - propertyImagecount.length;
                let image_ = images_required === 1 ? "image" : "images";
                let error_msg = `Please select at least ${images_required} more ${image_}`;
                setPropertyImageError(error_msg);
                setAnyError("See error message above")
                setLoading(false);
                return false;
            }
            else {
                setPropertyImageError("");
                setAnyError("");
            }
        }
        else {
            return false;

        }
        if (!propertyDescription) {
            setDescriptionError("Please enter property description")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setDescriptionError("")
            setAnyError("")
        }


        for (var i = 0; i < masterExternalFeaturesData.length; i++) {
            if (masterExternalFeaturesData[i].data === null) {
                SetExternalFeaturesError('Please select External features');
                setSubmited('false');
                setLoading(false);
                return false;
            } else {
                SetExternalFeaturesError('');
            }

        }
        if (retirement === "" || onShow === "" || auction === "" || repossessed === "" || securityEstate === "") {
            setOtherError("Please select from Others if applicable");
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setOtherError("")
            setAnyError("")
        }



        if (!diff_between_properties) {
            setDiffBetweenPropertiesError("Please select Differentiate Between Properties")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setDiffBetweenPropertiesError("")
            setAnyError("")
        }
        if (propertyType != 8 && !building_type) {
            setBuildingTypeError("Please select Building Type")
            setAnyError("See error message above")
            setLoading(false);
            return false;
        } else {
            setBuildingTypeError("")
            setAnyError("")

        }
        const formData = new FormData();
        formData.append("listing_type", listing_type);
        formData.append("property_name", propertyName);
        if (propertyPrice) {
            formData.append("property_price", propertyPrice);
        } else {
            formData.append("property_price", 0);
        }

        formData.append("property_address", propertyAddress);
        formData.append("description", propertyDescription);
        for (var i = 0; i < masterAmenityData.length; i++) {
            formData.append("amenities[" + [i] + "][id]", masterAmenityData[i].id);
            formData.append("amenities[" + [i] + "][data]", masterAmenityData[i].data);
        }
        formData.append("property_type", propertyType);
        formData.append("street_address", propertyStreetAddress);
        formData.append("lattitude", lattitude);
        formData.append("longitude", longitude);
        formData.append("occupation", occupation);
        formData.append("floor_size", floorSize);
        formData.append("is_display_poa", poi);
        formData.append("security_estate", securityEstate);
        formData.append("repossessed", repossessed);
        formData.append("auction", auction);
        formData.append("on_show", onShow);
        formData.append("is_display_address", isDisplayAddress);
        formData.append("retirement", retirement);
        for (var i = 0; i < masterExternalFeaturesData.length; i++) {
            formData.append("external_features[" + [i] + "][id]", masterExternalFeaturesData[i].id);
            formData.append("external_features[" + [i] + "][data]", masterExternalFeaturesData[i].data);
        }
        for (var i = 0; i < reasonData.length; i++) {
            formData.append("reasons[" + [i] + "]", reasonData[i]);
        }
        if (propertyImage) {
            for (var i = 0; i < propertyImage.length; i++) {
                // if (propertyImagePriview === "upload") {
                formData.append("images[]", propertyImage[i]);
                // }
            }
        }
        formData.append("diff_between_properties", diff_between_properties);
        if (propertyType != 8) {
            formData.append("building_type", building_type);
        }

        UpdateProperty(formData, Token, slug).then((res) => {
            if (res.status === 200) {
                swal("Success", res.data.message, "success");
                history.push('/property');
                setLoading(false);
            } else {
                swal("error", res.data.message, "error");
                setLoading(false);
            }
        }).catch((err) => {
            if (e && e.response && e.response.data &&
                e.response.data.message) {
                swal("Error!", e.response.data.message, "error");
            }
            // swal("Error!", "Something went wrong!", "error");
            setLoading(false);
        });


    };

    const back = () => {
        history.push('/property');
    };

    const handleDeleteImage = (id) => {
        swal({
            text: `Are you sure want to Delete this image ?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const token = localStorage.getItem("token");
                let data = {
                    id: id,
                    property_slug: slug,
                }
                PropertyImageDelete(data, token).then((res) => {
                    console.log(res);
                    if (res.data.type === "success") {
                        getPropertyData();
                        swal("Success", "Image Deleted succesfully.", "success");

                    }
                });

            }

        });
    };


    const handleStatus = (status) => {
        if (status == "Sold") {
            $('#staticBackdrop').modal('show');
        }
        if (status == "Rented") {
            $('#staticBackdrop').modal('show');
        }
        setDiffBetweenProperties(status)
    };

    const handleReason = (e, reason) => {
        if (e.target.checked) {
            setReason((prevDataArray) => [...prevDataArray, reason]);
        } else {
            if (reasonData.filter((item) => item === reason).length > 0) {
                setReason(reasonData.filter((item) => item !== reason));
            }

        }

    };

    const handlesaveReason = () => {
        if (reasonData.length > 0) {
            $('#staticBackdrop').modal('hide');
        } else {
            setReasonError("Please select the reasen first");
        }

    };

    const handleclosedModle = () => {
        setReason([]);
        $('#reason-form').reset();
    };

    return (
        <>
            <Navbar />
            {pageLoading ?
                <div className="main py-5">
                    <center>
                        <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                        </span>
                    </center>
                </div>
                :
                <div class="main py-5">
                    <div class="container ">

                        <div class="row pb-md-5">

                            <AgencyNavbar />

                            <div class="col-md-9 ps-md-4 border-start admin-right-part">

                                <div class="row">
                                    <form onSubmit={handleSubmit} class="add-property site-form">
                                        <div class="col-lg-8">
                                            <h2 class="text-primary semibold h1 pb-3">Edit Property</h2>


                                            <div class="d-flex align-items-center w-100 prop-sel-option pb-4">
                                                {listing_type === 'sell' ?
                                                    <div class="d-flex align-items-center col-4">
                                                        <label for="sell" class="d-flex align-items-center pb-0">
                                                            <img src="assets/image/sell.png" alt="" class="pe-3" /> For Sale
                                                        </label>

                                                        <input type="radio"
                                                            name="sell-rent"
                                                            id="sell"
                                                            checked={listing_type === 'sell' ? true : false}
                                                            onClick={() => handlePropertyType("sell")} />
                                                    </div>
                                                    : ""}
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {listing_typeError}
                                                </p>

                                                {listing_type === 'rent' ?
                                                    <div class="d-flex align-items-center col-4">
                                                        <label for="rent" class="d-flex align-items-center pb-0">
                                                            <img src="assets/image/rent.png" alt="" class="pe-3" /> For Rent
                                                        </label>

                                                        <input type="radio"
                                                            name="sell-rent"
                                                            id="rent"
                                                            checked={listing_type === 'rent' ? true : false}
                                                            onClick={() => handlePropertyType("rent")} />

                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>


                                            <div class="row">
                                                <div className="form-field col-md-6 offset-6">

                                                    <p className="d-flex justify-content-between ">
                                                        Price on Application (P.O.A)
                                                        <span className="d-flex align-items-center">
                                                            <span className="d-flex align-items-center pe-4">
                                                                {poi === 1 ?
                                                                    <input type="checkbox"
                                                                        checked={poi === 1 ? true : false}
                                                                        onClick={() => handlePOI(0)}
                                                                    />
                                                                    :
                                                                    <input type="checkbox"
                                                                        checked={poi === 1 ? true : false}
                                                                        onClick={() => handlePOI(1)}
                                                                    />
                                                                }


                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="form-field col-md-6">
                                                    <label class="d-block medium fs-15">Property Title</label>
                                                    <input
                                                        type="text"
                                                        class="w-100"
                                                        placeholder="Property Title"
                                                        value={propertyName}
                                                        onChange={(e) => { setPropertyName(e.target.value) }}

                                                    />
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {propertyNameError}
                                                    </p>
                                                </div>
                                                {poi === 0 ?
                                                    <div class="form-field col-md-6">

                                                        <label class="d-block medium fs-15">Property Price</label>
                                                        <span className="d-flex">

                                                            <CurrencyFormat className="w-100" placeholder="Property Price" value={propertyPrice} thousandSeparator={true} prefix={'R '} onValueChange={(values) => {
                                                                const { formattedValue, value } = values;

                                                                setPropertyPrice(value)
                                                            }} />
                                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                                {propertyPriceError}
                                                            </p>
                                                        </span>
                                                    </div>
                                                    : ""}


                                                <h3 class="semibold h3 pb-4">Property Overview</h3>

                                                <div class="row">
                                                    <div class="form-field col-md-6">
                                                        <label class="d-block medium fs-15">Listing Number</label>
                                                        <input type="text" class="w-100" value={listing_no} placeholder="" disabled />
                                                    </div>

                                                    <div class="form-field col-md-6">
                                                        <label class="d-block medium fs-15">Type of Property</label>
                                                        <select
                                                            name=""
                                                            id=""
                                                            onChange={(e) => setPropertyType(e.target.value)}

                                                        >
                                                            <option value="">Select...</option>
                                                            {masterTypeOfProperty.map((item, i) => {
                                                                return (
                                                                    <option value={item.id} selected={propertyType === item.id ? true : false}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {propertyTypeError}
                                                        </p>
                                                    </div>




                                                    {listing_type === 'rent' ?
                                                        <div class="form-field col-md-6">
                                                            <label class="d-block medium fs-15">Occupation Date</label>
                                                            <input type="date"
                                                                class="w-100"
                                                                placeholder="Occupation"
                                                                value={occupation}
                                                                onChange={(e) => { setOccupation(e.target.value) }}

                                                            />
                                                        </div>
                                                        : ""}


                                                    <div class="form-field col-md-6">
                                                        <label class="d-block medium fs-15">Erf Size</label>
                                                        <input type="text"
                                                            class="w-100"
                                                            placeholder="Erf Size"
                                                            value={floorSize}
                                                            onChange={(e) => { setFloorSize(e.target.value) }}

                                                        />
                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {erfSizeError}
                                                        </p>
                                                    </div>



                                                </div>

                                                <div class="form-field col-md-12">
                                                    <label class="d-block medium fs-15">Property Address</label>

                                                    <input type="text"
                                                        ref={inputRef}
                                                        value={propertyStreetAddress}
                                                        onChange={(e) => setPropertyStreetAddress(e.target.value)}
                                                        placeholder="Please Enter Location"
                                                    />
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {propertyAdressError}
                                                    </p>

                                                </div>

                                                <div className="form-field col-md-12">

                                                    <p className="d-flex justify-content-between ">
                                                        Click the checkbox if you want the address visible on the frontend
                                                        <span className="d-flex align-items-center">
                                                            <span className="d-flex align-items-center pe-4">
                                                                {isDisplayAddress === 0 ?
                                                                    <input type="checkbox"
                                                                        checked={isDisplayAddress === 1 ? true : false}
                                                                        onClick={() => setIsDisplayAddress(1)}
                                                                    />
                                                                    :
                                                                    <input type="checkbox"
                                                                        checked={isDisplayAddress === 1 ? true : false}
                                                                        onClick={() => setIsDisplayAddress(0)}
                                                                    />

                                                                }


                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>




                                                <div class="form-field col-md-12">
                                                    <label class="d-block medium fs-15 pb-2">Property Images (We recommend a minimum of 5 pictures and 100 MB max)</label>
                                                    <div>
                                                        <input type="file"
                                                            id="prop-img"
                                                            class="d-none"
                                                            multiple
                                                            accept="image/*"
                                                            onChange={(e) => handleMutipleImage(e)}

                                                        />
                                                        <label for="prop-img" class="property-img">
                                                            <i class="far fa-image"></i>
                                                        </label>
                                                    </div>
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {propertyImageError}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {propertyImage.length > 0 ?
                                            <div className="row">
                                                <label className="d-block medium fs-15">Selected Images</label>
                                                {propertyImage.map((item, i) => {

                                                    return (
                                                        <div className="col-md-3 d-flex p-2">


                                                            <img src={URL.createObjectURL(item)} style={{ width: "350px", height: "200px" }} />



                                                            <Link style={{ color: 'red', marginTop: "-12px", fontWeight: 500, }} onClick={() => handleDeletePreImage(i)}>
                                                                <small className="" style={{ color: 'red', marginTop: "0px" }}>X</small>
                                                            </Link>


                                                        </div>

                                                    );
                                                })}
                                            </div>
                                            :
                                            ""
                                        }

                                        {propertyImagecount.length > 0 ?
                                            <div className="row">
                                                <label className="d-block medium fs-15">Uploaded Images</label>
                                                {propertyImagecount.map((item, i) => {

                                                    return (
                                                        <div className="col-md-3 d-flex p-2">

                                                            <img src={item.image} style={{ width: "350px", height: "200px" }} />
                                                            <Link style={{ color: 'red', marginTop: "-12px", fontWeight: 500, }} onClick={() => handleDeleteImage(item.id)}>
                                                                <small className="" style={{ color: 'red', marginTop: "0px" }}>X</small>
                                                            </Link>


                                                        </div>

                                                    );
                                                })}
                                            </div>
                                            :
                                            ""
                                        }
                                        <div className="col-lg-8">
                                            <div className="row">
                                                <div class="form-field col-md-12">
                                                    <label class="d-block medium fs-15">Description</label>
                                                    {/* <textarea name=""
                                                        id=""
                                                        cols="30"
                                                        rows="10"
                                                        className="form-control autoresizing"
                                                        value={propertyDescription}
                                                        onChange={(e) => { handleChangeDescription(e) }}

                                                    ></textarea> */}
                                                    <CKEditor
                                                        data={propertyDescription}
                                                        className="editor"
                                                        onChange={(event, editor) => {
                                                            handleChangeDescription(editor.getData())
                                                        }}
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                                        }}
                                                    />
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {descriptionError}
                                                    </p>
                                                </div>


                                            </div>

                                            <h4 class="semibold pb-4">Amenities</h4>

                                            <div class="select-amenity pb-5">

                                                {masterAmenity.map((item, i) => {
                                                    return (
                                                        <p>{item.name}
                                                            <span>
                                                                <button class="minus" type="button" value="-" onClick={() => handleData(item, true)}>-</button>
                                                                <input type="text" name={item.name} value={masterAmenityData[i]?.data || 0} class="input-text qty" />
                                                                <button class="plus" type="button" value="+" onClick={() => handleData(item, false)}>+</button>
                                                            </span>
                                                        </p>
                                                    );
                                                })}

                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {amenityError}
                                                </p>

                                            </div>



                                            <h3 class="semibold h3 pb-3">External Features</h3>

                                            <div class="extra-features pb-5">

                                                {masterExternalFeatures.map((item, i) => {
                                                    return (

                                                        <p class="d-flex justify-content-between ">{item.name}
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio" name={item.name} id="swimming-yes" checked={masterExternalFeaturesData[i]?.data === '1' ? true : false} onClick={() => handleExternalFeatures(item, true)} />
                                                                    <label for="swimming-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio" name={item.name} id="swimming-no" checked={masterExternalFeaturesData[i]?.data === '0' ? true : false} onClick={() => handleExternalFeatures(item, false)} />
                                                                    <label for="swimming-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                    );
                                                })}
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {ExternalFeaturesError}
                                                </p>

                                            </div>

                                            {listing_type === 'sell' ?
                                                <>
                                                    <h3 className="semibold h3 pb-3">Others</h3>

                                                    <div class="extra-features pb-5">
                                                        <p class="d-flex justify-content-between ">Retirement
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        name="Retirement"
                                                                        id="Retirement-yes"
                                                                        checked={retirement === 1 ? true : false}
                                                                        value={1}
                                                                        onChange={(e) => { setRetirement(1) }}
                                                                    />
                                                                    <label for="Retirement-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={0}
                                                                        checked={retirement === 0 ? true : false}
                                                                        onChange={(e) => { setRetirement(0) }}
                                                                        name="Retirement" id="Retirement-no" />
                                                                    <label for="Retirement-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p class="d-flex justify-content-between ">On Show
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        value={1}
                                                                        checked={onShow === 1 ? true : false}
                                                                        onChange={(e) => { setOnShow(1) }}
                                                                        name="Show" id="Show-yes" />
                                                                    <label for="Show-yes">Yes</label>
                                                                </span>
                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={0}
                                                                        checked={onShow === 0 ? true : false}
                                                                        onChange={(e) => { setOnShow(0) }}
                                                                        name="Show" id="Show-no" />
                                                                    <label for="Show-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>


                                                        <p class="d-flex justify-content-between ">Auction
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        value={1}
                                                                        checked={auction === 1 ? true : false}
                                                                        onChange={(e) => { setAuction(1) }}
                                                                        name="Auction" id="Auction-yes" />
                                                                    <label for="Auction-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        value={0}
                                                                        checked={auction === 0 ? true : false}
                                                                        onChange={(e) => { setAuction(0) }}
                                                                        name="Auction" id="Auction-no" />
                                                                    <label for="Auction-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p class="d-flex justify-content-between ">Repossessed
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        value={1}
                                                                        checked={repossessed === 1 ? true : false}
                                                                        onChange={(e) => { setRepossessed(1) }}
                                                                        name="Repossessed" id="Repossessed-yes" />
                                                                    <label for="Repossessed-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        checked={repossessed === 0 ? true : false}
                                                                        onChange={(e) => { setRepossessed(0) }}
                                                                        name="Repossessed" id="Repossessed-no" />
                                                                    <label for="Repossessed-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p class="d-flex justify-content-between ">Security Estate
                                                            <span class="d-flex align-items-center">
                                                                <span class="d-flex align-items-center pe-4">
                                                                    <input type="radio"
                                                                        checked={securityEstate === 1 ? true : false}
                                                                        onChange={(e) => { setSecurityEstate(1) }}
                                                                        name="SecurityEstate" id="SecurityEstate-yes" />
                                                                    <label for="SecurityEstate-yes">Yes</label>
                                                                </span>

                                                                <span class="d-flex align-items-center">
                                                                    <input type="radio"
                                                                        checked={securityEstate === 0 ? true : false}
                                                                        onChange={(e) => { setSecurityEstate(0) }}
                                                                        name="SecurityEstate" id="SecurityEstate-no" />
                                                                    <label for="SecurityEstate-no">No</label>
                                                                </span>
                                                            </span>
                                                        </p>

                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {otherError}
                                                        </p>

                                                    </div>
                                                </>
                                                : ""}


                                            <div class="row">
                                                <div class="form-field col-md-6">
                                                    <label class="d-block medium fs-15"> Property status</label>
                                                    <select name="" id="" onChange={(e) =>
                                                        handleStatus(e.target.value)
                                                    }
                                                    >
                                                        <option value="">Select...</option>
                                                        <option selected={diff_between_properties === 'Under Offer' ? true : false} value="Under Offer">Under Offer</option>
                                                        <option selected={diff_between_properties === 'Price Reduced' ? true : false} value="Price Reduced">Price Reduced</option>
                                                        {listing_type === 'sell' ?
                                                            <option selected={diff_between_properties === 'Sold' ? true : false} value="Sold">Property Sold</option>
                                                            : ""}
                                                        <option selected={diff_between_properties === 'Newly Listed' ? true : false} value="Newly Listed">Newly Listed</option>
                                                        {listing_type === 'rent' ?
                                                            <option selected={diff_between_properties === 'Rented' ? true : false} value="Rented">Rented</option>
                                                            : ""}
                                                    </select>
                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                        {diff_between_propertiesError}
                                                    </p>
                                                </div>
                                                {propertyType == 8 ? "" :
                                                    <div class="form-field col-md-6">
                                                        <label class="d-block medium fs-15">Building Type</label>
                                                        <select name="" id="" onChange={(e) => setBuildingType(e.target.value)}>
                                                            <option value="">Select...</option>
                                                            {masterBuildingType.map((item, i) => {
                                                                return (
                                                                    <option value={item.id} selected={building_type === item.id ? true : false}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <p style={{ color: "red", margintop: "-10px" }}>
                                                            {building_typeError}
                                                        </p>
                                                    </div>
                                                }
                                            </div>


                                            <div class="add-prop-btn pt-4">
                                                {loading ?
                                                    <button type="submit" className="btn-sm" disabled={loading ? true : false}>
                                                        <span className='spinner-grow spinner-grow-sm '>

                                                        </span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="custom-btn" disabled={loading ? true : false}>Submit</button>
                                                }
                                                <button onClick={back} class="back-btn">Back</button>
                                            </div>
                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                {anyError}
                                            </p>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>

            }
            <Footer />

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Reason</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                handleclosedModle()
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <form id="reason-form">
                                <div className="border-1">
                                    {reasonError ?
                                        <span className="text-danger">{reasonError}</span>
                                        : ""}
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <h5>Was {diff_between_properties === 'Sold' ? "Sold" : "rented"} on Ask Property</h5>
                                        </div>
                                        <div className="">
                                            {diff_between_properties === 'Sold' ?
                                                <input type="checkbox" onChange={(e) => {
                                                    handleReason(e, "Was Sold on Ask Property")
                                                }}
                                                    checked={reasonData.filter((item) => item === "Was Sold on Ask Property").length > 0 ? true : false}
                                                />
                                                :
                                                <input type="checkbox" onChange={(e) => {
                                                    handleReason(e, "Was rented on Ask Property")
                                                }}
                                                    checked={reasonData.filter((item) => item === "Was rented on Ask Property").length > 0 ? true : false}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <h5>Was {diff_between_properties === 'Sold' ? "Sold" : "rented"} elsewhere</h5>
                                        </div>
                                        <div>
                                            {diff_between_properties === 'Sold' ?
                                                <input type="checkbox" onChange={(e) => {
                                                    handleReason(e, "Was Sold elsewhere")
                                                }}
                                                    checked={reasonData.filter((item) => item === "Was Sold elsewhere").length > 0 ? true : false}
                                                />
                                                :
                                                <input type="checkbox" onChange={(e) => {
                                                    handleReason(e, "Was rented elsewhere")
                                                }}
                                                    checked={reasonData.filter((item) => item === "Was rented elsewhere").length > 0 ? true : false}
                                                />
                                            }

                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <h5>i'd rather not answer</h5>
                                        </div>
                                        <div>
                                            <input type="checkbox" onChange={(e) => {
                                                handleReason(e, "i'd rather not answer")
                                            }}
                                                checked={reasonData.filter((item) => item === "i'd rather not answer").length > 0 ? true : false}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => {
                                handleclosedModle()
                            }} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={() => {
                                handlesaveReason()
                            }} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
