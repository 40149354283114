import axios from "axios";
import { dashboardURLs } from '../constants/url';


  export const GetAgencyDashboard = (token) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${dashboardURLs.AgencyDashboardUrl}`,
    method: "Post",
});