import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, THead, TH, TR, TD, TBody } from "../../table/index";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { GetAllProperty, PropertyDelete, RenewProperty } from '../../API/PropertyApi'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import Moment from 'moment';
import { GetProfile } from "../../API/AuthApi";
const $ = window.jQuery;


export class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allPropertyList: [],
            reasonData: [],
            reasonError: '',
            propertyType: 'sold',
            deletePropertyName: '',
            deletePropertySlug: '',
            role: localStorage.getItem("role"),
            image: "",
            addPropertyStatus: true,
            loading: false,
        };
    }


    componentDidMount() {
        // $("#datatable").dataTable();
        this.setState({
            loading: true,
        });
        this.getPropertyList();

    }
    getUserProfile = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        let data = {
            user_id: user.id
        }
        GetProfile(data).then((res) => {
            // console.log(res?.data?.data?.phone);
            if (res?.data?.status === "success") {
                let addPropertyStatus = true;
                if (res?.data?.data?.phone === null) {
                    swal("Update contact number", "Please add your mobile number from your profile page before creating a listing!", "info");

                    addPropertyStatus = false;
                }
                if (res?.data?.data?.role == 2 || res?.data?.data?.role == 5) {
                    if (res?.data?.data?.roleWiseProfileData?.user?.max_property_list_rent === 0
                        && res?.data?.data?.roleWiseProfileData?.user?.max_property_list_sale === 0) {
                        swal("Insufficient Balance", "Go to pricing tab and purchase the plan in order to proceed", "info");
                        addPropertyStatus = false;
                    }
                } else {
                    if (res?.data?.data?.roleWiseProfileData?.agency_profile?.user?.max_property_list_rent === 0
                        && res?.data?.data?.roleWiseProfileData?.agency_profile?.user?.max_property_list_sale === 0) {
                        swal("Insufficient Balance!", "Agency/Admin has not purchased any pricing please contact Agency for more info!", "info");
                        addPropertyStatus = false;
                    }
                }

                if (addPropertyStatus === true) {
                    this.props.history.push("/add-property");
                }

            }
        });
    };

    getPropertyList = () => {
        const token = localStorage.getItem("token");
        GetAllProperty(token)
            .then((resp) => {
                this.setState({
                    allPropertyList: resp.data.data,
                    loading: false,
                });
                $("#datatable").dataTable();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    onEditProperty = (slug) => {
        this.props.history.push("edit-property", { data: slug });
    };

    onViewProperty = (slug) => {
        this.props.history.push("view-property/" + slug, { data: slug });
    };


    onDeleteApprover = (slug, name) => {
        swal({
            text: `Are you sure you want to delete ${name}!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const token = localStorage.getItem("token");
                let data = {
                    reasons: this.state.reasonData
                }
                PropertyDelete(slug, token, data).then((res) => {
                    if (res.data.type === "success") {
                        this.setState({
                            reasonData: []
                        });
                        this.getPropertyList();

                        swal("Success", res.data.message, "success");

                    } else {
                        swal("Error", res.data.message, "error");
                    }
                });

            }

        });

    };
    onRenewProperty = (slug, name) => {
        swal({
            text: `Are you sure you want to renew this ${name} property!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const token = localStorage.getItem("token");

                RenewProperty(slug, token, slug).then((res) => {
                    if (res.data.type === "success") {
                        this.getPropertyList();
                        swal("Success", res.data.message, "success");

                    } else {
                        swal("Error", res.data.message, "error");
                    }
                });

            }

        });

    };

    getCurrentDate = (separator = '-') => {

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : date}`
    }

    getHandleReason = (e, reason) => {
        if (e.target.checked) {
            this.setState((prevState) => ({
                reasonData: [...prevState.reasonData, reason],
            }));
        } else {
            if (this.state.reasonData.filter((item) => item === reason).length > 0) {
                this.setState({
                    reasonData: this.state.reasonData.filter((item) => item !== reason)
                });

            }

        }

    };

    handleDeleteReason = (slug, name, listing_type) => {
        this.setState({
            propertyType: listing_type === "sell" ? 'sold' : 'rented',
            deletePropertyName: name,
            deletePropertySlug: slug,
        });
        $('#staticBackdrop').modal('show');
    };
    handlesaveReason = () => {
        if (this.state.reasonData.length > 0) {
            this.setState({
                reasonError: ""
            });
            this.onDeleteApprover(this.state.deletePropertySlug, this.state.deletePropertyName);
            $('#staticBackdrop').modal('hide');
        } else {
            this.setState({
                reasonError: "Please select the reasen first"
            });
        }
    };

    handleCloseModel = () => {
        this.setState({
            reasonData: []
        });
    };

    render() {
        return (
            <>

                <Navbar />

                {this.state.loading ?
                    <div className="main py-5">
                        <center>
                            <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                            </span>
                        </center>
                    </div>
                    :
                    <div className="main py-5">
                        <div className="container ">

                            <div className="row pb-md-5">

                                <AgencyNavbar />

                                <div className="col-md-9 ps-md-5 border-start admin-right-part">

                                    <div className="d-flex align-items-center justify-content-between pb-4">
                                        <h2 className="text-primary semibold h1 pb-3"> Properties</h2>
                                        {this.state.role == 3 || this.state.role == 5 || this.state.role == 2 || this.state.role == 6 ?
                                            <Link onClick={() => this.getUserProfile()} className="custom-btn">Add New Properties</Link>
                                            :
                                            ""
                                        }
                                    </div>

                                    <div className="datatable">
                                        <Table className="w-100" id="datatable">
                                            <THead>
                                                <TR>
                                                    <TH>S.No.</TH>
                                                    <TH>Image</TH>
                                                    <TH>Property Name</TH>
                                                    <TH>Agent</TH>
                                                    {this.state.role !== 4 ?

                                                        <TH>View Count</TH>
                                                        : ""}

                                                    <TH>Listing type</TH>


                                                    <TH>status</TH>
                                                    <TH>Updated date</TH>
                                                    <TH style={{ width: "100px" }}>Action</TH>

                                                </TR>
                                            </THead>
                                            <TBody>
                                                {this.state?.allPropertyList.map((item, index) => {
                                                    return (
                                                        <TR key={item.id}>
                                                            <TD className="h5 semibold"><h5 className="h5 semibold" />{index + 1}</TD>
                                                            <TD>
                                                                {item?.property_images.length === 0 ?
                                                                    ""
                                                                    :
                                                                    <img src={item?.property_images[0]?.user_image} alt="" />
                                                                }


                                                            </TD>
                                                            <TD className="h5 semibold"><h5 className="h5 semibold">{item.property_name} </h5></TD>
                                                            <TD><h6 className="fs-15 text-primary">{item.user.name} {item.user.surname}</h6></TD>
                                                            {this.state.role !== 4 ?
                                                                <TD><h6 className="fs-15 text-primary">{item.view_count}</h6></TD>
                                                                : ""}


                                                            <TD><h6 className="fs-15 text-primary">{item.listing_type}</h6></TD>

                                                            <TD className="table-action semibold">
                                                                {Moment(item.expired_date).format("YYYY-MM-DD") >= this.getCurrentDate() ?
                                                                    item.is_superadmin_approve == 0 ?
                                                                        <h5 className="h5 status-pending  semibold">In Review</h5>
                                                                        :
                                                                        <h5 className="h5 status-approve semibold">Active</h5>

                                                                    :
                                                                    <>
                                                                        <h5 className="h5 status-reject semibold">Expired</h5>
                                                                        {this.state.role == 3 || this.state.role == 5 || this.state.role == 2 || this.state.role == 6 ?
                                                                            <Link onClick={() => this.onRenewProperty(item.slug, item.property_name)} className="px-2 text-success"> Renew </Link>
                                                                            : ""}
                                                                    </>
                                                                }
                                                                {/* {item.property_status == "Pending" ?
                                                                    <h5 className="h5 status-pending semibold" style={{color:"red"}}>{item.property_status}</h5>
                                                                    :
                                                                    item.property_status == "Rejected" ?
                                                                        <h5 className="h5 status-reject semibold">{item.property_status}</h5>
                                                                    :
                                                                    item.property_status == "Approved" ?
                                                                    item.is_superadmin_approve == 1 ?
                                                                    <h5 className="h5 status-approve semibold">{item.property_status}</h5>
                                                                    :
                                                                    <h5 className="h5 status-pending semibold">Under review by ask p.</h5>

                                                                    :
                                                                    <h5 className="h5 status-approve semibold">{item.property_status}</h5>
                                                                } */}



                                                            </TD>
                                                            <TD><h6 className="fs-15 text-primary"> {Moment(item.updated_at).format("DD-MM-YYYY")}</h6></TD>
                                                            <TD className="table-action">
                                                                {this.state.role == 3 || this.state.role == 5 || this.state.role == 2 || this.state.role == 6 ?
                                                                    <Link onClick={() => this.onEditProperty(item.slug)} className="px-2"><i className="fa fa-edit"></i></Link>
                                                                    : ""}
                                                                <Link onClick={() => this.onViewProperty(item.slug)} className="px-2"><i className="fa fa-eye"></i></Link>
                                                                {this.state.role == 3 || this.state.role == 5 || this.state.role == 2 || this.state.role == 6 ?
                                                                    <Link onClick={() => this.handleDeleteReason(item.slug, item.property_name, item.listing_type)} className="px-2"><i className="fa fa-trash"></i></Link>
                                                                    : ""}

                                                            </TD>

                                                        </TR>
                                                    );
                                                })}
                                            </TBody>
                                        </Table>

                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                }
                <Footer />
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Reason</h5>
                                <button type="button"
                                    onClick={() => {
                                        this.handleCloseModel()
                                    }}
                                    className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="border-1">
                                    {this.state.reasonError ?
                                        <span className="text-danger ">{this.state.reasonError}</span>
                                        : ""}
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <h5>Was {this.state.propertyType} on Ask Property ?</h5>
                                        </div>
                                        <div className="">
                                            {this.state.propertyType === 'sold' ?
                                                <input type="checkbox" onChange={(e) => {
                                                    this.getHandleReason(e, "Was sold on Ask Property")
                                                }}
                                                    checked={this.state.reasonData.filter((item) => item === "Was sold on Ask Property").length > 0 ? true : false}
                                                />
                                                :
                                                <input type="checkbox" onChange={(e) => {
                                                    this.getHandleReason(e, "Was rented on Ask Property")
                                                }}
                                                    checked={this.state.reasonData.filter((item) => item === "Was rented on Ask Property").length > 0 ? true : false}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <h5>Was {this.state.propertyType} elsewhere</h5>
                                        </div>
                                        <div>
                                            {this.state.propertyType === 'sold' ?
                                                <input type="checkbox" onChange={(e) => {
                                                    this.getHandleReason(e, "Was Sold elsewhere")
                                                }}
                                                    checked={this.state.reasonData.filter((item) => item === "Was Sold elsewhere").length > 0 ? true : false}
                                                />
                                                :
                                                <input type="checkbox" onChange={(e) => {
                                                    this.getHandleReason(e, "Was rented elsewhere")
                                                }}
                                                    checked={this.state.reasonData.filter((item) => item === "Was rented elsewhere").length > 0 ? true : false}
                                                />
                                            }

                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <h5>No, Haven't {this.state.propertyType} this property</h5>
                                        </div>
                                        <div>
                                            {this.state.propertyType === 'sold' ?
                                                <input type="checkbox" onChange={(e) => {
                                                    this.getHandleReason(e, "No, haven't Sold this property")
                                                }}
                                                    checked={this.state.reasonData.filter((item) => item === "No, haven't Sold this property").length > 0 ? true : false}
                                                />
                                                :
                                                <input type="checkbox" onChange={(e) => {
                                                    this.getHandleReason(e, "No, haven't rented this property")
                                                }}
                                                    checked={this.state.reasonData.filter((item) => item === "No, haven't rented this property").length > 0 ? true : false}
                                                />
                                            }

                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <h5>i'd rather not answer</h5>
                                        </div>
                                        <div>
                                            <input type="checkbox" onChange={(e) => {
                                                this.getHandleReason(e, "i'd rather not answer")
                                            }}
                                                checked={this.state.reasonData.filter((item) => item === "i'd rather not answer").length > 0 ? true : false}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    onClick={() => {
                                        this.handleCloseModel()
                                    }}
                                    className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" onClick={() => {
                                    this.handlesaveReason()
                                }} className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(Index);