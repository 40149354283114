import axios from "axios";
import { FollowURLs } from '../constants/url';


  export const StartFollowing = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${FollowURLs.StartFollow}`,
    method: "Post",
    data:body,
});

export const GetFollowing = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${FollowURLs.getFollow}`,
    method: "Post",
});

export const RemoveFollowing = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${FollowURLs.removeFollow}`,
  method: "Post",
  data:body,
});

export const FollowBackUser = (token, body) => {
  return axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${FollowURLs.followBackUser}`,
    method: "Post",
    data:body,
  })
}

export const UnfollowUser = (token, body) => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${FollowURLs.unFollowUser}`,
    method: "Post",
    data:body,
  })
}