import React from "react";

const DisplayPropertyCard = ({ property, onPropertyDetail }) => {
  const otherItems = [
    { label: "Retirement", value: property.retirement },
    { label: "On Show", value: property.on_show },
    { label: "Auction", value: property.auction },
    { label: "Repossessed", value: property.repossessed },
    { label: "Security Estate", value: property.security_estate },
  ];

  const formatPrice = (price) => {
    return new Intl.NumberFormat("Af-ZA", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  return (
    <div
      className="d-md-flex w-100 mb-4 bg-white p-2 border cursor-pointer"
      style={{ borderRadius: "8px" }}
    >
      <div className="text-center py-md-0 py-3 listing-thumb col-md-4">
        {property?.diff_between_properties &&
          property?.diff_between_properties !== "undefined" && (
            <span>{property?.diff_between_properties}</span>
          )}

        {property?.property_images.length > 0 ? (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="other-items"
              onClick={() => onPropertyDetail(property.slug)}
            >
              <div
                className="d-none flex-wrap-reverse justify-content-between"
                style={{ position: "absolute", bottom: 20 }}
              >
                {otherItems
                  .filter((item) => item.value)
                  .map((item, index) => (
                    <div
                      className="px-2 my-1 text-light fs-12 text-uppercase bg-dark"
                      key={index}
                    >
                      {item.label}
                    </div>
                  ))}
              </div>
            </div>
            <img
              src={property?.property_images[0]?.user_image}
              // src="../../assets/image/listing-img3.png"
              className=""
              style={{ width: "200px", height: "150px", borderRadius: "15px" }}
              alt=""
              onClick={() => onPropertyDetail(property.slug)}
            />
          </div>
        ) : (
          <img
            src="../../assets/image/listing-img3.png"
            // className="left-image"
            // style={{ width: "200px", height: "200px" }}
            alt=""
            onClick={() => onPropertyDetail(property.slug)}
          />
        )}
      </div>
      <div className="p-4 px-3 py-1 col-md-12">
        <h5
          className="regular mb-0"
          onClick={() => onPropertyDetail(property.slug)}
        >
          {property?.property_name?.slice(0, 24)}
          {property?.property_name?.length > 24 && "..."}
        </h5>
        {property?.is_display_poa ? (
          <h5 className="text-primary py-1 semibold m-0">
            Price on Application (P.O.A)
          </h5>
        ) : (
          <h5 className="text-primary py-1 semibold m-0">
            {formatPrice(property?.property_price)}
          </h5>
        )}
        {property?.is_display_address ? (
          <div className="d-flex regular pb-1 w-100 fs-14">
            <i className="mt-1 fa fa-map-marker-alt pe-2 text-parrot"></i>{" "}
            <div className="col-8 text-breakword">
              {property?.street_address?.slice(0, 60)}
              {property?.street_address?.length > 60 && "..."}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="d-flex py-1">
          {property?.get_selected_amenities.map(
            (amenity, index) =>
              amenity?.master_property?.name === "Number of Bedrooms" && (
                <div className="d-flex align-items-center pe-3" key={index}>
                  <img
                    style={{ height: 22, width: 22 }}
                    src="../../assets/image/listing-icon-1.png"
                    alt=""
                  />
                  <h4 className="bold fs-15 px-2">{amenity?.data}</h4>
                </div>
              )
          )}
          {property?.get_selected_amenities.map(
            (amenity, index) =>
              amenity?.master_property?.name === "Number of Bathrooms" && (
                <div className="d-flex align-items-center pe-3" key={index}>
                  <img
                    style={{ height: 22, width: 22 }}
                    src="../../assets/image/listing-icon-2.png"
                    alt=""
                  />
                  <h4 className="bold fs-15 px-2">{amenity?.data}</h4>
                </div>
              )
          )}
          <div className="d-flex align-items-center pe-3">
            <img
              style={{ height: 22, width: 22 }}
              src="../../assets/image/listing-icon-3.png"
              alt=""
            />
            <div className="d-flex">
              <h4 className="bold fs-15 px-2">
                {property?.floor_size}m<sup>2</sup>
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "block",
            width: "100%",
            boxSizing: "border-box",
          }}
          className="pe-5"
        >
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              maxWidth: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            className="mb-0 fs-14"
          >
            {property?.get_selected_external_features
              .filter((feature) => feature.data !== "0")
              .map((feature, index) => feature.master_property.name)
              .join(", ")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayPropertyCard;
