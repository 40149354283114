import React, { useState, useEffect } from 'react'
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar'
import AgencyNavbar from '../layouts/AgencyNavbar'
import { GetAgencyDashboard } from "../API/DashboardApi";
import { Link } from 'react-router-dom';
import { GetProfile } from "../API/AuthApi";

export default function AgencyDashboard() {
    const [data, setData] = useState("")
    const [pageLoading, setPageLoading] = useState("")
    const [profileStatus, setProfileStatus] = useState("")
    const Token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const user = JSON.parse(localStorage.getItem("user"));
    console.log('user_role',role);
    useEffect(
        function () {
            let mounted = true;
            setPageLoading(true);
            GetAgencyDashboard(Token).then((res) => {
                if (mounted) {
                    setData(res.data.data);
                    setPageLoading(false);
                }
            });
            let data = {
                user_id: user.id
            }
            GetProfile(data).then((res) => {
                if (res?.data?.status === "success") {
                    
                    setProfileStatus(res?.data?.data?.roleWiseProfileData?.is_approved);
                    
                }
            });
            return () => {
                mounted = false;
            };
        },
        []
    );
    return (
        <>
            <Navbar />
            {pageLoading ?
                <div className="main">
                    <center>
                        <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

                        </span>
                    </center>
                </div>
                :
                <div class="main py-5">
                    <div class="container ">

                        <div class="row pb-md-5">
                            <AgencyNavbar />


                            <div class="col-md-9 ps-md-5 border-start admin-right-part">
                                <span className="d-flex row">
                                <h2 class="text-primary semibold h1 pb-3 col-md-9">Dashboard</h2>
                                {role == 2 ?
                                <>
                                {profileStatus === 0 ? 
                                
                                <span className="profile-btn col-md-3">Profile in review</span>
                                :""}
                                {profileStatus === 1 ? 
                                <span className="profile-btn col-md-3" >Profile Active</span>
                                :""}
                                {profileStatus === 2 ? 
                                <span className="profile-btn col-md-3" >Profile Suspended</span>
                                :""}
                                </>
                                :"" }
                                </span>
                                
                                    <div class="dashboard">
                                    <div class="row">
                                        {role == 2 || role == 6 ? 
                                        <div class="col-lg-4 col-md-6 dashboard-box pe-md-4 pb-4 mb-md-2">
                                            <Link to="/agent">
                                            <div class="border d-flex align-items-center p-3 w-10 ">
                                                <img src="assets/image/dashboard.png" alt="" />
                                                <div class="text-center ps-5">
                                                    <h3 class="semibold">{data.agents}</h3>
                                                    <h4 class="h4 semibold m-0">agents</h4>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        : ""}
                                        {role == 2 || role == 6 ? 
                                        <div class="col-lg-4 col-md-6 dashboard-box pe-md-4 pb-4 mb-md-2">
                                            <Link to="/approver" >
                                            <div class="border d-flex align-items-center p-3 w-10 ">
                                                <img src="assets/image/approver.png" alt="" />
                                                <div class="text-center ps-5">
                                                    <h3 class="semibold">{data.approver}</h3>
                                                    <h4 class="h4 semibold m-0">Approver</h4>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>

                                        : ""}
                                        {role == 2 ? 
                                        <div class="col-lg-4 col-md-6 dashboard-box pe-md-4 pb-4 mb-md-2">
                                            <Link to="/admin">
                                            <div class="border d-flex align-items-center p-3 w-10 ">
                                                <img src="assets/image/admins.png" alt="" />
                                                <div class="text-center ps-5">
                                                    <h3 class="semibold">{data.admins}</h3>
                                                    <h4 class="h4 semibold m-0">Admins</h4>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        : ""}

                                        <div class="col-lg-4 col-md-6 dashboard-box pe-md-4 pb-4 mb-md-2">
                                            <Link to="/property">
                                            <div class="border d-flex align-items-center p-3 w-10 ">
                                                <img src="assets/image/properties.png" alt="" />
                                                <div class="text-center ps-5">
                                                    <h3 class="semibold">{data.properties}</h3>
                                                    <h4 class="h4 semibold m-0">properties</h4>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        {role == 3  || role == 5 || role == 2 || role == 6   ?
                                        <div class="col-lg-4 col-md-6 dashboard-box pe-md-4 pb-4 mb-md-2">
                                            <Link to="/inquiries">
                                            <div class="border d-flex align-items-center p-3 w-10 ">
                                                <img src="assets/image/dashboard.png" alt="" />
                                                <div class="text-center ps-5">
                                                    <h3 class="semibold">{data.contactRequest}</h3>
                                                    <h4 class="h4 semibold m-0">Inquiries</h4>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        : "" }


                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            }
            <Footer />
        </>
    )
}
