import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { GetSearchPost } from "../API/CategoryApi";
import { GetProfile } from "../API/AuthApi";
import { checkUserLogin } from "../API/AuthApi";
const $ = window.jQuery;

export default function Navbar(props) {
  const location = useLocation();
  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState(
    localStorage.getItem("unread_notifications")
  );
  const [searchData, setSearchData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const Token = localStorage.getItem("token");
  const ProfileImage = localStorage.getItem("userProfile");
  const user = JSON.parse(localStorage.getItem("user"));
  const login_Type = localStorage.getItem("login_type");
  const role = localStorage.getItem("role");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // alert(localStorage.getItem('login_type'))
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const storageListener = () => {
    // alert('Hello world');
    setNotificationCount(localStorage.getItem("unread_notifications"));
    console.log("listening_for_storage_changes");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      checkUserLogin(token).then((res) => {
        if (res.data.status === "true") {
          if (role != res?.data?.data?.role) {
            swal({
              icon: "info",
              text: "Session Expired! Please login again.",
              showCancelButton: false,
              showConfirmButton: false,
            }).then((willDelete) => {
              if (willDelete) {
                localStorage.clear();
                window.location.href = "/login";
              }
            });
          }
        }
      });
    }
  }, []);
  const logout = () => {
    localStorage.clear();

    history.push("/");
    window.location.href = "/";
  };

  const onPostDetail = (slug) => {
    history.push("/post-detail" + "/" + slug, { slug: slug });
  };

  const handleSearch = (e) => {
    let data = {
      search_text: e.target.value,
    };
    GetSearchPost(data).then((res) => {
      if (res.data.status === "success") {
        setSearchData(res.data.data);
        setShowDropdown(true);
        res.data.data.length > 0
          ? $(".jsearch_drop").slideDown()
          : $(".jsearch_drop").slideUp();
      }
    });
  };
  const profile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let data = {
      user_id: user.id,
    };
    props?.refreshprofile?.();
    history.push("/profile" + "/" + user.id);
  };

  const handleAddProperty = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let data = {
      user_id: user.id,
    };
    GetProfile(data).then((res) => {
      // alert(JSON.stringify(res.data,null,4));
      if (res?.data?.status === "success") {
        let addPropertyStatus = true;
        if (res?.data?.data?.phone === null) {
          swal(
            "Update contact number",
            "Please add your mobile number from your profile page before creating a listing!",
            "info"
          );

          addPropertyStatus = false;
        }
        if (res?.data?.data?.role == 2 || res?.data?.data?.role == 5) {
          if (
            res?.data?.data?.roleWiseProfileData?.user
              ?.max_property_list_rent === 0 &&
            res?.data?.data?.roleWiseProfileData?.user
              ?.max_property_list_sale === 0
          ) {
            swal(
              "Insufficient Balance",
              "Go to pricing tab and purchase the plan in order to proceed",
              "info"
            );
            addPropertyStatus = false;
          }
        } else {
          if (
            res?.data?.data?.roleWiseProfileData?.agency_profile?.user
              ?.max_property_list_rent === 0 &&
            res?.data?.data?.roleWiseProfileData?.agency_profile?.user
              ?.max_property_list_sale === 0
          ) {
            swal(
              "Insufficient Balance!",
              "Agency/Admin has not purchased any pricing please contact Agency for more info!",
              "info"
            );
            addPropertyStatus = false;
          }
        }

        if (addPropertyStatus === true) {
          history.push("/add-property");
        }
      }
    });
  };
  return (
    <>
      <div className="mobile-search">
        <div className="d-flex align-items-center">
          <button className="close-btn">
            <i className="fa fa-chevron-left"></i>
          </button>
          <form action="#" className="m-0">
            <button type="submit" className="search">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 18a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15Zm10.45 2.95L16 16l4.95 4.95Z"
                  className="icon_svg-stroke"
                  stroke="#fff"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              placeholder="Search Questions"
              name="search"
              onChange={(e) => handleSearch(e)}
            />
          </form>
        </div>

        <div className="middle-area" style={{ background: "#fff" }}>
          <ul
            className="dropdown-menu jsearch_drop"
            aria-labelledby="dropdownMenuButton1"
            style={{ display: "contents" }}
          >
            {searchData.map((item, i) => {
              return (
                <li key={i} onClick={() => onPostDetail(item.slug)}>
                  <Link className="dropdown-item">
                    <span dangerouslySetInnerHTML={{ __html: item.question }} />
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-1 head-logo col-3">
              <img src="../assets/image/logo.png" alt="ask" />
            </div>

            <div className="col-lg-5 col-12 head-nav">
              <nav className="nav_area">
                <ul>
                  <li
                    className={
                      location.pathname === `/` ||
                      location.pathname === `/post-detail`
                        ? "active"
                        : ""
                    }
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Home"
                  >
                    <Link to="/">
                      <img src="../assets/image/icon1.png" alt="" />
                      <span>Home</span>
                    </Link>
                  </li>
                  {/* {Token === null ? (
                    ""
                  ) : (
                    <li
                      className={
                        location.pathname === `/refer-friend` ? "active" : ""
                      }
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Refer Friend"
                    >
                      <Link to="/refer-friend">
                        <img src="../assets/image/icon2.png" alt="" />{" "}
                        <span> Refer Friend</span>
                      </Link>
                    </li>
                  )} */}
                  {/* {Token === null ? (
                    ""
                  ) : ( */}
                  <li
                    className={
                      location.pathname === `/calculators` ? "active" : ""
                    }
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Calculators"
                  >
                    <Link
                      to={{
                        pathname: "/calculators",
                      }}
                    >
                      <img
                        src={
                          location.pathname === `/calculators`
                            ? "../assets/image/calculator_active.png"
                            : "../assets/image/calculator_icon.png"
                        }
                        alt=""
                        style={{
                          color:
                            location.pathname === `/calculators` && "#63af30",
                          opacity: "45%",
                          height: screenWidth >= 768 ? "33px" : "25px",
                          width: screenWidth >= 768 ? "35px" : "25px",
                        }}
                      />{" "}
                      <span>Calculators</span>
                    </Link>
                  </li>
                  {Token === null ? (
                    ""
                  ) : (
                    <li
                      style={{ position: "relative" }}
                      className={
                        location.pathname === `/notification` ? "active" : ""
                      }
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Notification"
                    >
                      <Link to="/notification">
                        <img src="../assets/image/icon3.png" alt="" />{" "}
                        <span>Notification</span>
                      </Link>
                      {notificationCount > 0 ? (
                        <div
                          style={{
                            position: "absolute",
                            top: 12,
                            left: 65,
                            backgroundColor: "red",
                            height: 20,
                            width: 20,
                            fontSize: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          {notificationCount}
                        </div>
                      ) : null}
                    </li>
                  )}
                  <li
                    className={location.pathname === `/listing` ? "active" : ""}
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Listing"
                  >
                    <Link to="/listing">
                      <img src="../assets/image/icon4.png" alt="" />{" "}
                      <span> Listings</span>
                    </Link>
                  </li>
                  <li
                    className={location.pathname === `/pricing` ? "active" : ""}
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Pricing"
                  >
                    <Link to="/pricing">
                      <img src="../assets/image/icon5.png" alt="" />{" "}
                      <span> Pricing</span>
                    </Link>
                  </li>
                  {Token === null ? (
                    ""
                  ) : (
                    <li class="d-lg-none">
                      <Link
                        to="#"
                        class="user-icon mr-4"
                        id="mo_profile_dropdown"
                        data-bs-toggle="dropdown"
                      >
                        {ProfileImage === "null" ? (
                          <img src="../assets/image/dummy_image.png" alt="" />
                        ) : (
                          <img src={ProfileImage} alt="" />
                        )}
                      </Link>

                      <div
                        class="dropdown-menu p-4 pb-2"
                        aria-labelledby="mo_profile_dropdown"
                      >
                        <div class="user-data">
                          <h5 class="fs-15 bold mb-0">
                            {user?.name} {user?.surname}
                          </h5>
                          <h6 class="fs-13">{user?.profile?.occupation}</h6>
                        </div>
                        <ul class="profile-pages">
                          <li></li>
                          <li>
                            <Link onClick={profile}>
                              <img
                                src="../assets/image/user_icon1.png"
                                alt=""
                              />
                              Profile
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard">
                              <img
                                src="../assets/image/user_icon2.png"
                                alt=""
                              />
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link to="/follow">
                              <img
                                src="../assets/image/user_icon3.png"
                                alt=""
                              />
                              Followers & Following
                            </Link>
                          </li>
                          <li>
                            <Link to="/message">
                              <img
                                src="../assets/image/user_icon4.png"
                                alt=""
                              />
                              Messages
                            </Link>
                          </li>
                          {login_Type == "normal" ? (
                            <li>
                              <Link to="/password-change">
                                <img
                                  src="../assets/image/user_icon5.png"
                                  alt=""
                                />
                                Security
                              </Link>
                            </li>
                          ) : null}
                          <li>
                            <Link to="/answer-later">
                              <img
                                src="../assets/image/user_icon6.png"
                                alt=""
                              />
                              Answer Later
                            </Link>
                          </li>
                          <li>
                            <Link to="/terms">
                              <img
                                src="../assets/image/user_icon7.png"
                                alt=""
                              />
                              Terms of Use
                            </Link>
                          </li>
                          <li>
                            <Link onClick={logout}>
                              <img
                                src="../assets/image/user_icon8.png"
                                alt=""
                              />
                              Log Out
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                </ul>
              </nav>
            </div>

            <div className="col-lg-3 head-search col-4  ">
              <div className="search-container">
                <button className="search-btn">
                  <i className="fa fa-search"></i> Search
                </button>
                <form action="#" className="m-0">
                  <button type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                  <input
                    type="text"
                    placeholder="Search Questions"
                    name="search"
                    onChange={(e) => handleSearch(e)}
                  />
                </form>
                <div>
                  <ul
                    className="dropdown-menu jsearch_drop"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {searchData.map((item, i) => {
                      return (
                        <li key={i}>
                          <Link
                            className="dropdown-item"
                            onClick={() => onPostDetail(item.slug)}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.question,
                              }}
                            />
                            {/* {[item.question]} */}
                            {/* <RichTextEditor  html={item.question} /> */}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 head-userdata head-without-login text-md-end col-5 ps-0 ">
              {Token === null ? (
                <span>
                  <Link to="/login" className="text-primary d-inline medium">
                    Login
                  </Link>
                  <Link
                    to="#"
                    className="sign-btn ms-2 ms-lg-3"
                    id="singup-dropdown"
                    data-bs-toggle="dropdown"
                  >
                    sign up
                  </Link>

                  <ul
                    className="dropdown-menu signup-menu"
                    aria-labelledby="singup-dropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="/agency-register">
                        <span>
                          <img src="../assets/image/agency.png" alt="" />
                        </span>
                        Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="/register" className="dropdown-item">
                        <span>
                          <img src="../assets/image/individual.png" alt="" />
                        </span>
                        Individual
                      </Link>
                    </li>
                  </ul>
                </span>
              ) : (
                <>
                  <Link
                    to="#"
                    class="head-user mr-4 d-lg-inline d-none"
                    id="profile_dropdown"
                    data-bs-toggle="dropdown"
                  >
                    {ProfileImage === "null" ? (
                      <img src="../assets/image/dummy_image.png" alt="" />
                    ) : (
                      <img src={ProfileImage} alt="" />
                    )}
                    {/* <i class="fa fa-caret-down "></i> */}
                  </Link>

                  <div
                    class="dropdown-menu p-4 pb-2"
                    aria-labelledby="profile_dropdown"
                  >
                    <div class="user-data">
                      <h5 class="fs-15 bold mb-0">
                        {user?.name} {user?.surname}
                      </h5>
                      <h6 class="fs-13">{user?.profile?.occupation}</h6>
                    </div>
                    <ul class="profile-pages">
                      <li></li>

                      <li>
                        {" "}
                        <Link onClick={profile}>
                          <img src="../assets/image/user_icon1.png" alt="" />
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard">
                          <img src="../assets/image/user_icon2.png" alt="" />
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link to="/follow">
                          <img src="../assets/image/user_icon3.png" alt="" />
                          Followers & Following
                        </Link>
                      </li>
                      <li>
                        <Link to="/message">
                          <img src="../assets/image/user_icon4.png" alt="" />
                          Messages
                        </Link>
                      </li>
                      {login_Type == "normal" ? (
                        <li>
                          <Link to="/password-change">
                            <img src="../assets/image/user_icon5.png" alt="" />
                            Security
                          </Link>
                        </li>
                      ) : null}
                      <li>
                        <Link to="/answer-later">
                          <img src="../assets/image/user_icon6.png" alt="" />
                          Answer Later
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms">
                          <img src="../assets/image/user_icon7.png" alt="" />
                          Terms of Use
                        </Link>
                      </li>
                      <li>
                        <Link onClick={logout}>
                          <img src="../assets/image/user_icon8.png" alt="" />
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {role == 3 || role == 5 || role == 2 || role == 6 ? (
                    <>
                      {location.pathname === `/add-property` ? (
                        ""
                      ) : (
                        <Link onClick={handleAddProperty} class="head-btn ms-3">
                          {" "}
                          Add new properties
                        </Link>
                      )}
                      {location.pathname === `/add-property` ? (
                        ""
                      ) : (
                        <Link onClick={handleAddProperty} class="add-btn">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              class="icon_svg-stroke"
                              stroke="#666"
                              strokeWidth="1.5"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g transform="translate(9 7)">
                                <path
                                  d="M3 6v-.5A2.5 2.5 0 1 0 .5 3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <circle
                                  class="icon_svg-fill_as_stroke"
                                  fill="#666"
                                  cx="3"
                                  cy="8.5"
                                  r="1"
                                  stroke="none"
                                ></circle>
                              </g>
                              <path
                                d="M7.5 4h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-3L9 22v-3H7.5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3Z"
                                strokeLinejoin="round"
                              ></path>
                            </g>
                          </svg>
                          Add
                        </Link>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
