import React, { useRef } from "react";
import { Link } from "react-router-dom";
import ApplyForHomeLoanBtn from "../shared/ApplyForHomeLoanBtn";
import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
const $ = window.jQuery;

const Calculators = () => {
  return (
    <>
      <Navbar />
      <div className="main">
        <div className="landing-calculator">
          <div className="container pt-5 pb-3">
            <div className="pb-md-">
              <h2 className="text-center text-primary semibold pb-3 fs-40">
                Home Loan Calculators
              </h2>
              <div className="justify-content-center row pb-md-4">
                <div className="col-md-7">
                  {/* <p className="medium text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Affordability Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link to="/affordabilty-calculator" className="custom-btn">
                      Calculate
                    </Link>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Repayment Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link to="/repayment-calculator" className="custom-btn">
                      Calculate
                    </Link>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Transfer Cost Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link to="/transfer-cost-calculator" className="custom-btn">
                      Calculate
                    </Link>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="text-center calc-column py-4">
                    <img src="assets/image/calculator.png" alt="" />
                    <h2 className="semibold">Extra Payments Calculator</h2>
                    {/* <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    <Link
                      to="/extra-payments-calculator"
                      className="custom-btn"
                    >
                      Calculate
                    </Link>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column flex-md-row">
                <div className="mx-2 mt-4 w-100 d-flex flex-column align-items-center justify-content-center">
                  <p>Get pre approved loan</p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScKSqCsPeuyHST0qI_Yn5fA5UmGIqkiyXKfiv3Y4im_QKyNCw/viewform"
                    target="_blank"
                    // className="w-100"
                  >
                    <button
                      style={{
                        backgroundColor: "#63af30",
                        borderRadius: "10px",
                        color: "white",
                        padding: "10px",
                      }}
                      className="border-0 px-3"
                    >
                      Apply for pre-approval
                    </button>
                  </a>
                </div>
                <ApplyForHomeLoanBtn
                  subtitle={"Apply for home loan"}
                  title={"Apply now"}
                />
              </div>
              <hr />
              <div className="d-flex justify-content-center align-items-center">
                Our service is FREE with zero obligation
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Calculators;
