import React from "react";

const TableBody = ({
  className,
  id,
  children,
  ...props
}) => {
    return (
        <tbody
            id={id}
            className={className}
            {...props}
        >
        {children}
        </tbody>
    );
};

export default TableBody;