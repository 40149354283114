import axios from "axios";
import { agentURLs } from '../constants/url';


  export const AddAgent = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${agentURLs.addagent}`,
    method: "Post",
    data:body,
});


export const ContactAgent = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${agentURLs.ContactAgent}`,
  method: "Post",
  data:body,
});

export const GetContactAgent = (token) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${agentURLs.GetContactAgent}`,
  method: "Post",
});


export const GetAllAgent = (token) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${agentURLs.GetAgent}`,
    method: "Post",
});

export const GetSingleAgent = (token, body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${agentURLs.SingalGetAgent}`,
    method: "Post",
    data:body,
});


export const DeactiveAgent = (token,body) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${agentURLs.DeactiveAgent}`,
    method: "Post",
    data:body,
});



