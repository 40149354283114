import React, { useState, useEffect } from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import { GetPricing, CoupanApply, StartPayment } from "../API/PricingApi";
import { GetProfile } from "../API/AuthApi";
import swal from "sweetalert";
import { formatter } from "../utils";

export default function Pricing() {
  const [pricingRentData, setPricingRentData] = useState([]);
  const [pricingSaleData, setPricingSaleData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [pricingId, setPricingId] = useState("");
  const [type, setType] = useState("sell");
  const [planPrice, setPlanPrice] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [coupan, setCoupan] = useState("");
  const [couponPercentage, setCouponPercentage] = useState(0);
  const [coupanError, setCoupanError] = useState("");
  const [agencyActiveStatus, setAgencyActiveStatus] = useState("");
  const Token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(function () {
    let mounted = true;
    setPageLoading(true);
    getPriciing();

    const user = JSON.parse(localStorage.getItem("user"));
    let data = {
      user_id: user?.id,
    };
    GetProfile(data).then((res) => {
      if (res?.data?.status === "success") {
        if (res?.data?.data?.role === 2) {
          setAgencyActiveStatus(
            res?.data?.data?.roleWiseProfileData?.is_approved
          );
        } else {
          setAgencyActiveStatus(
            res?.data?.data?.roleWiseProfileData?.agency_profile?.is_approved
          );
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const getPriciing = () => {
    GetPricing().then((res) => {
      if (res.data.status === "success") {
        // setPricingData(res.data.data);
        setPricingSaleData(res?.data?.data?.planSale);
        setPricingRentData(res?.data?.data?.planRent);
        setPageLoading(false);
      }
    });
  };
  const handlePricngData = (Id, Price) => {
    setPlanPrice(Price);
    setPricingId(Id);
  };

  const handlePropertyType = (val) => {
    setType(val);
    setPlanPrice(0);
    setPricingId("");
  };

  const handleCoupanApply = () => {
    let data = {
      coupon_code: coupan,
    };
    CoupanApply(Token, data).then((res) => {
      console.log(res);
      if (res?.data?.status === "success") {
        setCouponPercentage(res?.data?.data?.coupon_percentage);
        swal("Success", "Coupon applied successfully.", "success");
      } else if (res?.data?.message?.coupon_code) {
        setCoupanError(res?.data?.message?.coupon_code[0]);
      } else {
        setCoupanError(res?.data?.message);
      }
    });
  };

  const handleBuyNow = () => {
    if (!pricingId) {
      swal("Error", "Please select the pricing", "error");
    }

    if (pricingId) {
      setButtonLoading(true);
      let data = {
        return_url: window.location.origin + "/payment-success",
        cancel_url: window.location.origin + "/payment-cancel",
        coupon_code: coupan,
        plan_id: pricingId,
      };
      StartPayment(Token, data).then((res) => {
        if (res.status === 200) {
          document.getElementById("payment-form").innerHTML += res.data;
          handleSubmit();
        } else {
          swal("Error", "Something Went Wrong", "error");
          setButtonLoading(false);
        }
      });
    }
  };

  const handleinfo = () => {
    swal(
      "Info",
      "Your account is currently under review.  You will be able to purchase once your profile is approved ",
      "info"
    );
  };

  const handleSubmit = () => {
    const button = document.getElementsByClassName("final-paynow")[0];
    button.form.submit();
  };

  return (
    <>
      <Navbar />

      {pageLoading ? (
        <div className="main py-5">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div class="main py-5">
          <div class="container ">
            <div class="pricing-list">
              <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active "
                    id="sell-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#sell"
                    type="button"
                    role="tab"
                    aria-selected="true"
                    onClick={() => handlePropertyType("sell")}
                  >
                    Sale
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link text-dark"
                    id="rent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#rent"
                    type="button"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handlePropertyType("rent")}
                  >
                    Rent
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="sell"
                  role="tabpanel"
                  aria-labelledby="sell-tab"
                >
                  <div class="row">
                    <div class="col-md-9 pe-md-4">
                      <h5 class="h5  bold">
                        Pricing Plan to list properties in South Africa{" "}
                        {Token == null || role == 5 ? (
                          ""
                        ) : (
                          <small style={{ fontSize: 12, fontWeight: 500 }}>
                            {" "}
                            (agencies, scroll down to view full pricing plan)
                          </small>
                        )}
                      </h5>
                      <div class="row ">
                        {pricingSaleData.map((item, i) => {
                          return item.plan_for === "private" ? (
                            <div
                              class="col-md-4 mb-5"
                              onClick={() =>
                                handlePricngData(item?.id, item?.price)
                              }
                            >
                              <div class="pricing-list">
                                <div class="pricing-item text-center ">
                                  <h3 class="text-primary bold h5 m-0">
                                    {item.name}
                                  </h3>
                                  <h2 class="bold py-2 m-0">
                                    {formatter.format(item.price)}
                                  </h2>
                                  <h4 class="fs-15">
                                    <span class="bold">Quantity: </span>{" "}
                                    {item.max_property_listing} Listing
                                  </h4>
                                  <ul class="listing">
                                    {item?.plan_modules.map((ite, index) => {
                                      return (
                                        <li>{ite?.modules[0]?.description}</li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          );
                        })}

                        {Token === null || role == 5 ? (
                          ""
                        ) : (
                          <>
                            {/* <h5 class="h5 fs-15 bold">Price for Agencies</h5> */}
                            {/* <div class="row mb-4"> */}
                            {pricingSaleData.map((item, i) => {
                              return item.plan_for === "agency" ? (
                                <div
                                  class="col-md-4 mb-5"
                                  onClick={() =>
                                    handlePricngData(item?.id, item?.price)
                                  }
                                >
                                  <div class="pricing-list">
                                    <div class="pricing-item text-center ">
                                      <h3 class="text-primary bold h5 m-0">
                                        {item.name}
                                      </h3>
                                      <h2 class="bold py-2 m-0">
                                        {formatter.format(item.price)}
                                      </h2>
                                      {item.max_property_listing === -1 ? (
                                        <h4 class="fs-15">
                                          <span class="bold">Quantity: </span>{" "}
                                          Unlimited
                                        </h4>
                                      ) : (
                                        <h4 class="fs-15">
                                          <span class="bold">Quantity: </span>{" "}
                                          {item.max_property_listing} Listing
                                        </h4>
                                      )}
                                      <ul class="listing">
                                        {item?.plan_modules.map(
                                          (ite, index) => {
                                            return (
                                              <li>
                                                {ite?.modules[0]?.description}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              );
                            })}
                          </>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                    {Token === null ? (
                      <center>
                        We have affordable packages for agencies. Sign up to see
                        our various packages and select the relevant package
                        that suits your business need
                      </center>
                    ) : (
                      ""
                    )}
                    {Token === null || role == 3 || role == 4 ? (
                      ""
                    ) : (
                      <div class="col-md-3  ps-md-4 border-start">
                        <div class="right-side">
                          <div class="d-flex justify-content-between">
                            <h5 class="fs-15 bold">Amount</h5>
                            <h4 class="h3 bold">
                              {formatter.format(planPrice)}
                            </h4>
                          </div>
                          <div class="discount-title">
                            <h5 class="fs-15 bold">Discount Code</h5>
                            <div class="d-flex discount-form">
                              <input
                                type="text"
                                value={coupan}
                                onChange={(e) => setCoupan(e.target.value)}
                              />

                              <button
                                type="submit"
                                class="custom-btn"
                                onClick={handleCoupanApply}
                              >
                                Apply
                              </button>
                            </div>
                            <p style={{ color: "red", margintop: "-10px" }}>
                              {coupanError}
                            </p>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <h5 class="fs-15 bold m-0">Total Amount</h5>
                            <h4 class="h3 bold m-0">
                              {formatter.format(
                                planPrice - (planPrice * couponPercentage) / 100
                              )}{" "}
                            </h4>
                          </div>
                          <hr />
                          {buttonLoading ? (
                            <button
                              type="submit"
                              class="custom-btn buy-btn w-100 mt-4"
                            >
                              <span className="spinner-grow spinner-grow-sm"></span>
                            </button>
                          ) : role == 2 || role === 6 ? (
                            agencyActiveStatus === 1 ? (
                              <button
                                type="submit"
                                class="custom-btn buy-btn w-100 mt-4"
                                onClick={handleBuyNow}
                              >
                                Buy Now
                              </button>
                            ) : (
                              <button
                                type="submit"
                                class="custom-btn buy-btn w-100 mt-4"
                                onClick={handleinfo}
                              >
                                Buy Now
                              </button>
                            )
                          ) : (
                            <button
                              type="submit"
                              class="custom-btn buy-btn w-100 mt-4"
                              onClick={handleBuyNow}
                            >
                              Buy Now
                            </button>
                          )}
                        </div>
                        <br />
                        <span style={{ color: "red" }}>
                          {planPrice > 0
                            ? "You are about to purchase a Sales plan"
                            : ""}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="rent"
                  role="tabpanel"
                  aria-labelledby="rent-tab"
                >
                  <div class="row">
                    <div class="col-md-9 pe-md-4">
                      <h5 class="h5  bold">
                        Pricing Plan to list properties in South Africa{" "}
                        {Token == null || role == 5 ? (
                          ""
                        ) : (
                          <small style={{ fontSize: 12, fontWeight: 500 }}>
                            (agencies, scroll down to view full pricing plan)
                          </small>
                        )}
                      </h5>
                      <div class="row ">
                        {pricingRentData.map((item, i) => {
                          return item.plan_for === "private" ? (
                            <div
                              class="col-md-4 mb-5"
                              onClick={() =>
                                handlePricngData(item?.id, item?.price)
                              }
                            >
                              <div class="pricing-list">
                                <div class="pricing-item text-center ">
                                  <h3 class="text-primary bold h5 m-0">
                                    {item.name}
                                  </h3>
                                  <h2 class="bold py-2 m-0">
                                    {formatter.format(item.price)}
                                  </h2>
                                  <h4 class="fs-15">
                                    <span class="bold">Quantity: </span>{" "}
                                    {item.max_property_listing} Listing
                                  </h4>
                                  <ul class="listing">
                                    {item?.plan_modules.map((ite, index) => {
                                      return (
                                        <li>{ite?.modules[0]?.description}</li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          );
                        })}
                        {Token === null || role == 5 ? (
                          ""
                        ) : (
                          <>
                            {/* <h5 class="h5 fs-15 bold">Price for Agencies</h5>
                                        <div class="row mb-4"> */}
                            {pricingRentData.map((item, i) => {
                              return item.plan_for === "agency" ? (
                                <div
                                  class="col-md-4 mb-5"
                                  onClick={() =>
                                    handlePricngData(item?.id, item?.price)
                                  }
                                >
                                  <div class="pricing-list">
                                    <div class="pricing-item text-center ">
                                      <h3 class="text-primary bold h5 m-0">
                                        {item.name}
                                      </h3>
                                      <h2 class="bold py-2 m-0">
                                        {formatter.format(item.price)}
                                      </h2>
                                      {item.max_property_listing === -1 ? (
                                        <h4 class="fs-15">
                                          <span class="bold">Quantity: </span>{" "}
                                          Unlimited
                                        </h4>
                                      ) : (
                                        <h4 class="fs-15">
                                          <span class="bold">Quantity: </span>{" "}
                                          {item.max_property_listing} Listing
                                        </h4>
                                      )}
                                      <ul class="listing">
                                        {item?.plan_modules.map(
                                          (ite, index) => {
                                            return (
                                              <li>
                                                {ite?.modules[0]?.description}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              );
                            })}

                            {/* </div> */}
                          </>
                        )}
                      </div>
                    </div>
                    {Token === null ? (
                      <center>
                        We have affordable packages for agencies. Sign up to see
                        our various packages and select the relevant package
                        that suits your business need
                      </center>
                    ) : (
                      ""
                    )}
                    {Token === null || role == 3 || role == 4 ? (
                      ""
                    ) : (
                      <div class="col-md-3  ps-md-4 border-start">
                        <div class="right-side">
                          <div class="d-flex justify-content-between">
                            <h5 class="fs-15 bold">Amount</h5>
                            <h4 class="h3 bold">
                              {formatter.format(planPrice)}
                            </h4>
                          </div>
                          <div class="discount-title">
                            <h5 class="fs-15 bold">Discount Code</h5>
                            <div class="d-flex discount-form">
                              <input
                                type="text"
                                value={coupan}
                                onChange={(e) => setCoupan(e.target.value)}
                              />

                              <button
                                type="submit"
                                class="custom-btn"
                                onClick={handleCoupanApply}
                              >
                                Apply
                              </button>
                            </div>
                            <p style={{ color: "red", margintop: "-10px" }}>
                              {coupanError}
                            </p>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <h5 class="fs-15 bold m-0">Total Amount</h5>
                            <h4 class="h3 bold m-0">
                              {" "}
                              {formatter.format(
                                planPrice - (planPrice * couponPercentage) / 100
                              )}
                            </h4>
                          </div>
                          <hr />
                          {buttonLoading ? (
                            <button
                              type="submit"
                              class="custom-btn buy-btn w-100 mt-4"
                            >
                              <span className="spinner-grow spinner-grow-sm"></span>
                            </button>
                          ) : role == 2 || role === 6 ? (
                            agencyActiveStatus === 1 ? (
                              <button
                                type="submit"
                                class="custom-btn buy-btn w-100 mt-4"
                                onClick={handleBuyNow}
                              >
                                Buy Now
                              </button>
                            ) : (
                              <button
                                type="submit"
                                class="custom-btn buy-btn w-100 mt-4"
                                onClick={handleinfo}
                              >
                                Buy Now
                              </button>
                            )
                          ) : (
                            <button
                              type="submit"
                              class="custom-btn buy-btn w-100 mt-4"
                              onClick={handleBuyNow}
                            >
                              Buy Now
                            </button>
                          )}
                        </div>
                        <br />
                        <span style={{ color: "red" }}>
                          {planPrice > 0
                            ? "You are about to purchase a renting plan"
                            : ""}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <span id="payment-form"></span>
    </>
  );
}
