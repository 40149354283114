import React, { useEffect, useState } from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import { PropertyDetail, ShowContactNo } from "../API/PropertyApi";
import { Link, useLocation, useParams } from "react-router-dom";
import { ContactAgent } from "../API/AgentApi";
import swal from "sweetalert";
import { CreatePostShare } from "../API/PostApi";
import { formatter } from "../utils";
const $ = window.jQuery;
export default function SearchPropertyDetail(props) {
  const location = useLocation();
  const params = useParams();
  const Token = localStorage.getItem("token");
  const slug = params.slug;
  const [propertyData, setPropertyData] = useState("");
  const [PageLoading, setPageLoading] = useState(false);
  const [externalFeatures, setExternalFeatures] = useState([]);
  const [propertyImage, setPropertyImage] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [propertyNearestPlaces, setPropertyNearestPlaces] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [agent_user_id, setagentUserId] = useState("");
  const [showContact, setShowContact] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [scrollTop, setScrollTop] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    PropertyDetail(Token, slug).then((res) => {
      setPageLoading(false);
      if (res.data.status === "success") {
        setPropertyData(res?.data?.data?.property);
        setAmenities(res?.data?.data?.property?.get_selected_amenities);
        setExternalFeatures(
          res?.data?.data?.property?.get_selected_external_features
        );
        setPropertyNearestPlaces(
          res?.data?.data?.property?.property_nearest_places
        );
        setPropertyImage(res?.data?.data?.property?.property_images);
        setagentUserId(res?.data?.data?.property?.user?.id);
      }
    });
  }, []);

  useEffect(function () {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    setScrollTop(false);
  }, []);

  const handleContactSave = (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    if (!email) {
      setEmailError("Please enter your email address");
      setButtonDisabled(false);
    }

    if (!mobile) {
      setMobileError("Please enter your mobile number");
      setButtonDisabled(false);
    }
    if (!message) {
      setMessageError("Please enter your message");
      setButtonDisabled(false);
    }

    if (!name) {
      setNameError("Please enter your name");
      setButtonDisabled(false);
    }

    if (name && email && mobile && message) {
      // let data = {
      //     agent_user_id:agent_user_id,
      //     name:name,
      //     email:email,
      //     phone:mobile,
      //     message:message,
      //     property_slug:propertyData?.slug,
      // }
      const data = new FormData();
      data.append("agent_user_id", agent_user_id);
      data.append("email", email);
      data.append("name", name);
      data.append("phone", mobile);
      data.append("message", message);
      data.append("property_slug", propertyData?.slug);

      ContactAgent(Token, data).then((res) => {
        if (res.data.status === "success") {
          setButtonDisabled(false);
          setMessage("");
          setMobile("");
          setEmail("");
          setName("");
          swal("Success", "Message sent successfully", "success");
        } else {
          swal("Error", res.data.message, "error");
        }
      });
    }
  };

  const handleShowContact = () => {
    let obj = {
      property_slug: slug,
    };
    ShowContactNo(Token, obj).then((res) => {
      if (res.data.status === "success") {
        setShowContact(true);
      }
    });
  };
  const postShare = (slug, element) => {
    if (element === "capylink") {
      const url = window.location.origin + "/property-detail" + "/" + slug;
      navigator.clipboard.writeText(url);
      swal({
        text: "Link copied successfully",
        icon: "success",
      });
    } else {
      const url = window.location.origin + "/property-detail" + "/" + slug;
      const data = {
        post_slug: slug,
        share_type: element,
        post_url: url,
        share_request: "property",
      };
      CreatePostShare(Token, data).then((res) => {
        window.open(res.data.data.shareLinkUrl, "_blank");
      });
    }
  };

  return (
    <>
      <Navbar />

      {PageLoading ? (
        <div className="main">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div className="main">
          <div className="container py-5">
            <h1 className="h1 text-primary address-font semibold pb-3">
              Property for{" "}
              {propertyData?.listing_type === "sell" ? "Sale" : "Rent"}{" "}
              {propertyData?.is_display_address === 1
                ? " at " + propertyData?.street_address
                : ""}
            </h1>

            <div className="property-detail-gallery ">
              <div className="top-gallery pb-5">
                <div className="row">
                  <div className="col-md-7 gal-left pb-md-0 pb-3">
                    {propertyImage.length > 0 ? (
                      <a
                        href={propertyImage[0]?.image}
                        data-fancybox="images-preview"
                      >
                        <img src={propertyImage[0]?.image} />
                      </a>
                    ) : (
                      <a
                        href="../../assets/image/gal1.png"
                        data-fancybox="images-preview"
                      >
                        <img src="../../assets/image/gal1.png" />
                      </a>
                    )}
                  </div>

                  {propertyImage.length > 1 ? (
                    <div className="col-md-5 pl-md-0 gal-right">
                      {propertyImage.map((item, i) => {
                        return i > 0 ? (
                          i < 5 ? (
                            <div className="gal-item">
                              <a
                                href={item.image}
                                data-fancybox="images-preview"
                              >
                                <img src={item.image} />
                              </a>
                            </div>
                          ) : (
                            <div className="d-none">
                              <a
                                href={item.image}
                                data-fancybox="images-preview"
                              >
                                <img src={item.image} />
                              </a>
                            </div>
                          )
                        ) : (
                          ""
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row property-detail">
              <div className="col-md-8 ">
                <div className="pb-4 ">
                  <h3 className="regular m-0">
                    {propertyData?.property_name}
                    {/* {propertyData?.is_display_address === 1 ?
                                <>
                                in <span className="bold">{propertyData?.street_address} </span>
                                </>
                                :""} */}
                  </h3>
                  {/* <h2 className="h1 text-primary semibold py-3 m-0">R {propertyData?.property_price}</h2> */}
                  {propertyData?.is_display_poa === 1 ? (
                    <h2 className="h1 text-primary py-3 address-font semibold m-0">
                      Price on Application (P.O.A)
                    </h2>
                  ) : (
                    <h2 className="h1 text-primary py-3 address-font semibold m-0">
                      {formatter.format(propertyData?.property_price)}
                    </h2>
                  )}

                  {propertyData?.is_display_address === 1 ? (
                    <h4 className="regular">
                      <i className="fa fa-map-marker-alt pe-3 text-parrot"></i>
                      {propertyData?.street_address}
                    </h4>
                  ) : (
                    ""
                  )}
                </div>

                <div className="pb-5">
                  <h4 className="semibold h3 detail-title">Description</h4>

                  <p
                    className="light"
                    dangerouslySetInnerHTML={{
                      __html: propertyData?.description,
                    }}
                  ></p>
                </div>

                <div className="amenities pb-5">
                  <h4 className="semibold h3 detail-title pb-3">Amenities</h4>

                  <div className="row amenities-list">
                    {amenities.length > 0 ? (
                      amenities.map((item, i) => {
                        return (
                          <div className="col-sm-5 pb-4">
                            <div className="d-flex align-items-center">
                              <span>
                                <img
                                  src={item?.master_property?.image}
                                  alt=""
                                />
                              </span>
                              <h5 className="h5 semibold">
                                <span className="text-gray medium">
                                  {item?.master_property?.name} :
                                </span>{" "}
                                {item?.data}
                              </h5>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-sm-5 pb-4">
                        <div className="d-flex align-items-center">
                          <h5 className="h5 semibold">No Any Amenities</h5>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="property-overview pb-5">
                  <h4 className="semibold h3 detail-title pb-3">
                    Property Overview
                  </h4>

                  <p className="h5">
                    Listing Number <span>{propertyData?.listing_no}</span>
                  </p>
                  <p className="h5">
                    Type of Property{" "}
                    <span>{propertyData?.property_type?.name}</span>
                  </p>
                  {/* {propertyData?.is_display_address === 1 ?
                                <p className="h5 clearfix">Street Address <span>{propertyData?.property_address}</span></p>
                                :""} */}
                  {propertyData?.listing_type === "rent" ? (
                    <p className="h5">
                      Occupation Date <span>{propertyData?.occupation}</span>
                    </p>
                  ) : (
                    ""
                  )}
                  <p className="h5">
                    Erf Size <span>{propertyData?.floor_size}</span>
                  </p>
                </div>

                {propertyData?.property_type?.id === 8 ? (
                  ""
                ) : (
                  <div className="property-overview pb-5">
                    <h4 className="semibold h3 detail-title pb-3">
                      Building Type
                    </h4>

                    <p className="h5">{propertyData?.building_type?.name} </p>
                  </div>
                )}

                {/* <div className="property-overview pb-5">
                                <h4 className="semibold h3 detail-title pb-3">Rooms</h4>

                                <p className="h5">Bedrooms <span>3</span></p>
                                <p className="h5">Bathrooms <span>2</span></p>
                                <p className="h5">Reception Rooms <span>1</span></p>
                            </div> */}

                <div className="property-overview pb-5">
                  <h4 className="semibold h3 detail-title pb-3">
                    External Features
                  </h4>

                  {externalFeatures.length > 0 ? (
                    externalFeatures.map((item, i) => {
                      return (
                        <p className="h5">
                          {item?.master_property?.name}
                          {item.data === "1" ? (
                            <span>Yes</span>
                          ) : (
                            <span>No</span>
                          )}
                        </p>
                      );
                    })
                  ) : (
                    <p className="h5">No Any External Features</p>
                  )}
                </div>
                {propertyData?.retirement === 1 ||
                propertyData?.on_show === 1 ||
                propertyData?.auction === 1 ||
                propertyData?.repossessed === 1 ||
                propertyData?.security_estate === 1 ? (
                  <div className="property-overview pb-5">
                    <h4 className="semibold h3 detail-title pb-3">Others</h4>

                    {propertyData?.retirement === 1 ? (
                      <p className="h5">
                        Retirement
                        {propertyData?.retirement === 1 ? (
                          <span>Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                    {propertyData?.on_show === 1 ? (
                      <p className="h5">
                        On Show
                        {propertyData?.on_show === 1 ? (
                          <span>Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                    {propertyData?.auction === 1 ? (
                      <p className="h5">
                        Auction
                        {propertyData?.auction === 1 ? (
                          <span>Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                    {propertyData?.repossessed === 1 ? (
                      <p className="h5">
                        Repossessed
                        {propertyData?.repossessed === 1 ? (
                          <span>Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                    {propertyData?.security_estate === 1 ? (
                      <p className="h5">
                        Security Estate
                        {propertyData?.security_estate === 1 ? (
                          <span>Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {propertyNearestPlaces.length > 0 ? (
                  <div className="property-overview pb-5">
                    <h4 className="semibold h3 detail-title pb-3">
                      Point of Interest
                    </h4>

                    {propertyNearestPlaces.map((item, i) => {
                      return (
                        <p className="h5">
                          {item?.name}{" "}
                          <span> {JSON.parse(item?.distance).text} </span>
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* {propertyData?.user?.role !== 1 ?    */}

              <div className="col-md-4">
                <div className="agent-box rounded text-center">
                  <div className="d-lg-flex align-items-start ">
                    {propertyData?.user?.role !== 1 ? (
                      propertyData?.user?.roleWiseProfileData?.user_image ===
                      null ? (
                        <img
                          src="../../assets/image/dummy_image.png"
                          className="user-image mb-3"
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            propertyData?.user?.roleWiseProfileData?.user_image
                          }
                          className="user-image mb-3"
                          alt=""
                        />
                      )
                    ) : (
                      ""
                    )}
                    <div className="text-start">
                      {propertyData?.user?.role !== 1 ? (
                        <h5 className="h5 bold m-0 pb-1">
                          {propertyData?.user?.name}{" "}
                          {propertyData?.user?.surname}
                        </h5>
                      ) : (
                        ""
                      )}
                      {propertyData?.user?.role !== 1 ? (
                        <h6 className="regular text-gray fs-14">
                          {propertyData?.user?.user_role?.name}
                        </h6>
                      ) : (
                        ""
                      )}

                      {showContact === false ? (
                        <Link
                          className="custom-btn mt-3"
                          onClick={handleShowContact}
                        >
                          Show Contact No.
                        </Link>
                      ) : propertyData?.user?.role === 1 ? (
                        <h6 className="regular text-gray fs-14">
                          Phone : {propertyData?.super_phone}
                        </h6>
                      ) : (
                        <h6 className="regular text-gray fs-14">
                          {propertyData?.user?.phone}
                        </h6>
                      )}
                    </div>
                  </div>
                  <hr />
                  {propertyData?.user?.roleWiseProfileData?.agency_profile
                    ?.user_image === null ? (
                    <img src="../../assets/image/company-logo.png" alt="" />
                  ) : propertyData?.user?.role === 2 ? (
                    <img
                      src={propertyData?.user?.roleWiseProfileData?.agency_logo}
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        propertyData?.user?.roleWiseProfileData?.agency_profile
                          ?.user_image
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className="agent-form mt-4 p-3 rounded">
                  {propertyData?.user?.role === 5 ? (
                    <h3 className="h3 text-center bold pb-3">Contact Seller</h3>
                  ) : (
                    <h3 className="h3 text-center bold pb-3">Contact Agent</h3>
                  )}

                  <form onSubmit={handleContactSave} className="site-form">
                    <div className="form-field">
                      <input
                        type="text"
                        className="field-name"
                        placeholder="Enter your name"
                        value={name ? name : ""}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <p style={{ color: "red", margintop: "-10px" }}>
                        {nameError}
                      </p>
                    </div>

                    <div className="form-field">
                      <input
                        type="email"
                        className="field-email"
                        placeholder="Enter your email"
                        value={email ? email : ""}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p style={{ color: "red", margintop: "-10px" }}>
                        {emailError}
                      </p>
                    </div>

                    <div className="form-field">
                      <input
                        type="tel"
                        className="field-phone"
                        placeholder="Enter your mobile number"
                        value={mobile ? mobile : ""}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                      <p style={{ color: "red", margintop: "-10px" }}>
                        {mobileError}
                      </p>
                    </div>

                    <div className="form-field">
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder="Write your message"
                        value={message ? message : ""}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <p style={{ color: "red", margintop: "-10px" }}>
                        {messageError}
                      </p>
                    </div>

                    <button
                      type="submit"
                      className="custom-btn border-0 w-100 mt-3"
                      disabled={buttonDisabled}
                    >
                      {" "}
                      {!buttonDisabled ? "Send Message" : "Please wait..."}
                    </button>
                  </form>
                </div>
                <br /> <br />
                <div
                  className="rounded text-center"
                  style={{ background: "#ececec", padding: "30px" }}
                >
                  <h5>Share In :</h5>
                  <br />
                  <div className="align-items-start row">
                    <div className="col-md-4">
                      <Link
                        className="d-block fs-15 medium"
                        onClick={() =>
                          postShare(propertyData?.slug, "facebook")
                        }
                      >
                        <img
                          src="../../assets/image/fb.png"
                          alt=""
                          className="pe-2"
                        />
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <Link
                        className="d-block fs-15 medium"
                        onClick={() => postShare(propertyData?.slug, "twitter")}
                      >
                        <img
                          src="../../assets/image/twitter.png"
                          alt=""
                          className="pe-2"
                        />
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <Link
                        className="d-block fs-15 medium"
                        onClick={() =>
                          postShare(propertyData?.slug, "capylink")
                        }
                      >
                        <img
                          src="../../assets/image/link.png"
                          alt=""
                          className="pe-2"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* :"" } */}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
