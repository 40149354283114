import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  GetPostDetail,
  CreateLikePost,
  CreatePostReport,
  CreatePostShare,
} from "../API/PostApi";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import { AnswerSave, AnswerLaterSave, AnswerReplySave } from "../API/AnswerApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const $ = window.jQuery;

export default function PostDetail() {
  const location = useLocation();
  const slug = location.state.slug;
  const history = useHistory();
  const [post, setPost] = useState();
  const [likeStatus, setLikeStatus] = useState("");
  const [likeCount, setCount] = useState("");
  const [postlikeId, setLikePostId] = useState("");

  const [answerInput, setAnswerInput] = useState(false);
  const [showAnswerInputId, setShowAnswerInputId] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [answerTextError, setAnswerTextError] = useState("");

  const [reportPostId, setReportPostId] = useState("");
  const [reportReason, setReportReason] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [reportReasonError, setReportReasonError] = useState("");
  const [reportDescriptionError, setReportDescriptionError] = useState("");

  const [imagePriview, setImagePriview] = useState("");

  const [replyInput, setReplyInput] = useState(false);
  const [showReplyInputId, setShowReplyInputId] = useState("");
  const [showReplyPostInputId, setShowReplyPostInputId] = useState("");
  const [replyText, setReplyText] = useState("");
  const [replyTextError, setReplyTextError] = useState("");
  const [replysubmitted, setReplySubmitted] = useState(false);
  const [replySubmittedResponse, setReplySubmittedResponse] = useState([]);
  const [replyLimit, setReplyLimit] = useState(2);

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const Token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(function () {
    let mounted = true;
    setPageLoading(true);
    let body = {
      post_slug: slug,
    };
    GetPostDetail(body, Token).then((res) => {
      if (mounted) {
        setPost(res.data.data);
        setPageLoading(false);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const handleReport = (postId) => {
    setReportPostId(postId);
  };
  const handleReportReason = (e) => {
    setReportReason(e.target.value);
  };
  const submitReport = (event) => {
    event.preventDefault();
    setLoading(true);
    if (!reportReason) {
      setReportReasonError("Please select reason");
      setLoading(false);
    }
    if (!reportDescription) {
      setReportDescriptionError("Please Enter Your Report");
      setLoading(false);
    }

    if (reportReason && reportDescription) {
      setReportDescriptionError("");
      setReportReasonError("");
      let data = {
        post_id: reportPostId,
        report: reportDescription,
        reason: reportReason,
      };
      CreatePostReport(Token, data).then((res) => {
        if (res.data.status === "success") {
          setReportReason("");
          setReportDescription("");
          setLoading(false);
          $("#myModalrep").modal("hide");
          swal({
            text: "Report Submitted Successfully",
            icon: "success",
          });
        } else {
          swal({
            text: "Issue in submitting report,Please try again later",
            icon: "error",
          });
        }
      });
    }
  };

  const handleImagePriview = (image) => {
    setImagePriview(image);
    $("#myModalpop").modal("show");
  };

  const handleReply = (id, postId) => {
    setShowReplyInputId(id);
    setShowReplyPostInputId(postId);
    setReplyInput(true);
    setReplySubmitted(false);
    setReplySubmittedResponse([]);
  };
  const reply = (e) => {
    $(".autoresizing").on("input", function () {
      this.style.height = "auto";

      this.style.height = this.scrollHeight + "px";
    });
    setReplyText(e.target.value);
  };
  const handleViewAllReply = (limit) => {
    setReplyLimit(limit);
  };

  const handleReplySubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let data = {
      answer_id: showReplyInputId,
      answer: replyText,
    };
    if (!replyText) {
      setReplyTextError("Please Enter Your Answer");
      setLoading(false);
    }
    if (replyText) {
      AnswerReplySave(Token, data).then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          setReplyInput(false);
          setReplyText("");

          setReplySubmitted(true);
          setReplySubmittedResponse(res.data.data);
          swal("Success", "Post Answer Reply Added Successfully", "success");
        } else {
          setLoading(false);
          swal("Error", "Something went wrong", "error");
        }
      });
    }
  };

  const like = (id) => {
    let data = {
      post_id: id,
    };
    setLikePostId(id);
    CreateLikePost(Token, data).then((res) => {
      setLikeStatus(res.data.data.like_status);
      setCount(res.data.data.count);
    });
  };
  const answer = (id) => {
    setShowAnswerInputId(id);
    setAnswerInput(true);
  };
  const handleAnswer = (e) => {
    $(".autoresizing").on("input", function () {
      this.style.height = "auto";

      this.style.height = this.scrollHeight + "px";
    });
    setAnswerText(e.target.value);
  };
  const handleAnswerSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!answerText) {
      setAnswerTextError("Please Enter Your Answer");
      setLoading(false);
    }
    if (answerText) {
      let data = {
        post_id: showAnswerInputId,
        answer: answerText,
      };
      AnswerSave(Token, data).then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          setAnswerInput(false);
          setLoading(false);
          setAnswerText("");
          let body = {
            post_slug: slug,
          };
          GetPostDetail(body, Token).then((res) => {
            setPost(res.data.data);
          });
          swal("Success", "Answer Submitted Successfully", "success");
        }
      });
    }
  };
  const userProfile = (userId) => {
    // alert('Hellow')
    history.push("/profile" + "/" + userId, { userId: userId });
  };
  const answerLater = (id) => {
    let data = {
      post_id: id,
    };
    AnswerLaterSave(Token, data).then((res) => {
      if (res.data.status === "success") {
        history.push("/answer-later");
        // swal("Success", "Answer Submitted Successfully", "success");
      } else {
        swal("Error", "Something went wrong", "error");
      }
    });
  };

  const postShare = (slug, element) => {
    if (element === "capylink") {
      const url = window.location.origin + "/post-detail" + "/" + slug;
      navigator.clipboard.writeText(url);
      swal({
        text: "Link copied successfully",
        icon: "success",
      });
    } else {
      const url = window.location.origin + "/post-detail" + "/" + slug;
      const data = {
        post_slug: slug,
        share_type: element,
        post_url: url,
      };
      CreatePostShare(Token, data).then((res) => {
        window.open(res.data.data.shareLinkUrl, "_blank");
      });
    }
  };

  const NotLogin = () => {
    swal({
      title: "Sign up for Ask Property",
      text: "Create a profile, ask and answer questions, list your property and more",
      icon: "info",
      buttons: {
        login: {
          text: "login",
        },
        register: {
          text: "Register",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete == "register") {
        history.push("/register");
      } else if (willDelete == "login") {
        history.push("/login");
      }
    });
  };

  return (
    <>
      <Navbar />
      {pageLoading ? (
        <div className="main">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div className="main">
          <div className="container ">
            <div className="row pt-5 justify-content-center">
              <div className="col-lg-5  mb-5 ">
                <div className="feed-box bg-white mb-4">
                  <div className="feed-top">
                    <div className="d-flex align-items-start  w-100 pb-2">
                      {post?.user?.roleWiseProfileData?.user_image !== null ? (
                        <img
                          src={post?.user?.roleWiseProfileData?.user_image}
                          alt=""
                          className="feed-user"
                          onClick={() =>
                            handleImagePriview(
                              post?.user?.roleWiseProfileData?.user_image
                            )
                          }
                        />
                      ) : (
                        <img
                          src="assets/image/dummy_image.png"
                          alt=""
                          className="feed-user"
                          onClick={() =>
                            handleImagePriview("assets/image/dummy_image.png")
                          }
                        />
                      )}
                      <div className="ps-3">
                        <Link onClick={() => userProfile(post?.user?.id)}>
                          <h3>
                            {post?.user?.name} {post?.user?.surname}
                          </h3>
                        </Link>
                        {post?.user?.role === 5 ? (
                          <h4 className="">
                            {post?.user?.roleWiseProfileData?.occupation}
                          </h4>
                        ) : post?.user?.role === 2 ? (
                          <h4 className="">
                            {post?.user?.roleWiseProfileData?.agency_name}
                          </h4>
                        ) : (
                          <h4 className="">
                            {
                              post?.user?.roleWiseProfileData?.agency_profile
                                ?.agency_name
                            }
                          </h4>
                        )}
                      </div>
                      <div className="ms-auto">
                        <span className="fs-12">{post?.CreatedAtCustom}</span>
                      </div>
                    </div>
                    <hr className="border-top" />
                    <p
                      className="ck"
                      dangerouslySetInnerHTML={{ __html: post?.question }}
                    ></p>
                  </div>
                  <div className="feed-img">
                    {post?.post_image !== null ? (
                      <img
                        src={post?.post_image}
                        alt="ask-feed"
                        className="w-100"
                        onClick={() => handleImagePriview(post?.post_image)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {Token === null ? (
                    <div className="feed-share">
                      <span onClick={NotLogin}>
                        <img
                          src="assets/image/like.png"
                          className="pe-2"
                          alt=""
                        />
                        {post?.post_like_count}
                      </span>
                      <span onClick={NotLogin}>
                        <img src="assets/image/answer.png" alt="" />
                      </span>
                      <span onClick={NotLogin}>
                        <img src="assets/image/report.png" alt="" />
                      </span>
                      <span onClick={NotLogin}>
                        <img
                          src="assets/image/clock.png"
                          className="pe-2"
                          alt=""
                        />
                        answer Later
                      </span>
                      <span className="ms-auto p-0" onClick={NotLogin}>
                        <img src="assets/image/share.png" alt="" />
                      </span>
                    </div>
                  ) : (
                    <div className="feed-share">
                      {(likeStatus === "like" && post?.id === postlikeId) ||
                      post?.isAuthUserLikedPost === true ? (
                        <span onClick={() => like(post?.id)}>
                          <img
                            src="assets/image/like-fill.png"
                            className="pe-2"
                            alt=""
                            style={{ width: "33px", height: "25px" }}
                          />
                          {likeStatus === "like" && post?.id === postlikeId
                            ? likeCount
                            : post?.post_like_count}
                        </span>
                      ) : likeStatus === "deslike" &&
                        post?.id === postlikeId ? (
                        <span onClick={() => like(post.id)}>
                          <img
                            src="assets/image/like.png"
                            className="pe-2"
                            alt=""
                          />
                          {likeStatus === "deslike" && post?.id === postlikeId
                            ? likeCount
                            : post?.post_like_count}
                        </span>
                      ) : (
                        <span onClick={() => like(post?.id)}>
                          <img
                            src="assets/image/like.png"
                            className="pe-2"
                            alt=""
                          />
                          {likeStatus === "like" && post?.id === postlikeId
                            ? likeCount
                            : post?.post_like_count}
                        </span>
                      )}

                      <span onClick={() => answer(post?.id)}>
                        <img src="assets/image/answer.png" alt="" />
                      </span>
                      {user.id === post?.user_id ? (
                        ""
                      ) : (
                        <span
                          data-bs-toggle="modal"
                          data-bs-target="#myModalrep"
                          onClick={() => handleReport(post?.id)}
                        >
                          <img src="assets/image/report.png" alt="" />
                        </span>
                      )}

                      {post?.isAuthUserPostAnswerLater === false &&
                      post?.isAuthUserPostAnswer === false ? (
                        <span onClick={() => answerLater(post?.id)}>
                          <img
                            src="assets/image/clock.png"
                            className="pe-2"
                            alt=""
                          />
                          answer Later
                        </span>
                      ) : (
                        ""
                      )}
                      <Link
                        className="ms-auto p-0"
                        id="share_dropdown"
                        x-placement="bottom-end"
                        data-bs-toggle="dropdown"
                      >
                        <img src="assets/image/share.png" alt="" />
                      </Link>
                      <div
                        className="dropdown-menu p-3"
                        aria-labelledby="share_dropdown"
                      >
                        <Link
                          className="d-block fs-15 medium"
                          onClick={() => postShare(post?.slug, "facebook")}
                        >
                          <img
                            src="assets/image/fb.png"
                            alt=""
                            className="pe-2"
                          />{" "}
                          Facebook
                        </Link>
                        <Link
                          className="d-block py-3 fs-15 medium"
                          onClick={() => postShare(post?.slug, "twitter")}
                        >
                          <img
                            src="assets/image/twitter.png"
                            alt=""
                            className="pe-2"
                          />{" "}
                          Twitter
                        </Link>
                        <Link
                          className="d-block fs-15 medium"
                          onClick={() => postShare(post?.slug, "capylink")}
                        >
                          <img
                            src="assets/image/link.png"
                            alt=""
                            className="pe-2"
                          />{" "}
                          Copylink
                        </Link>
                      </div>
                    </div>
                  )}
                  {answerInput ? (
                    <div className="feed-comments">
                      <div className="row hidden">
                        <div className="col-12">
                          <form
                            className="ans-save"
                            onSubmit={handleAnswerSubmit}
                          >
                            <div className="row">
                              <div className="col-10">
                                {/* <textarea
                                                                    name="answer"
                                                                    className="form-control autoresizing"
                                                                    style={{ height: '39px' }}
                                                                    value={answerText}
                                                                    onChange={(e) => handleAnswer(e)}
                                                                ></textarea> */}
                                <CKEditor
                                  data={answerText}
                                  className="editor"
                                  cols="30"
                                  rows="10"
                                  onChange={(event, editor) => {
                                    handleAnswer(editor.getData());
                                  }}
                                  editor={ClassicEditor}
                                  config={{
                                    toolbar: [
                                      "heading",
                                      "|",
                                      "bold",
                                      "italic",
                                      "blockQuote",
                                      "link",
                                      "numberedList",
                                      "bulletedList",
                                      "|",
                                      "undo",
                                      "redo",
                                    ],
                                  }}
                                />
                                <p style={{ color: "red", margintop: "-10px" }}>
                                  {answerTextError}
                                </p>
                              </div>
                              <div className="col-2">
                                {loading ? (
                                  <button
                                    type="submit"
                                    className="border-btn btn"
                                    disabled={loading ? true : false}
                                  >
                                    <span className="spinner-grow spinner-grow-sm"></span>
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="send-btn"
                                    disabled={loading ? true : false}
                                  >
                                    Send
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <h3 className="h5 bold">
                  {post?.post_answer_count > 1
                    ? post?.post_answer_count + " Answers"
                    : post?.post_answer_count + " Answer"}
                </h3>

                {post?.post_answer.map((item, i) => {
                  return (
                    <div className="feed-comments feed-answer bg-white border ">
                      <div className="comment-list">
                        <div className="comment d-flex align-items-start w-100 pb-4">
                          {item?.user?.roleWiseProfileData?.user_image !==
                          null ? (
                            <img
                              src={item?.user?.roleWiseProfileData?.user_image}
                              alt=""
                              className="feed-user"
                              onClick={() =>
                                handleImagePriview(
                                  item?.user?.roleWiseProfileData?.user_image
                                )
                              }
                            />
                          ) : (
                            <img
                              src="assets/image/dummy_image.png"
                              alt=""
                              className="feed-user"
                              onClick={() =>
                                handleImagePriview(
                                  "assets/image/dummy_image.png"
                                )
                              }
                            />
                          )}
                          <div className="ps-3 w-100">
                            <Link onClick={() => userProfile(item?.user?.id)}>
                              <h3 className="">
                                {item?.user?.name} {item?.user?.surname}{" "}
                              </h3>
                            </Link>

                            <div className="d-flex justify-content-between py-1 comment-date">
                              {item?.user?.role === 5 ? (
                                <span className="">
                                  {item?.user?.roleWiseProfileData?.occupation}
                                </span>
                              ) : item?.user?.role === 2 ? (
                                <span className="">
                                  {item?.user?.roleWiseProfileData?.agency_name}
                                </span>
                              ) : (
                                <span className="">
                                  {
                                    item?.user?.roleWiseProfileData
                                      ?.agency_profile?.agency_name
                                  }
                                </span>
                              )}

                              <span className="">{item?.CreatedAtCustom}</span>
                            </div>

                            <p
                              className="ck"
                              dangerouslySetInnerHTML={{ __html: item?.answer }}
                            ></p>

                            <div className="fs-13 medium d-flex justify-content-between row">
                              {Token === null ? (
                                <Link
                                  className="text-primary pe-3 col-6"
                                  onClick={NotLogin}
                                >
                                  Reply
                                </Link>
                              ) : (
                                <Link
                                  className="text-primary pe-3 col-6"
                                  onClick={() =>
                                    handleReply(item?.id, post?.id)
                                  }
                                >
                                  Reply
                                </Link>
                              )}
                              {replysubmitted &&
                              showReplyPostInputId === item?.id ? (
                                replySubmittedResponse.length > 0 ? (
                                  <span
                                    className="align-items-center col-6"
                                    style={{ textAlign: "center" }}
                                  >
                                    {replySubmittedResponse.length} Reply
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : item?.answer_reply.length > 0 ? (
                                <span className="col-6">
                                  {item?.answer_reply.length} Reply
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            {replyInput && showReplyInputId === item?.id ? (
                              <div className="feed-comments">
                                <div className="row hidden">
                                  <div className="col-12">
                                    <form
                                      className="ans-save"
                                      onSubmit={handleReplySubmit}
                                    >
                                      <div className="row">
                                        <div className="col-10">
                                          {/* <textarea
                                                                                        name="answer"
                                                                                        className="form-control autoresizing"
                                                                                        style={{ height: '39px' }}
                                                                                        value={replyText}
                                                                                        onChange={(e) => reply(e)}
                                                                                    ></textarea> */}

                                          <CKEditor
                                            data={replyText}
                                            className="editor"
                                            cols="30"
                                            rows="10"
                                            onChange={(event, editor) => {
                                              reply(editor.getData());
                                            }}
                                            editor={ClassicEditor}
                                            config={{
                                              toolbar: [
                                                "heading",
                                                "|",
                                                "bold",
                                                "italic",
                                                "blockQuote",
                                                "link",
                                                "numberedList",
                                                "bulletedList",
                                                "|",
                                                "undo",
                                                "redo",
                                              ],
                                            }}
                                          />

                                          <p
                                            style={{
                                              color: "red",
                                              margintop: "-10px",
                                            }}
                                          >
                                            {replyTextError}
                                          </p>
                                        </div>
                                        <div className="col-2">
                                          {loading ? (
                                            <button
                                              type="submit"
                                              className="border-btn btn"
                                              disabled={loading ? true : false}
                                            >
                                              <span className="spinner-grow spinner-grow-sm"></span>
                                            </button>
                                          ) : (
                                            <button
                                              type="submit"
                                              className="send-btn"
                                              disabled={loading ? true : false}
                                            >
                                              Send
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {replysubmitted && showReplyInputId === item?.id
                          ? replySubmittedResponse.map((replyItem, i) => {
                              return i < replyLimit ? (
                                <div className="reply-comment comment d-flex align-items-start w-100 pb-4">
                                  {replyItem?.user?.roleWiseProfileData
                                    ?.user_image === null ? (
                                    <img
                                      src="assets/image/dummy_image.png"
                                      alt=""
                                      className="feed-user"
                                      onClick={() =>
                                        handleImagePriview(
                                          "assets/image/dummy_image.png"
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={
                                        replyItem?.user?.roleWiseProfileData
                                          ?.user_image
                                      }
                                      alt=""
                                      className="feed-user"
                                      onClick={() =>
                                        handleImagePriview(
                                          replyItem?.user?.roleWiseProfileData
                                            ?.user_image
                                        )
                                      }
                                    />
                                  )}
                                  <div className="ps-3 w-100">
                                    <Link
                                      onClick={() =>
                                        userProfile(replyItem.user?.id)
                                      }
                                    >
                                      <h3 className="">
                                        {replyItem.user.name}{" "}
                                        {replyItem.user.surname}{" "}
                                      </h3>
                                    </Link>
                                    <div className="d-flex justify-content-between py-1 comment-date">
                                      {replyItem?.user?.role === 5 ? (
                                        <span className="">
                                          {
                                            replyItem?.user?.roleWiseProfileData
                                              ?.occupation
                                          }
                                        </span>
                                      ) : replyItem?.user?.role === 2 ? (
                                        <span className="">
                                          {
                                            replyItem?.user?.roleWiseProfileData
                                              ?.agency_name
                                          }
                                        </span>
                                      ) : (
                                        <span className="">
                                          {
                                            replyItem?.user?.roleWiseProfileData
                                              ?.agency_profile?.agency_name
                                          }
                                        </span>
                                      )}

                                      <span className="">
                                        {replyItem?.CreatedAtCustom}
                                      </span>
                                    </div>

                                    <p
                                      className="ck"
                                      dangerouslySetInnerHTML={{
                                        __html: replyItem?.answer,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              ) : (
                                ""
                              );
                            })
                          : item?.answer_reply.map((replyItem, i) => {
                              return i < replyLimit ? (
                                <div className="reply-comment comment d-flex align-items-start w-100 pb-4">
                                  {replyItem?.user?.roleWiseProfileData
                                    ?.user_image === null ? (
                                    <img
                                      src="assets/image/dummy_image.png"
                                      alt=""
                                      className="feed-user"
                                      onClick={() =>
                                        handleImagePriview(
                                          "assets/image/dummy_image.png"
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={
                                        replyItem?.user?.roleWiseProfileData
                                          ?.user_image
                                      }
                                      alt=""
                                      className="feed-user"
                                      onClick={() =>
                                        handleImagePriview(
                                          replyItem?.user?.roleWiseProfileData
                                            ?.user_image
                                        )
                                      }
                                    />
                                  )}
                                  <div className="ps-3 w-100">
                                    <Link
                                      onClick={() =>
                                        userProfile(replyItem.user?.id)
                                      }
                                    >
                                      <h3 className="">
                                        {replyItem.user.name}{" "}
                                        {replyItem.user.surname}{" "}
                                      </h3>
                                    </Link>
                                    <div className="d-flex justify-content-between py-1 comment-date">
                                      {replyItem?.user?.role === 5 ? (
                                        <span className="">
                                          {
                                            replyItem?.user?.roleWiseProfileData
                                              ?.occupation
                                          }
                                        </span>
                                      ) : replyItem?.user?.role === 2 ? (
                                        <span className="">
                                          {
                                            replyItem?.user?.roleWiseProfileData
                                              ?.agency_name
                                          }
                                        </span>
                                      ) : (
                                        <span className="">
                                          {
                                            replyItem?.user?.roleWiseProfileData
                                              ?.agency_profile?.agency_name
                                          }
                                        </span>
                                      )}
                                      <span className="">
                                        {replyItem?.CreatedAtCustom}
                                      </span>
                                    </div>

                                    <p
                                      className="ck"
                                      dangerouslySetInnerHTML={{
                                        __html: replyItem?.answer,
                                      }}
                                    >
                                      {replyItem?.answer}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                ""
                              );
                            })}

                        {replysubmitted && showReplyPostInputId === item?.id ? (
                          replySubmittedResponse.length > 1 &&
                          replyLimit < replySubmittedResponse.length ? (
                            <div className="reply-comment pb-4">
                              <Link
                                className="medium fs-13"
                                onClick={() =>
                                  handleViewAllReply(
                                    replySubmittedResponse.length
                                  )
                                }
                              >
                                View all reply
                              </Link>
                            </div>
                          ) : (
                            ""
                          )
                        ) : item?.answer_reply.length > 1 &&
                          replyLimit < item?.answer_reply.length ? (
                          <div className="reply-comment pb-4">
                            <Link
                              className="medium fs-13"
                              onClick={() =>
                                handleViewAllReply(item?.answer_reply.length)
                              }
                            >
                              View all reply
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <div class="modal" id="myModalrep">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Post Report</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <form class="report-save"> */}

            <div class="modal-body">
              <label>Reason</label>
              <select
                class="form-control"
                onChange={(e) => handleReportReason(e)}
                name="reason"
                require
              >
                <option
                  value="Please select reason"
                  selected={reportReason === "" ? "selected" : ""}
                >
                  Please select reason
                </option>
                <option value="Post is offensive">Post is offensive</option>
                <option value="Post violates community guidelines">
                  Post violates community guidelines
                </option>
                <option value="Harassment">Harassment</option>
                <option value="Spam">Spam</option>
                <option value="Hate Speech">Hate Speech</option>
                <option value="Nudity">Nudity</option>
                <option value="Violence">Violence</option>
              </select>
              <p style={{ color: "red", margintop: "-10px" }}>
                {reportReasonError}
              </p>
              <br />
              <label>Report</label>
              <textarea
                class="form-control"
                name="report"
                id="report_text"
                rows="5"
                require
                value={reportDescription}
                onChange={(e) => setReportDescription(e.target.value)}
              ></textarea>
              <p style={{ color: "red", margintop: "-10px" }}>
                {reportDescriptionError}
              </p>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {loading ? (
                <button type="submit" class="btn btn-success" disabled>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </button>
              ) : (
                <button
                  type="submit"
                  class="btn btn-success"
                  onClick={submitReport}
                  disabled={loading ? true : false}
                >
                  Submit{" "}
                </button>
              )}
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      <div class="modal fade" id="myModalpop">
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content image-pop"
            style={{ background: "transparent", border: "none" }}
          >
            <button
              type="button"
              class="close text-right close-btn popup_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ color: "red", fontWeight: 900 }}
            >
              &times;
            </button>
            <img
              src={imagePriview}
              class="popup_image"
              style={{ height: "90%", width: "90%" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
