import React, { useState } from 'react'
import SecondFooter from '../layouts/SecondFooter'
import SecondHeader from '../layouts/SecondHeader'
import { useHistory, Link } from "react-router-dom";
import { RegisterAgency } from "../API/AuthApi";
import swal from "sweetalert";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export default function AgencyRegister() {
    const history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password_confirmation, setPasswordConfirmation] = useState("")
    const [agency_name, setAgencyName] = useState("")
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [image_logo, setImageLogo] = useState("")
    const [image_documents, setImageDocuments] = useState([])
    const [emailerror, setEmailError] = useState("");
    const [passworderror, setpasswordError] = useState("");
    const [password_confirmationerror, setPasswordConfirmationError] = useState("")
    const [agency_nameerror, setAgencyNameError] = useState("")
    const [phoneerror, setphoneError] = useState("")
    const [cityerror, setcityError] = useState("")
    const [documentError, setDocumentError] = useState("")
    const [imageError, setImageError] = useState("")
    const [profileImage, setProfileImage] = useState("")
    const [profileImageError, setProfileImageError] = useState("")

    const [principalFccNo, setPrincipalFccNo] = useState(1)
    const [principalIdNo, setPrincipalIdNo] = useState(1)
    const [agencyFccNo, setAgencyFccNo] = useState(1)
    const [isRegisteredWithPpra, setIsRegisteredWithPpra] = useState("1")
    const [firstNameError, setFirstNameError] = useState("")
    const [lastNameError, setLaststNameError] = useState("")

    const [principalFccNoerror, setPrincipalFccNoerror] = useState("")
    const [principalIdNoerror, setPrincipalIdNoerror] = useState("")
    const [agencyFccNoerror, setAgencyFccNoerror] = useState("")
    const [isRegisteredWithPpraerror, setIsRegisteredWithPpraerror] = useState("")

    const [loading, setLoading] = useState(false)
    const [password_hidden, setPasswordHidden] = useState(true);
    const [confirm_password_hidden, setConfirmPasswordHidden] = useState(true);
    const handleImage = (e) => {
        setImageLogo({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };
    const handleProfileImage = (e) => {
        setProfileImage({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };

    const handleDocument = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        setImageDocuments([...image_documents, ...chosenFiles]);
        // setImageDocuments(chosenFiles);
    };

    

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email) {
            setEmailError("Please enter your email address");
            setLoading(false);
        }
        if (!first_name) {
            setFirstNameError("Please enter first name");
            setLoading(false);
        }
        if (!last_name) {
            setLaststNameError("Please enter last name");
            setLoading(false);
        }

        if (!password) {
            setpasswordError("Please enter your password");
            setLoading(false);
        }
        if (!password_confirmation) {
            setPasswordConfirmationError("Please enter your confirm password");
            setLoading(false);
        }
        if (password !== password_confirmation) {
            setPasswordConfirmationError("Password and confirm password don't match");
            setLoading(false);
        }

        if (!agency_name) {
            setAgencyNameError("Please enter your agency name");
            setLoading(false);
        }
        if (!city) {
            setcityError("Please enter your city");
            setLoading(false);
        }

        // if (!isRegisteredWithPpra) {
        //     setIsRegisteredWithPpraerror("Please Select Regitration with PPRA");
        //     setLoading(false);
        // }

        // if (!agencyFccNo) {
        //     setAgencyFccNoerror("Please enter your agency fcc no");
        //     setLoading(false);
        // }

        // if (!principalIdNo) {
        //     setPrincipalIdNoerror("Please enter your principal id no");
        //     setLoading(false);
        // }

        // if (!principalFccNo) {
        //     setPrincipalFccNoerror("Please enter your principal fcc Number");
        //     setLoading(false);
        // }
        // if (image_documents.length === 0) {
        //     setDocumentError("Please upload document");
        //     setLoading(false);
        // }
        if (!image_logo) {
            setImageError("Please add Agency logo");
            setLoading(false);
        }
        if (!profileImage) {
            setProfileImageError("Please add profile image");
            setLoading(false);
        }

        
        if (!phone) {
            setphoneError("Please enter your phone number");
            setLoading(false);
        }
        if (email 
            && password 
            && password_confirmation 
            && agency_name 
            && city 
            && phone 
            && password === password_confirmation 
            && image_logo
            // && image_documents.length > 0 
            // && isRegisteredWithPpra
            // && agencyFccNo
            // && principalIdNo
            // && principalFccNo
            && profileImage
            ) {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            formData.append("password_confirmation", password_confirmation);
            formData.append("agency_name", agency_name);
            formData.append("first_name", first_name);
            formData.append("last_name", last_name);
            formData.append("phone", phone);
            formData.append("city", city);

            formData.append("is_registered_with_ppra", isRegisteredWithPpra);
            formData.append("agency_fcc_no", agencyFccNo);
            formData.append("principal_id_no", principalIdNo);
            formData.append("principal_fcc_no", principalFccNo);
            if(image_logo){
                formData.append("image_logo", image_logo.fileBytes);
            }
            if(profileImage){
                formData.append("image", profileImage.fileBytes);
            }
            // if(image_documents){
            //     for (var i = 0; i < image_documents.length; i++) {
            //     formData.append("image_documents[]", image_documents[i]);
            // }
            // }

            RegisterAgency(formData).then((res)=>{
                setLoading(false);
                if(res.status === 200){
                swal("Success", res.data.message, "success");
                setLoading(false);
                history.push('/login')   
                }
                if(res.status === 201){
                    console.log(res.data.message)
                    if(res.data.message.email){
                    swal("Error", res.data.message.email[0], "error");
                    setLoading(false);
                    }else if (res.data.message.image_documents || res.data.message.image_logo) {
                        swal("Error","Please upload valid document ", "error");
                        setLoading(false);
                    } 
                    else{
                        setLoading(false);
                        swal("Error", "somethin went wrong", "error");  
                    }
                }
            });


        }


    };
    const information = () =>{
        swal("","As part of the registration process, we require supporting documents to vet all Real Estate Agencies in South Africa. Your documents will be stored in compliance with the POPI Act and will not be shared with any 3rd party", "info");  
       
    };

    return (
        <>
            <div className="wrapper  login-wrapper">
             <SecondHeader />
                <div className="main bg-transparent border-0">
                    <div className="container pt-4 ">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 ">
                                <div className="signup-form p-4 text-center bg-white">
                                    <h3 className="h3 bold pb-4">Sign Up</h3>
                                    <form onSubmit={handleSubmit} className="site-form text-start">

                                        <div className="row pb-4">

                                            <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">Name of Agency</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={agency_name}
                                                onChange={(e) => { setAgencyName(e.target.value) }}
                                                 
                                                
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {agency_nameerror}
                                                </p>
                                            </div>


                                            <div className="form-field col-md-6">
                                                <label className="d-block medium fs-15">First Name of Principal</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={first_name}
                                                onChange={(e) => { setFirstName(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {firstNameError}
                                                </p>
                                            </div>

                                            <div className="form-field col-md-6">
                                                <label className="d-block medium fs-15">Last Name of Principals</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={last_name}
                                                onChange={(e) => { setLastName(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {lastNameError}
                                                </p>
                                            </div>

                                            <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">Email Address of Principal</label>
                                                <input type="email" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {emailerror}
                                                </p>
                                            </div>

                                            <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">Phone Number</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={phone}
                                                onChange={(e) => { setPhone(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {phoneerror}
                                                </p>
                                            </div>

                                            <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">City</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={city}
                                                onChange={(e) => { setCity(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {cityerror}
                                                </p>
                                            </div>

                                            {/* <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">Principal FFC Number</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={principalFccNo}
                                                onChange={(e) => { setPrincipalFccNo(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {principalFccNoerror}
                                                </p>
                                            </div> */}
{/*                                             
                                            <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">Agency FFC Number</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={agencyFccNo}
                                                onChange={(e) => { setAgencyFccNo(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {agencyFccNoerror}
                                                </p>
                                            </div> */}


                                            {/* <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">ID/PASSPORT number of Principal</label>
                                                <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={principalIdNo}
                                                onChange={(e) => { setPrincipalIdNo(e.target.value) }}
                                                 
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {principalIdNoerror}
                                                </p>
                                            </div> */}

                                            {/* <div className="form-field col-md-12">
                                                <label className="d-block medium fs-15">Is your Agency registered with the PPRA</label>
                                                <select className="w-100 form-control select"  onChange={(e) => { setIsRegisteredWithPpra(e.target.value) }} >
                                                    <option value={''}>--Select--</option>
                                                    <option value={'1'}>Yes</option>
                                                    <option value={'0'}>No</option>
                                                </select>
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {isRegisteredWithPpraerror}
                                                </p>
                                            </div> */}

                                            {/* this belwo input box was inside but it was already commented */}
                                                {/* <input type="text" 
                                                className="w-100" 
                                                placeholder="" 
                                                value={isRegisteredWithPpra}
                                                onChange={(e) => { setIsRegisteredWithPpra(e.target.value) }}
                                                required 
                                                /> */}
                                            {/* --------------------////////---------------- */}

                                            <div className="form-field col-md-6">
                                                <label className="d-block medium fs-15">Password</label>
                                                <div className='d-flex align-items-center' style={{position : 'relative'}}>
                                                    <input type={password_hidden ? "password" : "text"}
                                                    className="w-100" 
                                                    placeholder="" 
                                                    value={password}
                                                    onChange={(e) => { setPassword(e.target.value) }}
                                                    style={{paddingRight : 40}}
                                                    
                                                    />
                                                    <div style={{
                                                        cursor : 'pointer', 
                                                        position : 'absolute', 
                                                        right : 10
                                                    }} 
                                                    onClick={()=>setPasswordHidden(!password_hidden)}
                                                    >
                                                        {
                                                            password_hidden ? 
                                                            <AiFillEyeInvisible size={25}/>
                                                            :
                                                            <AiFillEye size={25}/>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {passworderror}
                                                </p>
                                            </div>
                                            <div className="form-field col-md-6">
                                                <label className="d-block medium fs-15">Confirm Password</label>
                                                <div className='d-flex align-items-center' style={{position : 'relative'}}>

                                                <input type={confirm_password_hidden ? "password" : "text"}
                                                className="w-100" 
                                                placeholder="" 
                                                value={password_confirmation}
                                                onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                                                 style={{paddingRight : 40}}
                                                />
                                                <div style={{
                                                    cursor : 'pointer', 
                                                    position : 'absolute', 
                                                    right : 10
                                                }} 
                                                onClick={()=>setConfirmPasswordHidden(!confirm_password_hidden)}
                                                >
                                                    {
                                                        confirm_password_hidden ? 
                                                        <AiFillEyeInvisible size={25}/>
                                                        :
                                                        <AiFillEye size={25}/>
                                                    }
                                                </div>
                                                </div>
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {password_confirmationerror}
                                                </p>
                                            </div>


                                            <div className="form-field col-md-12 singup-photo">
                                                <input type="file" 
                                                id="sign-profilepic" 
                                                onChange={(e)=>handleImage(e)}
                                                accept="image/*"
                                                 
                                                />
                                                <label for="sign-profilepic" className="d-flex align-items-center">

                                                {image_logo.fileUrl ? (
                                                    <span>
                                                    <img
                                                        src={image_logo.fileUrl}
                                                        height="170px"
                                                        width="100%"
                                                        className="image-priview"
                                                        
                                                    />
                                                    </span>
                                                ) : (
                                                    <span><i className="far fa-image"></i></span>
                                                )}
                                                    
                                                    Add agency logo
                                                </label>
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {imageError}
                                                </p>
                                                
                                            </div>
                                            <div className="form-field col-md-12 singup-photo">
                                                <input type="file" 
                                                id="sign-profilePhoto" 
                                                onChange={(e)=>handleProfileImage(e)}
                                                accept="image/*"
                                                 
                                                />
                                                <label for="sign-profilePhoto" className="d-flex align-items-center">

                                                {profileImage.fileUrl ? (
                                                    <span>
                                                    <img
                                                        src={profileImage.fileUrl}
                                                        height="170px"
                                                        width="100%"
                                                        className="image-priview"
                                                        
                                                    />
                                                    </span>
                                                ) : (
                                                    <span><i className="far fa-image"></i></span>
                                                )}
                                                    
                                                    Add Profile Photo
                                                </label>
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {profileImageError}
                                                </p>
                                                
                                            </div>

                                            {/* <div className="form-field col-md-12 doc-uploading">
                                                <input type="file" 
                                                id="doc-upload" 
                                                className="d-none" 
                                                multiple 
                                                onChange={(e)=>handleDocument(e)}
                                                 
                                                />

                                                
                                                <label for="doc-upload" className="d-flex align-items-center">
                                                    <span><img src="assets/image/upload.png" className="align-middle" alt="" /></span>
                                                    Upload Documents
                                                </label>
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {documentError}
                                                </p>
                                            </div> */}
                                            {/* {image_documents.length > 0 ?
                                                 <label for="doc-upload" className="d-flex align-items-center">
                                                    <div className="row">
                                                    {image_documents.map((item, i) => {

                                                    return (
                                                        <>
                                                        <div className="col-md-12">{item.name}
                                                        </div>
                                                         <br />
                                                         </>
                                                       
                                                        );
                                                    })}
                                                    </div>
                                                </label>
                                                :
                                                ""
                                                } */}
                                              

                                            {/* <div className="form-text w-100 row d-flex">
                                                <p className="fs-13 text-gray col-md-11">
                                                    Upload the following documents:(PDF or Images format only and 10 MB per doc size) <br />  */}
                                                    {/* 1 Company Registration document (COR14.3)<br /> */}
                                                    {/* 1 ID/Passport Copy of Principal<br />
                                                    2 Fidelity Funds Certificate of Principal<br />
                                                    3 Fidelity Funds Certificate of Agency
                                                </p>
                                                <small 
                                                    className="pr-6  col-md-1" 
                                                    style={{fontSize:"20px", marginTop:"", color:"black"}} 
                                                    onClick={information}
                                                    >
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                </small>
                                                  
                                            </div> */}
                                            
                                            <div class="form-field col-md-12">

                                            </div>
                                            <div class="form-field col-md-12">
                                                <h6>
                                                    By signing up, you are giving us consent with your personal information and you
                                                    acknowledge that you have read and accepted our Privacy Policy.
                                                </h6>
                                            </div>

                                        </div>

                                        <div className="text-center">
                                        {loading ?
                                                <button type="submit" className="btn-sm" disabled={loading ? true : false}>
                                                    <span className='spinner-grow spinner-grow-sm '>

                                                    </span>
                                                </button>
                                            :
                                            <button className="btn-md" type="submit">Sign Up</button>
                                        }
                                        </div>

                                    </form>
                                </div>

                                <div className="text-center py-5">
                                    <p className="medium">Already have an account?<Link to="/login" className="text-primary bold">Log In</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             <SecondFooter />
            </div>
        </>
    )
}
