import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, THead, TH, TR, TD, TBody } from "../../table/index";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { GetAllAdmin, DeactiveAdmin } from '../../API/AdminApi'
import { GetAllAgent } from '../../API/AgentApi'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import { changeRole } from '../../API/AuthApi'
const $ = window.jQuery;


export class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allAdminList: [],
      allAgentList: [],
      isActive: "",
      userType: "",
      ChangeUserId: 0,
      AgentUserId: "",
      AgencyUserId: "",
      Reason: "",
      ReasonError: "",
      userTypeError: "",
      AdminAgentError: "",
      role:localStorage.getItem("role"),
      loading: false,
      buttonLoading: false,
    };
  }


  componentDidMount() {
    // $("#datatable").dataTable();
    this.getAdminList();
    this.getAgentList();
  }

  getAdminList = () => {
    this.setState({
      loading: true,
    });
    const token = localStorage.getItem("token");
    GetAllAdmin(token)
      .then((resp) => {
        this.setState({
          allAdminList: resp.data.data,
          loading: false,
        });
        $("#datatable").dataTable();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getAgentList = () => {
    const token = localStorage.getItem("token");
    GetAllAgent(token)
      .then((resp) => {
        this.setState({
          allAgentList: resp.data.data,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onEditAdmin = (id) => {
    this.props.history.push("edit-admin", { data: id });
  };

  onUserType = (type) => {
    this.setState({
      userType: type
    });
  };

  onSelectAdmin = (admin) => {

    this.setState({
      ChangeUserId: admin,
    });
  };


  onSelectAgent = (agent) => {
    this.setState({
      ChangeUserId: agent,
    });
  };

  onAgentReason = (reason) => {
    this.setState({
      Reason: reason,
    });
  };

  submitReason = () => {
    this.setState({
      buttonLoading: true,
    });
    let selectedUserId = "";
    if (!this.state.Reason) {
      this.setState({
        buttonLoading: false,
        ReasonError: "Reason Is Required",
      });
      return;
    }
    if (!this.state.userType) {
      this.setState({
        ReasonError: "",
        userTypeError: "User Type Is Required",
        buttonLoading: false,
      });
      return;
    }

    if (this.state.userType === "admin" || this.state.userType === "agent") {
      if (!this.state.ChangeUserId) {
        this.setState({
          ReasonError: "",
          userTypeError: "",
          AdminAgentError: "Please select " + this.state.userType,
          buttonLoading: false,
        });
        return;
      }
    }



    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    if (this.state.userType === 'self') {
      selectedUserId = user.id;
    } else if (this.state.userType === 'agency') {
      selectedUserId = this.state.AgencyUserId;
    } else if (this.state.userType === 'admin') {
      selectedUserId = this.state.ChangeUserId;
    } else if (this.state.userType === 'agent') {
      selectedUserId = this.state.ChangeUserId;
    }
    let data = {
      id: this.state.AgentUserId,
      is_active: 0,
      selected_user_id: selectedUserId,
      reason: this.state.Reason,

    }
    DeactiveAdmin(token, data).then((res) => {
      // console.log(res);
      if (res.data.type === "success") {
        this.setState({
          buttonLoading: false,
          userType: "",
          ChangeUserId: 0,
          AgentUserId: "",
          AgencyUserId: "",
          Reason: "",
        });
        $('#myModalrep').modal('hide');
        this.getAdminList();
        swal("Success", "Admin deactivated succesfully. ", "success");

      } else {
        this.setState({
          buttonLoading: false,
        });
        swal("Success", res.data.message, "success");
      }
    });


  };
   information = () =>{
    swal("","In this section, you can add, remove and edit admin. The admin will be able to add and remove real estate agents and approvers. The admin will also be able to purchase listing plans. The admin will be able edit listing.", "info");  
   
  };

  onChangeRole = (id, name)=>{
    swal({
      text: `Are you sure want to change the role of ${name} to Agent?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const token = localStorage.getItem("token");
        let data = {
          user_id:id, 
          change_role: 3
        }
        changeRole(data, token).then((res)=>{
          if(res.data.status === "success"){
            this.getAdminList();
            swal("success", res.data.message, "success")
          }
        });
      }
    });
  };

  

  onDeleteAdmin = (id, name, e, agencyID) => {

    if (e.target.value === '0') {
      swal({
        text: `Are you sure want to deactivate the ${name}?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const token = localStorage.getItem("token");
          let data = {
            id: id,
            is_active: 0
          }

          DeactiveAdmin(token, data).then((res) => {
            console.log(res);
            if (res.data.type === "success") {
              this.getAdminList();
              swal("Success", "Admin deactivated succesfully. ", "success");

            }
          });

        }



      });
    } else {
      swal({
        text: `Are you sure want to activate the ${name}?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {

          const token = localStorage.getItem("token");
          let data = {
            id: id,
            is_active: 1,
          }
          DeactiveAdmin(token, data).then((res) => {
            if (res.data.type === "success") {
              this.getAdminList();
              swal("Success", "Admin activated succesfully.", "success");

            }
          });

        }



      });
    }
  };

  render() {
    return (
      <>

        <Navbar />

        {this.state.loading ?
          <div className="main py-5">
            <center>
              <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

              </span>
            </center>
          </div>
          :
          <div className="main py-5">
            <div className="container ">

              <div className="row pb-md-5">

                <AgencyNavbar />

                <div className="col-md-9 ps-md-5 border-start admin-right-part">

                  <div className="d-flex align-items-center justify-content-between pb-4">
                    <h2 className="text-primary semibold h1 pb-3"> Admin
                    <small style={{fontSize:"20px", marginLeft:"35px", color:"black"}} data-toggle="tooltip" data-placement="right" onClick={this.information}><i class="fa fa-info-circle" aria-hidden="true"></i></small>
                    </h2>
                   
                    <Link to="/add-admin" className="custom-btn">Add New Admin</Link>
                  </div>

                  <div className="datatable">
                    <Table className="w-100" id="datatable">
                      <THead>
                        <TR>
                          <TH>S.No.</TH>
                          <TH>Image</TH>
                          <TH>Name</TH>
                          <TH>Email</TH>
                          <TH>Change role to agent</TH>
                          <TH>Edit</TH>
                          <TH>status</TH>
                        </TR>
                      </THead>
                      <TBody>
                        {this.state?.allAdminList.map((item, index) => {
                          return (
                            <TR key={item.id}>
                              <TD className="h5 semibold"><h5 className="h5 semibold" />{index+1}</TD>
                              <TD>
                              {item.image === null ?
                                <img src="assets/image/dummy_image.png" alt="" />
                              :
                                <img src={item.image} alt="" />
                              }
                              
                              </TD>
                              <TD className="h5 semibold"><h5 className="h5 semibold">{item.user.name} {item.user.surname} </h5></TD>
                              <TD><h6 className="fs-15 text-primary">{item.user.email}</h6></TD>
                              <TD className="table-action">
                                <Link className="px-2" onClick={() => this.onChangeRole(item.user_id, item.user.name)}>
                                  <i className="fa fa-edit"></i>
                                </Link>
                              </TD>
                              <TD className="table-action">
                                <Link className="px-2" onClick={() => this.onEditAdmin(item.id)}>
                                  <i className="fa fa-edit"></i>
                                  
                                  </Link>
                              </TD>
                              <TD>
                                <div className="form-check form-switch">
                                  {item.is_active === 1 ?
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value="0" checked autocompleted="" onChange={(e) => this.onDeleteAdmin(item.id, item.user.name, e)} />
                                    :

                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value="1" autocompleted="" onChange={(e) => this.onDeleteAdmin(item.id, item.user.name, e)} />
                                  }
                                </div>
                              </TD>
                            </TR>
                          );
                        })}
                      </TBody>
                    </Table>

                  </div>


                </div>
              </div>

            </div>
          </div>
        }

<div className="modal" id="myModalrep">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">


              <div className="modal-header">
                <h4 className="modal-title">Admin Deactive Reason</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {/* <form class="report-save"> */}

              <div className="modal-body row">
                <div className="col-md-12">
                  <label>
                    Reason
                  </label>
                  <textarea className="form-control"
                    name="report"
                    id="report_text"
                    rows="5"
                    require
                    value={this.state.Reason}
                    onChange={(e) => this.onAgentReason(e.target.value)}
                  ></textarea>
                  <p style={{ color: "red", margintop: "-10px" }}>
                    {this.state.ReasonError}
                  </p>
                </div>
                <div className="col-md-12 row">
                  <div className="col-md-12">
                    <label>
                      Select User Type
                    </label>
                  </div>
                  
                  <div className="col-md-3">
                    <input type="radio" id="Self" name="user_type" checked={this.state.userType === "self" ? true : false} onClick={() => this.onUserType("self")} />
                    &nbsp;
                    <label for="Self">Self</label>
                  </div>
                  {this.state.role === 6 ?
                  <div className="col-md-3">
                    <input type="radio" id="Agency" name="user_type" checked={this.state.userType === "agency" ? true : false} onClick={() => this.onUserType("agency")} />
                    &nbsp;
                    <label for="Agency"> Agency</label>
                  </div>
                  : ""}
                  <div className="col-md-3">
                    <input type="radio" id="Admin" name="user_type" checked={this.state.userType === "admin" ? true : false} onClick={() => this.onUserType("admin")} />
                    &nbsp;
                    <label for="Admin"> Admin</label>
                  </div>
                  <div className="col-md-3">
                    <input type="radio" id="Agent" name="user_type" checked={this.state.userType === "agent" ? true : false} onClick={() => this.onUserType("agent")} />
                    &nbsp;
                    <label for="Agent"> Agent</label>
                  </div>
                  <p style={{ color: "red", margintop: "-10px" }}>
                    {this.state.userTypeError}
                  </p>
                </div>
                {this.state.userType === "admin" ?
                  <div className="col-md-12">
                    <label>Admin</label>
                    <select className="form-control" onChange={(e) => this.onSelectAdmin(e.target.value)} >
                      <option value="">--Select--</option>
                      {this.state?.allAdminList.map((item, index) => {
                        return (
                          <option value={item?.user_id}>{item?.user?.name + ' ' + item?.user?.surname}</option>
                        );
                      })}

                    </select>
                    <p style={{ color: "red", margintop: "-10px" }}>
                      {this.state.AdminAgentError}

                    </p>
                  </div>
                  : ""}
                {this.state.userType === "agent" ?
                  <div className="col-md-12">
                    <label>Agent</label>
                    <select className="form-control" onChange={(e) => this.onSelectAgent(e.target.value)}>
                      <option value="">--Select--</option>
                      {this.state?.allAgentList.map((item, index) => {
                        return (
                          <option value={item?.user_id}>{item?.user?.name + ' ' + item?.user?.surname}</option>
                        );
                      })}

                    </select>
                    <p style={{ color: "red", margintop: "-10px" }}>
                      {this.state.AdminAgentError}

                    </p>
                  </div>
                  : ""}
              </div>


              <div class="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                {this.state.buttonLoading ?
                  <button type="submit" className="btn btn-success" disabled>
                    <span className='spinner-grow spinner-grow-sm'>

                    </span>
                  </button>

                  :
                  <button type="submit" className="btn btn-success"
                    onClick={() => this.submitReason()} disabled={this.state.buttonLoading ? true : false}

                  >Submit </button>
                }
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

}

export default withRouter(Index);