import React, { useState } from "react";
import { EmailSend } from "../../API/ForgotPasswordApi";
import { useLocation } from 'react-router-dom';
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import SecondFooter from '../../layouts/SecondFooter'
import SecondHeader from '../../layouts/SecondHeader'

export default function ChangePassword() {
    const history = useHistory();
    const location = useLocation();
    const email = location.state.email;
    const otp = location.state.otp;
    const [newpassword, setNewPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [newpassworderror, setNewPassworderror] = useState("");
    const [confirmpassworderror, setConfirmPassworderror] = useState("");
    const [samepassworderror, setSamepassworderror] = useState("");
    const [loading, setLoading] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (!newpassword) {
          setNewPassworderror("Please enter new password");
          setLoading(false);
        }
        if (!confirmpassword) {
          setConfirmPassworderror("Please enter confirm password");
          setLoading(false);
        }
    
        if (newpassword !== confirmpassword) {
          setSamepassworderror("Passwords and confirm password must be same.");
          setLoading(false);
        }
    
        if (newpassword !== confirmpassword) {
          setNewPassworderror('');
          setConfirmPassworderror('');
          swal("Password Not Match!", "Password and Confirm Password must be match!", "error");
          setLoading(false);
        }
    
        if (newpassword && confirmpassword && newpassword === confirmpassword) {
          setNewPassworderror("");
          setConfirmPassworderror("");
          setSamepassworderror("");
          var body = {
            email:email,
            stage:"change",
            password:newpassword,
            password_confirmation:confirmpassword,
            otp_code:otp
          };
          EmailSend(body)
            .then((res) => {
              setLoading(false);
              if (res.data.status === "true") {
    
                swal({
                  title: res.data.message,
                  icon: "success",
                  button: "OK",
                });
                history.push("/login");
              } else {
                swal({
                  title: res.data.message,
                  icon: "error",
                  button: "OK",
                });
    
              }
            })
            .catch((e) => {
              setLoading(false);
              swal("Error!", "Something Went Wrong!", "error");
            });
        }
      };

    return (
        <>
            <div className="wrapper  login-wrapper">
                <SecondHeader />
                <div className="main bg-transparent border-0">
                    <div className="container pt-4 pb-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 mb-5 ">
                                <div className="forgot-pwd p-4 text-center bg-white">
                                    <h3 className="h3 bold pb-2">Create New Password</h3>

                                    <img src="assets/image/forgot3.png" alt="" />

                                    <form onSubmit={handleSubmit} className="forgot-step">
                                        <div className="form-field text-start pb-4">
                                            <label for="" className="d-block pb-2 medium">New Password</label>
                                            <input type="password" className="w-100"
                                             onChange={(e) => setNewPassword(e.target.value)}
                                             />
                                             <p style={{ color: "red", marginBottom: "10px" }}>{newpassworderror}</p>
                                        </div>

                                        <div className="form-field text-start pb-4">
                                            <label for="" className="d-block pb-2 medium">Confirm Password</label>
                                            <input type="password" 
                                              className="w-100" 
                                              onChange={(e) => setConfirmPassword(e.target.value)}
                                              />
                                              <p style={{ color: "red", marginBottom: "10px" }}>{confirmpassworderror}</p>
                                              <p style={{ color: "red", marginBottom: "10px" }}>{samepassworderror}</p>
                              
                                        </div>

                                        {loading === true ?
                                            <Link
                                            className="btn-md"
                                                disabled={loading ? true : false}
                                            >
                                                <span classNameName='spinner-grow spinner-grow-sm'>

                                                </span>
                                            </Link>
                                            :
                                        <button type="submit" className="btn-md">Save</button>
                                        }   
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SecondFooter />
            </div>
        </>
    )
}
