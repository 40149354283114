import React from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";

export default function Terms() {
  return (
    <>
      <Navbar />
      <div class="main">
        <div class="container pt-md-5">
          <div class="row pt-4">
            <div class="col-md-12">
              <h3>
                <b>Terms of Service</b>
              </h3>
              <br />
            </div>
            <div class="col-md-12">
              <p>
                <strong>
                  <span>
                    An Introduction to Ask Property’s Terms of Service
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  Welcome to Ask Property! Here is a quick summary of the
                  highlights of our Terms of Service:
                </span>
              </p>
              <ul>
                <li>
                  <strong>
                    <em>
                      <span>
                        Our mission is to bring property investor together with
                        property practitioners
                      </span>
                    </em>
                  </strong>
                  <span>
                    . The Ask Property platform offers a place to ask questions
                    and connect with people who contribute unique insights and
                    quality answers. This empowers people to learn from each
                    other and to better understand the real estate industry.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>You own the content that you post</span>
                  </strong>
                  <span>
                    ; you also grant us and other users of the Ask Property
                    platform certain rights and license to use it. The details
                    of these licenses are described in section 3(c) below.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>
                      You are responsible for the content that you post
                    </span>
                  </strong>
                  <span>
                    . This includes ensuring that you have the rights needed for
                    you to post that content and that your content does not
                    violate the legal rights of another party or any applicable
                    laws.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>
                      You can repost content from Ask Property elsewhere
                    </span>
                  </strong>
                  <span>
                    , provided that you attribute the content back to the Ask
                    Property platform and respect the rights of the original
                    poster, including any “not for reproduction” designation,
                    and do not use automated tools.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>
                      We do not endorse or verify content posted by users
                    </span>
                  </strong>
                  <span>
                    . Our content and materials are provided to you “as is,”
                    without any guarantees. You are solely responsible for your
                    own use of the Ask Property platform. Posts from lawyers and
                    other professionals should not be treated as a substitute
                    for professional advice for your specific situation.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>You agree to follow the rules of our platform</span>
                  </strong>
                  <span>
                    . When you use the Ask Property platform, you also agree to
                    our Terms of Service, accept our{" "}
                    <em>
                      <u>
                        <span>Privacy Policy</span>
                      </u>
                    </em>
                    .
                  </span>
                </li>
                <li>
                  <strong>
                    <span>
                      We offer tools for you to give feedback and report
                      complaints
                    </span>
                  </strong>
                  <span>
                    . If you think someone has violated your intellectual
                    property rights, other laws, or Ask Property's policies, you
                    can initiate a report at
                  </span>
                  <a href="mailto:info@askpropety.co.za">
                    <span>info@askpropety.co.za</span>
                  </a>
                  <span>
                    . We are pleased that you want to join the Ask Property
                    platform and encourage you to read the full Terms of
                    Service.
                  </span>
                </li>
              </ul>
              <br />
              <p>
                <strong>
                  <span>Ask Property Terms of Service</span>
                </strong>
              </p>
              <p>
                <span>
                  Welcome to Ask Property! Ask Property is a platform to gain
                  and share knowledge, empowering people to learn from others
                  and better understand the property environment in South
                  Africa. Users in the community and agencies can now list
                  properties on the platform which will be visible to the public
                  and members of the community. All listings done on the Ask
                  Property platform are valid for 6 months unless removed by the
                  user sooner. All listing plans are also valid for 6 months
                  from date purchased.
                </span>
              </p>
              <p>
                <span>
                  These terms of service (“Terms of Service”) set forth the
                  agreement (“Agreement”) between you and Ask Property, Inc.
                  (“Ask Property” “we” or “us”). It governs your use of the
                  products and services we offer through our websites and
                  applications (collectively the “Ask Property Platform”).
                </span>
              </p>
              <p>
                <span>
                  Please make sure to read it, because, by using the Ask
                  Property Platform, you consent to these terms.
                </span>
              </p>
              <ol>
                <li>
                  <strong>
                    <span>
                      1. The Mission of the Ask Property Platform
                      <br />
                    </span>
                  </strong>
                  <span>
                    <br />{" "}
                    <strong>
                      <em>
                        Ask Property's mission is to build a community platform
                        where users can ask questions, learn from the answers,
                        share knowledge through advice and life experience and
                        finally list and sell properties.
                      </em>
                    </strong>
                    The Ask Property Platform is a place to ask questions and
                    connect with people who contribute unique insights and
                    quality answers. This empowers people to learn from each
                    other and to better understand the world.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>2. Using the Ask Property Platform</span>
                  </strong>
                  <ul>
                    <li>
                      <strong>
                        <span>Who Can Use It</span>
                      </strong>
                      <span>
                        . Use of the Ask Property Platform by anyone under 13
                        years of age is prohibited. You represent that you are
                        at least the age of majority in the jurisdiction where
                        you live or, if you are not, your parent or legal
                        guardian must consent to these Terms of Service and
                        affirm that they accept this Agreement on your behalf
                        and bear responsibility for your use. If you are
                        accepting these Terms of Service on behalf of someone
                        else or an entity, you confirm that you have the legal
                        authority to bind that person or entity to this
                        Agreement.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>Registration</span>
                      </strong>
                      <span>
                        . When you set up a profile on the Ask Property
                        Platform, you will be asked to provide certain
                        information about yourself. You agree to provide us
                        accurate information, including your real name or real
                        name of the entity you represent, when you create your
                        account on the Ask Property Platform. We will treat
                        information you provide as part of registration in
                        accordance with our{" "}
                        <em>
                          <u>
                            <span>Privacy Policy</span>
                          </u>
                        </em>
                        . You should take care in maintaining the
                        confidentiality of your password.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>Privacy Policy</span>
                      </strong>
                      <span>
                        . Our privacy practices are set forth in our{" "}
                        <em>
                          <u>
                            <span>Privacy Policy</span>
                          </u>
                        </em>
                        . By use of the Ask Property Platform, you agree to
                        accept our
                      </span>
                      <a href="https://www.quora.com/about/privacy">
                        <em>
                          <span>Privacy Policy</span>
                        </em>
                      </a>
                      <span>
                        , regardless of whether you are a registered user.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>Acceptable Use Policy</span>
                      </strong>
                      <span>
                        . In your interaction with others on the Ask Property
                        Platform, you agree to follow the{" "}
                        <em>
                          <u>
                            <span>Acceptable Use Policy</span>
                          </u>
                        </em>{" "}
                        at all times.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>Termination</span>
                      </strong>
                      <span>
                        . You may close your account at any time by going to
                        account settings and disabling your account. We may
                        terminate or suspend your Ask Property account if you
                        violate any Ask Property policy or for any other reason.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>Changes to the Ask Property Platform</span>
                      </strong>
                      <span>
                        . We are always trying to improve your experience on the
                        Ask Property Platform. We may need to add or change
                        features and may do so without notice to you.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>Feedback</span>
                      </strong>
                      <span>
                        . We welcome your feedback and suggestions about how to
                        improve the Ask Property Platform. Feel free to submit
                        feedback at{" "}
                        <u>
                          <span>info.askproperty.net</span>
                        </u>
                        . By submitting feedback, you agree to grant us the
                        right, at our discretion, to use, disclose and otherwise
                        exploit the feedback, in whole or part, freely and
                        without compensation to you.
                      </span>
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                <span></span>
              </p>
              <ol>
                <li>
                  <strong>
                    <span>3. Your Content</span>
                  </strong>
                  <ul>
                    <li>
                      <strong>
                        <span>Definition of Your Content</span>
                      </strong>
                      <span>
                        . The Ask Property Platform enables you to add posts,
                        texts, photos, videos, links, and other files and
                        information about yourself to share with others. All
                        material that you upload, publish or display to others
                        via the Ask Property Platform will be referred to
                        collectively as “Your Content.” You acknowledge and
                        agree that, as part of using the Ask Property Platform,
                        Your Content may be viewed by the general public.
                      </span>
                    </li>
                    <li>
                      <strong>
                        <span>
                          Ownership. You, or your licensors, as applicable,
                          retain ownership of the copyright and other
                          intellectual property in Your Content, subject to the
                          non-exclusive rights granted below.
                        </span>
                      </strong>
                    </li>
                    <li>
                      <strong>
                        <span>License and Permission to Use Your Content.</span>
                      </strong>
                    </li>
                  </ul>
                </li>
              </ol>
              <ul>
                <li>
                  <span>
                    By submitting, posting, or displaying Your Content on the
                    Ask Property Platform, you grant Ask Property and its
                    affiliated companies a nonexclusive, worldwide, royalty
                    free, fully paid up, transferable, sub licensable (through
                    multiple tiers), license to use, copy, reproduce, process,
                    adapt, modify, create derivative works from, publish,
                    transmit, store, display and distribute, translate,
                    communicate and make available to the public, and otherwise
                    use Your Content in connection with the operation or use of
                    the Ask Property Platform or the promotion, advertising or
                    marketing of the Ask Property Platform or our business
                    partners, in any and all media or distribution methods (now
                    known or later developed), including via means of automated
                    distribution, such as through an application programming
                    interface (also known as an “API”). You agree that this
                    license includes the right for Ask Property to make Your
                    Content available to other companies, organizations,
                    business partners, or individuals who collaborate with Ask
                    Property for the syndication, broadcast, communication and
                    making available to the public, distribution or publication
                    of Your Content on the Ask Property Platform or through
                    other media or distribution methods. This license also
                    includes the right for other users of the Ask Property
                    Platform to use, copy, reproduce, adapt, modify, create
                    derivative works from, publish, transmit, display, and
                    distribute, translate, communicate and make available to the
                    public Your Content, subject to our Terms of Service. Except
                    as expressly provided in these Terms of Service, this
                    license will not confer the right for you to use automated
                    technology to copy or post questions and answers or to
                    aggregate questions and answers for the purpose of making
                    derivative works. If you do not wish to allow your answers
                    to be translated by other users, you can globally opt out of
                    translation in your profile settings or you can designate
                    certain answers not for translation.
                  </span>
                </li>
                <li>
                  <span>
                    Once you post an answer to a question, you may edit or
                    delete your answer at any time from public display on{" "}
                    <u>
                      <span>https://www.www.askproperty.co.za</span>
                    </u>
                    , except in the case of anonymously posted answers. However,
                    we may not be able to control removal of the answer from
                    display on syndicated channels or other previously
                    distributed methods outside of{" "}
                    <u>
                      <span>https://www.www.askproperty.co.za</span>
                    </u>
                    . Ask Property may remove suspected spam from your answers.
                    Once you post a question, it may be edited or deleted by
                    other users or by Ask Property at any time. Any edits and
                    changes made by you may be visible to other users. The right
                    for Ask Property to copy, display, transmit, publish,
                    perform, distribute, store, modify, and otherwise use any
                    question you post, and sublicense those rights to others, is
                    perpetual and irrevocable, to the maximum extent permitted
                    by law, except as otherwise specified in this Agreement.
                  </span>
                </li>
                <li>
                  <span>
                    You acknowledge and agree that Ask Property may preserve
                    Your Content and may also disclose Your Content and related
                    information if required to do so by law or in the good faith
                    belief that such preservation or disclosure is reasonably
                    necessary to: (a) comply with legal process, applicable laws
                    or government requests; (b) enforce these Terms of Service;
                    (c) respond to claims that any of Your Content violates the
                    rights of third parties; (d) detect, prevent, or otherwise
                    address fraud, security or technical issues; or (e) protect
                    the rights, property, or personal safety of Ask Property,
                    its users, or the public.
                  </span>
                </li>
                <li>
                  <span>
                    You understand that we may modify, adapt, or create
                    derivative works from Your Content in order to transmit,
                    display or distribute it over computer networks, devices,
                    service providers, and in various media. We also may remove
                    or refuse to publish Your Content, in whole or part, at any
                    time.
                  </span>
                </li>
                <li>
                  <span>
                    You further give us the permission and authority to act as
                    your nonexclusive agent to take enforcement action against
                    any unauthorized use by third parties of any of Your Content
                    outside of the Ask Property Platform or in violation of our
                    Terms of Service.
                  </span>
                </li>
              </ul>
              <p>
                <span></span>
              </p>
              <ol>
                <ul>
                  <li>
                    <strong>
                      <span>Your Responsibilities for Your Content</span>
                    </strong>
                    <span>
                      . By posting Your Content on the Ask Property Platform,
                      you represent and warrant to us that: i) you have the
                      ownership rights, or you have obtained all necessary
                      licenses or permissions to use Your Content and grant us
                      the rights to use Your Content as provided for under this
                      Agreement, and ii) that posting Your Content violates no
                      intellectual property or personal right of others or any
                      applicable law or regulation, including any laws or
                      regulations requiring disclosure that you have been
                      compensated for Your Content. You accept full
                      responsibility for avoiding infringement of the
                      intellectual property or personal rights of others or
                      violation of laws and regulations in connection with Your
                      Content. You are responsible for ensuring that Your
                      Content does not violate Ask Property’s{" "}
                      <em>
                        <u>
                          <span>Acceptable Use Policy</span>
                        </u>
                      </em>
                      ,{" "}
                      <em>
                        <u>
                          <span>Copyright Policy</span>
                        </u>
                      </em>
                      ,{" "}
                      <em>
                        <u>
                          <span>Trademark Policy</span>
                        </u>
                        ,
                      </em>{" "}
                      other published Ask Property policy, or any applicable law
                      or regulation. You agree to pay all royalties, fees, and
                      any other monies owed to any person by reason of Your
                      Content.
                    </span>
                  </li>
                </ul>
              </ol>
              <p>
                <strong>
                  <span>4. Property Listing</span>
                </strong>
              </p>
              <ul>
                <li>
                  <span>
                    If you use the Ask Propertylisting service then, in addition
                    to the terms and conditions above, the following terms and
                    conditions apply.
                  </span>
                </li>
                <li>
                  <span>
                    In the following, "material" includes any text, photographs,
                    floor plans or other material prepared and submitted by any
                    third party service provider on your behalf.
                  </span>
                </li>
                <li>
                  <span>
                    Ask Property is not, and does not hold itself out to be:
                  </span>
                  <ul type="circle">
                    <li>
                      <span>
                        an estate agent generally, or an "estate agent" as
                        defined and contemplated in the Estate Agency Affairs
                        Act, 1976, as amended, or any replacement legislation,
                        in particular; or
                      </span>
                    </li>
                    <li>
                      <span>
                        an attorney, conveyancer, financial or tax advisor,
                        mortgage bond originator, or valuer.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    Ask Property has no power as agent or otherwise to negotiate
                    or conclude any agreement on your behalf, and no such power
                    must be inferred. You are liable for all obligations and
                    consequences howsoever arising from your negotiation or
                    conclusion of any agreement pursuant to the use of the
                    Listing Service. You are strongly urged to make detailed
                    enquiries about any party that you intend negotiating or
                    contracting with, and to obtain advice from a qualified
                    professional before concluding any contract.
                  </span>
                </li>
                <li>
                  <span>You use the Listing Service at your own risk.</span>
                </li>
                <li>
                  <span>
                    When using the Listing Service you may not submit any
                    material that:
                  </span>
                  <ul type="circle">
                    <li>
                      <span>is unlawful;</span>
                    </li>
                    <li>
                      <span>is harmful to minors;</span>
                    </li>
                    <li>
                      <span>is fraudulent, false or misleading;</span>
                    </li>
                    <li>
                      <span>
                        infringes any third party intellectual property,
                        personal, confidentiality, or other rights; or
                      </span>
                    </li>
                    <li>
                      <span>is defamatory or obscene.</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    You are solely responsible for all material that you submit
                    and any consequences that may result from your submission.
                    Ask Property has the right (but not the obligation), in its
                    sole discretion and without notice, to refuse or to delete
                    any material that Ask Property in its sole discretion
                    considers to be in breach of these terms and conditions.
                  </span>
                </li>
                <li>
                  <span>
                    You must submit all material in the format and detail
                    determined by Ask Property from time to time.
                  </span>
                </li>
                <li>
                  <span>
                    Ask Property has the right (but not the obligation), without
                    notice:
                  </span>
                  <ul type="circle">
                    <li>
                      <span>
                        to monitor and moderate all material submitted on the
                        Listing Service; and
                      </span>
                    </li>
                    <li>
                      <span>
                        to refuse or to restrict any user's use of the Listing
                        Service, temporarily or permanently.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>Ask Property:</span>
                  <ul type="circle">
                    <li>
                      <span>
                        is not responsible for the accuracy of any material that
                        is submitted; and
                      </span>
                    </li>
                    <li>
                      <span>
                        does not endorse any material submitted or product
                        listed, and no such endorsement must be inferred, or
                        claimed by you.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    You grant Ask Property a perpetual, royalty-free,
                    transferable right to publish all material submitted by you.
                  </span>
                </li>
                <li>
                  <span>
                    You must comply with all applicable laws, including (without
                    limitation) the Consumer Protection Act, 2008 (in
                    particular, sections 29 to 39 of that act) and the
                    Alienation of Land Act, 1981.
                  </span>
                </li>
                <li>
                  <span>
                    If you instruct Ask Property to contract with any third
                    party to prepare and submit material on your behalf, Ask
                    Property is not liable to you for any damages or costs
                    howsoever arising as a result of any act or omission on the
                    part of the third party, including (without limitation) any
                    deficiency in the material, or damage or loss incurred at
                    your premises when the third party renders the service; and
                    you indemnify Ask Property accordingly.
                  </span>
                </li>
              </ul>
              <p>
                <span></span>
              </p>
              <p>
                <strong>
                  <span>5 Our Content and Materials</span>
                </strong>
              </p>
              <ul>
                <li>
                  <strong>
                    <span>Definition of Our Content and Materials</span>
                  </strong>
                  <span>
                    . All intellectual property in or related to the Ask
                    Property Platform (specifically including, but not limited
                    to our software, the Ask Property marks, the Ask Property
                    logo, but excluding Your Content) is the property of Ask
                    Property, Inc., or its subsidiaries and affiliates, or
                    content posted by other Ask Property users licensed to us
                    (collectively “Our Content and Materials”).
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Data</span>
                  </strong>
                  <span>
                    . All data Ask Property collects (“Data”) about use of the
                    Ask Property Platform by you or others is the property of
                    Ask Property, Inc., its subsidiaries, and affiliates. For
                    clarity, Data does not include Your Content and is separate
                    from Our Content and Materials.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Our License to You.</span>
                  </strong>
                  <ul>
                    <li>
                      <span>
                        We grant you a personal, limited, non-exclusive license
                        to use and access Our Content and Materials and Data as
                        made available to you on the Ask Property Platform in
                        connection with your use of the Ask Property Platform,
                        subject to the terms and conditions of this Agreement.
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <span></span>
              </p>
              <ul>
                <li>
                  <span>
                    Ask Property gives you a worldwide, royalty-free, revocable,
                    personal, non-assignable and non-exclusive license to
                    re-post Our Content and Materials anywhere on the web,
                    subject to these Terms and Conditions and provided that: (a)
                    the content in question was added to the Ask Property
                    Platform after April 22, 2010; (b) the user who created the
                    content has not explicitly marked the content as not for
                    reproduction on the Ask Property Platform; (c) you do not
                    modify the content; (d) you attribute Ask Property by name
                    in readable text and with a human and machine-followable
                    link (an HTML &lt;a&gt; anchor tag) linking back to the page
                    displaying the original source of the content on{" "}
                    <u>
                      <span>https://www.askproperty.co.za</span>
                    </u>{" "}
                    on every page that contains Our Content and Materials; (e)
                    upon request, either by Ask Property or a user, you remove
                    the user's name from content which the user has subsequently
                    made anonymous; (f) upon request, either by Ask Property or
                    by a user who contributed to the content, you make a
                    reasonable effort to update a particular piece of content to
                    the latest version on the Ask Property Platform; and (g)
                    upon request, either by Ask Property or by a user who
                    contributed to the content, you make a reasonable attempt to
                    delete content that has been deleted or marked as not for
                    reproduction on the Ask Property Platform; (h) you don’t
                    republish more than a small portion of Our Content and
                    Materials; (i) you do not use any automated tools to
                    aggregate or create derivative works. In exercising these
                    rights, you may not implicitly or explicitly assert any
                    connection with, sponsorship or endorsement by Ask Property,
                    or any Ask Property user, without separate, express prior
                    written permission from us.
                  </span>
                </li>
                <li>
                  <span>
                    We may terminate our license to you at any time for any
                    reason. We have the right but not the obligation to refuse
                    to distribute any content on the Ask Property Platform or to
                    remove content. Except for the rights and license granted in
                    these Terms of Service, we reserve all other rights and
                    grant no other rights or licenses, implied or otherwise.
                  </span>
                </li>
              </ul>
              <div>
                <ul>
                  <li>
                    <strong>
                      <span>Permitted uses</span>
                    </strong>
                    <span>
                      . If you operate a search engine, web crawler, bot,
                      scraping tool, data mining tool, bulk downloading tool,
                      utility, or similar data gathering or extraction tool, you
                      may access the Ask Property Platform, subject to the
                      following additional rules: i) you must use a descriptive
                      user agent header; ii) you must follow robots.txt at all
                      times; iii) your access must not adversely affect any
                      aspect of the Ask Property Platform’s functioning; and iv)
                      you must make it clear how to contact you, either in your
                      user agent string, or on your website if you have one. You
                      represent and warrant that you will not use any automated
                      tools such as artificial intelligence or machine learning
                      i) to create derivative works of Our Content and
                      Materials; ii) to create any service competitive to the
                      Ask Property Platform; or iii) for other commercial
                      purposes except as expressly permitted by these Terms of
                      Service or the written consent of Ask Property.
                    </span>
                  </li>
                </ul>
              </div>
              <ol>
                <ul>
                  <li>
                    <strong>
                      <span>No Endorsement or Verification</span>
                    </strong>
                    <span>
                      . Please note that the Ask Property Platform contains
                      access to third-party content, products and services, and
                      it offers interactions with third parties. Participation
                      or availability on the Ask Property Platform does not
                      amount to endorsement or verification by us. We make no
                      warranties or representations about the accuracy,
                      completeness, or timeliness of any content posted on the
                      Ask Property Platform by anyone.
                    </span>
                  </li>
                  <li>
                    <strong>
                      <span>Ownership</span>
                    </strong>
                    <span>
                      . You acknowledge and agree that Our Content and Materials
                      remain the property of Ask Property's users or Ask
                      Property. The content, information and services made
                      available on the Ask Property Platform are protected by
                      SOUTH AFRICA and international copyright, trademark, and
                      other laws, and you acknowledge that these rights are
                      valid and enforceable.
                    </span>
                  </li>
                </ul>
              </ol>
              <p>
                <span></span>
              </p>
              <p>
                <strong>
                  <span>6 Integrated Service Provider</span>
                </strong>
                <span>
                  . You may enable another online service provider, such as a
                  social networking service (“Integrated Service Provider”), to
                  be directly integrated into your account on the Ask Property
                  Platform. By enabling an integrated service, you are allowing
                  us to pass to, and receive from, the Integrated Service
                  Provider your log-in information and other user data. For more
                  information about Ask Property’s use, storage, and disclosure
                  of information related to you and your use of integrated
                  services within Ask Property, please see our{" "}
                  <em>
                    <u>
                      <span> Privacy Policy</span>
                    </u>
                  </em>
                  . Note that your use of any Integrated Service Provider and
                  its own handling of your data and information is governed
                  solely by their terms of use, privacy policies, and other
                  policies.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    7 More About Certain Offerings on the Ask Property Platform
                  </span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>7.1 Digests</span>
                </strong>
                <span>
                  . When you sign up for the Ask Property Platform, the service
                  includes receipt of a digest of questions and answers that may
                  be of interest to you. You can opt out of receipt of the
                  digest, and adjust other communication settings, by going to
                  “Email and Notification” settings in your account profile, as
                  further described in our{" "}
                  <em>
                    <u>
                      <span>Privacy Policy</span>
                    </u>
                  </em>
                  .
                </span>
              </p>
              <p>
                <strong>
                  <span>7.2 Advertisements</span>
                </strong>
                <span>
                  . The Ask Property Platform may include advertisements, which
                  may be targeted to content or information on the Ask Property
                  Platform, queries made through the Ask Property Platform, or
                  other information, in an effort to make them relevant to you.
                  The types and extent of advertising by Ask Property are
                  subject to change. In consideration for Ask Property granting
                  you access to and use of the Ask Property Platform, you agree
                  that Ask Property and its third-party providers and partners
                  may place such advertising on the Ask Property Platform. If
                  you wish to become an advertiser, you will be required to
                  enter into separate and supplemental terms with us about
                  providing advertising services on the Ask Property Platform.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    7.3 Legal, Medical &amp; Other Professional Contributors
                  </span>
                </strong>
                <span>
                  . Some users who post content are members of legal, medical,
                  and other licensed professions (collectively, “Professional
                  Contributors”). Content posted by Professional Contributors
                  should not be relied on as a substitute for advice from a
                  professional that is appropriate for your specific situation.
                  Ask Property has provided certain disclaimer template language
                  that Professional Contributors may edit and incorporate in
                  their answers. Ethics rules differ by state or location, and
                  it is the responsibility of Professional Contributors to
                  determine and provide disclaimers appropriate for their
                  profession and the content provided.
                </span>
              </p>
              <p>
                <strong>
                  <span>7.4 Buttons, Links and Widgets</span>
                </strong>
                <span>
                  . You have permission to use Ask Property's buttons, links,
                  and widgets, subject to these Terms of Service (including the
                  disclaimers and limitations of liability) and provided that:
                  (a) your use of such buttons, links and widgets link only to
                  the Ask Property Platform; (b) you will not modify such
                  buttons, links, or widgets or associated code in any manner;
                  (c) you will not use any such buttons, links, or widgets in
                  any manner which implies or suggests that Ask Property
                  endorses, sponsors, or recommends the website on which such
                  buttons, links and widgets are used; and (d) the use of such
                  buttons, links and widgets, and the website on which such
                  buttons, links and widgets are used do not violate Ask
                  Property’s{" "}
                  <em>
                    <u>
                      <span>Acceptable Use Policy</span>
                    </u>
                  </em>
                  .
                </span>
              </p>
              <p>
                <strong>
                  <span>7.5 Web resources and third-party services</span>
                </strong>
                <span>
                  . The Ask Property Platform may also offer you the opportunity
                  to visit links to other websites or to engage with third-party
                  products or services. You assume all risk arising out of your
                  use of such websites or resources.
                </span>
              </p>
              <p>
                <strong>
                  <span>7.6 Services that Require Separate Agreement</span>
                </strong>
                <span>
                  . Certain features or services may require that you enter into
                  a separate and supplemental written agreement prior to use.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    8 Reporting Violations of Your Intellectual Property Rights,
                    Ask Property Policies, or Applicable Laws
                  </span>
                </strong>
                <span>
                  . We have a special process for reporting violations of your
                  intellectual property rights or other violations of Ask
                  Property policies or applicable laws.
                </span>
              </p>
              <p>
                <strong>
                  <span>8.1 Copyright Policy and Trademark Policy</span>
                </strong>
                <span>
                  . We have adopted and implemented a{" "}
                  <em>
                    <u>
                      <span>Copyright Policy</span>
                    </u>
                  </em>{" "}
                  and{" "}
                  <em>
                    <u>
                      <span>Trademark Policy</span>
                    </u>
                  </em>
                  . For more information, including detailed information about
                  how to submit a request for takedown if you believe content on
                  the Ask Property Platform infringes your intellectual property
                  rights, please read our{" "}
                  <em>
                    <u>
                      <span>Copyright Policy</span>
                    </u>
                  </em>{" "}
                  and{" "}
                  <em>
                    <u>
                      <span>Trademark Policy</span>
                    </u>
                  </em>
                  . For your convenience, we provide you the following{" "}
                  <em>
                    <u>
                      <span>Copyright Infringement Claim Form</span>
                    </u>
                  </em>{" "}
                  and{" "}
                  <em>
                    <u>
                      <span>Trademark Infringement Claim Form</span>
                    </u>
                  </em>
                  , which you should use, as applicable, for fastest processing.
                </span>
              </p>
              <p>
                <strong>
                  <span>8.2 Reports of Other Violations</span>
                </strong>
                <span>
                  . If you believe content on the Ask Property Platform violates
                  Ask Property’s{" "}
                  <em>
                    <u>
                      <span>Acceptable Use Policy</span>
                    </u>
                  </em>{" "}
                  or otherwise violates applicable law (apart from copyright or
                  trademark violations) or other Ask Property policies, you send
                  an email to
                </span>
                <a href="mailto:info.askproperty.net">
                  <span>info.askproperty.net</span>
                </a>
                <span>
                  and will take necessary action and get back to you within 7
                  days.
                </span>
              </p>
              <p>
                <span>
                  <br />
                  We have no obligation to delete content that you personally
                  may find objectionable or offensive. We endeavour to respond
                  promptly to requests for content removal, consistent with our
                  policies and applicable law.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    9 DISCLAIMERS AND LIMITATION OF LIABILITY
                    <br />
                  </span>
                </strong>
                <span>
                  <br />{" "}
                  <strong>
                    PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE
                    LIABILITY OF ASK PROPERTY ENTITIES TO YOU.
                    <br />
                  </strong>
                  <br />
                  “ASK PROPERTY ENTITIES” MEANS ASK PROPERTY SA AND ANY
                  SUBSIDIARIES, AFFILIATES, RELATED COMPANIES, SUPPLIERS,
                  LICENSORS AND PARTNERS, AND THE OFFICERS, DIRECTORS,
                  EMPLOYEES, AGENTS AND REPRESENTATIVES OF EACH OF THEM. EACH
                  PROVISION BELOW APPLIES TO THE MAXIMUM EXTENT PERMITTED UNDER
                  APPLICABLE LAW.
                </span>
              </p>
              <p>
                <span>
                  9.1 WE ARE PROVIDING YOU THE ASK PROPERTY PLATFORM, ALONG WITH
                  OUR CONTENT AND MATERIALS AND THE OPPORTUNITY TO CONNECT WITH
                  OTHERS, ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT
                  WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. WITHOUT LIMITING THE
                  FOREGOING, ASK PROPERTY ENTITIES EXPRESSLY DISCLAIM ANY AND
                  ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, TITLE,
                  ACCURACY AND COMPLETENESS, UNINTERRUPTED OR ERROR-FREE
                  SERVICE, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT,
                  NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
                  DEALING OR TRADE USAGE.
                </span>
              </p>
              <p>
                <span>
                  9.2 ASK PROPERTY MAKES NO PROMISES WITH RESPECT TO, AND
                  EXPRESSLY DISCLAIMS ALL LIABILITY FOR: (i) CONTENT POSTED BY
                  ANY USER OR THIRD PARTY; (ii) ANY THIRD-PARTY WEBSITE,
                  THIRD-PARTY PRODUCT, OR THIRD-PARTY SERVICE LISTED ON OR
                  ACCESSIBLE TO YOU THROUGH THE ASK PROPERTY PLATFORM, INCLUDING
                  AN INTEGRATED SERVICE PROVIDER OR PROFESSIONAL CONTRIBUTOR;
                  (iii) THE QUALITY OR CONDUCT OF ANY THIRD PARTY YOU ENCOUNTER
                  IN CONNECTION WITH YOUR USE OF THE ASK PROPERTY PLATFORM; OR
                  (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT.
                  ASK PROPERTY MAKES NO WARRANTY THAT: (a) THE ASK PROPERTY
                  PLATFORM WILL MEET YOUR REQUIREMENTS; (b) THE ASK PROPERTY
                  PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE;
                  (c) THE RESULTS OR INFORMATION THAT YOU MAY OBTAIN FROM THE
                  USE OF THE ASK PROPERTY PLATFORM, A PROFESSIONAL CONTRIBUTOR,
                  OR ANY OTHER USER WILL BE ACCURATE OR RELIABLE; OR (d) THE
                  QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
                  MATERIAL OBTAINED OR PURCHASED BY YOU THROUGH THE ASK PROPERTY
                  PLATFORM WILL BE SATISFACTORY.
                </span>
              </p>
              <p>
                <span>
                  9.3 YOU AGREE THAT TO THE MAXIMUM EXTENT PERMITTED BY LAW, ASK
                  PROPERTY ENTITIES WILL NOT BE LIABLE TO YOU UNDER ANY THEORY
                  OF LIABILITY. WITHOUT LIMITING THE FOREGOING, YOU AGREE THAT,
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, ASK PROPERTY ENTITIES
                  SPECIFICALLY WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                  CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES, LOSS OF PROFITS,
                  BUSINESS INTERRUPTION, REPUTATIONAL HARM, OR LOSS OF DATA
                  (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES OR SUCH DAMAGES ARE FORESEEABLE) ARISING OUT OF OR IN
                  ANY WAY CONNECTED WITH YOUR USE OF, OR INABILITY TO USE, THE
                  ASK PROPERTY PLATFORM.
                </span>
              </p>
              <p>
                <span>
                  9.4 YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE ASK PROPERTY
                  PLATFORM IS TO STOP USING THE ASK PROPERTY PLATFORM.
                </span>
              </p>
              <p>
                <span>
                  9.5 WITHOUT LIMITING THE FOREGOING, ASK PROPERTY’S MAXIMUM
                  AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU
                  SUFFER IN CONNECTION WITH THE ASK PROPERTY PLATFORM OR THIS
                  AGREEMENT IS LIMITED TO THE AMOUNT PAID TO ASK PROPERTY IN
                  CONNECTION WITH THE ASK PROPERTY PLATFORM IN THE TWELVE (12)
                  MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY.
                </span>
              </p>
              <p>
                <span>
                  9.6 SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
                  WARRANTIES OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF
                  DAMAGES. AS A RESULT, THE ABOVE LIMITATIONS OR EXCLUSIONS MAY
                  NOT APPLY TO YOU IN WHOLE OR IN PART, AND THE FOREGOING
                  SECTIONS 8(c), 8(d), AND 8(e) WILL NOT APPLY TO A RESIDENT OF
                  NEW JERSEY, TO THE EXTENT DAMAGES TO SUCH NEW JERSEY RESIDENT
                  ARE THE RESULT OF ASK PROPERTY’S NEGLIGENT, FRAUDULENT,
                  RECKLESS, OR INTENTIONAL MISCONDUCT.
                </span>
              </p>
              <p>
                <strong>
                  <span>
                    10 Indemnification
                    <br />
                  </span>
                </strong>
                <span>
                  <br />
                  You agree to release, indemnify, and defend Ask Property
                  Entities from all third-party claims and costs (including
                  reasonable attorneys’ fees) arising out of or related to: i)
                  your use of the Ask Property Platform, ii) Your Content, iii)
                  your conduct or interactions with other users of the Ask
                  Property Platform, or iv) your breach of any part of this
                  Agreement. We will promptly notify you of any such claim and
                  will provide you (at your expense) with reasonable assistance
                  in defending the claim. You will allow us to participate in
                  the defense and will not settle any such claim without our
                  prior written consent. We reserve the right, at our own
                  expense, to assume the exclusive defense of any matter
                  otherwise subject to indemnification by you. In that event,
                  you will have no further obligation to defend us in that
                  matter.
                </span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <strong>
                  <span>11 POPI Disclaimer.</span>
                </strong>
              </p>
              <p>
                <span>
                  We respect your right to privacy and therefore aim to ensure
                  that we comply with the legal requirement of the POPI Act
                  which regulates the manner in which we collect, process,
                  store, share and destroy any personal information which you
                  have provided to us.
                </span>
              </p>
              <p>
                <strong>
                  <span>Why we collect Personal Information:</span>
                </strong>
              </p>
              <p>
                <span>
                  We collect personal information from in order to liaise with
                  you via our website or email so that we may:
                </span>
              </p>
              <p>
                <span>
                  1. Respond to any query or comment received from you;
                </span>
              </p>
              <p>
                <span>2. Inform you of new offers;</span>
              </p>
              <p>
                <span>
                  3. Enable us to process, validate and verify information and
                  requests for the supply of services;
                </span>
              </p>
              <p>
                <span>
                  4. For the purposes for which you specifically provided the
                  information; and
                </span>
              </p>
              <p>
                <span>
                  5. Generally to improve your experience on our website.
                </span>
              </p>
              <p>
                <strong>
                  <span>Processing of Information</span>
                </strong>
              </p>
              <p>
                <span>We will share your personal information:</span>
              </p>
              <p>
                <span>
                  1. In order to comply with applicable law or with legal
                  process served on our company;
                </span>
              </p>
              <p>
                <span>
                  2. In order to protect and defend the rights or property of
                  our company; and
                </span>
              </p>
              <p>
                <strong>
                  <span>Security Measures</span>
                </strong>
              </p>
              <p>
                <span>We will:</span>
              </p>
              <p>
                <span>
                  1. Treat your personal information as strictly confidential;
                </span>
              </p>
              <p>
                <span>
                  2. Take appropriate technical and organisational measures to
                  ensure that your personal information is kept secure and is
                  protected against unauthorised or unlawful processing,
                  accidental loss, destruction or damage, alteration, disclosure
                  or access;
                </span>
              </p>
              <p>
                <span>
                  3. Promptly notify you if we become aware of any unauthorised
                  use, disclosure or processing of your personal information;
                </span>
              </p>
              <p>
                <span>
                  4. Provide you with reasonable evidence of our compliance with
                  our obligations under this policy on reasonable request; and
                </span>
              </p>
              <p>
                <span>
                  5. We will not retain your personal information longer than
                  the period for which it was originally required, unless we are
                  required by law to do so, or you consent to us retaining such
                  information for a longer period.
                </span>
              </p>
              <p>
                <span>
                  ALTHOUGH WE TAKE THE AFOREMENTIONED PRECAUTIONS IN PROTECTING
                  YOUR PERSONAL INFORMATION, WE SHALL HOWEVER NOT BE LIABLE FOR
                  ANY LOSS OR DAMAGE, HOWSOEVER ARISING, SUFFERED AS A RESULT OF
                  THE DISCLOSURE OF SUCH INFORMATION IF OUTSIDE OUR REASONABLE
                  CONTROL.
                </span>
              </p>
              <p>
                <strong>
                  <span>Your rights</span>
                </strong>
              </p>
              <p>
                <span>
                  You may exercise any of the rights described in this section
                  before your applicableAsk PropertyData Controller and Payments
                  Data Controller by sendingan email.Please note that we may ask
                  you to verify your identity before taking further action on
                  your request
                </span>
              </p>
              <p>
                <span>You have the right at any time to:</span>
              </p>
              <p>
                <span>
                  1. Rectify the Personal Information collected by us;
                </span>
              </p>
              <p>
                <span>
                  2. Object to the processing of Personal Information (subject
                  to legislation);
                </span>
              </p>
              <p>
                <span>
                  3. Request the return or destruction of Personal Information
                  (subject to legislation);
                </span>
              </p>
              <p>
                <span>4. Lodge a complaint with the company.</span>
              </p>
              <p>
                <span>
                  The Personal Information processed by us is not
                  routed/transferred to an International organisation.
                </span>
              </p>
              <p>
                <span>
                  For more information regarding your personal information
                  lawfully stored or used by Ask Property, please contact
                </span>
                <a href="mailto:admin@askproperty.co.za">
                  <span>admin@askproperty.co.za</span>
                </a>
                <span>
                  and we <span>will gladly assist.</span>
                </span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <strong>
                  <span>12 GeneralTerms</span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>12.1 Changes to these Terms of Service</span>
                </strong>
                <span>
                  . We may amend this Agreement (including any policies, such as
                  the{" "}
                  <em>
                    <u>
                      <span>Privacy Policy</span>
                    </u>
                  </em>
                  ,{" "}
                  <em>
                    <u>
                      <span>Acceptable Use Policy</span>
                    </u>
                  </em>
                  ,{" "}
                  <em>
                    <u>
                      <span>Copyright Policy</span>
                    </u>
                  </em>
                  , and{" "}
                  <em>
                    <u>
                      <span>Trademark Policy</span>
                    </u>
                  </em>{" "}
                  that are incorporated into this Agreement) at any time, in our
                  sole discretion. If we amend material terms to this Agreement,
                  such amendment will be effective after we send you notice of
                  the amended agreement. Such notice will be in our sole
                  discretion, and the manner of notification could include, for
                  example, via email, posted notice on the Ask Property
                  Platform, or other manner. You can view the Agreement and our
                  main policies at any time on{" "}
                </span>
                <a href="https://www.askproperty.co.za/terms">ASK PROPERTY</a>
                <span>
                  . Your failure to cancel your account, or cease use of the Ask
                  Property Platform, after receiving notification of the
                  amendment, will constitute your acceptance of the amended
                  terms. If you do not agree to the amendments or to any of the
                  terms in this Agreement, your only remedy is to cancel your
                  account or to cease use of the Ask Property Platform.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.2 Governing Law and Jurisdiction</span>
                </strong>
                <span>
                  . You agree that Ask Property is operated in South Africa.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.3 Use Outside ofSouth Africa</span>
                </strong>
                <span>
                  . Ask Property expressly disclaims any representation or
                  warranty that the Ask Property Platform complies with all
                  applicable laws and regulations outside ofSouth Africa. If you
                  use the Ask Property Platform outside ofSouth Africa, you
                  expressly understand and agree that you are responsible for
                  determining compliance with different laws, regulations, or
                  customs that may apply in connection with your use of the Ask
                  Property Platform.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.4 Export</span>
                </strong>
                <span>
                  . The Ask Property Platform is controlled and operated from
                  our South Africa, Cape town office. Ask Property software is
                  subject to South Africa export controls. No software for Ask
                  Property may be downloaded or otherwise exported or
                  re-exported in violation of any applicable laws or
                  regulations.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.5 Applications and Mobile Devices</span>
                </strong>
                <span>
                  . If you access the Ask Property Platform throughAsk Property
                  application, you acknowledge that this Agreement is between
                  you and Ask Property only, and not with another application
                  service provider or application platform provider (such as
                  Apple Inc. or Google Inc.), which may provide you the
                  application subject to its own terms. To the extent you access
                  the Ask Property Platform through a mobile device, your
                  wireless carrier’s standard charges, data rates, and other
                  fees may apply.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.6 Survival</span>
                </strong>
                <span>
                  . The following provisions will survive expiration or
                  termination of this Agreement: Section 2(e)(Termination),
                  2(g)(Feedback), Section 3(Your Content), Section 4(a)-(b) and
                  (d)-(f)(Our Content and Materials), Section 8 (Disclaimers and
                  Limitation of Liability), Section 9 (Indemnification),
                  Sections 10 (Dispute Resolution), and Section 11 (General
                  Terms).
                </span>
              </p>
              <p>
                <strong>
                  <span>12.7 Assignment</span>
                </strong>
                <span>
                  . You may not assign or transfer this Agreement (or any of
                  your rights or obligations under this Agreement) without our
                  prior written consent; any attempted assignment or transfer
                  without complying with the foregoing will be void. We may
                  freely assign or transfer this Agreement. This Agreement
                  inures to the benefit of and is binding upon the parties and
                  their respective legal representatives, successors, and
                  assigns.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.8 Electronic Communications</span>
                </strong>
                <span>
                  . You consent to receive communications from us by email in
                  accordance with this Agreement and applicable law. You
                  acknowledge and agree that all agreements, notices,
                  disclosures and other communications that we provide to you
                  electronically will satisfy any legal requirement that such
                  communications be in writing.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.9 Entire Agreement/ Severability</span>
                </strong>
                <span>
                  . This Agreement supersedes all prior terms, agreements,
                  discussions and writings regarding the Ask Property Platform
                  and constitutes the entire agreement between you and us
                  regarding the Ask Property Platform (except as to services
                  that require separate written agreement with us, in addition
                  to this Agreement). If any provision in this Agreement is
                  found to be unenforceable, then that provision will not affect
                  the enforceability of the remaining provisions of the
                  Agreement, which will remain in full force and effect.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.10 Interpretation</span>
                </strong>
                <span>
                  . In construing or interpreting the terms of this Agreement:
                  (i) the headings in this Agreement are for convenience only,
                  and are not to be considered, and (ii) no presumption is to
                  operate in either party’s favour as a result of its counsel’s
                  role in drafting this Agreement.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.11 Notices</span>
                </strong>
                <span>
                  . All notices permitted or required under this Agreement,
                  unless specified otherwise in this Agreement, must be sent in
                  writing as follows in order to be valid: (i) if to you, by us
                  via email to the address associated with your account, and
                  (ii) if to us, by you via
                </span>
                <a href="mailto:info.askproperty.net">
                  <span>info.askproperty.net</span>
                </a>
                <span>
                  . Notices will be deemed given (a) if to you, when emailed,
                  and (b) if to us, on receipt by us.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.12 Relationship</span>
                </strong>
                <span>
                  . This Agreement does not create a joint venture, agency,
                  partnership, or other form of joint enterprise between you and
                  us. Except as expressly provided herein, neither party has the
                  right, power, or authority to create any obligation or duty,
                  express or implied, on behalf of the other.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.13 Waiver</span>
                </strong>
                <span>
                  . No waiver of any terms will be deemed a further or
                  continuing waiver of such term or any other term. Our failure
                  to assert a right or provision under this Agreement will not
                  constitute a waiver of such right or provision.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.14 Further Assurances</span>
                </strong>
                <span>
                  . You agree to execute a hard copy of this Agreement and any
                  other documents, and to take any actions at our expense that
                  we may request to confirm and effect the intent of this
                  Agreement and any of your rights or obligations under this
                  Agreement.
                </span>
              </p>
              <p>
                <strong>
                  <span>12.15 Contact</span>
                </strong>
                <span>
                  . Feel free to contact us through{" "}
                  <u>
                    <span>info.askproperty.net</span>
                  </u>{" "}
                  with any questions about these terms.
                </span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <span></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
