import React, { useState } from 'react'
import { useHistory } from "react-router-dom";

import {
    CreateLikePost,
    CreatePostReport,
    CreatePostShare,

} from "../API/PostApi";
import { AnswerSave, AnswerLaterSave, AnswerReplySave, AnswerDelete } from "../API/AnswerApi";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const $ = window.jQuery;

export default function PostItem({ item, i, setImagePriview }) {
    const history = useHistory();
    const [likeStatus, setLikeStatus] = useState('')
    const [likeCount, setCount] = useState('')
    const [answerInput, setAnswerInput] = useState(false)
    const [showAnswerInputId, setShowAnswerInputId] = useState('')
    const [editAnswerId, setEditAnswerId] = useState('')
    const [answerText, setAnswerText] = useState('')
    const [answerTextError, setAnswerTextError] = useState('')
    const [postlikeId, setLikePostId] = useState('')
    const [answersubmitted, setAnswerSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)
    const [answerSubmittedResponse, setAnswerSubmittedResponse] = useState('')
    const [reportPostId, setReportPostId] = useState('')
    const [reportReason, setReportReason] = useState('')
    const [reportDescription, setReportDescription] = useState('')
    const [reportReasonError, setReportReasonError] = useState('')
    const [reportDescriptionError, setReportDescriptionError] = useState('')

    const [replyInput, setReplyInput] = useState(false)
    const [replyId, setReplyId] = useState('')
    const [showReplyInputId, setShowReplyInputId] = useState('')
    const [showReplyPostInputId, setShowReplyPostInputId] = useState('')
    const [replyText, setReplyText] = useState('')
    const [replyTextError, setReplyTextError] = useState('')
    const [replysubmitted, setReplySubmitted] = useState(false)
    const [replySubmittedResponse, setReplySubmittedResponse] = useState([])

    // const [imagePriview, setImagePriview] = useState('')
    const Token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const [replyLimit, setReplyLimit] = useState(2)




    const onPostDetail = (slug) => {
        history.push("/post-detail" + '/' + slug, { slug: slug })
    };
    const userProfile = (userId) => {
        history.push("/profile" + '/' + userId, { userId: userId })
    };
    const handleReport = (postId) => {
        console.log(postId);
        setReportPostId(postId);
    };
    const handleReportReason = (e) => {
        setReportReason(e.target.value);
    };
    const submitReport = (event) => {
        event.preventDefault();
        setLoading(true);
        if (!reportReason) {
            setReportReasonError("Please select reason");
            setLoading(false);
        }
        if (!reportDescription) {
            setReportDescriptionError("Please Enter Your Report")
            setLoading(false);
        }

        if (reportReason && reportDescription) {
            setReportDescriptionError("");
            setReportReasonError("");
            let data = {
                post_id: reportPostId,
                report: reportDescription,
                reason: reportReason,
            }
            CreatePostReport(Token, data).then((res) => {
                if (res.data.status === "success") {
                    setReportReason("");
                    setReportDescription("");
                    setLoading(false);
                    $('#myModalrep').modal('hide');
                    swal({
                        text: "Report Submitted Successfully",
                        icon: "success",
                    });

                }
            });
        }


    };
    const NotLogin = () => {
        swal({
            title: "Sign up for Ask Property",
            text: "Create a profile, ask and answer questions, list your property and more",
            icon: "info",
            buttons: {
                login: {
                    text: 'login'
                },
                register: {
                    text: "Register"
                }
            },
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete == 'register') {
                    history.push('/register');
                } else if (willDelete == 'login') {
                    history.push('/login');
                }
            });

    };

    const handleImagePriview = (image) => {
        console.log("image",image);
        setImagePriview(image);
        $("#myModalpop").modal('show');
    };
    const handleReply = (id, postId) => {


        setShowReplyInputId(id);
        setShowReplyPostInputId(postId);
        setReplyInput(true);
        setReplySubmitted(false);
        setReplySubmittedResponse([]);
    };
    const reply = (value) => {
        // $('.autoresizing').on('input', function () {
        //     this.style.height = 'auto';

        //     this.style.height =
        //         (this.scrollHeight) + 'px';
        // });
        setReplyText(value);
    };
    const handleViewAllReply = (limit) => {
        setReplyLimit(limit);
    };

    const handleReplySubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        // let data = {
        //     answer_id: showReplyInputId,
        //     answer: replyText,
        // }

        let data = "";
        if (replyId) {
            data = {
                answer_id: showReplyInputId,
                answer: replyText,
                id: replyId,
            }
        } else {
            data = {
                answer_id: showReplyInputId,
                answer: replyText,
            }
        }

        if (!replyText) {
            setReplyTextError("Please Enter Your Answer");
            setLoading(false);
        }
        if (replyText) {
            AnswerReplySave(Token, data).then((res) => {
                if (res.data.status === "success") {

                    setLoading(false);
                    setReplyInput(false);
                    setReplyText('');

                    setReplySubmitted(true);
                    setReplySubmittedResponse(res.data.data);
                    swal("Success", "Post Answer Reply Added Successfully", "success");
                } else {
                    setLoading(false);
                    swal("Error", "Something went wrong", "error");
                }
            });
        }

    };

    const answer = (id) => {
        setShowAnswerInputId(id);
        setAnswerInput(true);
        setAnswerTextError("");
    };

    const answerLater = (id) => {
        let data = {
            post_id: id
        }
        AnswerLaterSave(Token, data).then((res) => {
            if (res.data.status === "success") {
                history.push("/answer-later");
                // swal("Success", "Answer Submitted Successfully", "success");
            } else {
                swal("Error", "Something went wrong", "error");
            }

        });
    };
    const handleAnswer = (value) => {

        setAnswerText(value);
    };
    const handleAnswerSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let data = "";
        if (editAnswerId) {
            data = {
                post_id: showAnswerInputId,
                answer: answerText,
                id: editAnswerId,
            }
        } else {
            data = {
                post_id: showAnswerInputId,
                answer: answerText,
            }
        }
        if (!answerText) {
            setAnswerTextError("Please Enter Your Answer");
            setLoading(false);
        }
        if (answerText) {
            AnswerSave(Token, data).then((res) => {
                if (res.data.status === "success") {

                    setLoading(false);
                    setAnswerInput(false);
                    setAnswerText('');

                    setAnswerSubmitted(true);
                    setAnswerSubmittedResponse(res.data.data);
                    swal("Success", "Answer Submitted Successfully", "success");
                } else {
                    swal("Error", "Something went wrong", "error");
                }
            });
        }


    };

    const like = (id) => {
        let data = {
            post_id: id,
        }
        setLikePostId(id);
        CreateLikePost(Token, data).then((res) => {
            setLikeStatus(res.data.data.like_status);
            setCount(res.data.data.count);


        });

    };

    const LongText = ({ content, limit, slug }) => {

        if (content.length <= limit) {
            // there is nothing more to show
            return <p className="ck" dangerouslySetInnerHTML={{ __html: content }}></p>
        }

        // In the final case, we show a text with ellipsis and a `Read more` button
        const toShow = content.substring(0, limit) + "... ";
        return <p >
            <span className="ck" dangerouslySetInnerHTML={{ __html: toShow }}>
            </span>
            <Link onClick={() => onPostDetail(slug)}>More</Link>
            {/*  */}
        </p>
    };


    const postShare = (slug, element) => {
        if (element === "capylink") {
            const url = window.location.origin + "/post-detail" + "/" + slug;
            navigator.clipboard.writeText(url);
            swal({
                text: "Link copied successfully",
                icon: 'success'
            });
        } else {
            const url = window.location.origin + "/post-detail" + "/" + slug;
            const data = {
                post_slug: slug,
                share_type: element,
                post_url: url,
            }
            CreatePostShare(Token, data).then((res) => {
                window.open(res.data.data.shareLinkUrl, '_blank');
            });
        }
    };


    const handleDeleteAnswer = (Id, type) => {
        let data = {
            request_for: type,
            answer_id: Id
        }
        AnswerDelete(Token, data).then((res) => {
            if (res.data.status === "success") {
                if (type === 'answer') {
                    setAnswerSubmitted(true);
                    setShowAnswerInputId(res?.data?.data?.answer_data?.post_id)
                    setAnswerSubmittedResponse(res.data.data);
                } else {
                    setReplySubmitted(true);
                    setShowReplyPostInputId(res?.data?.data?.answer_data?.post_id);
                    setReplySubmittedResponse(res.data.data?.answer_data?.answerReply);
                }


                swal("Success", res.data.message, "success");
            } else {
                setLoading(false);
                swal("Error", "Something went wrong", "error");
            }
        });

    };

    const handleEditAnswer = (answer_id, post_id, answerText) => {
        setAnswerInput(true);
        setShowAnswerInputId(post_id);
        setEditAnswerId(answer_id);
        setAnswerText(answerText);
    };

    const handleEditreply = (reply_id, post_id, answer_id, answerText) => {
        setReplyInput(true);
        setShowReplyPostInputId(post_id);
        setShowReplyInputId(answer_id);
        setReplyId(reply_id);
        setReplyText(answerText);
    };



    return (
        <>
            <li className="feed-box bg-white mb-4">
                <div className="feed-top">
                    <div className="d-flex align-items-start  w-100 pb-2">
                        {item?.user?.roleWiseProfileData?.user_image === null ?
                            <img src="../assets/image/dummy_image.png" alt="" className="feed-user" onClick={() => handleImagePriview("../assets/image/dummy_image.png")} />
                            :
                            <img src={item?.user?.roleWiseProfileData?.user_image} alt="" className="feed-user" onClick={() => handleImagePriview(item?.user?.roleWiseProfileData?.user_image)} />

                        }
                        <div className="ps-3">
                            <Link onClick={() => userProfile(item?.user?.id)}>
                                <h3 >{item?.user?.name} {item?.user?.surname}</h3>
                            </Link>
                            {item?.user?.role === 5 ?
                                <h4 className="">{item?.user?.roleWiseProfileData?.occupation}</h4>

                                :
                                item?.user?.role === 2 ?
                                    <h4 className="">{item?.user?.roleWiseProfileData?.agency_name}</h4>
                                    :
                                    <h4 className="">{item?.user?.roleWiseProfileData?.agency_profile?.agency_name}</h4>
                            }

                        </div>
                        <div className="ms-auto">
                            <span className="fs-12"> {item?.CreatedAtCustom}</span>
                        </div>
                    </div>
                    <hr className="border-top" />

                    <LongText content={item.question} limit={200} slug={item.slug} />
                </div>
                <div className="feed-img">
                    {item.post_image === null ? '' :
                        <img src={item.post_image} alt="ask-feed" className="w-100" onClick={() => handleImagePriview(item.post_image)} />
                    }

                </div>
                {Token === null ?
                    <div className="feed-share">

                        <span onClick={NotLogin}><img src="../assets/image/like.png" className="pe-2" alt="" />{item.post_like_count}</span>
                        <span onClick={NotLogin}><img src="../assets/image/answer.png" alt="" /></span>
                        <span onClick={NotLogin}><img src="../assets/image/report.png" alt="" /></span>
                        <span onClick={NotLogin}><img src="../assets/image/clock.png" className="pe-2" alt="" />answer Later</span>
                        <span className="ms-auto p-0" onClick={NotLogin}> <img src="../assets/image/share.png" alt="" /></span>


                    </div>
                    :

                    <div className="feed-share">
                        {likeStatus === "like" && item.id === postlikeId || item.isAuthUserLikedPost === true ?
                            <span onClick={() => like(item.id)}><img src="../assets/image/like-fill.png" className="pe-2" alt="" style={{ width: "33px", height: "25px" }} />
                                {likeStatus === "like" && item.id === postlikeId ?
                                    likeCount
                                    :
                                    item.post_like_count
                                }

                            </span>
                            :
                            likeStatus === "deslike" && item.id === postlikeId ?
                                <span onClick={() => like(item.id)}><img src="../assets/image/like.png" className="pe-2" alt="" />
                                    {likeStatus === "deslike" && item.id === postlikeId ?
                                        likeCount
                                        :
                                        item.post_like_count
                                    }

                                </span>
                                :
                                <span onClick={() => like(item.id)}><img src="../assets/image/like.png" className="pe-2" alt="" />
                                    {likeStatus === "like" && item.id === postlikeId ?

                                        likeCount

                                        :
                                        item.post_like_count
                                    }
                                </span>
                        }




                        <span onClick={() => answer(item?.id)}><img src="../assets/image/answer.png" alt="" /></span>

                        {user?.id === item.user_id ? "" :
                            <span data-bs-toggle="modal" data-bs-target="#myModalrep" onClick={() => handleReport(item?.id)}><img src="../assets/image/report.png" alt="" /></span>
                        }
                        {item.isAuthUserPostAnswerLater === false && item.isAuthUserPostAnswer === false ?
                            <span onClick={() => answerLater(item?.id)}><img src="../assets/image/clock.png" className="pe-2" alt="" />answer Later</span>
                            : ''
                        }

                        <Link className="ms-auto p-0" id="share_dropdown" x-placement="bottom-end" data-bs-toggle="dropdown"><img src="../assets/image/share.png" alt="" /></Link>
                        <div className="dropdown-menu p-3" aria-labelledby="share_dropdown">
                            <Link className="d-block fs-15 medium" onClick={() => postShare(item?.slug, 'facebook')}><img src="../assets/image/fb.png" alt="" className="pe-2" /> Facebook</Link>
                            <Link className="d-block py-3 fs-15 medium" onClick={() => postShare(item?.slug, 'twitter')}><img src="../assets/image/twitter.png" alt="" className="pe-2" /> Twitter</Link>
                            <Link className="d-block fs-15 medium" onClick={() => postShare(item?.slug, 'capylink')}><img src="../assets/image/link.png" alt="" className="pe-2" /> Copylink</Link>
                        </div>

                    </div>
                }

                {answerInput && showAnswerInputId === item?.id ?
                    <div className="feed-comments">
                        <div className="row hidden">
                            <div className="col-12">
                                <form className="ans-save" onSubmit={handleAnswerSubmit}>

                                    <div className="row">
                                        <div className="col-10">


                                            <CKEditor
                                                data={answerText}
                                                className="editor"
                                                cols="30"
                                                rows="10"
                                                onChange={(event, editor) => {
                                                    handleAnswer(editor.getData())
                                                }}
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                                }}
                                            />


                                            <p style={{ color: "red", margintop: "-10px" }}>
                                                {answerTextError}
                                            </p>

                                        </div>
                                        <div className="col-2">
                                            {loading ?
                                                <Link className="border-btn btn" disabled={loading ? true : false}>
                                                    <span className='spinner-grow spinner-grow-sm'>

                                                    </span>
                                                </Link>
                                                :
                                                <input type="submit" className="border-btn btn" disabled={loading ? true : false} value="Send" />
                                                // <button type="submit" className="border-btn btn" disabled={loading ? true : false}>Send</button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    : ''
                }
                <div className="feed-comments">
                    {
                        answersubmitted && showAnswerInputId === item?.id ?
                            <h3 className="pb-3">
                                {answerSubmittedResponse.answerCount > 1 ?
                                    // answerSubmittedResponse.answerCount + 'Answers'
                                    answerSubmittedResponse.answerCount + 'Comments'

                                    :
                                    // answerSubmittedResponse.answerCount + ' Answer'
                                    answerSubmittedResponse.answerCount + ' Comments'

                                }

                            </h3>
                            :


                            <h3 className="pb-3">
                                {item?.post_answer_count > 1 ?
                                    // item?.post_answer_count + ' Answers'
                                    item?.post_answer_count + ' Comments'

                                    
                                    
                                    :
                                    // item?.post_answer_count + ' Answer'
                                    item?.post_answer_count + ' Comment'


                                }
                            </h3>
                    }

                    {answersubmitted && showAnswerInputId === item?.id ?
                        <div className="comment-list">
                            <div className="comment d-flex align-items-start w-100 pb-4">
                                {answerSubmittedResponse?.user?.roleWiseProfileData?.user_image === null ?
                                    <img src="../assets/image/dummy_image.png" alt="" className="feed-user" onClick={() => handleImagePriview("../assets/image/dummy_image.png")} />
                                    :
                                    <img src={answerSubmittedResponse?.user?.roleWiseProfileData?.user_image} alt="" className="feed-user" onClick={() => handleImagePriview(answerSubmittedResponse?.user?.roleWiseProfileData?.user_image)} />
                                }
                                <div className="ps-3 w-100">
                                    <Link onClick={() => userProfile(answerSubmittedResponse.user?.id)}>
                                        <h3 className="">{answerSubmittedResponse.user?.name} {answerSubmittedResponse.user?.surname} </h3>
                                    </Link>
                                    <div className="d-flex justify-content-between py-1 comment-date">
                                        {answerSubmittedResponse.user?.role === 5 ?
                                            <span className="">{answerSubmittedResponse?.user?.roleWiseProfileData?.occupation}</span>
                                            :
                                            answerSubmittedResponse.user?.role === 2 ?
                                                <span className="">{answerSubmittedResponse?.user?.roleWiseProfileData?.agency_name}</span>
                                                :
                                                <span className="">{answerSubmittedResponse?.user?.roleWiseProfileData?.agency_profile?.agency_name}</span>

                                        }

                                        <span className="">{answerSubmittedResponse?.answer_data?.created_at}</span>
                                    </div>

                                    <p className="ck" dangerouslySetInnerHTML={{ __html: answerSubmittedResponse?.answer_data?.answer }}></p>
                                    {user?.id === answerSubmittedResponse?.user?.roleWiseProfileData?.user_id ?
                                        <div className="d-flex">
                                            <Link className="fs-12 text-primary green-text"
                                                onClick={() => {
                                                    handleEditAnswer(answerSubmittedResponse?.answer_data?.id, item?.id, answerSubmittedResponse?.answer_data?.answer)
                                                }}
                                            >Edit</Link>
                                            &nbsp;
                                            <Link className="fs-12 delete-text text-primary green-text" onClick={() => {
                                                handleDeleteAnswer(answerSubmittedResponse?.answer_data?.id, "answer")
                                            }}>Delete</Link>
                                        </div>
                                        : ""}

                                    <div className="fs-13 medium">
                                        {Token === null ?
                                            <Link className="text-primary pe-3 border-btn btn" onClick={NotLogin}>Reply</Link>
                                            :
                                            <Link className="text-primary pe-3" onClick={() => handleReply(answerSubmittedResponse?.answer_data?.id, item?.id)}>Reply</Link>
                                        }
                                        {replysubmitted && showReplyPostInputId === item?.id ?
                                            replySubmittedResponse.length > 0 ?
                                                <span className="">
                                                    {replySubmittedResponse.length}  Reply
                                                </span>
                                                :
                                                ""
                                            :

                                            answerSubmittedResponse.length > 0 ?
                                                <span className="">
                                                    {answerSubmittedResponse.length}  Reply
                                                </span>
                                                : ""}
                                    </div>
                                    {replyInput && showReplyPostInputId === item?.id ?
                                        <div className="feed-comments">
                                            <div className="row hidden">
                                                <div className="col-12">
                                                    <form className="ans-save" onSubmit={handleReplySubmit}>

                                                        <div className="row">
                                                            <div className="col-10">

                                                                <CKEditor
                                                                    className="editor"
                                                                    cols="30"
                                                                    rows="10"
                                                                    onChange={(event, editor) => {
                                                                        reply(editor.getData())
                                                                    }}
                                                                    editor={ClassicEditor}
                                                                    config={{
                                                                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                                                    }}
                                                                />
                                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                                    {replyTextError}
                                                                </p>

                                                            </div>
                                                            <div className="col-2">
                                                                {loading ?
                                                                    <Link type="submit" className="border-btn btn" disabled={loading ? true : false}>
                                                                        <span className='spinner-grow spinner-grow-sm'>

                                                                        </span>
                                                                    </Link>
                                                                    :
                                                                    <input type="submit" className="border-btn btn" disabled={loading ? true : false} value="Send" />
                                                                    // <button type="submit" className="border-btn btn" disabled={loading ? true : false}>Send</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            {replysubmitted && showReplyPostInputId === item?.id ?

                                replySubmittedResponse.map((replyItem, i) => {
                                    return (
                                        i < replyLimit ?
                                            <div className="reply-comment comment d-flex align-items-start w-100 pb-4">
                                                {replyItem?.user?.roleWiseProfileData?.user_image === null ?
                                                    <img src="../assets/image/dummy_image.png" alt="" className="feed-user" onClick={() => handleImagePriview("../assets/image/dummy_image.png")} />
                                                    :
                                                    <img src={replyItem?.user?.roleWiseProfileData?.user_image} alt="" className="feed-user" onClick={() => handleImagePriview(replyItem?.user?.roleWiseProfileData?.user_image)} />
                                                }
                                                <div className="ps-3 w-100">
                                                    <Link onClick={() => userProfile(replyItem.user?.id)}>
                                                        <h3 className="">{replyItem.user.name} {replyItem.user.surname} </h3>
                                                    </Link>
                                                    <div className="d-flex justify-content-between py-1 comment-date">
                                                        {replyItem?.user?.role === 5 ?
                                                            <span className="">{replyItem?.user?.roleWiseProfileData?.occupation}</span>
                                                            :
                                                            replyItem?.user?.role === 2 ?
                                                                <span className="">{replyItem?.user?.roleWiseProfileData?.agency_name}</span>
                                                                :
                                                                <span className="">{replyItem?.user?.roleWiseProfileData?.agency_profile?.agency_name}</span>
                                                        }
                                                        <span className="" >{replyItem?.CreatedAtCustom}</span>
                                                    </div>

                                                    <p className="ck" dangerouslySetInnerHTML={{ __html: replyItem?.answer }}></p>

                                                    {user?.id === replyItem?.user?.roleWiseProfileData?.user_id ?
                                                        <div className="d-flex">
                                                            <Link className="fs-12 text-primary green-text"
                                                                onClick={() => {
                                                                    handleEditreply(replyItem?.id, item?.id, replyItem?.answer_id, replyItem?.answer)
                                                                }}
                                                            >Edit</Link>
                                                            &nbsp;
                                                            <Link className="fs-12 delete-text text-primary green-text" onClick={() => {
                                                                handleDeleteAnswer(replyItem?.id, "reply")
                                                            }}>Delete</Link>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                            : ''
                                    );
                                })
                                :

                                item?.post_answer.length > 0 ?
                                    item?.post_answer[0]?.answer_reply.map((replyItem, i) => {
                                        return (
                                            i < replyLimit ?
                                                <div className="reply-comment comment d-flex align-items-start w-100 pb-4">
                                                    {replyItem?.user?.roleWiseProfileData?.user_image === null ?
                                                        <img src="../assets/image/dummy_image.png" alt="" className="feed-user" onClick={() => handleImagePriview("../assets/image/dummy_image.png")} />
                                                        :
                                                        <img src={replyItem?.user?.roleWiseProfileData?.user_image} alt="" className="feed-user" onClick={() => handleImagePriview(replyItem?.user?.roleWiseProfileData?.user_image)} />
                                                    }
                                                    <div className="ps-3 w-100">
                                                        <Link onClick={() => userProfile(replyItem.user?.id)}>
                                                            <h3 className="">{replyItem.user.name} {replyItem.user.surname} </h3>
                                                        </Link>
                                                        <div className="d-flex justify-content-between py-1 comment-date">

                                                            {replyItem?.user?.role === 5 ?
                                                                <span className="">{replyItem?.user?.roleWiseProfileData?.occupation}</span>
                                                                :
                                                                replyItem?.user?.role === 2 ?
                                                                    <span className="">{replyItem?.user?.roleWiseProfileData?.agency_name}</span>
                                                                    :
                                                                    <span className="">{replyItem?.user?.roleWiseProfileData?.agency_profile?.agency_name}</span>
                                                            }
                                                            <span className="">{replyItem?.CreatedAtCustom}</span>
                                                        </div>

                                                        <p className="ck" dangerouslySetInnerHTML={{ __html: replyItem?.answer }}></p>
                                                        {user?.id === replyItem?.user?.roleWiseProfileData?.user_id ?
                                                            <div className="d-flex">
                                                                <Link className="fs-12 text-primary green-text"
                                                                    onClick={() => {
                                                                        handleEditreply(replyItem?.id, item?.id, replyItem?.answer_id, replyItem?.answer)
                                                                    }}
                                                                >Edit</Link>
                                                                &nbsp;
                                                                <Link className="fs-12 delete-text text-primary green-text" onClick={() => {
                                                                    handleDeleteAnswer(replyItem?.id, "reply")
                                                                }}>Delete</Link>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </div>
                                                : ''
                                        );
                                    })
                                    : ""}



                            {replysubmitted && showReplyPostInputId === item?.id ?
                                replySubmittedResponse.length > 1 && replyLimit < replySubmittedResponse.length ?
                                    <div className="reply-comment pb-4">
                                        <Link className="medium fs-13" onClick={() => handleViewAllReply(replySubmittedResponse.length)}>View all reply</Link>
                                    </div>
                                    : ""

                                :
                                item?.post_answer[0]?.answer_reply.length > 1 && replyLimit < item?.post_answer[0]?.answer_reply.length ?
                                    <div className="reply-comment pb-4">
                                        <Link className="medium fs-13" onClick={() => handleViewAllReply(item?.post_answer[0]?.answer_reply.length)}>View all reply</Link>
                                    </div>
                                    : ""
                            }

                            {answerSubmittedResponse?.answerCount > 1 ?
                                <Link className="view-link" onClick={() => onPostDetail(item.slug)}>View More Answers</Link>
                                : ''}
                        </div>
                        :
                        item?.post_answer?.length > 0 ?
                            <div className="comment-list">
                                <div className="comment d-flex align-items-start w-100 pb-4">
                                    {item?.post_answer[0]?.user?.roleWiseProfileData?.user_image === null ?
                                        <img src="../assets/image/dummy_image.png" alt="" className="feed-user" onClick={() => handleImagePriview("../assets/image/dummy_image.png")} />
                                        :
                                        <img src={item?.post_answer[0]?.user?.roleWiseProfileData?.user_image} alt="" className="feed-user" onClick={() => handleImagePriview(item?.post_answer[0]?.user?.roleWiseProfileData?.user_image)} />
                                    }
                                    <div className="ps-3 w-100">
                                        <Link onClick={() => userProfile(item?.post_answer[0]?.user?.id)}>
                                            <h3 className="">{item?.post_answer[0]?.user?.name} {item?.post_answer[0]?.user?.surname} </h3>
                                        </Link>
                                        <div className="d-flex justify-content-between py-1 comment-date">
                                            {item?.post_answer[0]?.user?.role === 5 ?
                                                <span className="">{item?.post_answer[0]?.user?.roleWiseProfileData?.occupation}</span>
                                                :
                                                item?.post_answer[0]?.user?.role === 2 ?
                                                    <span className="">{item?.post_answer[0]?.user?.roleWiseProfileData?.agency_name}</span>
                                                    :
                                                    <span className="">{item?.post_answer[0]?.user?.roleWiseProfileData?.agency_profile?.agency_name}</span>
                                            }
                                            <span className="">{item?.post_answer[0]?.CreatedAtCustom}</span>
                                        </div>

                                        <p className="ck" dangerouslySetInnerHTML={{ __html: item?.post_answer[0]?.answer }}></p>
                                        {user?.id === item?.post_answer[0]?.user_id ?
                                            <div className="d-flex">
                                                <Link className="fs-12 text-primary green-text"
                                                    onClick={() => {
                                                        handleEditAnswer(answerSubmittedResponse?.answer_data?.id, item?.id, item?.post_answer[0]?.answer)
                                                    }}>Edit</Link>
                                                &nbsp;
                                                <Link className="fs-12 delete-text text-primary green-text"
                                                    onClick={() => {
                                                        handleDeleteAnswer(item?.post_answer[0]?.id, "answer")
                                                    }}
                                                >Delete</Link>
                                            </div>
                                            : ""}

                                        <div className="fs-13 medium d-flex justify-content-between">
                                            {Token === null ?
                                                <Link className="text-primary pe-3" onClick={NotLogin}>Reply</Link>
                                                :
                                                <Link className="text-primary pe-3" onClick={() => handleReply(item?.post_answer[0]?.id, item?.id)}>Reply</Link>
                                            }
                                            {replysubmitted && showReplyPostInputId === item?.id ?
                                                replySubmittedResponse.length > 0 ?
                                                    <span className="">
                                                        {replySubmittedResponse.length}  Reply
                                                    </span>
                                                    :
                                                    ""
                                                :

                                                item?.post_answer[0]?.answer_reply.length > 0 ?
                                                    <span className="">
                                                        {item?.post_answer[0]?.answer_reply.length}  Reply
                                                    </span>
                                                    : ""}


                                        </div>

                                        {replyInput && showReplyPostInputId === item?.id ?
                                            <div className="feed-comments">
                                                <div className="row hidden">
                                                    <div className="col-12">
                                                        <form className="ans-save" onSubmit={handleReplySubmit}>

                                                            <div className="row">
                                                                <div className="col-10">


                                                                    <CKEditor
                                                                        data={replyText}
                                                                        className="editor"
                                                                        cols="30"
                                                                        rows="10"
                                                                        onChange={(event, editor) => {
                                                                            reply(editor.getData())
                                                                        }}
                                                                        editor={ClassicEditor}
                                                                        config={{
                                                                            toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                                                        }}
                                                                    />
                                                                    <p style={{ color: "red", margintop: "-10px" }}>
                                                                        {replyTextError}
                                                                    </p>

                                                                </div>
                                                                <div className="col-2">
                                                                    {loading ?
                                                                        <button type="submit" className="border-btn btn" disabled={loading ? true : false}>
                                                                            <span className='spinner-grow spinner-grow-sm'>

                                                                            </span>
                                                                        </button>
                                                                        :
                                                                        <input type="submit" className="border-btn btn" disabled={loading ? true : false} value="Send" />
                                                                        // <button type="submit" className="border-btn btn" disabled={loading ? true : false}>Send</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                    </div>
                                </div>

                                {replysubmitted && showReplyPostInputId === item?.id ?
                                    replySubmittedResponse.map((replyItem, i) => {
                                        return (
                                            i < replyLimit ?
                                                <div className="reply-comment comment d-flex align-items-start w-100 pb-4">
                                                    {replyItem?.user?.roleWiseProfileData?.user_image === null ?
                                                        <img src="../assets/image/dummy_image.png" alt="" className="feed-user" onClick={() => handleImagePriview("../assets/image/dummy_image.png")} />
                                                        :
                                                        <img src={replyItem?.user?.roleWiseProfileData?.user_image} alt="" className="feed-user" onClick={() => handleImagePriview(replyItem?.user?.roleWiseProfileData?.user_image)} />
                                                    }
                                                    <div className="ps-3 w-100">
                                                        <Link onClick={() => userProfile(replyItem.user?.id)}>
                                                            <h3 className="">{replyItem.user.name} {replyItem.user.surname} </h3>
                                                        </Link>
                                                        <div className="d-flex justify-content-between py-1 comment-date">



                                                            {replyItem?.user?.role === 5 ?
                                                                <span className="">{replyItem?.user?.roleWiseProfileData?.occupation}</span>
                                                                :
                                                                replyItem?.user?.role === 2 ?
                                                                    <span className="">{replyItem?.user?.roleWiseProfileData?.agency_name}</span>
                                                                    :
                                                                    <span className="">{replyItem?.user?.roleWiseProfileData?.agency_profile?.agency_name}</span>
                                                            }

                                                            <span className="">{replyItem?.CreatedAtCustom}</span>
                                                        </div>

                                                        <p className="ck" dangerouslySetInnerHTML={{ __html: replyItem?.answer }}></p>
                                                        {user?.id === replyItem?.user?.roleWiseProfileData?.user_id ?
                                                            <div className="d-flex">
                                                                <Link className="fs-12 text-primary green-text"
                                                                    onClick={() => {
                                                                        handleEditreply(replyItem?.id, item?.id, replyItem?.answer_id, replyItem?.answer)
                                                                    }}
                                                                >Edit</Link>
                                                                &nbsp;
                                                                <Link className="fs-12 delete-text text-primary green-text" onClick={() => {
                                                                    handleDeleteAnswer(replyItem?.id, "reply")
                                                                }}>Delete</Link>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </div>
                                                : ''
                                        );
                                    })
                                    :

                                    item?.post_answer.length > 0 ?
                                        item?.post_answer[0]?.answer_reply.map((replyItem, i) => {
                                            return (
                                                i < replyLimit ?
                                                    <div className="reply-comment comment d-flex align-items-start w-100 pb-4">
                                                        {replyItem?.user?.roleWiseProfileData?.user_image === null ?
                                                            <img src="../assets/image/dummy_image.png" alt="" className="feed-user" onClick={() => handleImagePriview("../assets/image/dummy_image.png")} />
                                                            :
                                                            <img src={replyItem?.user?.roleWiseProfileData?.user_image} alt="" className="feed-user" onClick={() => handleImagePriview(replyItem?.user?.roleWiseProfileData?.user_image)} />
                                                        }
                                                        <div className="ps-3 w-100">
                                                            <Link onClick={() => userProfile(replyItem.user?.id)}>
                                                                <h3 className="">{replyItem.user.name} {replyItem.user.surname} </h3>
                                                            </Link>
                                                            <div className="d-flex justify-content-between py-1 comment-date">


                                                                {replyItem?.user?.role === 5 ?
                                                                    <span className="">{replyItem?.user?.roleWiseProfileData?.occupation}</span>
                                                                    :
                                                                    replyItem?.user?.role === 2 ?
                                                                        <span className="">{replyItem?.user?.roleWiseProfileData?.agency_name}</span>
                                                                        :
                                                                        <span className="">{replyItem?.user?.roleWiseProfileData?.agency_profile?.agency_name}</span>
                                                                }

                                                                <span className="">{replyItem?.CreatedAtCustom}</span>
                                                            </div>

                                                            <p className="ck" dangerouslySetInnerHTML={{ __html: replyItem?.answer }}></p>
                                                            {user?.id === replyItem?.user?.roleWiseProfileData?.user_id ?
                                                                <div className="d-flex">
                                                                    <Link className="fs-12 text-primary green-text"
                                                                        onClick={() => {
                                                                            handleEditreply(replyItem?.id, item?.id, replyItem?.answer_id, replyItem?.answer)
                                                                        }}
                                                                    >Edit</Link>
                                                                    &nbsp;
                                                                    <Link className="fs-12 delete-text text-primary green-text" onClick={() => {
                                                                        handleDeleteAnswer(replyItem?.id, "reply")
                                                                    }}>Delete</Link>
                                                                </div>
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    : ''
                                            );
                                        })
                                        : ""}



                                {replysubmitted && showReplyPostInputId === item?.id ?
                                    replySubmittedResponse.length > 1 && replyLimit < replySubmittedResponse.length ?
                                        <div className="reply-comment pb-4">
                                            <Link className="medium fs-13" onClick={() => handleViewAllReply(replySubmittedResponse.length)}>View all reply</Link>
                                        </div>
                                        : ""

                                    :
                                    item?.post_answer[0]?.answer_reply?.length > 1 && replyLimit < item?.post_answer[0]?.answer_reply.length ?
                                        <div className="reply-comment pb-4">
                                            <Link className="medium fs-13" onClick={() => handleViewAllReply(item?.post_answer[0]?.answer_reply.length)}>View all reply</Link>
                                        </div>
                                        : ""
                                }



                                {item?.post_answer_count > 1 ?
                                    <Link className="view-link" onClick={() => onPostDetail(item.slug)}>View More Answers</Link>
                                    : ''}
                            </div>
                            : ''
                    }
                </div>
            </li>
            <div class="modal" id="myModalrep">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Post Report</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/* <form class="report-save"> */}

                        <div class="modal-body">

                            <label>
                                Reason
                            </label>
                            <select class="form-control"
                                onChange={(e) => handleReportReason(e)}
                                name="reason" require>
                                <option value="Please select reason" selected={reportReason === "" ? "selected" : ""}>Please select reason</option>
                                <option value="Post is offensive">Post is offensive</option>
                                <option value="Post violates community guidelines">Post violates community guidelines</option>
                                <option value="Harassment">Harassment</option>
                                <option value="Spam">Spam</option>
                                <option value="Hate Speech">Hate Speech</option>
                                <option value="Nudity">Nudity</option>
                                <option value="Violence">Violence</option>
                            </select>
                            <p style={{ color: "red", margintop: "-10px" }}>
                                {reportReasonError}
                            </p>
                            <br />
                            <label>
                                Report
                            </label>
                            <textarea class="form-control"
                                name="report"
                                id="report_text"
                                rows="5"
                                require
                                value={reportDescription}
                                onChange={(e) => setReportDescription(e.target.value)}
                            ></textarea>
                            <p style={{ color: "red", margintop: "-10px" }}>
                                {reportDescriptionError}
                            </p>

                        </div>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            {loading ?
                                <button type="submit" class="btn btn-success" disabled>
                                    <span className='spinner-grow spinner-grow-sm'>

                                    </span>
                                </button>

                                :
                                <button type="submit" class="btn btn-success" onClick={submitReport} disabled={loading ? true : false}>Submit </button>
                            }
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
           
        </>

    )
}
