export const TransferCostDeedOffice = [
    { value: 606, start:300000, end:300000},
    { value: 755, start:300000, end:600000},
    { value: 1063, start:600000, end:800000},
    { value: 1220, start:800000, end:1000000},

    { value: 1371, start:1000000, end:2000000},
    { value: 1900, start:2000000, end:4000000},
    { value: 2305, start:4000000, end:6000000},

    { value: 2745, start:6000000, end:8000000},
    { value: 3209, start:8000000, end:10000000},
    { value: 3819, start:10000000, end:15000000},
    { value: 4588, start:15000000, end:20000000},
    { value: 6111, start:20000000, end:'unlimited'},

];

 