export const RentPricingOption = [
    { value: 1000, label:'R 1 000'},
    { value: 1500, label:'R 1 500'},
    { value: 2000, label:'R 2 000'},
    { value: 2500, label:'R 2 500'},
    { value: 3000, label:'R 3 000'},
    { value: 3500, label:'R 3 500'},
    { value: 4000, label:'R 4 000'},
    { value: 4500, label:'R 4 500'},
    { value: 5000, label:'R 5 000'},
    { value: 6000, label:'R 6 000'},
    { value: 7000, label:'R 7 000'},
    { value: 8000, label:'R 8 000'},
    { value: 9000, label:'R 9 000'},
    { value: 10000, label:'R 10 000'},
    { value: 11000, label:'R 11 000'},
    { value: 12000, label:'R 12 000'},
    { value: 13000, label:'R 13 000'},
    { value: 14000, label:'R 14 000'},
    { value: 15000, label:'R 15 000'},
    { value: 16000, label:'R 16 000'},
    { value: 17000, label:'R 17 000'},
    { value: 18000, label:'R 18 000'},
    { value: 19000, label:'R 19 000'},
    { value: 20000, label:'R 20 000'},
    { value: 25000, label:'R 25 000'},
    { value: 30000, label:'R 30 000'},
    { value: 35000, label:'R 35 000'},
    { value: 40000, label:'R 40 000'},
    { value: 50000, label:'R 50 000'},
    { value: 500000, label:'R 500 000'},
    { value: 600000, label:'R 600 000'},
    { value: 700000, label:'R 700 000'},
    { value: 800000, label:'R 800 000'},
    { value: 900000, label:'R 900 000'},
    { value: 1000000, label:'R 1 000 000'},
    { value: 1250000, label:'R 1 250 000'},
    { value: 1500000, label:'R 1 500 000'},
    { value: 1750000, label:'R 1 750 000'},
    { value: 2000000, label:'R 2 000 000'},
    { value: 2500000, label:'R 2 500 000'},
    { value: 3000000, label:'R 3 000 000'},
    { value: 4000000, label:'R 4 000 000'},
    { value: 5000000, label:'R 5 000 000'},
    { value: 6000000, label:'R 6 000 000'},
    { value: 7000000, label:'R 7 000 000'},
    { value: 8000000, label:'R 8 000 000'},
    { value: 9000000, label:'R 9 000 000'},
    { value: 10000000, label:'R 10 000 000'},
];