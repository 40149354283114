import React, { useState, useEffect } from "react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import { TransferCostDeedOffice } from "../.././constants/TransferCostDeedOffice";
import { BondCostDeedOffice } from "../.././constants/BondCostDeedOffice";
import CurrencyFormat from "react-currency-format";
import ApplyForHomeLoanBtn from "../shared/ApplyForHomeLoanBtn";
import { formatter } from "../../utils";

export default function TransferCostCalculator() {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [OwnershipType, setOwnershipType] = useState("");
  const [SellerVat, setSellerVat] = useState("");
  const [purchaseStatus, setPurchaseStatus] = useState("");
  const [purchasePriceError, setPurchasePriceError] = useState("");

  const [Propertytransfercosts, setPropertytransfercosts] = useState(0);
  const [PropertytransfercostsDeedOffice, setPropertytransfercostsDeedOffice] =
    useState(0);
  const [PostPettiesFicaFee, setPostPettiesFicaFee] = useState(0);
  const [transferCostDuty, setTransferCostDuty] = useState(0);
  const [transferCostAmount, setTransferCostAmount] = useState(0);

  const [Propertyboandcost, setPropertyboandcost] = useState(0);
  const [PropertyboandcostDeedOffice, setPropertyboandcostDeedOffice] =
    useState(0);
  const [bankRegistration, setBankRegistration] = useState(0);
  const [BondPostPettiesFicaFee, setBondPostPettiesFicaFee] = useState(0);
  const [totalboandcost, setTotalboandcost] = useState(0);
  const [scrollTop, setScrollTop] = useState(true);
  const [isCalculated, setIsCalculated] = useState(false);
  useEffect(function () {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    setScrollTop(false);
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    if (purchasePrice < 300000) {
      setPurchasePriceError("Please enter minimum purchase price R 300000");
    }
    if (purchasePrice >= 300000) {
      let newtransfercost = "";
      if (purchasePrice > 300000) {
        const extrapurchasePrice = purchasePrice - 300000;
        const numberExtratrnsfercost = extrapurchasePrice / 50000;
        const totaltransferconst =
          parseInt(
            numberExtratrnsfercost * process.env.REACT_APP_EXTRA_TRANSFER_FEE
          ) + parseInt(process.env.REACT_APP_TRANSFER_FEE);
        newtransfercost = parseInt(totaltransferconst);
      } else {
        newtransfercost = parseInt(process.env.REACT_APP_TRANSFER_FEE);
      }
      setPropertytransfercosts(newtransfercost);

      const deedOfficeFees = transferCostDeedOfficeCal(purchasePrice);
      setPropertytransfercostsDeedOffice(deedOfficeFees);
      let PostPettiesFicaFeeValue = "";
      if (OwnershipType === "Freehold") {
        PostPettiesFicaFeeValue =
          process.env.REACT_APP_FREEHOLD_POST_PETTIES_FICA_OTHER_FEE;
      } else {
        PostPettiesFicaFeeValue =
          process.env.REACT_APP_SECTIONAL_POST_PETTIES_FICA_OTHER_FEE;
      }
      setPostPettiesFicaFee(PostPettiesFicaFeeValue);
      let dutyvalue = 0;
      if (purchasePrice >= 1100001 && purchasePrice <= 1512500) {
        const Transferduty = purchasePrice - 1100000;
        const newTransferduty = (Transferduty * 3) / 100;
        dutyvalue = newTransferduty;
        setTransferCostDuty(newTransferduty);
      } else if (purchasePrice >= 1512501 && purchasePrice <= 2117500) {
        const Transferduty = purchasePrice - 1512500;
        const newTransferduty = (Transferduty * 6) / 100 + 12375;
        dutyvalue = newTransferduty;
        setTransferCostDuty(newTransferduty);
      } else if (purchasePrice >= 2117501 && purchasePrice <= 2722500) {
        const Transferduty = purchasePrice - 2117500;
        const newTransferduty = (Transferduty * 8) / 100 + 48675;
        dutyvalue = newTransferduty;
        setTransferCostDuty(newTransferduty);
      } else if (purchasePrice >= 2722501 && purchasePrice <= 12100000) {
        const Transferduty = purchasePrice - 2722500;
        const newTransferduty = (Transferduty * 11) / 100 + 97075;
        dutyvalue = newTransferduty;
        setTransferCostDuty(newTransferduty);
      } else if (purchasePrice >= 12100001) {
        const Transferduty = purchasePrice - 12100000;
        const newTransferduty = (Transferduty * 13) / 100 + 1128600;
        dutyvalue = newTransferduty;
        setTransferCostDuty(newTransferduty);
      }

      const TransferCostAmountTotal =
        parseInt(newtransfercost) +
        parseInt(deedOfficeFees) +
        parseInt(PostPettiesFicaFeeValue) +
        parseInt(dutyvalue);
      setTransferCostAmount(TransferCostAmountTotal);
    }

    if (loanAmount >= 150000) {
      let newtotalloanAmount = "";
      if (loanAmount > 150100) {
        const extraloanAmount = loanAmount - 150100;
        const numberextraloanAmount = extraloanAmount / 50000;
        const totalnumberextraloanAmount =
          parseInt(
            numberextraloanAmount * process.env.REACT_APP_EXTRA_BOND_FEE
          ) + parseInt(process.env.REACT_APP_BOND_FEE);
        newtotalloanAmount = parseInt(totalnumberextraloanAmount);
      } else {
        newtotalloanAmount = parseInt(process.env.REACT_APP_BOND_FEE);
      }
      setPropertyboandcost(newtotalloanAmount);
      let PostPettiesFicaFeeValue = "";
      if (OwnershipType === "Freehold") {
        PostPettiesFicaFeeValue =
          process.env.REACT_APP_FREEHOLD_POST_PETTIES_FICA_OTHER_FEE;
      } else {
        PostPettiesFicaFeeValue =
          process.env.REACT_APP_SECTIONAL_POST_PETTIES_FICA_OTHER_FEE;
      }
      setBondPostPettiesFicaFee(PostPettiesFicaFeeValue);

      const deedOfficeFees = BoandCostDeedOfficeCal(loanAmount);
      setPropertyboandcostDeedOffice(deedOfficeFees);
      let bankFee = process.env.REACT_APP_BANK_INITIATION_FEE;
      setBankRegistration(bankFee);

      const BondCostAmountTotal =
        parseInt(newtotalloanAmount) +
        parseInt(deedOfficeFees) +
        parseInt(bankFee) +
        parseInt(PostPettiesFicaFeeValue);
      setTotalboandcost(BondCostAmountTotal);
    }
    setIsCalculated(true);
  };

  const transferCostDeedOfficeCal = (purchasePriceValue) => {
    let DeedValue = "";
    TransferCostDeedOffice.map((item, i) => {
      if (
        parseInt(purchasePriceValue) > parseInt(item.start) &&
        parseInt(purchasePriceValue) <= parseInt(item.end)
      ) {
        DeedValue = item.value;
      }
      if (parseInt(purchasePriceValue) === 300000) {
        DeedValue = 606;
      }
      if (parseInt(purchasePriceValue) > 20000000) {
        DeedValue = 6111;
      }
    });
    return DeedValue;
  };

  const BoandCostDeedOfficeCal = (loanPriceValue) => {
    let DeedValue = "";
    BondCostDeedOffice.map((item, i) => {
      if (
        parseInt(loanPriceValue) > parseInt(item.start) &&
        parseInt(loanPriceValue) <= parseInt(item.end)
      ) {
        DeedValue = item.value;
      }
      if (parseInt(loanPriceValue) === 150000) {
        DeedValue = 468;
      }
      if (parseInt(loanPriceValue) > 30000000) {
        DeedValue = 7640;
      }
    });
    return DeedValue;
  };
  return (
    <>
      <Navbar />
      <div className="main py-5">
        <div className="container ">
          <h1 className="text-primary semibold cal-title text-center pb-5">
            Transfer Cost Calculator
          </h1>

          <div className="row">
            <div className="col-md-6 pb-5">
              <form onSubmit={handleSubmit} className="calculator-form">
                <div className="row">
                  <div className="form-field col-md-12">
                    <label className="d-block medium fs-15">
                      Purchase price (including VAT)*
                    </label>
                    <small style={{ color: "green" }}>
                      Note : Minimum Purchase Price will be R 300,000
                    </small>

                    <CurrencyFormat
                      className="w-100"
                      value={purchasePrice}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Purchase price (including VAT)*"
                      required
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setPurchasePrice(value);
                      }}
                    />
                    <p style={{ color: "red", margintop: "-10px" }}>
                      {purchasePriceError}
                    </p>
                  </div>

                  <div className="form-field col-md-12">
                    <label className="d-block medium fs-15">Loan amount*</label>

                    <CurrencyFormat
                      className="w-100"
                      value={loanAmount}
                      thousandSeparator={true}
                      prefix={"R "}
                      placeholder="Loan amount*"
                      required
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setLoanAmount(value);
                      }}
                    />
                  </div>

                  <div className="form-field col-md-12">
                    <label className="d-block medium fs-15">
                      Type of Property Ownership*
                    </label>
                    <select
                      required
                      onChange={(e) => {
                        setOwnershipType(e.target.value);
                      }}
                    >
                      <option value="">-- Please select --</option>
                      <option value="Freehold">Freehold</option>
                      <option value="Sectional">Sectional</option>
                    </select>
                  </div>

                  <div className="form-field col-md-12">
                    <label className="d-block medium fs-15">
                      Seller registered for VAT*
                    </label>
                    <select
                      required
                      onChange={(e) => {
                        setSellerVat(e.target.value);
                      }}
                    >
                      <option value="">-- Please select --</option>
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>

                  <div className="form-field col-md-12">
                    <label className="d-block medium fs-15">
                      Status of purchaser*
                    </label>
                    <select
                      required
                      onChange={(e) => {
                        setPurchaseStatus(e.target.value);
                      }}
                    >
                      <option value="">-- Please select --</option>
                      <option value="Natural Person">Natural Person</option>
                      <option value="Company">Company</option>
                      <option value="Closed Corporation">
                        Closed Corporation
                      </option>
                      <option value="Trust">Trust</option>
                    </select>
                  </div>

                  <div className="form-field col-md-12">
                    <button type="submit" className="custom-btn">
                      calculate
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-6 px-md-4">
              <div className="cal-right-box bg-white py-5 px-4">
                <h2 className="semibold pb-4">Total costs (including VAT) </h2>
                <h3 className="text-primary semibold pb-5 ">
                  {" "}
                  {formatter.format(
                    parseInt(totalboandcost) + parseInt(transferCostAmount)
                  )}
                </h3>

                <h2 className="semibold pb-4 d-flex">
                  Bond registration costs (incl VAT){" "}
                  <span className="text-right">
                    {formatter.format(totalboandcost)}{" "}
                  </span>
                </h2>
                <div className="calc-cost">
                  <p>
                    Bond registration cost (incl VAT){" "}
                    <span>{formatter.format(Propertyboandcost)}</span>
                  </p>

                  <p>
                    Bank initiation fee (incl VAT) *
                    <span>{formatter.format(bankRegistration)}</span>
                  </p>

                  <p>
                    Deeds office fees
                    <span>{formatter.format(PropertyboandcostDeedOffice)}</span>
                  </p>

                  <p>
                    Post, petties, FICA other fees (incl VAT) ***
                    <span>{formatter.format(BondPostPettiesFicaFee)}</span>
                  </p>
                </div>

                <div className="calc-cost">
                  <h2 className="semibold py-4 d-flex">
                    Transfer costs (incl VAT){" "}
                    <span className="text-right px-2">
                      {formatter.format(transferCostAmount)}
                    </span>
                  </h2>
                  <p>
                    Property transfer costs (incl VAT){" "}
                    <span>{formatter.format(Propertytransfercosts)}</span>
                  </p>

                  <p>
                    Transfer duty **
                    <span>{formatter.format(transferCostDuty)}</span>
                  </p>

                  <p>
                    Deeds office fees
                    <span>
                      {formatter.format(PropertytransfercostsDeedOffice)}
                    </span>
                  </p>

                  <p>
                    Post, petties, FICA and other fees (incl VAT) ***
                    <span>{formatter.format(PostPettiesFicaFee)}</span>
                  </p>
                </div>
              </div>
              {isCalculated && <ApplyForHomeLoanBtn />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
