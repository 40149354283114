export const RentPriceOption = [
    { start: 1000, end:6000, label:'R 1 000 - R 6 000'},
    { start: 6000, end:11000, label:'R 6 000 - R 11 000'},
    { start: 11000, end:16000, label:'R 11 000 - R 16 000'},
    { start: 16000, end:21000, label:'R 16 000 - R 21 000'},
    { start: 21000, end:26000, label:'R 21 000 - R 26 000'},
    { start: 26000, end:31000, label:'R 26 000 - R 31 000'},
    { start: 31000, end:36000, label:'R 31 000 - R 36 000'},
    { start: 36000, end:41000, label:'R 36 000 - R 41 000'},

    // { start: 1000, end:1500, label:'R 1 000 - R 1 500'},
    // { start: 1500, end:2000, label:'R 1 500 - R 2 000'},
    // { start: 2000, end:2500, label:'R 2 000 - R 2 500'},
    // { start: 2500, end:3000, label:'R 2 500 - R 3 000'},
    // { start: 3000, end:3500, label:'R 3 000 - R 3 500'},
    // { start: 3500, end:4000, label:'R 3 500 - R 4 000'},
    // { start: 4000, end:4500, label:'R 4 000 - R 4 500'},
    // { start: 4500, end:5000, label:'R 4 500 - R 5 000'},
    // { start: 5000, end:6000, label:'R 5 000 - R 6 000'},
    // { start: 6000, end:7000, label:'R 6 000 - R 7 000'},
    // { start: 7000, end:8000, label:'R 7 000 - R 8 000'},
    // { start: 8000, end:9000, label:'R 8 000 - R 9 000'},
    // { start: 9000, end:10000, label:'R 9 000 - R 10 000'},
    // { start: 10000, end:11000, label:'R 10 000 - R 11 000'},
    // { start: 11000, end:12000, label:'R 11 000 - R 12 000'},
    // { start: 12000, end:13000, label:'R 12 000 - R 13 000'},
    // { start: 13000, end:14000, label:'R 13 000 - R 14 000'},
    // { start: 14000, end:15000, label:'R 14 000 - R 15 000'},
    // { start: 15000, end:16000, label:'R 15 000 - R 16 000'},
    // { start: 16000, end:17000, label:'R 16 000 - R 17 000'},
    // { start: 17000, end:18000, label:'R 17 000 - R 18 000'},
    // { start: 18000, end:19000, label:'R 18 000 - R 19 000'},
    // { start: 19000, end:20000, label:'R 19 000 - R 20 000'},
    // { start: 20000, end:25000, label:'R 20 000 - R 25 000'},
    // { start: 25000, end:30000, label:'R 25 000 - R 30 000'},
    // { start: 30000, end:35000, label:'R 30 000 - R 35 000'},
    // { start: 35000, end:40000, label:'R 35 000 - R 40 000'},
    // { start: 40000, end:50000, label:'R 40 000 - R 50 000'},
    // { start: 500000, end:600000, label:'R 500 000 - R 600 000'},
    // { start: 600000, end:700000, label:'R 600 000 - R 700 000'},
    // { start: 700000, end:800000, label:'R 700 000 - R 800 000'},
    // { start: 800000, end:900000, label:'R 800 000 - R 900 000'},
    // { start: 900000, end:1000000, label:'R 900 000 - R 1000 000'},
    // { start: 1000000, end:1250000, label:'R 1000 000 - R 1 250 000'},
    // { start: 1250000, end:1500000, label:'R 1 250 000 - R 1 500 000'},
    // { start: 1500000, end:1750000, label:'R 1 500 000 - R 1 750 000'},
    // { start: 1750000, end:2000000, label:'R 1 750 000 - R 2 000 000'},
    // { start: 2000000, end:2500000, label:'R 2 000 000 - R 2 500 000'},
    // { start: 2500000, end:3000000, label:'R 2 500 000 - R 3 000 000'},
    // { start: 3000000, end:4000000, label:'R 3 000 000- R 4 000 000'},
    // { start: 4000000, end:5000000, label:'R 4 000 000- R 5 000 000'},
    // { start: 5000000, end:6000000, label:'R 5 000 000- R 6 000 000'},
    // { start: 6000000, end:7000000, label:'R 6 000 000- R 7 000 000'},
    // { start: 7000000, end:8000000, label:'R 7 000 000- R 8 000 000'},
    // { start: 8000000, end:9000000, label:'R 8 000 000- R 9 000 000'},
    // { start: 9000000, end:10000000, label:'R 9 000 000- R 10 000 000'},
];