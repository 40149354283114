import React from 'react'
import { Link } from 'react-router-dom'

export default function SecondHeader() {
  return (
    <div className=" py-4">
    <div className="container">
        <div className="head-logo text-center">
        <Link to="/">
            <img src="assets/image/logo.png" alt="ask" />
        </Link>
        </div>
    </div>
</div>
  )
}
