const ApplyForHomeLoanBtn = ({ title = "Apply for a home loan", subtitle }) => {
  return (
    <div className="w-100 d-flex flex-column justify-content-center align-items-center mx-2 mt-4">
      {subtitle && <p>{subtitle}</p>}
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdH-JBAfxr0PDpS1Srfc6c9LB945WO34FBbgjiWpyufmS7ypw/viewform"
        target="_blank"
        className="w-100 d-flex justify-content-center align-items-center"
      >
        <button
          style={{
            backgroundColor: "#63af30",
            borderRadius: "10px",
            color: "white",
            padding: "10px",
          }}
          className="border-0 px-3"
        >
          {title}
        </button>
      </a>
    </div>
  );
};

export default ApplyForHomeLoanBtn;
