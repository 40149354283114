export default [
    {
        id : 'nearest',
        value : 'Nearest'
    },
    {
        id : 'low_to_high',
        value : 'Low to High'
    },
    {
        id : 'high_to_low',
        value : 'High to Low'
    },
    {
        id : 'recent',
        value : 'Recent'
    }
    
]