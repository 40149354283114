import axios from "axios";
import { AnswerURLs } from '../constants/url';


  export const GetAnswerLater = (token) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: `${AnswerURLs.AnswerLater}`,
    method: "Post",
});

export const GetNextPost = (url, token) =>
  axios({
    headers: {
        Authorization: `Bearer ${token}`,
    },
    url: url,
    method: "POST",
});

export const AnswerSave = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${AnswerURLs.CreateAnswer}`,
  method: "Post",
  data:body,
});

export const AnswerLaterSave = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${AnswerURLs.CreateAnswerLater}`,
  method: "Post",
  data:body,
});

export const AnswerReplySave = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${AnswerURLs.CreateReply}`,
  method: "Post",
  data:body,
});

export const AnswerDelete = (token, body) =>
axios({
  headers: {
      Authorization: `Bearer ${token}`,
  },
  url: `${AnswerURLs.CreateDelete}`,
  method: "Post",
  data:body,
});







