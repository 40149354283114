import React from "react";

const TableHeadCell = ({
  className,
  id,
  children,
  ...props
}) => {
    return (
        <th
            id={id}
            className={className}
            {...props}
        >
        {children}
        </th>
    );
};

export default TableHeadCell;