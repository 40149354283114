import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {
  Getproperties,
  GetMasterData,
  GetpropertiesByPage,
} from "../API/PropertyApi";
import { BedRoomOption } from "../constants/BedRoomOption";
import { SalePricingOption } from "../constants/SalePricingOption";
import { RentPricingOption } from "../constants/RentPricingOption";
import SubscribeModel from "./SubscribeModel";

import Autocomplete from "react-google-autocomplete";
import { formatter } from "../utils";
import swal from "sweetalert";
import SortByOptions from "../constants/SortByOptions";
const $ = window.jQuery;

export default function SearchProperty(props) {
  const [scrollTop, setScrollTop] = useState(true);
  useEffect(function () {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    setScrollTop(false);
  });
  useEffect(() => {
    $(document).ready(function () {
      console.log("document_ready and JQUERY running");
      $(".filter-mobile-ic, .filter-close").click(function () {
        console.log("button_clicked");
        $(".filter-sidebar").toggle();
      });
    });
  }, []);
  const location = useLocation();
  const history = useHistory();
  const searchRequestData = location?.state?.data;
  console.log(searchRequestData);
  const Url = location?.state?.url;
  const [PropertyData, setPropertyData] = useState([]);
  const [lattitude, setPropertyLattitude] = useState(
    searchRequestData?.lattitude
  );
  const [longitude, setPropertyLongitude] = useState(
    searchRequestData?.longitude
  );
  const [masterTypeOfProperty, setMasterTypeOfProperty] = useState([]);
  const [listing_type, setlistingType] = useState(
    searchRequestData?.listing_type
  );
  const [minPrice, setMinPrice] = useState(searchRequestData?.minPrice);
  const [maxPrice, setMaxPrice] = useState(searchRequestData?.maxPrice);
  const [Bedrooms, setBedrooms] = useState(searchRequestData?.Bedrooms);
  const [TypeOfProperty, setTypeOfProperty] = useState(
    searchRequestData?.TypeOfProperty
  );
  const [PageLoading, setPageLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [bathrooms, setBathrooms] = useState();
  const [parking, setParking] = useState();
  const [garages, setGarages] = useState();

  const [pool, setPool] = useState();
  const [petFriendly, setPetFriendly] = useState();
  const [flatlet, setFlatlet] = useState();
  const [garden, setGarden] = useState();

  const [retirement, setRetirement] = useState();
  const [onShow, setOnShow] = useState();
  const [auction, setAuction] = useState();
  const [repossessed, setRepossessed] = useState();
  const [securityEstate, setSecurityEstate] = useState();

  const [amenities, setAmenities] = useState([]);
  const [externalFeatures, setExternalFeatures] = useState([]);

  const [NextUrl, setNextUrl] = useState("");
  const [prevPageUrl, setPrevpageurl] = useState("");
  const [current_page, setCurrentPage] = useState("");
  const Token = localStorage.getItem("token");
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [noOfBedrooms, setNoOfBedrooms] = useState(0);
  const [propertyAddress, setPropertyAddress] = useState("");
  const [propertyLocationLat, setPropertyLocationLat] = useState("");
  const [propertyLocationLong, setPropertyLocationLong] = useState("");
  const [sortBy, setSortBy] = useState("nearest");
  useEffect(function () {
    // alert("Good one");
    let mounted = true;
    // setPageLoading(true);

    GetMasterData().then((res) => {
      if (res.data.type === "success") {
        setMasterTypeOfProperty(res.data.data.masterTypeOfProperty);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(function () {
    setTimeout(function () {
      if (Url === null) {
        getSearchReasult();
      } else {
        getSearchReasultByPagination(Url);
      }
    }, 1000);
  }, []);

  const toggleFilter = () => {
    $(".filter-sidebar").toggle();
  };

  const getSearchReasultByPagination = (Pageurl) => {
    if (!lattitude) {
      swal("Error", "Lattitude is required", "error");
    }
    if (!longitude) {
      swal("Error", "Longitude is required", "error");
    }
    if (!listing_type) {
      swal("Error", "Listing type is required", "error");
    }
    if (lattitude && longitude && listing_type) {
      const formData = new FormData();
      formData.append("lattitude", lattitude);
      formData.append("longitude", longitude);
      formData.append("listing_type", listing_type);
      if (minPrice) {
        formData.append("min_price", minPrice);
      }
      if (TypeOfProperty) {
        formData.append("type_of_property", TypeOfProperty);
      }

      if (Bedrooms) {
        formData.append("bedroom_quantity", Bedrooms);
      }

      if (maxPrice) {
        formData.append("max_price", maxPrice);
      }
      if (sortBy) {
        formData.append("sort_by", sortBy);
      }
      GetpropertiesByPage(Pageurl, formData).then((res) => {
        setPageLoading(false);
        if (res.data.status === "success") {
          window.scrollTo(0, 0);
          setPropertyData(res.data.data.data);
          setAmenities(res?.data?.data?.data?.get_selected_amenities);
          setExternalFeatures(
            res?.data?.data?.data?.get_selected_external_features
          );
          setPagination(res.data.data.links);
          setNextUrl(res?.data?.data?.next_page_url);
          setCurrentPage(res?.data?.data?.current_page);
          setPrevpageurl(res?.data?.data?.prev_page_url);
        }
      });
    }
  };

  const getSearchReasult = () => {
    if (!lattitude) {
      swal("Error", "Lattitude is required", "error");
    }
    if (!longitude) {
      swal("Error", "Longitude is required", "error");
    }
    if (!listing_type) {
      swal("Error", "Listing type is required", "error");
    }
    if (lattitude && longitude && listing_type) {
      const formData = new FormData();
      formData.append("lattitude", lattitude);
      formData.append("longitude", longitude);
      formData.append("listing_type", listing_type);
      if (minPrice) {
        formData.append("min_price", minPrice);
      }
      if (TypeOfProperty) {
        formData.append("type_of_property", TypeOfProperty);
      }

      if (Bedrooms) {
        formData.append("bedroom_quantity", Bedrooms);
      }

      if (maxPrice) {
        formData.append("max_price", maxPrice);
      }
      // let sort = localStorage.getItem('sortBy');
      // if(sort){
      //     setSortBy(sort);
      //     formData.append('sort_by',sort);
      // }
      if (sortBy) {
        formData.append("sort_by", sortBy);
      }

      Getproperties(formData).then((res) => {
        setPageLoading(false);
        if (res.data.status === "success") {
          window.scrollTo(0, 0);
          setPropertyData(res.data.data.data);
          setAmenities(res.data.data.data.get_selected_amenities);
          setExternalFeatures(
            res.data.data.data.get_selected_external_features
          );
          setPagination(res.data.data.links);
          setNextUrl(res?.data?.data?.next_page_url);
          setCurrentPage(res?.data?.data?.current_page);
          setPrevpageurl(res?.data?.data?.prev_page_url);
        }
      });
    }
  };

  const fileter = (data) => {
    // if(sortBy){
    //     data.append("sort_by",sortBy);
    // }

    if (Url === null) {
      Getproperties(data).then((res) => {
        setFilterLoading(false);
        if (res.data.status === "success") {
          window.scrollTo(0, 0);
          setPropertyData(res.data.data.data);
          setAmenities(res.data.data.data.get_selected_amenities);
          setExternalFeatures(
            res.data.data.data.get_selected_external_features
          );
          setPagination(res.data.data.links);
          setNextUrl(res?.data?.data?.next_page_url);
          setCurrentPage(res?.data?.data?.current_page);
          setPrevpageurl(res?.data?.data?.prev_page_url);
        }
      });
    } else {
      GetpropertiesByPage(Url, data).then((res) => {
        setFilterLoading(false);
        if (res.data.status === "success") {
          window.scrollTo(0, 0);
          setPropertyData(res.data.data.data);
          setAmenities(res.data.data.data.get_selected_amenities);
          setExternalFeatures(
            res.data.data.data.get_selected_external_features
          );
          setPagination(res.data.data.links);
          setNextUrl(res?.data?.data?.next_page_url);
          setCurrentPage(res?.data?.data?.current_page);
          setPrevpageurl(res?.data?.data?.prev_page_url);
        }
      });
    }
  };

  useEffect(() => {
    if (sortBy !== null) {
      handleSort();
    }
  }, [sortBy]);

  const handleSort = () => {
    setFilterLoading(true);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (garages) {
      formData.append("garages_quantity", garages);
    }
    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }
    fileter(formData);
  };
  const ChangeTypeOfProperty = (TypeOfProperty) => {
    setFilterLoading(true);
    setTypeOfProperty(TypeOfProperty);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (garages) {
      formData.append("garages_quantity", garages);
    }
    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const ChangeMinPrice = (Price) => {
    setFilterLoading(true);
    setMinPrice(Price);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (Price) {
      formData.append("min_price", Price);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const ChangeMaxPrice = (price) => {
    setFilterLoading(true);
    setMaxPrice(price);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (price) {
      formData.append("max_price", price);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };
  const ChangeBedrooms = (bedroom) => {
    setFilterLoading(true);
    setBedrooms(bedroom);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (minPrice) {
      formData.append("min_price", minPrice);
    }

    if (bedroom) {
      formData.append("bedroom_quantity", bedroom);
    }

    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }
    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };
  const ChangeParking = (parking) => {
    setFilterLoading(true);
    setParking(parking);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (minPrice) {
      formData.append("min_price", minPrice);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const ChangeBathrooms = (bathrooms) => {
    setFilterLoading(true);
    setBathrooms(bathrooms);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (minPrice) {
      formData.append("min_price", minPrice);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };
  const ChangeGarages = (garages) => {
    setFilterLoading(true);
    setGarages(garages);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const ChangeAmenitiesPool = (pool) => {
    setFilterLoading(true);
    setPool(pool);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const ChangeAmenitiesPet = (petFriendly) => {
    setFilterLoading(true);
    setPetFriendly(petFriendly);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }
    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };
  const ChangeAmenitiesFlatlet = (flatlet) => {
    setFilterLoading(true);
    setFlatlet(flatlet);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }

    if (sortBy) {
      formData.append("sort_by", sortBy);
    }
    fileter(formData);
  };

  const ChangeAmenitiesGarden = (garden) => {
    setFilterLoading(true);
    setGarden(garden);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const Changeotherretirement = (retirement) => {
    setFilterLoading(true);
    setRetirement(retirement);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const ChangeotheronShow = (onShow) => {
    setFilterLoading(true);
    setOnShow(onShow);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }

    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const Changeotherauction = (auction) => {
    setFilterLoading(true);
    setAuction(auction);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }

    if (sortBy) {
      formData.append("sort_by", sortBy);
    }
    fileter(formData);
  };

  const Changeotherrepossessed = (repossessed) => {
    setFilterLoading(true);
    setRepossessed(repossessed);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }

    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const ChangeothersecurityEstate = (securityEstate) => {
    setFilterLoading(true);
    setSecurityEstate(securityEstate);
    const formData = new FormData();
    formData.append("lattitude", lattitude);
    formData.append("longitude", longitude);
    formData.append("listing_type", listing_type);
    if (pool) {
      formData.append("is_pool", pool);
    }
    if (petFriendly) {
      formData.append("is_pet_friendly", petFriendly);
    }
    if (flatlet) {
      formData.append("is_flatlet", flatlet);
    }
    if (garden) {
      formData.append("is_garden", garden);
    }

    if (minPrice) {
      formData.append("min_price", minPrice);
    }
    if (garages) {
      formData.append("garages_quantity", garages);
    }

    if (Bedrooms) {
      formData.append("bedroom_quantity", Bedrooms);
    }
    if (bathrooms) {
      formData.append("bathroom_quantity", bathrooms);
    }
    if (parking) {
      formData.append("parking_quantity", parking);
    }

    if (TypeOfProperty) {
      formData.append("type_of_property", TypeOfProperty);
    }

    if (maxPrice) {
      formData.append("max_price", maxPrice);
    }
    if (retirement) {
      formData.append("is_retirement", retirement);
    }
    if (onShow) {
      formData.append("is_on_show", onShow);
    }
    if (auction) {
      formData.append("is_auction", auction);
    }
    if (repossessed) {
      formData.append("is_repossessed", repossessed);
    }
    if (securityEstate) {
      formData.append("is_security_estate", securityEstate);
    }
    if (sortBy) {
      formData.append("sort_by", sortBy);
    }

    fileter(formData);
  };

  const handlePage = (label, url) => {
    setPageLoading(true);
    let data = {
      lattitude: lattitude,
      longitude: longitude,
      listing_type: listing_type,
      min_price: minPrice ? minPrice : 0,
      type_of_property: TypeOfProperty ? TypeOfProperty : 0,
      bedroom_quantity: Bedrooms ? Bedrooms : 0,
      max_price: maxPrice ? maxPrice : 0,
      propertyStreetAddress: searchRequestData?.propertyStreetAddress,
      sort_by: sortBy,
    };
    if (label === "&laquo; Previous") {
      getSearchReasultByPagination(prevPageUrl);
      let page = current_page - 1;
      history.push("/search-result/page-" + page, { url: url, data: data });
    } else if (label === "Next &raquo;") {
      getSearchReasultByPagination(NextUrl);
      let page = parseInt(current_page) + 1;
      history.push("/search-result/page-" + page, { url: url, data: data });
    } else {
      getSearchReasultByPagination(url);
      history.push("/search-result/page-" + label, { url: url, data: data });
    }
  };

  const propertyDetail = (slug) => {
    window.open(
      window.location.origin + "/property-detail" + "/" + slug,
      "_blank"
    );
    // history.push('/property-detail/' + slug, { slug: slug });
  };

  // if(props.match===null){
  //     return <></>
  // }
  return (
    <>
      <Navbar />

      {PageLoading ? (
        <div className="main">
          <center>
            <span
              className="spinner-grow spinner-grow-sm "
              style={{ marginTop: "200px" }}
            ></span>
          </center>
        </div>
      ) : (
        <div className="main bg-white">
          <div className="container pb-1 ">
            <div className="mobile-filter-icon d-md-none">
              <a
                className="filter-mobile-ic"
                onClick={() => {
                  toggleFilter();
                }}
              >
                <img src="../assets/image/search-refine.svg" alt="" class="" />
              </a>
            </div>
            <div
              className="d-flex flex-column flex-md-row justify-content-between align-items-center"
              // className='d-flex justify-content-between align-items-center'
            >
              <div className="col-12 col-md-8 d-flex flex-column py-5">
                <h1 className="col-12 text-primary semibold cal-title mb-1 mb-md-0">
                  Property for{" "}
                  {searchRequestData?.listing_type === "sell"
                    ? "Sale"
                    : searchRequestData?.listing_type}{" "}
                  around {searchRequestData?.propertyStreetAddress}
                </h1>
                <div
                  className="col-12 fs-15"
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  Displaying properties in{" "}
                  {searchRequestData?.propertyStreetAddress} and nearby
                  neighbourhoods up to 6km away.
                </div>
              </div>

              <div className="col-12 col-md-4 col-lg-3">
                <div className="pb-3">
                  <h5 className="medium pb-1 h5">Sort By</h5>
                  <select
                    className="border border-dark"
                    style={{ borderRadius: 4, width: 100 + "%", padding: 10 }}
                    value={sortBy ? sortBy : null}
                    onChange={(e) => {
                      console.log("on_change_sort_by", e.target.value);
                      localStorage.setItem("sortBy", e.target.value);
                      setSortBy(e.target.value);
                    }}
                  >
                    {SortByOptions.map((item, i) => {
                      return (
                        <option
                          selected={sortBy === item.id ? true : false}
                          value={item.id}
                        >
                          {item.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/* <h1 className="text-primary semibold cal-title py-5">Property for {searchRequestData?.listing_type === "sell" ? 'Sale' : searchRequestData?.listing_type} around {searchRequestData?.propertyStreetAddress}</h1> */}
            <div className="row pt-md-4 pb-md-5">
              <div className="col-lg-3 filter-sidebar left-sidebar pb-5 pb-md-0 pe-md-4 ">
                {/* // className="col-lg-3 left-sidebar pb-1 pb-md-0 pe-md-4 "> */}
                <h2 class="text-primary bold pb-4 d-flex align-items-center justify-content-between">
                  Filter
                  <a
                    onClick={() => {
                      toggleFilter();
                    }}
                    class="d-md-none text-white filter-close"
                  >
                    <i class="fal fa-close"></i>
                  </a>
                </h2>
                <div className="filter-item row">
                  <div className="pb-2 col-md-12 col-6">
                    <h5 className="medium h5">Property Type</h5>
                    <select
                      onChange={(e) => ChangeTypeOfProperty(e.target.value)}
                    >
                      <option value="">Any </option>

                      {masterTypeOfProperty.map((item, i) => {
                        return (
                          <option
                            value={item.id}
                            selected={TypeOfProperty === item.id ? true : false}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {searchRequestData?.listing_type === "sell" ? (
                    <div className="pb-2 col-md-12 col-6">
                      <h5 className="medium h5">Min Price</h5>
                      <select onChange={(e) => ChangeMinPrice(e.target.value)}>
                        <option value="">Any</option>
                        {SalePricingOption.map((item, i) => {
                          return (
                            <option
                              value={item.value}
                              selected={minPrice === item.value ? true : false}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className="pb-2 col-md-12 col-6">
                      <h5 className="medium h5">Min Price</h5>
                      <select onChange={(e) => ChangeMinPrice(e.target.value)}>
                        <option value="">Any</option>
                        {RentPricingOption.map((item, i) => {
                          return (
                            <option
                              value={item.value}
                              selected={minPrice === item.value ? true : false}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  {searchRequestData?.listing_type === "sell" ? (
                    <div className="pb-2 col-md-12 col-6">
                      <h5 className="medium h5">Max Price</h5>
                      <select onChange={(e) => ChangeMaxPrice(e.target.value)}>
                        <option value="">Any</option>
                        {SalePricingOption.map((item, i) => {
                          return (
                            <option
                              value={item.value}
                              selected={maxPrice === item.value ? true : false}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className="pb-2 col-md-12 col-6">
                      <h5 className="medium h5">Max Price</h5>
                      <select onChange={(e) => ChangeMaxPrice(e.target.value)}>
                        <option value="">Any</option>
                        {RentPricingOption.map((item, i) => {
                          return (
                            <option
                              value={item.value}
                              selected={maxPrice === item.value ? true : false}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="pb-2 col-md-12 col-6">
                    <h5 className="medium h5">Bedrooms</h5>
                    <select onChange={(e) => ChangeBedrooms(e.target.value)}>
                      <option value="">Any</option>
                      {BedRoomOption.map((item, i) => {
                        return (
                          <option
                            value={item.value}
                            selected={Bedrooms === item.value ? true : false}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="pb-2 col-md-12 col-6">
                    <h5 className="medium h5">Bathrooms</h5>
                    <select onChange={(e) => ChangeBathrooms(e.target.value)}>
                      <option value="">Any</option>
                      {BedRoomOption.map((item, i) => {
                        return (
                          <option
                            value={item.value}
                            selected={Bedrooms === item.value ? true : false}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="pb-2 col-md-12 col-6">
                    <h5 className="medium h5">Garages</h5>
                    <select onChange={(e) => ChangeGarages(e.target.value)}>
                      <option value="">Any</option>
                      {BedRoomOption.map((item, i) => {
                        return (
                          <option
                            value={item.value}
                            selected={Bedrooms === item.value ? true : false}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="pb-2 col-md-12 col-6">
                    <h5 className="medium h5">Parking</h5>
                    <select onChange={(e) => ChangeParking(e.target.value)}>
                      <option value="">Any</option>
                      {BedRoomOption.map((item, i) => {
                        return (
                          <option
                            value={item.value}
                            selected={Bedrooms === item.value ? true : false}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="pb-2 col-md-12 col-6">
                    <h5 className="medium h5">Amenities</h5>
                    {pool === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name="pool"
                          checked
                          onClick={(e) => ChangeAmenitiesPool("")}
                        />{" "}
                        Pool
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name="pool"
                          onClick={(e) => ChangeAmenitiesPool(1)}
                        />{" "}
                        Pool
                      </>
                    )}
                    <br />
                    {petFriendly === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name="pet"
                          checked
                          onClick={(e) => ChangeAmenitiesPet("")}
                        />{" "}
                        Pet Friendly
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name="pet"
                          onClick={(e) => ChangeAmenitiesPet(1)}
                        />{" "}
                        Pet Friendly
                      </>
                    )}
                    <br />
                    {flatlet === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name="flatlet"
                          checked
                          onClick={(e) => ChangeAmenitiesFlatlet("")}
                        />{" "}
                        Flatlet
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name="flatlet"
                          onClick={(e) => ChangeAmenitiesFlatlet(1)}
                        />{" "}
                        Flatlet
                      </>
                    )}
                    <br />
                    {garden === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name="garden"
                          checked
                          onClick={(e) => ChangeAmenitiesGarden("")}
                        />{" "}
                        Garden
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name="garden"
                          onClick={(e) => ChangeAmenitiesGarden(1)}
                        />{" "}
                        Garden
                      </>
                    )}
                  </div>
                  <div className="pb-2 col-md-12 col-6">
                    <h5 className="medium h5">Others</h5>
                    {retirement === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          checked
                          onClick={() => Changeotherretirement("")}
                        />{" "}
                        Retirement
                        <br />
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          onClick={() => Changeotherretirement(1)}
                        />{" "}
                        Retirement
                        <br />
                      </>
                    )}

                    {onShow === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          checked
                          onClick={() => ChangeotheronShow("")}
                        />{" "}
                        On Show
                        <br />
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          onClick={() => ChangeotheronShow(1)}
                        />{" "}
                        On Show
                        <br />
                      </>
                    )}
                    {auction === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          checked
                          onClick={() => Changeotherauction("")}
                        />{" "}
                        Auction
                        <br />
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          onClick={() => Changeotherauction(1)}
                        />{" "}
                        Auction
                        <br />
                      </>
                    )}
                    {repossessed === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          checked
                          onClick={() => Changeotherrepossessed("")}
                        />{" "}
                        Repossessed
                        <br />
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          onClick={() => Changeotherrepossessed(1)}
                        />{" "}
                        Repossessed
                        <br />
                      </>
                    )}
                    {securityEstate === 1 ? (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          checked
                          onClick={() => ChangeothersecurityEstate("")}
                        />{" "}
                        Security Estate
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name=""
                          onClick={() => ChangeothersecurityEstate(1)}
                        />{" "}
                        Security Estate
                      </>
                    )}
                  </div>

                  {Token !== null ? (
                    <Link
                      to="#"
                      className="custom-btn w-100 text-center"
                      data-bs-toggle="modal"
                      data-bs-target="#property_popup"
                    >
                      Subscribe to property alerts
                    </Link>
                  ) : (
                    ""
                  )}

                  <div
                    className="modal fade"
                    id="property_popup"
                    aria-labelledby="property_popup"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog  modal-dialog-centered">
                      <div className="modal-content">
                        <SubscribeModel />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {filterLoading ? (
                <div className="col-lg-9 listing-right-side ps-md-4 border-start">
                  <center>
                    <span
                      className="spinner-grow spinner-grow-sm "
                      style={{ marginTop: "200px" }}
                    ></span>
                  </center>
                </div>
              ) : PropertyData.length > 0 ? (
                <div className="col-lg-9 listing-right-side ps-md-4 border-start">
                  <div>
                    {PropertyData.map((item, i) => {
                      let other_items = [
                        {
                          label: "Retirement",
                          value: item.retirement,
                        },
                        {
                          label: "On Show",
                          value: item.on_show,
                        },
                        {
                          label: "Auction",
                          value: item.auction,
                        },
                        {
                          label: "Repossessed",
                          value: item.repossessed,
                        },
                        {
                          label: "Security Estate",
                          value: item.security_estate,
                        },
                      ];
                      return (
                        <div className="listing-item mb-4 d-md-flex mb-5">
                          <div className="text-center py-md-0 py-3 listing-thumb col-md-4">
                            {item?.diff_between_properties === "" ||
                            item?.diff_between_properties === "undefined" ? (
                              ""
                            ) : (
                              <span>{item?.diff_between_properties}</span>
                            )}

                            {item?.property_images.length > 0 ? (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="other-items"
                                  onClick={() => propertyDetail(item.slug)}
                                  // style={{
                                  //     position : 'relative',
                                  //     bottom : -300,
                                  //     backgroundColor :'rgba(230,230,230,0.9)',
                                  //     borderBottomLeftRadius : 25,
                                  //     borderBottomRightRadius : 25,
                                  //     width : 340,
                                  //     marginLeft : 'auto',
                                  //     marginRight : 'auto'
                                  // }}
                                >
                                  <div
                                    className="d-flex flex-wrap-reverse justify-content-between"
                                    style={{ position: "absolute", bottom: 20 }}
                                  >
                                    {other_items
                                      .filter((ite) => ite.value)
                                      .map((ite, ind) => {
                                        return (
                                          <div
                                            className="px-2 my-1 text-light h6 text-uppercase bg-dark"
                                            key={ind}
                                          >
                                            {ite.label}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                <img
                                  src={item?.property_images[0]?.user_image}
                                  className="left-image"
                                  alt=""
                                  onClick={() => propertyDetail(item.slug)}
                                />
                              </div>
                            ) : (
                              <img
                                src="../../assets/image/listing-img3.png"
                                className="left-image"
                                alt=""
                                onClick={() => propertyDetail(item.slug)}
                              />
                            )}
                          </div>
                          <div className="listing-content p-4 px-5 col-md-6">
                            <h4
                              className="regular"
                              onClick={() => propertyDetail(item.slug)}
                            >
                              {item?.property_name}
                              {/* {item?.is_display_address === 1 ?
                                                            <>
                                                            in <span className="bold"> {item?.street_address}</span>
                                                            </>
                                                            :""} */}
                            </h4>
                            {item?.is_display_poa === 1 ? (
                              <h2 className="h1 text-primary py-3 semibold m-0">
                                Price on Application (P.O.A)
                              </h2>
                            ) : (
                              <h2 className="h1 text-primary py-3 semibold m-0">
                                {formatter.format(item?.property_price)}
                              </h2>
                            )}
                            {item?.is_display_address === 1 ? (
                              <h5 className="regular pb-2">
                                <i className="fa fa-map-marker-alt pe-2 text-parrot"></i>{" "}
                                {item?.street_address}
                              </h5>
                            ) : (
                              ""
                            )}
                            <div className="d-flex py-4">
                              {item?.get_selected_amenities.map(
                                (item, index) => {
                                  return item?.master_property?.name ===
                                    "Number of Bedrooms" ? (
                                    <div
                                      className="d-flex align-items-center pe-5"
                                      key={index}
                                    >
                                      <img
                                        src="../../assets/image/listing-icon-1.png"
                                        alt=""
                                      />
                                      <h4 className="bold fs-19 px-2">
                                        {item?.data}
                                      </h4>
                                    </div>
                                  ) : (
                                    ""
                                  );
                                }
                              )}

                              {item?.get_selected_amenities.map((item) => {
                                return item?.master_property?.name ===
                                  "Number of Bathrooms" ? (
                                  <div className="d-flex align-items-center pe-5">
                                    <img
                                      src="../../assets/image/listing-icon-2.png"
                                      alt=""
                                    />
                                    <h4 className="bold fs-19 px-2">
                                      {item?.data}
                                    </h4>
                                  </div>
                                ) : (
                                  ""
                                );
                              })}

                              <div className="d-flex align-items-center pe-5">
                                <img
                                  src="../../assets/image/listing-icon-3.png"
                                  alt=""
                                />
                                <div className="d-flex">
                                  <h4 className="bold fs-19 px-2">
                                    {item?.floor_size}m<sup>2</sup>
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <h5 className="regular m-0">
                              {/* {item?.get_selected_external_features.map((item, index) => {
                                                                    return (
                                                                        item?.data > 0 ?
                                                                            <span key={index}> {index > 0 ? ',' : ""} {item?.master_property?.name}</span>
                                                                            : ""
                                                                    );

                                                                })} */}

                              {item?.get_selected_external_features
                                .filter((item) => item.data != "0")
                                .map((item, index) => {
                                  return item.master_property.name;
                                })
                                .join(", ")}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {pagination.length > 0 ? (
                    <div className="pagination my-4">
                      {pagination.map((item, i) => {
                        return item.label === "&laquo; Previous" ? (
                          prevPageUrl === null ? (
                            // <Link className="disable page-next" >
                            //     <span dangerouslySetInnerHTML={{ __html: item.label }} />
                            // </Link>
                            ""
                          ) : (
                            <Link
                              onClick={(e) => handlePage(item.label, item.url)}
                              className="enable page-next"
                            >
                              <span
                                dangerouslySetInnerHTML={{ __html: item.label }}
                              />
                            </Link>
                          )
                        ) : item.label === "Next &raquo;" ? (
                          NextUrl === null ? (
                            // <Link className="disable page-next" >
                            //     <span dangerouslySetInnerHTML={{ __html: item.label }} />
                            // </Link>
                            ""
                          ) : (
                            <Link
                              onClick={(e) => handlePage(item.label, item.url)}
                              className="enable page-next"
                            >
                              <span
                                dangerouslySetInnerHTML={{ __html: item.label }}
                              />
                            </Link>
                          )
                        ) : (
                          <Link
                            onClick={(e) => handlePage(item.label, item.url)}
                            className={
                              item.active === true
                                ? "current"
                                : "enable page-next"
                            }
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: item.label }}
                            />
                          </Link>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="col-lg-9 listing-right-side ps-md-4 border-start">
                  <span>
                    <center> No property listed yet in this area</center>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
