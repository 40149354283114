import React, { useState } from 'react';
import Footer from '../layouts/Footer';
import Navbar from '../layouts/Navbar';
import { referFriend } from '../API/AuthApi';
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export default function ReferFriend() {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [loading, setLoading] = useState(false)
    const Token = localStorage.getItem("token");
    const history = useHistory();
    if(Token === null){
        history.push("login");
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if(!email){
            setEmailError("Please enter email address");
            setLoading(false);
        }
       
        if(email){
        let data = {
            email:email
        }
        referFriend(data, Token).then((res) =>{
            if(res.data.status === "success"){
            setLoading(false);
            setEmail('');
            swal("Success", "Email sent successfully", "success");
            }else{
                setLoading(false);
                swal("Error", "Something went wrong", "error"); 
            }
        });
        }
    };

    return (
        <>
            <Navbar />
            <div className="main">
                <div className="container pb-5 pt-3">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="text-center">
                                <img src="assets/image/refer-friend.png" alt="" />
                            </div>
                            <h4 className="h4 bold">Refer A Friend </h4>
                            <form action="" className="refer-form" onSubmit={handleSubmit}>
                                <div className="bg-white p-3 border rounded">
                                    <label className="d-block bold fs-15">Friend's Email Address</label>
                                    <input
                                        type="email"
                                        className="w-100"
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        required />
                                    <p style={{ color: "red", margintop: "-10px" }}>
                                        {emailError}
                                    </p>
                                </div>
                                <div className="text-center py-3">
                                    {loading ? 
                                    <button type="submit" className="btn-md" disabled>
                                        <span className='spinner-grow spinner-grow-sm'>

                                        </span>
                                    </button>
                                    :
                                    <button type="submit" className="btn-md" disabled={ loading ? true : false}>refer</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}
