import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, THead, TH, TR, TD, TBody } from "../../table/index";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { GetAllApprover, DeactiveApprover } from '../../API/ApproverApi'
import AgencyNavbar from '../../layouts/AgencyNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import { changeRole } from '../../API/AuthApi'
const $ = window.jQuery;


export class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allApproverList: [],
      isActive: "",
      changeRoleUserId:"",
      changeRoleId:"",
      changeRoleError:"",
      loading: false,
      buttonLoading: false,
    };
  }


  componentDidMount() {
    // $("#datatable").dataTable();
    this.getApproverList();
  }

  getApproverList = () => {
    this.setState({
      loading: true,
    });
    const token = localStorage.getItem("token");
    GetAllApprover(token)
      .then((resp) => {
        this.setState({
          allApproverList: resp.data.data,
          loading: false,
        });
        $("#datatable").dataTable();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onEditApprover = (id) => {
    this.props.history.push("edit-approver", { data: id });
  };
  information = () =>{
    swal("","In this section, you can add, remove and edit approvers. Once a listing is added, it will go to the approver. An approver is the moderator for the agency. This is the person that will review the listing and approve before the listing goes live. If no approver is added, the listing will go live once published by the agent.", "info");  
   
  };

  onChangeRole = (id, name)=>{
    swal({
      text: `Are you sure want to change role of ${name}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {

        this.setState({
          changeRoleUserId: id,
        });
        $('#myModalrep').modal('show');
       
      }
    });
  };
  SubmitRoleChange = () => {
    if(!this.state.changeRoleId){
      this.setState({
        changeRoleError: "Please select the role",
      });
      return false;
    }else{
      this.setState({
        changeRoleError: "",
      });
    }
    
    this.setState({
      buttonLoading: true,
    });
    const token = localStorage.getItem("token");
      let data = {
          user_id:this.state.changeRoleUserId, 
          change_role: this.state.changeRoleId
        }
        changeRole(data, token).then((res)=>{
          if(res.data.status === "success"){
            $('#myModalrep').modal('hide');
            this.getApproverList();
            this.setState({
              buttonLoading: false,
            });
            swal("success", res.data.message, "success");
           
          }
        });
  };
  onDeleteApprover = (id, name, e) => {

    if (e.target.value === '0') {
      swal({
        text: `Are you sure want to deactivate the ${name}?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const token = localStorage.getItem("token");
          let data = {
            id: id,
            is_active: 0,
          }
          DeactiveApprover(token, data).then((res) => {
            console.log(res);
            if (res.data.type === "success") {
              this.getApproverList();
              swal("Success", "Approver deactivated succesfully.", "success");

            }
          });

        }



      });
    } else {
      swal({
        text: `Are you sure want to activate the ${name}?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {

          const token = localStorage.getItem("token");
          let data = {
            id: id,
            is_active: 1,
          }
          DeactiveApprover(token, data).then((res) => {
            if (res.data.type === "success") {
              this.getApproverList();
              swal("Success", "Approver activated succesfully.", "success");

            }
          });

        }



      });
    }
  };
  
  handleChangeRole = (e) => {
    if(e.target.value === "admin"){
      this.setState({
        changeRoleId: 6,
      });
    }
    if(e.target.value === "agent"){
      this.setState({
        changeRoleId: 3,
      });
    }
    
  };


  render() {
    return (
      <>

        <Navbar />

        {this.state.loading ?
          <div className="main py-5">
            <center>
              <span className='spinner-grow spinner-grow-sm ' style={{ marginTop: "200px" }}>

              </span>
            </center>
          </div>
          :
          <div className="main py-5">
            <div className="container ">

              <div className="row pb-md-5">

                <AgencyNavbar />

                <div className="col-md-9 ps-md-5 border-start admin-right-part">

                  <div className="d-flex align-items-center justify-content-between pb-4">
                    <h2 className="text-primary semibold h1 pb-3"> Approver
                    <small 
                    style={{fontSize:"20px", marginLeft:"35px", color:"black"}} 
                   
                    onClick={this.information}
                    ><i class="fa fa-info-circle" aria-hidden="true"></i></small>
                   
                    </h2>
                    <Link to="/add-approver" className="custom-btn">Add New Approver</Link>
                  </div>

                  <div className="datatable">
                    <Table className="w-100" id="datatable">
                      <THead>
                        <TR>
                          <TH>S.No.</TH>
                          <TH>Image</TH>
                          <TH>Name</TH>
                          <TH>Email</TH>
                          <TH>Role</TH>
                          <TH>Edit</TH>
                          <TH>status</TH>
                        </TR>
                      </THead>
                      <TBody>
                        {this.state?.allApproverList.map((item, index) => {
                          return (
                            <TR key={item.id}>
                              <TD className="h5 semibold"><h5 className="h5 semibold" />{index+1}</TD>
                              <TD>
                              {item.image === null ?
                                <img src="assets/image/dummy_image.png" alt="" />
                              :
                                <img src={item.image} alt="" />
                              }

                              </TD>
                              <TD className="h5 semibold"><h5 className="h5 semibold">{item.user.name} {item.user.surname} </h5></TD>
                              <TD><h6 className="fs-15 text-primary">{item.user.email}</h6></TD>
                              <TD className="table-action">
                                <Link className="px-2" onClick={() => this.onChangeRole(item.user_id, item.user.name)}>
                                  <i className="fa fa-edit"></i>
                                </Link>
                              </TD>
                              <TD className="table-action">
                                <Link className="px-2" onClick={() => this.onEditApprover(item.id)}>
                                  <i className="fa fa-edit"></i>
                                  </Link>
                              </TD>
                              <TD>
                                <div className="form-check form-switch">
                                  {item.is_active === 1 ?
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value="0" checked autocompleted="" onChange={(e) => this.onDeleteApprover(item.id, item.user.name, e)} />
                                    :

                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value="1" autocompleted="" onChange={(e) => this.onDeleteApprover(item.id, item.user.name, e)} />
                                  }
                                </div>
                              </TD>
                            </TR>
                          );
                        })}
                      </TBody>
                    </Table>

                  </div>


                </div>
              </div>

            </div>
          </div>
        }
        <Footer />
        <div class="modal" id="myModalrep">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Change the role</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/* <form class="report-save"> */}

                        <div class="modal-body">

                            <label>
                                Select Role
                            </label>
                            <select class="form-control"
                                onChange={(e) => this.handleChangeRole(e)}
                                name="role" >
                                <option value="Please select reason" >Please select role</option>
                                <option value="admin">Admin</option>
                                <option value="agent">Agent</option>
                            </select>
                            <p style={{ color: "red", margintop: "-10px" }}>
                                {this.state.changeRoleError}
                            </p>

                        </div>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            {this.state.buttonLoading ?
                                <button type="submit" class="btn btn-success" disabled>
                                    <span className='spinner-grow spinner-grow-sm'>

                                    </span>
                                </button>

                                :
                                <button type="submit" class="btn btn-success" disabled={this.state.buttonLoading ? true : false} onClick={this.SubmitRoleChange} >Submit </button>
                            }
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
      </>
    );
  }

}

export default withRouter(Index);