import React from "react";

const TableCell = ({
  className,
  id,
  children,
  ...props
}) => {
    return (
        <td
            id={id}
            className={className}
            {...props}
        >
        {children}
        </td>
    );
};

export default TableCell;